import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import FacebookProvider, { Login } from 'react-facebook';
import { GoogleLogin } from 'react-google-login';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import KnktLogo from '../components/common/KnktLogo';

import {
  loginUserAction,
  loginFacebookAction,
  loginFailedAction,
  loginTwitterAction,
  loginGoogleAction,
  loginInstagramAction,
  clearLoginErrorMessage,
  updateField,
  updateErrorField,
  clearConfirmationMessage,
} from '../actions/Login/loginAction';

import {
  getFBAppId,
  getFBLoginEnabled,
  getGoogleClientId,
  getGoogleLoginEnabled,
} from '../config/siteConfig';

const styles = {
  loginLeft: {
    backgroundColor: '#1a1d24',
    height: '100vh',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loginRight: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alginItems: 'center',
    color: '#ffffff',
  },
  loginRightBackground: {
    background: 'url(/img/background/loginBgObject.png) no-repeat',
    backgroundSize: '60%',
    backgroundPosition: 'center center',
    transform: 'rotate(-45deg)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  loginRightContent: {
    width: '100%',
    zIndex: 100,
  },
  logo: {
    marginTop: 30,
  },
  signinText: {
    fontSize: '1.5em',
    marginTop: 50,
    fontWeight: 'bold',
  },
  formInputColumn: {
    marginTop: 10,
  },
  formInput: {
    backgroundColor: 'transparent',
    color: '#ffffff',
  },
  forgotPassword: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  forgotPasswordText: {
    color: '#ffffff',
  },
  loginButton: {
    width: '100%',
  },
  loginRightTexts: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: '1.5em',
  },
  loginMessage: {
    color: '#ff0000'
  },
  createButton: {
    width: '50%'
  }
};

class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideRight: false,
      extraLarge: false,
      username: null,
      password: null,
    };

    this.usernameInput = React.createRef();

    this.handleUpdateField = this.handleUpdateField.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.getFacebookLoginButton =
      this.getFacebookLoginButton.bind(this);
    this.getGoogleLoginButton = this.getGoogleLoginButton.bind(this);
    this.onGoogleResponse = this.onGoogleResponse.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();

    this.usernameInput.current.focus();
  }

  resize() {
    this.setState({ hideRight: window.innerWidth <= 768 });
    this.setState({ extraLarge: window.innerWidth >= 1200 });
  }

  handleUpdateField(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.props.clearConfirmationMessage();
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state;
    if (username && password) {
      let formIsGood = username.length > 0 && password.length > 0;

      if (formIsGood) {
        this.props.loginUserAction(username, password);
      }
    }
  }

  getFacebookLoginButton() {
    const scope = `
      email,
      ads_management, 
      business_management,
      pages_manage_cta,
      pages_show_list,
      instagram_basic,
      instagram_manage_comments,
      read_insights, 
      instagram_manage_insights,
      instagram_basic,
      pages_read_engagement,
      pages_manage_metadata,
      pages_read_user_content,
      pages_manage_ads,
      pages_manage_posts,
      pages_manage_engagement,
      public_profile,
      ads_read,
      page_events
    `;

    if (getFBLoginEnabled()) {
      return (
        <React.Fragment>
          <FacebookProvider appId={getFBAppId()}>
            <Login
              scope={scope}
              onResponse={this.handleResponse}
              onError={this.handleError}
            >
              <Button
                type="submit"
                variant="light"
                style={styles.loginButton}
              >
                <i
                  className="fab fa-facebook-f"
                  style={styles.icon}
                ></i>
              </Button>
            </Login>
          </FacebookProvider>
        </React.Fragment>
      );
    }
    return null;
  }

  handleResponse = (data) => {
    this.props.loginFacebookAction(data);
  };

  handleError = (error) => {
    this.props.loginFailedAction("Can't login at this time.");
  };

  getGoogleLoginButton() {
    if (getGoogleLoginEnabled()) {
      return (
        <GoogleLogin
          clientId={getGoogleClientId()}
          buttonText="Login"
          onSuccess={this.onGoogleResponse}
          onFailure={this.onGoogleResponse}
          // cookiePolicy={'single_host_origin'}
          className="google-login"
          style={styles.googleLoginButton}
          responseType="id_token"
          accessType="online"
          prompt="consent"
          scope="profile email"
          render={(renderProps) => (
            <Button
              type="button"
              variant="light"
              style={styles.loginButton}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <i className="fab fa-google" style={styles.icon}></i>
            </Button>
          )}
        />
      );
    }
    return null;
  }

  onGoogleResponse(response) {
    let tokenId = response.tokenId;
    if (tokenId) {
      console.log('---accessToken:', tokenId);
      this.props.loginGoogleAction({ tokenId });
    }
  }

  render() {
    const { hideRight, extraLarge, username, password } = this.state;

    return (
      <Row nogutters="true">
        <Col xs={12} md={5} style={styles.loginLeft}>
          <Row>
            <Col xs={{ span: 2 }}>
              <KnktLogo
                imgStyle={{ width: 80 }}
                divStyle={{ marginLeft: 50 }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 8, offset: 2 }}>
              <Row>
                <Col xs={12}>
                  <div style={styles.signinText}>Sign In</div>
                </Col>
                <Col xs={12}>
                  <Form
                    noValidate
                    validated={this.state.validated}
                    onSubmit={this.handleFormSubmit}
                  >
                    <Row>
                      <Col xs={12} style={styles.formInputColumn}>
                        <FloatingLabel
                          label="Username"
                        >
                          <Form.Control
                            placeholder="Username"
                            id="username"
                            type="text"
                            style={styles.formInput}
                            onChange={this.handleUpdateField}
                            required
                            isInvalid={
                              username !== null && username.length < 1
                            }
                            ref={this.usernameInput}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter your username
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col xs={12} style={styles.formInputColumn}>
                        <FloatingLabel
                          label="Password"
                        >
                          <Form.Control
                            placeholder="Password"
                            id="password"
                            style={styles.formInput}
                            type="password"
                            onChange={this.handleUpdateField}
                            isInvalid={
                              password !== null && password.length < 1
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter your password
                          </Form.Control.Feedback>
                        </FloatingLabel>
                      </Col>
                      <Col xs={6} style={styles.formInputColumn}>
                        <div style={styles.forgotPassword}>
                          <NavLink
                            to="/forgotPassword"
                            style={styles.forgotPasswordText}
                          >
                            Forgot password?
                          </NavLink>
                        </div>
                      </Col>
                      <Col xs={6} style={styles.formInputColumn}>
                        <Button
                          type="submit"
                          variant="light"
                          style={styles.loginButton}
                          onClick={this.handleFormSubmit}
                          disabled={
                            username === null ||
                            username.length < 1 ||
                            password === null ||
                            password.length < 1
                          }
                        >
                          Login
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} style={styles.loginMessage}>
                        {this.props.user.confirmation}
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xs={12}>
                  <Row style={{ margin: '20px 0' }}>
                    <Col
                      style={{
                        borderBottom: '1px solid white',
                        marginBottom: 8,
                      }}
                    />
                    <Col xs="auto">or</Col>
                    <Col
                      style={{
                        borderBottom: '1px solid white',
                        marginBottom: 8,
                      }}
                    />
                  </Row>
                </Col>
                <Col xs={6}>{this.getFacebookLoginButton()}</Col>
                <Col xs={6}>{this.getGoogleLoginButton()}</Col>
                <Col
                  style={{
                    borderBottom: '1px solid white',
                    marginTop: 20,
                    marginBottom: 20
                  }}
                  xs={12}
                />

                <Col xs={12} style={{...styles.formInputColumn, textAlign: 'center'}}>
                  <a href='/signup'>
                    <Button
                      type="submit"
                      variant="light"
                      style={styles.createButton}
                    >
                      Create Account
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        {!hideRight && (
          <Col md={7} style={styles.loginRight}>
            <div style={styles.loginRightContent}>
              <Row nogutters="true">
                <Col md={12} xl={6}>
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10.0em',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    KNKT
                  </div>
                </Col>
                <Col md={12} xl={6} style={styles.loginRightTexts}>
                  <div
                    style={{
                      textAlign: extraLarge ? 'left' : 'center',
                    }}
                  >
                    <div>Every Fan.</div>
                    <div>Every Performance.</div>
                    <div>Every Data Point.</div>
                    <div>Everything Music.</div>
                    <div>
                      <img
                        src="/img/background/ctaButton.png"
                        style={{ width: '100%', marginTop: 20 }}
                        alt="CTAbutton"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    brand: state.site.brand,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginUserAction: (username, password) =>
      dispatch(loginUserAction(username, password)),
    loginFacebookAction: (data) =>
      dispatch(loginFacebookAction(data)),
    loginFailedAction: (message) =>
      dispatch(loginFailedAction(message)),
    loginTwitterAction: (data) => dispatch(loginTwitterAction(data)),
    loginGoogleAction: (data) => dispatch(loginGoogleAction(data)),
    loginInstagramAction: (code) =>
      dispatch(loginInstagramAction(code)),
    clearLoginErrorMessage: (name, email) =>
      dispatch(clearLoginErrorMessage(name, email)),
    clearConfirmationMessage: () =>
      dispatch(clearConfirmationMessage()),

    updateField: (field, value) =>
      dispatch(updateField(field, value)),
    updateErrorField: (field, value) =>
      dispatch(updateErrorField(field, value))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
