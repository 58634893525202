import React, { Component } from 'react';
import { FormGroup, Form } from 'react-bootstrap';

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';

import TextInputGroup from '../../common/TextInputGroup';
// import countries from '../../../utils/country';
import { isEmpty } from '../../../utils/formUtils';

import '../../../style/calendar.css';

class TourFrom extends Component {

  constructor(props, context) {
    super(props, context);

    const now = new Date();

    this.state = {
      tourName: null,
      active: true,
      backgroundImage: '/img/logo/knkt-logo.svg',
      artistId: this.props.artistId,
      tourEvents: [],
      errors: [],
      source: 'knkt',
      now
    };

    this.updateField = this.updateField.bind(this);
    this.buildErrorList = this.buildErrorList.bind(this);
  }

  componentDidMount() {
    if (this.props.user && this.props.user.artistId) {
      this.setState({
        artistId: this.props.user.artistId
      })
    }
  }

  // tourId: 'tid000000',
  // tourName: 'Tour0',
  // active: true,
  // backgroundImage: '/img/sample/002-sample.jpg',
  // events: ['12345', '12347', '12348'],
  // artistId: 2

  getValidationState() {
    const length = this.state.value.length;
    if (length > 10) return 'success';
    else if (length > 5) return 'warning';
    else if (length > 0) return 'error';
    return null;
  }

  updateField(e) {
    let field = '';
    // console.log('---e.target.id:', e.target);
    switch(e.target.id) {
      case 'formControlsTourName': {
        field = 'tourName';
        break;
      }
      case 'formControlsBackgroundImage': {
        field = 'backgroundImage';
        break;
      }
      default: {}
    }
    if (field) {
      this.setState({
        [field]: e.target.value
      })
    }

    // special case - handle checkbox
    if (e.target.id === 'formControlsActive') {
      this.setState({
        active: e.target.checked
      })
    }
  }

  submitForm(event) {
    event.preventDefault();

    let allErrors = [];
    let alertOK = true;

    let {
      tourName, backgroundImage, active, source, artistId, now
    } = this.state;

    if (isEmpty(tourName)) {
      allErrors.push('Please give the tour a name');
    }

    if (isEmpty(backgroundImage)) {
      allErrors.push('Enter the link to the background image');
    }

    this.setState({
      errors: allErrors
    });

    if (allErrors.length === 0 && alertOK) {
      let tour = {};
      tour.tourId = now.getTime();
      tour.tourName = tourName;
      tour.backgroundImage = backgroundImage;
      tour.active = active;
      tour.source = source;
      tour.artistId = artistId;
      tour.tourEvents = [];

      // console.log(tour);
      this.props.addTour(tour);
    }
  }

  buildErrorList() {
    let errorLists =[];
    this.state.errors.forEach(err => {
      errorLists.push(<li style={{color: '#a94442'}}>{err}</li>);
    });

    return (
      <div>
        {errorLists.length > 0 && (
          <div>Please check the followings:</div>
        )}
        <ul>
          { errorLists }
        </ul>
      </div>
    )
  }

  render() {
    // const CustomDateInput = ({ start, value, onClick }) => (
    //   <span style={{width: '100%', textAlign: 'center'}} onClick={onClick.bind(this, start, value)}>
    //     <FontAwesomeIcon icon={faCalendarAlt}/>  {value}
    //   </span>
    // );
    //
    // const CustomTimeInput = ({ start, value, onClick }) => (
    //   <span style={{width: '100%', textAlign: 'center'}} onClick={onClick.bind(this, start, value)}>
    //     {value}  <FontAwesomeIcon icon={faClock}/>
    //   </span>
    // );

    return (
      <form className="eventForm">
        <TextInputGroup
          id="formControlsTourName"
          type="text"
          label="Tour Name"
          placeholder="Enter name of tour"
          onChange={this.updateField}
          validationState={this.state.tourName === null ? null : (this.state.tourName.length > 0 ? "success" : "error")}
          help={this.state.tourName === null ? null : (this.state.tourName.length > 0 ? null : "Please give the tour a name")}
        />

        <TextInputGroup
          id="formControlsBackgroundImage"
          type="text"
          label="Background Image"
          placeholder="Enter the link to the background image"
          onChange={this.updateField}
          value={this.state.backgroundImage}
          validationState={this.state.backgroundImage === null ? null : (this.state.backgroundImage.length > 0 ? "success" : "error")}
          help={this.state.backgroundImage === null ? null : (this.state.backgroundImage.length > 0 ? null : "Please enter a link to the background image")}
        />

        <FormGroup>
          <Form.Check type="checkbox" id="formControlsActive" onChange={this.updateField} checked={this.state.active}>
            Mark active
          </Form.Check>
        </FormGroup>

        <div style={{position: 'relative'}}>
          <div className="text-left">
            {this.buildErrorList()}
          </div>
          <div style={{position: 'absolute', top: 5, right: 5}} className="text-right">
            <button type="submit" className="submitButton" onClick={this.submitForm.bind(this)}>Submit</button>
          </div>
        </div>
      </form>
    )
  }
}

export default TourFrom;