
// The url of this page and childrens will be as bellow
// fans/facebook
// fans/facebook/likes
// fans/facebook/followers
// fans/facebook/shares
// fans/youtube
// fans/youtube/likes
// fans/youtube/followers

import React, { Component } from 'react';
import { Route, Link, NavLink } from 'react-router-dom';
import { FaCaretRight } from 'react-icons/lib/fa';
import { connect } from 'react-redux';

import Engagement from '../engagement/Engagement';
import AllEngagements from './AllEngagements';

import ChartDataModel from './ChartDataModel';

import PAGE_CONFIG from './page-config.json';
import DATA from '../__mocks__/fans-home.json';

import './fans-home.scss'

class FansHome extends Component {

    constructor({ match }) {
        super();
        this.state = {
            otherFans: [],
            chartOptions: {},
            dataPref: []
        };
    }

    componentWillMount() {
        const { match: { params: { socialmedia } } } = this.props;
        // Refactor this to a function
        const config = this.getPageConfig(socialmedia);
        const chartOptions = this.getChartOptions(socialmedia);
        this.setState({ chartOptions, ...config });
    }

    getPageConfig(socialmedia) {
        const pageConfig = PAGE_CONFIG.socialPlatforms[socialmedia];
        const tabHeadings = pageConfig.possibleTabs.map(item => {
            return { ...item, path: `/fans/${socialmedia}${item.path}` };
        });
        const otherSocialPlatforms = Object.entries(PAGE_CONFIG.socialPlatforms).filter(([key]) => key !== socialmedia);
        const activeTimeLine = pageConfig.timeline.filter(item => item.isSelected);
        return { otherSocialPlatforms, tabHeadings, pageConfig, selectedTimeLine: activeTimeLine.key };
    }

    getChartOptions(socialmedia) {
        let apiData = DATA[socialmedia];
        let data = new ChartDataModel(apiData);
        return data;
    }

    componentWillReceiveProps(newProps) {
        const { match: { params: { socialmedia } } } = newProps;
        const config = this.getPageConfig(socialmedia);
        const chartOptions = this.getChartOptions(socialmedia);
        this.setState({ chartOptions, ...config });
    }

    onDataTimeLineClick = (key, e) => {
        let { pageConfig } = this.state;
        pageConfig.timeline = pageConfig.timeline.map(element => { return { ...element, isSelected: element.key === key } });
        this.setState({ pageConfig, selectedTimeLine: key });
    }

    render() {
        return (
            <div className="fans">
                <div className="page-heading">
                    <div className="fans-breadcrumb">
                        <Link to="/insights">insights</Link>
                        <FaCaretRight />
                        <span>{this.state.pageConfig.text} (fans)</span>
                    </div>
                    <div className="media-title">{this.state.pageConfig.text}</div>
                    <div></div>
                </div>
                <div className="data-timeline">
                    {
                        this.state.pageConfig.timeline.map(item => <button key={item.key} onClick={(e) => this.onDataTimeLineClick(item.key, e)} className={`btn btn-link ${item.isSelected ? 'active' : ''}`}>{item.text}</button>)
                    }
                </div>
                <div className="main-tab">
                    {
                        this.state.tabHeadings.map(item =>
                            <NavLink className="tab-heading" key={item.key} to={item.path} exact>{item.text}</NavLink>
                        )
                    }
                    <div className="tab-main-content">

                        <Route exact path='/fans/:socialmedia' render={(props) => <AllEngagements {...props} chartOptions={this.state.chartOptions} chartStyle={PAGE_CONFIG.chartStyle} />} />
                        <Route exact path='/fans/:socialmedia/:attribute' component={Engagement} />

                    </div>
                </div>
                <div className="view-also">
                    <span>Also View: </span>
                    <ul>
                        {
                            this.state.otherSocialPlatforms.map(([key, item]) => {
                                return (
                                    <li key={key}>
                                        <Link to={`/fans${item.path}`}>{item.text}</Link>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    console.log(state);
    return {
        ...state
    };
}

export default connect(mapStateToProps)(FansHome);