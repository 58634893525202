import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { passwordLength } from '../../../config/siteConfig';

const AgentSignupPage = props => {
  const styles = {
    loginLeft: {
      backgroundColor: '#1a1d24',
      height: '100vh',
      color: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    loginRight: {
      height: '100vh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alginItems: 'center',
      color: '#ffffff'
    },
    loginRightBackground: {
      background: "url(/img/background/loginBgObject.png) no-repeat",
      backgroundSize: '60%',
      backgroundPosition: 'center center',
      transform: 'rotate(-45deg)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1
    },
    loginRightContent: {
      width: '100%',
      zIndex: 100
    },
    logo: {
      marginTop: 30
    },
    signinText: {
      fontSize: '1.5em',
      marginTop: 50,
      fontWeight: 'bold'
    },
    formInputColumn: {
      marginTop: 10
    },
    formInput: {
      backgroundColor: 'transparent',
      color: '#ffffff'
    },
    forgotPassword: {
      display: 'flex',
      height: '100%',
      alignItems: 'center'
    },
    forgotPasswordText: {
      color: '#ffffff'
    },
    loginButton: {
      width: '100%'
    },
    loginRightTexts: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      fontSize: '1.5em'
    },
    errorMessage: {
      color: '#ff0000',
      textAlign: 'center',
      border: '1px solid #cccccc',
      borderRadius: 5,
      paddingTop: 10,
      paddingBottom: 10,
      marginTop: 10
    }
  };

  const clearMessage = _ => {
    setTimeout(_ => { props.clearConfirmationMessage() }, 5000)
  };

  return (
    <Col xs={12}>
      <Form noValidate onSubmit={props.handleFormSubmit}>
        <Row>
          <Col xs={12} style={styles.formInputColumn}>
            <FloatingLabel
                label="Username (Email)"
              >
              <Form.Control
                placeholder="Username (Email)"
                id="username"
                type="text"
                style={styles.formInput}
                onChange={props.handleUpdateField}
                required
                isInvalid={props.isValid('username')}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your username (email)
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>
          <Col xs={12} style={styles.formInputColumn}>
            <FloatingLabel
                label="Password"
              >
              <Form.Control
                placeholder="Password"
                id="password"
                style={styles.formInput}
                type="password"
                onChange={props.handleUpdateField}
                isInvalid={props.isValid('password')}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your password (at least {passwordLength} characters)
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col xs={12} style={styles.formInputColumn}>
            <FloatingLabel
              label="Agency Name"
            >
              <Form.Control
                placeholder="Agency Name"
                id="agencyName"
                type="text"
                style={styles.formInput}
                onChange={props.handleUpdateField}
                required
                isInvalid={props.isValid('agencyName')}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your Agency Name
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col xs={12} style={styles.formInputColumn}>
            <FloatingLabel
              label="First Name"
            >
              <Form.Control
                placeholder="First Name"
                id="firstName"
                type="text"
                style={styles.formInput}
                onChange={props.handleUpdateField}
                required
                isInvalid={props.isValid('firstName')}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your First Name
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col xs={12} style={styles.formInputColumn}>
            <FloatingLabel
                label="Last Name"
              > 
              <Form.Control
                placeholder="Last Name"
                id="lastName"
                type="text"
                style={styles.formInput}
                onChange={props.handleUpdateField}
                required
                isInvalid={props.isValid('lastName')}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your Last Name
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col xs={12} style={styles.formInputColumn}>
            <FloatingLabel
                label="Location"
              > 
              <Form.Control
                placeholder="Location"
                id="location"
                type="text"
                style={styles.formInput}
                onChange={props.handleUpdateField}
                required
                isInvalid={props.isValid('location')}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your location
              </Form.Control.Feedback>
            </FloatingLabel>
          </Col>

          <Col xs={{span: 6, offset: 6}} style={styles.formInputColumn}>
            <Button
              type="submit"
              variant="light"
              style={styles.loginButton}
              onClick={props.handleFormSubmit}
              disabled={!(props.isAllValid())}
            >Sign Up</Button>
          </Col>

          {
            props.signUpPageMessage.confirmation && (

              <Col xs={12}>
                <div style={styles.errorMessage}>{props.signUpPageMessage.confirmation}</div>
                {
                  clearMessage()
                }
              </Col>
            )
          }
        </Row>

      </Form>
    </Col>
  )
}

export default AgentSignupPage;