import {
  JUST_RETURN,
  PICK_ARTIST,
  GET_ARTISTS
} from '../actions/Admin/adminAction';

const initialState = {
  icon: 'artist',
  artists: []
};

function admin(state = initialState, action) {
  switch (action.type) {
    case JUST_RETURN:
    {
      let newState = Object.assign({}, state);
      if (action.errMessage) {
        newState.errMessage = action.errMessage;
      }
      return newState;
    }
    case PICK_ARTIST:
    {
      let newState = Object.assign({}, state);
      newState.icon = action.icon;
      return newState;
    }
    case GET_ARTISTS:
    {
      let newState = Object.assign({}, state);
      // console.log(action.data);
      newState.artists = action.data;
      return newState;
    }
    default:
    {
      return state;
    }
  }
}

export default admin;