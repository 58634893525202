const timeline = [
  {
    text: "7 Days",
    key: "sevenDay",
    apiKey: 'sevenDay'
  },
  {
    text: "28 Days",
    key: "twentyEightDay",
    apiKey: 'twentyEightDay'
  },
  {
    text: "All time",
    key: "all",
    apiKey: 'lifeTime'
  }
];

const subCharts = {
  insights: {
    ageGroups: {
      title: 'Age groups',
      type: 'pie',
      fields: [
        "ageGroup_13_17",
        "ageGroup_18_24",
        "ageGroup_25_34",
        "ageGroup_35_44",
        "ageGroup_45_54",
        "ageGroup_55_64"
      ]
    },
    gender: {
      title: 'Gender',
      type: 'donut',
      fields: [
        "gender_neutral",
        "men",
        "women"
      ]
    }
  },
  facebook: {
    pageLikeSources: {
      title: 'Page like sources',
      type: 'pie',
      fields: [
        "ads",
        "newsFeed",
        "other",
        "pageSuggestion",
        "restored",
        "search",
        "yourPage"
      ]
    },
    ageGroups: {
      title: 'Age groups',
      type: 'pie',
      fields: [
        "ageGroup_13_17",
        "ageGroup_18_24",
        "ageGroup_25_34",
        "ageGroup_35_44",
        "ageGroup_45_54",
        "ageGroup_55_64"
      ]
    },
    gender: {
      title: 'Gender',
      type: 'donut',
      fields: [
        "gender_neutral",
        "men",
        "women"
      ]
    }
  },
  facebookvideo: {
    pageLikeSources: {
      title: 'Page like sources',
      type: 'pie',
      fields: [
        "ads",
        "newsFeed",
        "other",
        "pageSuggestion",
        "restored",
        "search",
        "yourPage"
      ]
    },
    ageGroups: {
      title: 'Age groups',
      type: 'pie',
      fields: [
        "ageGroup_13_17",
        "ageGroup_18_24",
        "ageGroup_25_34",
        "ageGroup_35_44",
        "ageGroup_45_54",
        "ageGroup_55_64"
      ]
    },
    gender: {
      title: 'Gender',
      type: 'donut',
      fields: [
        "gender_neutral",
        "men",
        "women"
      ]
    }
  },
  instagram: {
    ageGroups: {
      title: 'Age groups',
      type: 'pie',
      fields: [
        "ageGroup_13_17",
        "ageGroup_18_24",
        "ageGroup_25_34",
        "ageGroup_35_44",
        "ageGroup_45_54",
        "ageGroup_55_64"
      ]
    },
    gender: {
      title: 'Gender',
      type: 'donut',
      fields: [
        "gender_neutral",
        "men",
        "women"
      ]
    }
  }
};

export {
  timeline,
  subCharts
};