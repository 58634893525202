import React, { Fragment, useState, useRef, useEffect } from "react";
import { Button, ConfigProvider, theme } from "antd";
import DragList from "../components/pages/promolink/DraggableList";

function usePrevious(value) {
  const ref = useRef();
  console.log(ref);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function CreateLinks() {
  const [count, setCount] = useState(4);
  const prevCount = usePrevious(count);

  useEffect(() => {
    if (prevCount !== count) console.log(prevCount, count);
    if (count === 11) setCount(1);
  });

  const styles = {
    row: {
      minHeight: "720px",
      color: "rgb(255,255,255)",
    },
    card: {
      backgroundColor: "rgb(29 30 30)",
      color: "rgb(255,255,255)",
    },
    clickCard: {
      backgroundColor: "#002733",
      color: "rgb(255,255,255)",
      border: "1px solid #383838",
    },
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="container"></div>
        <div className="row" style={styles.row}>
          <div className="col-sm-12" style={styles.card}>
            {count === 10 ? "You're set!" : "Step " + count + " of 8"}
          </div>
          <br></br>
          <div className="col-sm-12" style={styles.card}>
            {count === 1 && (
              <Fragment>
                <div className="col-sm-12 text-left">
                  <h2>{"WHAT ARE YOU PROMOTING?"}</h2>
                </div>
                <div className="col-sm-12 text-left">
                  <h3>
                    {
                      "Choose one of the templates below and we we'll set up a link that works best for you."
                    }
                  </h3>
                </div>
                <br></br>
                <div
                  className="card flex-row flex-wrap w-75"
                  style={styles.card}
                >
                  <div className="card-header border-0">
                    <img src="//placehold.it/200" alt="" />
                  </div>
                  <div className="card-block px-2 text-left w-50">
                    <h4 className="card-title">Landing Page</h4>
                    <p className="card-text">
                      Track and measure people visiting any webpage or link
                    </p>
                  </div>
                </div>
                <br></br>
                <div
                  className="card flex-row flex-wrap w-75"
                  style={styles.card}
                >
                  <div className="card-header border-0">
                    <img src="//placehold.it/200" alt="" />
                  </div>
                  <div className="card-block px-2 text-left w-50">
                    <h4 className="card-title">Music</h4>
                    <p className="card-text">
                      Create a landing page and smart link to a track or album
                      on any store and platform
                    </p>
                  </div>
                </div>
                <br></br>
                <div
                  className="card flex-row flex-wrap w-75"
                  style={styles.card}
                >
                  <div className="card-header border-0">
                    <img src="//placehold.it/200" alt="" />
                  </div>
                  <div className="card-block px-2 text-left w-50">
                    <h4 className="card-title">Mobile App</h4>
                    <p className="card-text">
                      Create a landing page to promote your app to users on all
                      devices
                    </p>
                  </div>
                </div>
                <br></br>
                <div className="col-sm-12 text-center">
                  {" "}
                  <Button onClick={() => setCount(count + 1)} ghost>
                    Button
                  </Button>
                </div>
              </Fragment>
            )}
            {count === 2 && (
              <Fragment>
                <div className="col-sm-12 text-left">
                  <h2>{"PromoLinks"}</h2>
                </div>
                <div className="col-sm-12 text-left">
                  <form class="form-inline">
                    <input
                      class="form-control mr-sm-2"
                      type="search"
                      placeholder="Search PromoLinks"
                      aria-label="Search"
                    />
                    <button
                      class="btn btn-outline-success my-2 my-sm-0"
                      type="submit"
                    >
                      Search
                    </button>
                  </form>
                </div>
                <div className="col-sm-12 text-right">
                  <button
                    class="btn btn-outline-success my-2 my-sm-0"
                    type="submit"
                  >
                    + Add PromoLink
                  </button>
                </div>
                <br></br>
                <h4>Active</h4>
                <div
                  className="card flex-row flex-wrap w-75"
                  style={styles.card}
                >
                  <div className="card-header border-0">
                    <img src="//placehold.it/200" alt="" />
                  </div>
                  <div className="card-block px-2 text-left w-50">
                    <h4 className="card-title">Release</h4>
                    <p className="card-text">Promo Name</p>
                    <small className="card-text"> Created Date</small>
                    <p className="card-text">Tags</p>
                    <button type="button" class="btn btn-link">
                      Views
                    </button>{" "}
                    <button type="button" class="btn btn-link">
                      Clicks
                    </button>
                  </div>
                </div>
                <div
                  className="card flex-row flex-wrap w-75"
                  style={styles.card}
                >
                  <div className="card-header border-0">
                    <img src="//placehold.it/200" alt="" />
                  </div>
                  <div className="card-block px-2 text-left w-50">
                    <h4 className="card-title">Upcoming</h4>
                    <p className="card-text">Promo Name</p>
                    <small className="card-text"> Created Date</small>
                    <p className="card-text">Tags</p>
                    <button type="button" class="btn btn-link">
                      Views
                    </button>{" "}
                    <button type="button" class="btn btn-link">
                      Clicks
                    </button>
                  </div>
                </div>
                <br></br>
                <h4>Expired</h4>
                <div
                  className="card flex-row flex-wrap w-75"
                  style={styles.card}
                >
                  <div className="card-header border-0">
                    <img src="//placehold.it/200" alt="" />
                  </div>
                  <div className="card-block px-2 text-left w-50">
                    <h4 className="card-title">Release</h4>
                    <p className="card-text">Promo Name</p>
                    <small className="card-text"> Created Date</small>
                    <p className="card-text">Tags</p>
                    <button type="button" class="btn btn-link">
                      Views
                    </button>{" "}
                    <button type="button" class="btn btn-link">
                      Clicks
                    </button>
                  </div>
                </div>
                <br></br>
                <div className="col-sm-12 text-center">
                  {" "}
                  <Button onClick={() => setCount(count + 1)} ghost>
                    Button
                  </Button>
                </div>
              </Fragment>
            )}
            {count === 3 && (
              <Fragment>
                <div className="col-sm-12 text-left">
                  <h2>{"Choose a template"}</h2>
                </div>
                <div className="col-sm-12 text-left">
                  <h3>
                    {
                      "Select one of the following options for how your music release page will look and feel"
                    }
                  </h3>
                </div>
                <br></br>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="card" style={styles.clickCard}>
                      <div class="card-body">
                        <h5 class="card-title">Existing Release</h5>
                        <p class="card-text">
                          For releases that are currently available for people
                          to listen to
                        </p>
                        <ul class="new-link-target-item-features-list">
                          <li class="new-link-target-item-features-list-item">
                            <i class="icon nc-check"></i>
                            <span class="new-link-target-item-features-list-item-text">
                              Create a landing page showcasing popular music
                              services to link your fans to
                            </span>
                          </li>
                          <li class="new-link-target-item-features-list-item">
                            <i class="icon nc-check"></i>
                            <span class="new-link-target-item-features-list-item-text">
                              Customize the look and feel of your landing page
                            </span>
                          </li>
                          <li class="new-link-target-item-features-list-item">
                            <i class="icon nc-check"></i>
                            <span class="new-link-target-item-features-list-item-text">
                              Choose a suitable shortlink URL and add tracking
                              pixels to your page
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="card" style={styles.clickCard}>
                      <div class="card-body">
                        <h5 class="card-title">Upcoming Release</h5>
                        <p class="card-text">
                          For collecting pre-saves for releases that are going
                          to be available at a future date
                        </p>
                        <ul class="new-link-target-item-features-list">
                          <li class="new-link-target-item-features-list-item">
                            <i class="icon nc-check"></i>
                            <span class="new-link-target-item-features-list-item-text">
                              Encourage people to pre-save on Spotify and/or
                              pre-add on Apple Music
                            </span>
                          </li>
                          <li class="new-link-target-item-features-list-item">
                            <i class="icon nc-check"></i>
                            <span class="new-link-target-item-features-list-item-text">
                              Create a landing page with a countdown timer for
                              your release
                            </span>
                          </li>
                          <li class="new-link-target-item-features-list-item">
                            <i class="icon nc-check"></i>
                            <span class="new-link-target-item-features-list-item-text">
                              Worry-free auto-scanning of popular services to be
                              added to your landing page on release day
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="col-sm-12 text-center">
                  {" "}
                  <Button onClick={() => setCount(count + 1)} ghost>
                    Button
                  </Button>
                </div>
              </Fragment>
            )}
            {count === 4 && (
              <Fragment>
                <ConfigProvider
                  theme={{
                    algorithm: theme.darkAlgorithm,
                  }}
                >
                  {/* <div className="input-group">
                                                         <input type="text" className="form-control" placeholder="Link.." />
                                                         <div className="input-group-append">
                                                             <button type="button" className="btn btn-outline-primary">Add</button>
                                                         </div>
                                                     </div> */}
                  <br></br>
                  <div className="card text-left" style={styles.card}>
                    <h5 className="card-header">Enter Stream Source</h5>
                    <div className="card-body">
                      <h5 className="card-title">Track/Album URL</h5>
                      <h6>
                        Start by entering the URL of your track or album and
                        we'll use it to find the links for it across popular
                        music services
                      </h6>
                      <p className="card-text">
                        {" "}
                        <input
                          type="text"
                          className="w-75"
                          placeholder="Link.."
                        />
                      </p>
                      <a
                        href="#"
                        className="btn btn-outline-primary pull-right"
                      >
                        Create Link
                      </a>
                    </div>
                  </div>
                  <Button onClick={() => setCount(count + 1)} ghost>
                    Next
                  </Button>
                </ConfigProvider>
              </Fragment>
            )}
            {count === 5 && (
              <Fragment>
                <div className="col-sm-12 text-left">
                  <h2>{"SELECT SERVICES"}</h2>
                </div>
                <br></br>
                <div className="col-sm-10">
                  <div className="card text-left" style={styles.card}>
                    <div className="card-header border-0 container-fluid">
                      <div className="row">
                        <div className="col-sm-6">
                          <img src="//placehold.it/75" alt="logo" />
                        </div>
                        <div className="col-sm-6 text-right">
                          <div className="custom-control custom-switch pull-right">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="switch1"
                              name="example"
                            />
                            <label
                              className="custom-control-label switch-label"
                              htmlFor="switch1"
                            >
                              Enabled
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Link URL</h5>
                      <p className="card-text">
                        {" "}
                        <input
                          type="text"
                          className="w-75"
                          placeholder="Link goes here.."
                        />
                      </p>
                      <a
                        href="#"
                        className="btn btn-outline-primary pull-right"
                      >
                        Open
                      </a>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="col-sm-10">
                  <div className="card text-left" style={styles.card}>
                    <div className="card-header border-0 container-fluid">
                      <div className="row">
                        <div className="col-sm-6">
                          <img src="//placehold.it/75" alt="logo" />
                        </div>
                        <div className="col-sm-6 text-right">
                          <div className="custom-control custom-switch pull-right">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="switch3"
                              name="example"
                            />
                            <label
                              className="custom-control-label switch-label"
                              htmlFor="switch3"
                            >
                              Enabled
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Link URL</h5>
                      <p className="card-text">
                        {" "}
                        <input
                          type="text"
                          className="w-75"
                          placeholder="Add Custom Service.."
                        />
                      </p>
                      <a
                        href="#"
                        className="btn btn-outline-primary pull-right"
                      >
                        Open
                      </a>
                    </div>
                  </div>
                </div>
                <br></br>
                <Button onClick={() => setCount(count + 1)} ghost>
                  Next
                </Button>
              </Fragment>
            )}
            {count === 6 && (
              <Fragment>
                <div className="col-sm-12">
                  <div className="card text-left" style={styles.card}>
                    <div className="card-header border-0 container-fluid">
                      <h3>Customize Landing Page</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="col-sm-12">
                            <h5 className="card-title">Landing Page Image</h5>
                            <p className="card-text">
                              {" "}
                              This is the featured image for your landing page.
                            </p>
                            <a
                              href="#"
                              className="btn btn-outline-primary pull-right"
                            >
                              Change Langding Page Image
                            </a>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">Title</h5>
                            <p className="card-text">
                              {" "}
                              The title of your song or album.
                            </p>
                            <p className="card-text">
                              {" "}
                              <input
                                type="text"
                                className="w-100"
                                placeholder="Laurent Garnier - The Man With The Red Face"
                              />
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">Description</h5>
                            <p className="card-text">
                              {" "}
                              The artist name or description of the track
                            </p>
                            <p className="card-text">
                              {" "}
                              <input
                                type="text"
                                className="w-100"
                                placeholder="Description goes here.."
                              />
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">
                              Page Background Image
                            </h5>
                            <p className="card-text">
                              {" "}
                              This is the Background image for your landing
                              page.
                            </p>
                            <a
                              href="#"
                              className="btn btn-outline-primary pull-right"
                            >
                              Upgrade to Change
                            </a>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card mb-3" style={styles.card}>
                            <iframe
                              width="100%"
                              height="315"
                              src="https://www.youtube.com/embed/J5bBCaUnPq4"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                            <div className="card-body">
                              <h5 className="card-title text-center">
                                {" "}
                                Laurent Garnier - The Man With The Red Face
                              </h5>
                              <DragList
                                items={"Spotify AppleMusic deezer itunes soundcloud youtube".split(
                                  " "
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <Button onClick={() => setCount(count + 1)} ghost>
                  Next
                </Button>
              </Fragment>
            )}
            {count === 7 && (
              <Fragment>
                <div className="col-sm-12">
                  <div className="card text-left" style={styles.card}>
                    <div className="card-header border-0 container-fluid">
                      <h3>Customize Preview</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="col-sm-12">
                            <h5 className="card-title">
                              Select service to generate a preview
                            </h5>
                            <p className="card-text">
                              <select>
                                <option>Youtube</option>
                              </select>
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">
                              Link to the audio file or YouTube/Vimeo video{" "}
                            </h5>
                            <p className="card-text">
                              {" "}
                              <input
                                type="text"
                                className="w-100"
                                value="https://www.youtube.com/watch?v=J5bBCaUnPq4"
                                placeholder="Laurent Garnier - The Man With The Red Face"
                              />
                            </p>
                          </div>
                          <br></br>
                        </div>
                        <div className="col-sm-6">
                          <div className="card mb-3" style={styles.card}>
                            <iframe
                              width="100%"
                              height="315"
                              src="https://www.youtube.com/embed/J5bBCaUnPq4"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                            <div className="card-body">
                              <h5 className="card-title text-center">
                                {" "}
                                Laurent Garnier - The Man With The Red Face
                              </h5>
                              <DragList
                                items={"Spotify AppleMusic deezer itunes soundcloud youtube".split(
                                  " "
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <Button onClick={() => setCount(count + 1)} ghost>
                  Next
                </Button>
              </Fragment>
            )}
            {count === 8 && (
              <Fragment>
                <div className="col-sm-12">
                  <div className="card text-left" style={styles.card}>
                    <div className="card-header border-0 container-fluid">
                      <h3>Edit Metadata</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="col-sm-12">
                            <h5 className="card-title">Title</h5>
                            <p className="card-text">
                              Customize the title for this link that will appear
                              when shared across social media.
                            </p>
                            <p className="card-text">
                              {" "}
                              <input
                                type="text"
                                className="w-100"
                                value="[HD] Laurent Garnier - The Man With The Red Face"
                                placeholder="Laurent Garnier - The Man With The Red Face"
                              />
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">Description</h5>
                            <p className="card-text">
                              {" "}
                              Modify the description for this link that will
                              appear when shared across social media. Remember
                              to keep it short and sweet.
                            </p>
                            <p className="card-text">
                              {" "}
                              <input
                                type="text"
                                className="w-100"
                                value="by Laurent Garnier"
                                placeholder="Description goes here.."
                              />
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">Preview Image</h5>
                            <p className="card-text">
                              {" "}
                              This will be the image people will see when you
                              share this link across social media
                            </p>
                            <a
                              href="#"
                              className="btn btn-outline-primary pull-right"
                            >
                              Change Image
                            </a>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card mb-3" style={styles.card}>
                            <iframe
                              width="100%"
                              height="315"
                              src="https://www.youtube.com/embed/J5bBCaUnPq4"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                            <div className="card-body">
                              <h5 className="card-title text-center">
                                {" "}
                                Facebook Preview Post
                              </h5>
                            </div>
                          </div>
                          <div className="card mb-3" style={styles.card}>
                            <iframe
                              width="100%"
                              height="315"
                              src="https://www.youtube.com/embed/J5bBCaUnPq4"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                            <div className="card-body">
                              <h5 className="card-title text-center">
                                Google Search Preview
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <Button onClick={() => setCount(count + 1)} ghost>
                  Next
                </Button>
              </Fragment>
            )}

            {count === 9 && (
              <Fragment>
                <div className="col-sm-12">
                  <div className="card text-left" style={styles.card}>
                    <div className="card-header border-0 container-fluid">
                      <h3>Modify Link URL</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="col-sm-12">
                            <h5 className="card-title">Link Path</h5>
                            <p className="card-text">
                              Customize the URL specific for this link
                            </p>
                            <p className="card-text">
                              {" "}
                              <input
                                type="text"
                                className="w-100"
                                value="dXmm"
                                placeholder=""
                              />
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">Subdomain (Optional)</h5>
                            <p className="card-text">
                              {" "}
                              Attached to your domain, this helps your brand
                              stay consistent across your links
                            </p>
                            <p className="card-text">
                              {" "}
                              <input
                                type="text"
                                className="w-100"
                                value=""
                                placeholder="The name of your brand or organization"
                              />
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">Domain</h5>
                            <p className="card-text">
                              Choose one of our pre-set domains or add your own
                            </p>
                            <p className="card-text">
                              <select>
                                <option>promolink.to</option>
                                <option>promolink2.to</option>
                              </select>
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">Link URL Preview</h5>
                            <p className="card-text">
                              This is what the URL for your newly created
                              FanLink will look like:
                            </p>
                            <p className="card-text">
                              <a href="https://fanlink.to/dXmm">
                                https://fanlink.to/dXmm
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <Button onClick={() => setCount(count + 1)} ghost>
                  Next
                </Button>
              </Fragment>
            )}

            {count === 10 && (
              <Fragment>
                <div className="col-sm-12">
                  <div className="card text-left" style={styles.card}>
                    <div className="card-header border-0 container-fluid">
                      <h3>Review Link</h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="col-sm-12">
                            <h1 className="card-title">
                              Everything Looks Good!
                            </h1>
                            <p className="card-text">
                              When you're ready, go ahead and launch your new
                              link
                            </p>
                          </div>
                          <br></br>
                          <div className="col-sm-12">
                            <h5 className="card-title">Link Tags</h5>
                            <p className="card-text">
                              {" "}
                              Add tags to this link to easily group, search, and
                              find similar links
                            </p>
                            <p className="card-text">
                              <select placeholder="Search existing tags or type to create a new tag">
                                <option>tag1</option>
                              </select>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br></br>
                <button type="button" className="btn btn-outline-success">
                  Finish
                </button>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CreateLinks;
