import axios from 'axios';

import history from '../../redux/manageHistory';
import { getApiUrl } from '../../config/siteConfig';
import { pageAfterConnect } from '../../config/siteConfig';
import { loginUser, CLEAR_SHOW_SOUNDCLOUD_MODAL, SHOW_SOUNDCLOUD_MODAL } from '../Login/loginAction';

// const CONNECT_INSTAGRAM = 'CONNECT_INSTAGRAM';
const LOGIN_CONFIRMATION = 'LOGIN_CONFIRMATION';
const CONNECT_FAILED = 'CONNECT_FAILED';
const DISCONNECT_FAILED = 'DISCONNECT_FAILED';

function connectInstagramAction(code) {
  const token = window.localStorage.getItem('token');
  return function (dispatch) {
    axios({
      url: getApiUrl() + '/api/v1/instagram/connect',
      method: 'post',
      headers: {
        'token': token
      },
      data: {
        code: code
      }
    }).then((res) => {
      let data = res.data;

      if (data.status === 'successful') {
        dispatch(loginUser(null, data.user));

        window.localStorage.setItem('token', data.token);
        window.localStorage.removeItem('instagram.reconnect');
        history.push(pageAfterConnect);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: CONNECT_FAILED
        });
        history.push(pageAfterConnect);
      }
    }).catch(err => {
      console.error('connect instagram error: ', err);

      let responseData = err.response.data;
      // console.log('-----responseData.error:', responseData.error);
      // console.log('-----responseData.message:', responseData.message);
      let errorMessage = (responseData.error && responseData.message) ? responseData.message : CONNECT_FAILED;

      dispatch({
        type: LOGIN_CONFIRMATION,
        value: errorMessage
      });
      history.push(pageAfterConnect);
    })
  }
}

function connectGoogleAction(googleData) {
  const token = window.localStorage.getItem('token');
  return function (dispatch) {
    axios({
      url: getApiUrl() + '/api/v1/google/connect',
      method: 'post',
      headers: {
        'token': token
      },
      data: {
        code: googleData.code
      }
    })
      .then((res) => {
      let data = res.data;
      if (data.connect === true) {
        dispatch(loginUser(null, data.user));

        window.localStorage.setItem('token', data.token);
        history.push(pageAfterConnect);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: (data && data.message) || CONNECT_FAILED
        });
      }
    }).catch((err) => {
      console.error('connect google error: ', err);

      let responseData = err.response.data;
      // console.log('-----responseData.error:', responseData.error);
      // console.log('-----responseData.message:', responseData.message);
      let errorMessage = (responseData.error && responseData.message) ? responseData.message : CONNECT_FAILED;

      dispatch({
        type: LOGIN_CONFIRMATION,
        value: errorMessage
      });
      history.push(pageAfterConnect);
    })
  }
}

function disconnectPlatformAction(platform) {
  const token = window.localStorage.getItem('token');
  return function (dispatch) {
    axios({
      url: getApiUrl() + `/api/v1/${platform}/disconnect`,
      method: 'post',
      headers: {
        'token': token
      }
    }).then((res) => {
      let data = res.data;
      if (data.disconnect === 'successful') {
        dispatch(loginUser(null, data.user));

        window.localStorage.setItem('token', data.token);
        history.push(pageAfterConnect);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: DISCONNECT_FAILED
        });
        history.push(pageAfterConnect);
      }
    }).catch(err => {
      console.error(`disconnect ${platform} error: `, err);

      let responseData = err.response.data;
      // console.log('-----responseData.error:', responseData.error);
      // console.log('-----responseData.message:', responseData.message);
      let errorMessage = (responseData.error && responseData.message) ? responseData.message : DISCONNECT_FAILED;

      dispatch({
        type: LOGIN_CONFIRMATION,
        value: errorMessage
      });
      history.push(pageAfterConnect);
    })
  }
}

const showSoundCloudModal = _ => {
  return function (dispatch) {
    dispatch({
      type: SHOW_SOUNDCLOUD_MODAL
    })
  }
}

const connectSoundCloudAction = ({ platform, soundCloudUrl }) => {
  const token = window.localStorage.getItem('token');
  return function (dispatch) {
    axios({
      url: getApiUrl() + `/api/v1/${platform}/connect`,
      method: 'post',
      headers: {
        'token': token
      },
      data: {
        soundCloudUrl
      }
    }).then((res) => {
      let data = res.data;

      if (data.connect === 'successful') {
        dispatch(loginUser(null, data.user));
        dispatch({
          type: CLEAR_SHOW_SOUNDCLOUD_MODAL
        });

        window.localStorage.setItem('token', data.token);
        window.localStorage.removeItem('soundcloud.reconnect');
        history.push(pageAfterConnect);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: CONNECT_FAILED
        });
        history.push(pageAfterConnect);
      }
    }).catch(err => {
      console.error('... connect SoundCloud error: ', err);

      let responseData = err.response.data;
      // console.log('-----responseData.error:', responseData.error);
      // console.log('-----responseData.message:', responseData.message);
      let errorMessage = (responseData.error && responseData.message) ? responseData.message : CONNECT_FAILED;

      dispatch({
        type: LOGIN_CONFIRMATION,
        value: errorMessage
      });
      history.push(pageAfterConnect);
    })
  }
};

export {
  connectInstagramAction,
  connectGoogleAction,
  disconnectPlatformAction,
  showSoundCloudModal,
  connectSoundCloudAction
};