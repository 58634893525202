import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { Link, withRouter } from 'react-router-dom';

const NotificationSection = props => {
  const [ showAlert, setShowAlert ] = useState(false);
  const [ alertText, setAlertText ] = useState([]);

  useEffect(() => {
    checkReconnect();
  });

  const styles = {
    text: {
      textAlign: 'center',
      fontSize: '1.2em'
    }
  };

  const checkReconnect = _ => {
    const pathname = props.location.pathname;
    setTimeout(_ => {
      const texts = [];
      if (window.localStorage.getItem('facebook.reconnect') === 'true') {
        texts.push(<span key="1">Looks like we don't have access to your Facebook.</span>);
        if (pathname.indexOf('connection') < 0) {
          texts.push(<span key="2">Please <Link to="/connection">reconnect</Link> your Facebook</span>);
        }
      }

      if (window.localStorage.getItem('instagram.reconnect') === 'true') {
        texts.push(<span key="1">Looks like we don't have access to your Instagram.</span>);
        if (pathname.indexOf('connection') < 0) {
          texts.push(<span key="2">Please <Link to="/connection">reconnect</Link> your Instagram</span>);
        }
      }

      if (window.localStorage.getItem('soundcloud.reconnect') === 'true') {
        texts.push(<span key="1">Looks like we don't have access to your SoundCloud.</span>);
        if (pathname.indexOf('connection') < 0) {
          texts.push(<span key="2">Please <Link to="/connection">reconnect</Link> your SoundCloud</span>);
        }
      }

      if (texts.length > 0) {
        setAlertText(texts);
        setShowAlert(true);
      }

    }, 500);
  };

  const closeNotification = _ => {
    setShowAlert(false);
    window.localStorage.setItem('facebook.reconnect', 'done');
    window.localStorage.setItem('instagram.reconnect', 'done');
    window.localStorage.setItem('soundcloud.reconnect', 'done');
  };

  return (
    <div>
      { showAlert && alertText.length > 0 && (
        <Alert
          variant="info"
          onClose={closeNotification}
          dismissible
        >
          {
            alertText
          }
        </Alert>
      )}
    </div>
  )
};

const NotificationSectionComponent = withRouter(props => <NotificationSection {...props}/>);

export default NotificationSectionComponent;