import React from 'react';
import ChangePassword from '../../../components/pages/settings/ChangePassword';
import '../../../style/settings.css'

export default function Password({props}) {
    // console.log("userProfile from Password.jsx", userProfile);
    return (
        <div className="settings-tab">
            
            <ChangePassword props={props}/>
        </div>

    )
};