import React from 'react';

import TabHeader from './TabHeader';

const TimelineSelector = props => {
  const styles = {
    btnLink: {
      color: '#a8a8a8',
      textTransform: 'uppercase',
      outline: 'none',
      textDecoration: 'none'
    },
    btnActive: {
      borderBottom: 'solid 2px white',
      color: '#ffffff'
    }
  };

  const customStyle = {
    tabHeaders: {
      justifyContent: 'left'
    },
    tabHeading: {
      width: 150,
      marginTop: 5
    }
  };

  return (
    <div style={styles.timelineSelector}>
      <TabHeader
        onFansTabSelected={props.onTimelineUpdated}
        selectedFansTab={props.selectedTimeLine}
        tabData={props.timeline}
        customStyle={customStyle}
      />
    </div>
  );
};

export default TimelineSelector;