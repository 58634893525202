import React from 'react';

function PoweredByKnkt(props) {
  return (
    <div style={props.style} className="text-center">
      Powered by KNKT
    </div>
  );
}

export default PoweredByKnkt;