import React, { Component } from 'react';
// import FacebookProvider, {Login} from 'react-facebook';
import InstagramLogin from 'react-instagram-login';

import { getInstagramClientId } from '../../../config/siteConfig';
// import styles from '../../../style/social';

class InstagramConnect extends Component {

  handleInstagramResponse = (code) => {
    console.log('---code:'+code);
  };

  handleError = (error) => {
    this.props.loginFailedAction("Something went wrong!");
  };

  getInstagramLoginButton = () => {
    return (
      <p style={{padding: 100}}>
        <InstagramLogin
          clientId={getInstagramClientId()}
          buttonText="Instagram Login"
          onSuccess={this.handleInstagramResponse}
          onFailure={this.handleError}
          scope="user_profile,user_media"
          tag="div"
          type="button"
        />
      </p>
    )
  };

  render() {
    return (
      <div>
        {this.props.user.instagram && this.props.user.instagram.id && (
          <div>You are connected!</div>
        )}
        {this.props.user.instagram && !this.props.user.instagram.id && (
          <div>
            { this.getInstagramLoginButton() }
          </div>
        )}
      </div>
    )
  }
}

export default InstagramConnect;