import React from 'react';
import { FormGroup, FormLabel, FormControl, Form } from 'react-bootstrap';

function TextInputGroup({ id, label, help, validationState, ...props }) {
  return (
    <FormGroup controlId={id} validationState={validationState}>
      <FormLabel>{label}</FormLabel>
      <FormControl {...props} />
      {help && <Form.Text>{help}</Form.Text>}
    </FormGroup>
  );
}

export default TextInputGroup;