import React, { Component } from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from '../../../style/calendar';

class SlickCarousel extends Component {
  render() {

    let props = this.props;
    const {isOwner, user, activeCount, theTours} = props;

    const tourLength = theTours ? theTours.length : 0;
    const tourToShow = tourLength < 4 ? tourLength : 4;

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: tourToShow,
      slidesToScroll: tourToShow,
      responsive: [
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: tourToShow,
            slidesToScroll: 1,
            arrows: true
          }
        }
      ]
    };

    if (theTours.length < 1) {
      return null;
    }
    return (
      <div>
        <Slider {...settings}>
          {
            theTours
          }
        </Slider>
        { isOwner && user.tier && (
          <div style={styles.calendarTourUsageText}>Usage: {activeCount}/{user.tier.tour} active</div>
        )}
      </div>
    );
  }
}

export default SlickCarousel;