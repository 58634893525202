import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import history from '../redux/manageHistory';
import { validateEmail, callApi } from '../utils/utils';

const styles = {
  forgotPassword: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  pageTitle: {
    color: '#cccccc',
    marginBottom: 50
  },
  modal: {
    color: '#ffffff'
  }
};

const ForgotPasswordContainer = props => {

  const [ email, setEmail ] = useState('');
  const [ isInvalid, setIsInvalid ] = useState(null);
  const [ showConfirmation, setShowConfirmation ] = useState(false);

  const updateEmail = event => {
    const email = event.target.value;
    setEmail(email);
    setIsInvalid(!validateEmail(email));
  };

  const handleCloseConfirmation = _ => {
    setShowConfirmation(false);
    if (!isInvalid && isInvalid !== null) {
      history.push('/');
    }
  };

  const backToHome = _ => {
    handleCloseConfirmation();
  };

  const sendResetEmail = async() => {
    const resetEmail = await callApi('/api/v1/sendMyCode', 'post', {email: email});

    const { data } = resetEmail;

    if (data.sendMyCode === true) {
      setShowConfirmation(true);
    }
  };

  return (
    <Row style={styles.forgotPassword}>
      <Col xs={{ span: 8, offset: 2 }}>
        <h2 style={styles.pageTitle}>Reset Password</h2>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Enter your account's email"
              onChange={updateEmail}
              isInvalid={isInvalid}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email
            </Form.Control.Feedback>
            {/*<Form.Text className="text-muted">
              Please enter the EMAIL of your account
            </Form.Text>*/}
          </Form.Group>

          <Button variant="primary" disabled={(isInvalid || isInvalid==null)} onClick={sendResetEmail}>
            Submit
          </Button>
        </Form>
      </Col>

      <Modal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        style={styles.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>All Set!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please check your email and follow the instructions in the email to reset your password.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={backToHome}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  )
};

export default ForgotPasswordContainer;
