import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUserCircle} from '@fortawesome/free-solid-svg-icons';

const SignupStepUploadImage = props => {
  const { setImageSelected, postImageErrorMessage, savingImageMessage } = props;
  const [ selectedImage, setSelectedImage ] = useState(null);

  const styles = {
    modalTitle: {
      fontSize: '150%'
    },
    modalImage: {
      display: 'flex',
      flexDirection: 'row'
    },
    imageBox: {
      width: 200,
      height: 200,
      border: '1px dotted #ffffff',
      marginTop: 20,
      marginBottom: 20,
      marginRight: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    imageDescription: {
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    imageUploadButton: {
      marginTop: 10,
      border: '1px solid #ccc',
      display: 'inline-block',
      padding: '6px 12px',
      cursor: 'pointer'
    },
    errorMessage: {
      color: '#ff0000'
    },
    savingImageMessage: {
      color: '#0000ff',
      fontSize: '150%',
      marginTop: 5
    }
  }

  const setImage = event => {
    const image = event.target.files[0];
    setImageSelected(image);
    setSelectedImage(image)
  }

  const removeImage = _ => {
    setImageSelected(null);
    setSelectedImage(null);
  }

  return (
    <>
      <Row>
        <Col xs={12} style={styles.modalTitle}>
          Your picture
        </Col>
        <Col xs={12}>
          This image will appear as your profile image.
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={styles.modalImage}>
          <div style={styles.imageBox}>
          { selectedImage ? (
            <div>
              <img
                alt="sample"
                width={"150px"}
                accept=".jpg, .jpeg, .png"
                src={URL.createObjectURL(selectedImage)}
              />
            </div>
          ) : (
              <FontAwesomeIcon size='6x' icon={faUserCircle}  />
          )}
            
          </div>
          <div style={styles.imageDescription}>
            <p>
              Format: JPEG or PNG only
              <br />
              Size: 1000 x 1000 pixels minimum
              <br />
              5MB maximum
              <br />
              The picture will be cropped if not square.
            </p>
            <label style={styles.imageUploadButton} class="btn btn-primary">
                <input
                  type="file"
                  name="myImage"
                  onChange={setImage}
                  style={{display: 'none'}}
                />
                  Upload your image
            </label>
            { selectedImage && (
              <Button onClick={removeImage}>Remove</Button>
            )}
            { postImageErrorMessage && (
              <div style={styles.errorMessage}>{postImageErrorMessage}</div>
            )}
            {
              savingImageMessage && (
                <div style={styles.savingImageMessage}>{savingImageMessage}</div>
              )
            }
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SignupStepUploadImage;