import {
  JUST_RETURN,
  PICK_SOCIAL_SITE, 
  FETCH_SOCIAL_DATA,
  CLEAR_ERROR_MESSAGE,
  ADD_SOCIAL_ERROR_MESSAGE
} from '../actions/Social/socialAction';

const initialState = {
  site: 'main',
  errMessage: ''
};

function social(state = initialState, action) {
  switch (action.type) {
    case JUST_RETURN:
    {
      let newState = Object.assign({}, state);
      if (action.errMessage) {
        newState.errMessage = action.errMessage;
      }
      return newState;
    }
    case PICK_SOCIAL_SITE:
    {
      // console.log('---action.site:',action.site);
      let newState = Object.assign({}, state);
      newState.site = action.site;
      if (action.errMessage) {
        newState.errMessage = action.errMessage;
      }
      return newState;
    }
    case FETCH_SOCIAL_DATA:
    {
      let newState = Object.assign({}, state);
      newState[action.site] = action.data;
      return newState;
    }
    case ADD_SOCIAL_ERROR_MESSAGE:
    {
      let newState = Object.assign({}, state);
      newState.errMessage = action.errMessage;
      return newState;
    }
    case CLEAR_ERROR_MESSAGE: {
      let newState = Object.assign({}, state);
      delete newState.errMessage;
      return newState;
    }
    default:
    {
      return state;
    }
  }
}

export default social;