import React, { PureComponent } from 'react';
import {IndexLinkContainer} from 'react-router-bootstrap';

class KnktLogo extends PureComponent {
  render() {
    const { divStyle, imgStyle } = this.props;
    return (
      <div style={divStyle}>
        <IndexLinkContainer style={imgStyle} to="/dashboard">
          <img src="/img/logo/knkt-logo.svg" alt="KNKT logo" />
          </IndexLinkContainer>
      </div>
    )
  }
}

export default KnktLogo;