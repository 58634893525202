import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Dialog from 'material-ui/Dialog';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import GigGroup from '../components/pages/calendar/GigGroup';
import history from '../redux/manageHistory';
import { updateEvent, addEvent } from '../actions/Calendar/calendarAction';
import EventFrom from '../components/pages/calendar/EventForm';
import { activateEvent, convertToUTCDate } from '../utils/calendar';
import { callApi } from '../utils/utils';

import '../style/calendar.css';
import styles from '../style/calendar';

const ct = require('countries-and-timezones');

class CalendarEventsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      updateEventErrorModal: false,
      errorMessage: ''
    };

    this.splitEvents = this.splitEvents.bind(this);
    this.handleClosePage = this.handleClosePage.bind(this);
    this.getActiveEvents = this.getActiveEvents.bind(this);
    this.getInactiveEvents = this.getInactiveEvents.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.handleEventErrorModalDialogClose = this.handleEventErrorModalDialogClose.bind(this);
    this.addEvent = this.addEvent.bind(this);
  }

  splitEvents(tourEvents, user) {
    let active = [];
    let inactive = [];
    tourEvents.forEach(event => {
      if (event.artistId === user.artistId) {
        if (event.active) {
          active.push(event);
        } else {
          inactive.push(event);
        }
      } else {
        if (event.active) {
          active.push(event);
        }
      }
    });
    return {
      active: active,
      inactive: inactive
    }
  }

  handleClosePage() {
    history.push('/calendar');
  }
  
  updateEvent(activate, event) {
    activateEvent(event.tourEventId, activate)
      .then(response => {
        if (response.data && response.data.success == true) {
          this.props.updateEvent(activate, event);
        }
      })
      .catch(err => {
        this.setState({
          updateEventErrorModal: true,
          errorMessage: err.mesage
        })
      });
  }

  getActiveEvents(active, props) {
    let thisEvents = [];

    active.forEach(event => {
      thisEvents.push(
        <CSSTransition key={event.tourEventId} classNames="eventTransition" timeout={300}>
          <Row>
            <Col xs={12} sm={10} smOffset={1}>
              <GigGroup event={event} active={true} user={props.user} updateEvent={this.updateEvent}/>
            </Col>
          </Row>
        </CSSTransition>
      );
    });

    return thisEvents;
  }

  getInactiveEvents(inactive, props) {
    let thisEvents = [];

    inactive.forEach(event => {
      thisEvents.push(
        <CSSTransition key={event.tourEventId} classNames="eventTransition" timeout={300}>
          <Row>
            <Col xs={12} sm={10} smOffset={1}>
              <GigGroup event={event} active={false} user={props.user} updateEvent={this.updateEvent}/>
            </Col>
          </Row>
        </CSSTransition>
      );
    })

    return thisEvents;
  }

  handleOpenDialog() {
    this.setState({
      open: true
    });
  }

  handleDialogClose() {
    this.setState({
      open: false
    });
  }

  handleEventErrorModalDialogClose() {
    this.setState({
      updateEventErrorModal: false,
      errorMessage: ''
    });
  }

  addEvent(event) {
    let {
      name,
      eventStartDate,
      eventEndDate,
      countryCode,
      city,
      venue,
      active,
      ticketLink,
      artistId
    } = event;

    const timezones = ct.getTimezonesForCountry(countryCode.toUpperCase());
    let utcOffset = 0;

    if (timezones && timezones.length > 0) {
      utcOffset = timezones[0].utcOffset;
    }

    // converting to UTC before sending to server
    eventStartDate = convertToUTCDate(eventStartDate).getTime() + utcOffset;
    eventEndDate = convertToUTCDate(eventEndDate).getTime() + utcOffset;

    callApi('/api/v1/tourEvent', 'post', {
      name,
      eventStartDate,
      eventEndDate,
      countryCode,
      city,
      venue,
      active,
      ticketLink,
      artistId,
      utcOffset
    }).then(res => {
      if (res.data && res.data.success == true) {
        const newEvent = res.data.tourEvent;

        this.props.addEvent(newEvent);
        this.handleDialogClose();
      }
    });
  }
  
  render() {
    const { isOwner, tourEvents, user } = this.props;
    const { active, inactive } = this.splitEvents(tourEvents, user);
    
    return (
      <div style={{color: '#ffffff', overflow: 'scroll'}}>
        { isOwner && (
          <div style={styles.gigAddEvent}>
          <span style={styles.gigAddEventButton} onClick={this.handleOpenDialog.bind(this)}>
            <FontAwesomeIcon icon={faPlusCircle}/> Add Event
          </span>
          </div>
        )}

        { active.length > 0 && (
          <TransitionGroup component={null}>
            <div style={styles.calendarEventTitle}>Upcoming Events:</div>
            { this.getActiveEvents(active, this.props) }
          </TransitionGroup>
        )}

        { isOwner && inactive.length > 0 && (
          <React.Fragment>
            <div style={styles.calendarEventTitle}>Past Events:</div>
            { this.getInactiveEvents(inactive, this.props) }
          </React.Fragment>
        )}

        <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
          <Dialog
            modal={false}
            open={this.state.open}
            onRequestClose={this.handleDialogClose}
            autoScrollBodyContent={true}
            bodyClassName="eventDialogBackground"
          >
            <EventFrom user={this.props.user} addEvent={this.addEvent} />
          </Dialog>

          <Dialog
            modal={true}
            open={this.state.updateEventErrorModal}
            onRequestClose={this.handleEventErrorModalDialogClose}
            autoScrollBodyContent={true}
            bodyClassName="eventDialogBackground"
          >
            { this.state.errorMessage }
          </Dialog>
        </MuiThemeProvider>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    tours: state.calendar.tours,
    tourEvents: state.calendar.tourEvents,
    edit: state.calendar.edit,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEvent: (activate, event) => dispatch(updateEvent(activate, event)),
    addEvent: event => dispatch(addEvent(event))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEventsContainer);

// export default CalendarEvents;