const igCategories = [
  {
    text: 'Engagement',
    key: 'engagement',
    apiKey: 'engagement'
  },
  {
    text: 'Followers',
    key: 'followers',
    apiKey: 'followers'
  },
  {
    text: 'Interactions',
    key: 'interactions',
    apiKey: 'interactions'
  },
  {
    text: 'Saved',
    key: 'saved',
    apiKey: 'saved'
  },
  {
    text: 'Reach',
    key: 'reach',
    apiKey: 'reach'
  },
  {
    text: 'Impressions',
    key: 'impressions',
    apiKey: 'impressions'
  }
];

const sampleIgData = {
  "createdDate": "2020-07-14T02:07:05.703Z",
  "knktId": 2,
  "engagement": {
    "lifeTime": 350,
    "sevenDay": 134,
    "twentyEightDay": 245
  },
  "followers": {
    "lifeTime": 100,
    "sevenDay": 40,
    "twentyEightDay": 65
  },
  "impressions": {
    "lifeTime": 200,
    "sevenDay": 98,
    "twentyEightDay": 145
  },
  "interactions": {
    "lifeTime": 500,
    "sevenDay": 298,
    "twentyEightDay": 345
  },
  "reach": {
    "lifeTime": 420,
    "sevenDay": 209,
    "twentyEightDay": 374
  },
  "saved": {
    "lifeTime": 440,
    "sevenDay": 298,
    "twentyEightDay": 345
  },
  "oneDay": [
    {
      "createdDate": "2020-07-14T00:27:47.065Z",
      "engagement": 566,
      "followers": 200,
      "impressions": 440,
      "interactions": 350,
      "reach": 420,
      "saved": 310
    },
    {
      "createdDate": "2020-07-13T00:27:47.065Z",
      "engagement": 566,
      "followers": 210,
      "impressions": 440,
      "interactions": 360,
      "reach": 420,
      "saved": 300
    },
    {
      "createdDate": "2020-07-12T00:27:47.065Z",
      "engagement": 556,
      "followers": 201,
      "impressions": 540,
      "interactions": 360,
      "reach": 420,
      "saved": 320
    },
    {
      "createdDate": "2020-07-11T00:27:47.065Z",
      "engagement": 506,
      "followers": 191,
      "impressions": 504,
      "interactions": 320,
      "reach": 365,
      "saved": 303
    },
    {
      "createdDate": "2020-07-10T00:27:47.065Z",
      "engagement": 506,
      "followers": 191,
      "impressions": 504,
      "interactions": 320,
      "reach": 365,
      "saved": 300
    },
    {
      "createdDate": "2020-07-09T00:27:47.065Z",
      "engagement": 506,
      "followers": 191,
      "impressions": 504,
      "interactions": 320,
      "reach": 365,
      "saved": 301
    },
    {
      "createdDate": "2020-07-08T00:27:47.065Z",
      "engagement": 502,
      "followers": 178,
      "impressions": 498,
      "interactions": 306,
      "reach": 325,
      "saved": 300
    },
    {
      "createdDate": "2020-07-07T00:27:47.065Z",
      "engagement": 502,
      "followers": 178,
      "impressions": 498,
      "interactions": 306,
      "reach": 325,
      "saved": 300
    },
    {
      "createdDate": "2020-07-06T00:27:47.065Z",
      "engagement": 502,
      "followers": 178,
      "impressions": 498,
      "interactions": 306,
      "reach": 325,
      "saved": 303
    },
    {
      "createdDate": "2020-07-05T00:27:47.065Z",
      "engagement": 542,
      "followers": 198,
      "impressions": 478,
      "interactions": 286,
      "reach": 305,
      "saved": 305
    },
    {
      "createdDate": "2020-07-04T00:27:47.065Z",
      "engagement": 552,
      "followers": 198,
      "impressions": 478,
      "interactions": 286,
      "reach": 325,
      "saved": 300
    },
    {
      "createdDate": "2020-07-03T00:27:47.065Z",
      "engagement": 542,
      "followers": 198,
      "impressions": 478,
      "interactions": 286,
      "reach": 305,
      "saved": 300
    },
    {
      "createdDate": "2020-07-02T00:27:47.065Z",
      "engagement": 512,
      "followers": 198,
      "impressions": 468,
      "interactions": 246,
      "reach": 299,
      "saved": 310
    },
    {
      "createdDate": "2020-07-01T00:27:47.065Z",
      "engagement": 512,
      "followers": 198,
      "impressions": 468,
      "interactions": 246,
      "reach": 299,
      "saved": 330
    },
    {
      "createdDate": "2020-06-30T00:27:47.065Z",
      "engagement": 502,
      "followers": 179,
      "impressions": 458,
      "interactions": 236,
      "reach": 292,
      "saved": 300
    },
    {
      "createdDate": "2020-06-29T00:27:47.065Z",
      "engagement": 502,
      "followers": 179,
      "impressions": 458,
      "interactions": 236,
      "reach": 292,
      "saved": 310
    },
    {
      "createdDate": "2020-06-28T00:27:47.065Z",
      "engagement": 502,
      "followers": 179,
      "impressions": 458,
      "interactions": 236,
      "reach": 292,
      "saved": 320
    },
    {
      "createdDate": "2020-06-27T00:27:47.065Z",
      "engagement": 502,
      "followers": 179,
      "impressions": 458,
      "interactions": 236,
      "reach": 292,
      "saved": 300
    },
    {
      "createdDate": "2020-06-26T00:27:47.065Z",
      "engagement": 492,
      "followers": 179,
      "impressions": 448,
      "interactions": 232,
      "reach": 282,
      "saved": 301
    },
    {
      "createdDate": "2020-06-25T00:27:47.065Z",
      "engagement": 502,
      "followers": 179,
      "impressions": 458,
      "interactions": 236,
      "reach": 292,
      "saved": 300
    },
    {
      "createdDate": "2020-06-24T00:27:47.065Z",
      "engagement": 488,
      "followers": 169,
      "impressions": 418,
      "interactions": 216,
      "reach": 262,
      "saved": 302
    },
    {
      "createdDate": "2020-06-23T00:27:47.065Z",
      "engagement": 488,
      "followers": 169,
      "impressions": 418,
      "interactions": 216,
      "reach": 262,
      "saved": 333
    },
    {
      "createdDate": "2020-06-22T00:27:47.065Z",
      "engagement": 478,
      "followers": 159,
      "impressions": 408,
      "interactions": 206,
      "reach": 272,
      "saved": 318
    },
    {
      "createdDate": "2020-06-21T00:27:47.065Z",
      "engagement": 471,
      "followers": 151,
      "impressions": 401,
      "interactions": 201,
      "reach": 271,
      "saved": 320
    },
    {
      "createdDate": "2020-06-20T00:27:47.065Z",
      "engagement": 471,
      "followers": 151,
      "impressions": 401,
      "interactions": 201,
      "reach": 271,
      "saved": 307
    },
    {
      "createdDate": "2020-06-19T00:27:47.065Z",
      "engagement": 477,
      "followers": 157,
      "impressions": 407,
      "interactions": 207,
      "reach": 277,
      "saved": 305
    },
    {
      "createdDate": "2020-06-18T00:27:47.065Z",
      "engagement": 477,
      "followers": 157,
      "impressions": 407,
      "interactions": 207,
      "reach": 277,
      "saved": 301
    },
    {
      "createdDate": "2020-06-17T00:27:47.065Z",
      "engagement": 477,
      "followers": 157,
      "impressions": 407,
      "interactions": 207,
      "reach": 277,
      "saved": 320
    },
    {
      "createdDate": "2020-06-16T00:27:47.065Z",
      "engagement": 467,
      "followers": 147,
      "impressions": 397,
      "interactions": 197,
      "reach": 267,
      "saved": 310
    }
  ]
};

const sampleIgTopEngagement = {
  "lifeTime": {
    "ageGroup_13_17": 240,
    "ageGroup_18_24": 350,
    "ageGroup_25_34": 223,
    "ageGroup_35_44": 234,
    "ageGroup_45_54": 345,
    "ageGroup_55_64": 260,
    "gender_neutral": 253,
    "men": 263,
    "women": 243,
    "topEngagements": [
      {
        "createdDate": "2020-07-23T05:03:15.262Z",
        "message": "top lifeTime1",
        "link": 'http://yahoo.com',
        "changePercentage": -25
      },
      {
        "createdDate": "2020-07-21T05:03:15.262Z",
        "message": "top lifeTime2",
        "link": 'http://yahoo.com',
        "changePercentage": 15
      },
      {
        "createdDate": "2020-07-12T05:03:15.262Z",
        "message": "top lifeTime3",
        "link": 'http://yahoo.com',
        "changePercentage": 5
      },
      {
        "createdDate": "2020-07-05T05:03:15.262Z",
        "message": "top lifeTime4",
        "link": 'http://yahoo.com',
        "changePercentage": 10
      },
      {
        "createdDate": "2020-07-24T05:03:15.262Z",
        "message": "top lifeTime5",
        "link": 'http://yahoo.com',
        "changePercentage": 8
      }
    ]
  },
  "createdDate": "2020-07-23T05:03:15.262Z",
  "id": {
    "counter": 0,
    "date": "2020-07-23T05:03:15.262Z",
    "machineIdentifier": 0,
    "processIdentifier": 0,
    "time": 0,
    "timeSecond": 0,
    "timestamp": 0
  },
  "knktId": 2,
  "sevenDay": {
    "ageGroup_13_17": 40,
    "ageGroup_18_24": 50,
    "ageGroup_25_34": 23,
    "ageGroup_35_44": 34,
    "ageGroup_45_54": 45,
    "ageGroup_55_64": 60,
    "gender_neutral": 53,
    "men": 63,
    "women": 43,
    "topEngagements": [
      {
        "createdDate": "2020-07-23T05:03:15.262Z",
        "message": "top sevenDay1",
        "link": 'http://yahoo.com',
        "changePercentage": -5
      },
      {
        "createdDate": "2020-07-21T05:03:15.262Z",
        "message": "top sevenDay2",
        "link": 'http://yahoo.com',
        "changePercentage": -5
      },
      {
        "createdDate": "2020-07-12T05:03:15.262Z",
        "message": "top sevenDay3",
        "link": 'http://yahoo.com',
        "changePercentage": 8
      },
      {
        "createdDate": "2020-07-05T05:03:15.262Z",
        "message": "top sevenDay4",
        "link": 'http://yahoo.com',
        "changePercentage": 10
      },
      {
        "createdDate": "2020-07-24T05:03:15.262Z",
        "message": "top sevenDay5",
        "link": 'http://yahoo.com',
        "changePercentage": 15
      }
    ]
  },
  "twentyEightDay": {
    "ageGroup_13_17": 140,
    "ageGroup_18_24": 150,
    "ageGroup_25_34": 123,
    "ageGroup_35_44": 134,
    "ageGroup_45_54": 145,
    "ageGroup_55_64": 160,
    "gender_neutral": 153,
    "men": 163,
    "women": 143,
    "topEngagements": [
      {
        "createdDate": "2020-07-23T05:03:15.262Z",
        "message": "top twentyEightDay1",
        "link": 'http://yahoo.com',
        "changePercentage": -5
      },
      {
        "createdDate": "2020-07-21T05:03:15.262Z",
        "message": "top twentyEightDay2",
        "link": 'http://yahoo.com',
        "changePercentage": 15
      },
      {
        "createdDate": "2020-07-12T05:03:15.262Z",
        "message": "top twentyEightDay3",
        "link": 'http://yahoo.com',
        "changePercentage": 8
      },
      {
        "createdDate": "2020-07-05T05:03:15.262Z",
        "message": "top twentyEightDay4",
        "link": 'http://yahoo.com',
        "changePercentage": 12
      },
      {
        "createdDate": "2020-07-24T05:03:15.262Z",
        "message": "top twentyEightDay5",
        "link": 'http://yahoo.com',
        "changePercentage": 15
      }
    ]
  }
};

const sampleIgLocation = {
  "createdDate": "2020-07-24T05:03:15.262Z",
  "id": {
    "counter": 0,
    "date": "2020-07-30T02:06:28.380Z",
    "machineIdentifier": 0,
    "processIdentifier": 0,
    "time": 0,
    "timeSecond": 0,
    "timestamp": 0
  },
  "knktId": 2,
  "lifeTime": {
    "cities": [
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      }
    ],
    "countries": [
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      }
    ]
  },
  "sevenDay": {
    "cities": [
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      }
    ],
    "countries": [
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      }
    ]
  },
  "twentyEightDay": {
    "cities": [
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      }
    ],
    "countries": [
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      }
    ]
  }
};

export {
  igCategories,
  sampleIgData,
  sampleIgTopEngagement,
  sampleIgLocation
};