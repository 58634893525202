import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import KnktCalendarPage from '../components/pages/calendar/KnktCalendarPage';
import { userTypePerPage } from '../utils/constants';
import history from '../redux/manageHistory';

const KnktCalendarContainer =  (props) =>{

    if (props.user && props.user.account) {
      if (!userTypePerPage['knktcalendar'].includes(props.user.account)) {
        return history.push('/dashboard');
      }
    }

    const styles = {
        parentContainer: {
            height: "100%", color: "#fff", textAlign: "center"
        }
    };

    return(
    <Row style={styles.parentContainer}>
       <Col xs="12"><KnktCalendarPage user={props.user}/></Col>

    </Row>
    );
}

export default KnktCalendarContainer;