import React  from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import ReactCountryFlag from "react-country-flag";

import styles from '../../../style/calendar';
import { MONTH_STRING } from '../../../utils/constants';
import { convertUTCDateToLocalDate } from '../../../utils/calendar';

function getDate(thisDate, utcOffset) {
  try {
    const serverData = new Date(thisDate);
    let utc = serverData.getTime() - utcOffset;
    const utcDate = new Date(utc);
    let d = convertUTCDateToLocalDate(utcDate);

    return {
      day: d.getDate(),
      month: MONTH_STRING[d.getMonth()+1],
      year: d.getFullYear()
    }
  } catch (e) {
    return {
      day: '',
      month: '',
      year: ''
    }
  }
}

function getText(count) {
  if (!isNaN(count)) {
    if (count > 1000) {
      return count/1000 + 'k';
    }
  }
  return count;
}

function editEvent(props, add, tour, event) {
  if (props.updateTour) {
    props.updateTour(add, tour, event);
  } else if (props.updateEvent) {
    props.updateEvent(add, event);
  }
}

function GigGroup(props) {
  const event = props.event;
  const { eventStartDate, name, country, city, venue, attending, countryCode, ticketLink, utcOffset } = event;
  const thisDate = getDate(eventStartDate, utcOffset);
  const attendingText = getText(attending);
  return (
    <Row key={event.tourEventId} style={{position: 'relative', opacity: 1}}>
      <Col xs={2} style={{...styles.gigGroup, ...styles.gigDate}} className="text-center">
        <div style={styles.gigDateMonth}>{thisDate.month}</div>
        <div style={styles.gigDateDay}>{thisDate.day}</div>
        <div style={styles.gigDateYear}>{thisDate.year}</div>
      </Col>
      <Col xs={9} style={{...styles.gigGroup, ...styles.gigTile}} className="gigGroup-mobile">
        <div>
          <ReactCountryFlag code={countryCode} /> { country }
          <br />
          { name }
          <br />
          <FontAwesomeIcon icon={faMapMarkerAlt} /> { venue }, { city }
        </div>
        <div style={styles.gigTicket} className="text-center">
          <a style={styles.gigTicketLink} href={ticketLink}>Tickets</a>
        </div>
        <div style={styles.gigAttending}>
          <span>{ attendingText }</span><span style={styles.gigAttendingFont}> Attending</span>
        </div>
      </Col>
      { props.user && props.user.account == 'artist' && (
        <div style={styles.gigClose}>
          { props.active && (
            <span style={{cursor: 'pointer'}} onClick={editEvent.bind(this, props, false, props.tour, event)}>X</span>
          )}
          { typeof props.active != 'undefined' && props.active == false && (
            <span style={{cursor: 'pointer'}} onClick={editEvent.bind(this, props, true, props.tour, event)}>
              <FontAwesomeIcon icon={faCheck} />
            </span>
          )}
        </div>
      )}
    </Row>
  );
}

export default GigGroup;
