import React, { Fragment, useState, useRef, useEffect } from 'react';

import { Row, Col, Button, Card, Image } from 'react-bootstrap';
import KnktButton from '../../../common/KnktButton';
import {
  fetchHighlightsData,
  fetchFbPageSources,
  fetchFbLocation,
  fetchFbMetrics,
  fetchFbAudience,
} from '../../../../utils/insights';
import SearchBar from '../../../common/SearchBar';
import { convertToMonDDYYYY } from '../../../../utils/calendar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faSoundcloud,
  faYoutube,
  faTwitter,
  faSpotify,
  faDeezer,
} from '@fortawesome/free-brands-svg-icons';

const styles = {
  searchBarRow: {
    margin: '1rem 0',
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
    transition: 'all 1s',
  },
  brandGroup: {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  },
  cardRow: {
    color: 'rgb(255,255,255)',
    justifyContent: 'center',
    padding: '1rem',
    margin: '1rem 0.5rem',
  },
  card: {
    backgroundColor: 'rgb(29, 30, 30)',
    color: 'rgb(255,255,255)',
    boxShadow: '0px 0px 20px -1px #007bff',
  },
  cardHeader: {
    padding: '0',
  },
  cardHorizontal: {
    display: 'flex',
    flex: '1 1 auto',
    flexWrap: 'wrap',
  },
  clickCard: {
    backgroundColor: '#002733',
    color: 'rgb(255,255,255)',
    border: '1px solid #383838',
  },
  cardDetails: {
    width: '60rem',
    wordBreak: 'break-word',
  },
  cardText: {
    fontSize: '1rem',
  },
  wrapper: {
    maxHeight: '70vh',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  timeStampFooter: {
    float: 'right',
    marginTop: '8rem',
  },
  centerFlex: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  pageHeader: {
    marginTop: '1rem',
  },
  textGlow: {
    textShadow: '0px 0px 10px rgb(0 123 255)',
    color: 'rgb(0 123 255)',
  },
};

const RenderCards = (props) => {
  return (
    <Fragment>
      {props.data.map((content, key) => (
        <Row style={styles.cardRow} key={key}>
          <Card style={styles.card}>
            <div
              className="card-horizontal"
              style={styles.cardHorizontal}
            >
              <div>
                <Image
                  src="https://images.unsplash.com/photo-1459749411175-04bf5292ceea?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=200"
                  fluid
                />
              </div>
              <div
                style={styles.cardDetails}
                className="card-body text-left"
              >
                <h4 className="card-title" style={styles.textGlow}>
                  {content.platform}
                </h4>
                <div style={styles.cardText} className="card-text">
                  {content.text}
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div style={styles.textGlow}>{content.createdDate}</div>
            </div>
          </Card>
        </Row>
      ))}
    </Fragment>
  );
};

const AudienceCards = (props) => {
  const { data } = props;
  if (!data) {
    return <></>;
  }
  return (
    <Fragment>
      {props.data.insights.map((content, key) => (
        <Row style={styles.cardRow} key={key}>
          <Card style={styles.card}>
            <div
              className="card-horizontal"
              style={styles.cardHorizontal}
            >
              <div>
                <Image
                  src="https://images.unsplash.com/photo-1459749411175-04bf5292ceea?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=400&q=200"
                  fluid
                />
              </div>
              <div
                style={styles.cardDetails}
                className="card-body text-left"
              >
                <h4 className="card-title" style={styles.textGlow}>
                  {content.link}
                </h4>
                <div style={styles.cardText} className="card-text">
                  {content.message +
                    ' with a change percentage of ' +
                    content.changePercentage}
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div style={styles.textGlow}>{content.createdDate}</div>
            </div>
          </Card>
        </Row>
      ))}
    </Fragment>
  );
};

const Metrics = (props) => {
  const [highlightsData, setHighlightsData] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [pageSourcesData, setPageSourcesData] = useState({});
  const [metricsData, setMetricsData] = useState({});
  const [audienceData, setAudienceData] = useState({});
  const [change, setChange] = useState(false);
  const [content, setContent] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [daysFilterKey, setDaysFilterKey] = useState('sevenDay');

  const [bookingTabsTitle, setBookingTabsTitle] = useState([
    {
      title: '7 Days',
      isActive: true,
      color: 'knktBlue',
    },
    {
      title: '28 Days',
      isActive: false,
      color: 'knktYellow',
    },
    {
      title: 'All Time',
      isActive: false,
      color: 'knktNeon',
    },
  ]);
  useEffect(() => {
    fetchData();
    getFbLocation();
    getFbAudience();
    getFbMetrics();
    getFbpageSources();
  }, []);

  const getFbLocation = async () => {
    let fetchData = await fetchFbLocation();
    setLocationData(fetchData);
    fetchData ? setChange(!change) : console.log('no location');
  };

  const getFbAudience = async () => {
    let fetchData = await fetchFbAudience();
    const audienceInsights = {
      sevenDay: { statusCard: '', insights: [] },
      twentyEightDay: { statusCard: '', insights: [] },
      lifeTime: { statusCard: '', insights: [] },
    };
    audienceInsights.sevenDay.statusCard = `Status Card for age group: ${fetchData.audience.sevenDay.ageGroup} Gender Neutral ${fetchData.audience.sevenDay.genderNeutral} Men ${fetchData.audience.sevenDay.men} Women ${fetchData.audience.sevenDay.women}`;
    audienceInsights.twentyEightDay.statusCard = `Status Card for age group: ${fetchData.audience.twentyEightDay.ageGroup} Gender Neutral ${fetchData.audience.twentyEightDay.genderNeutral} Men ${fetchData.audience.twentyEightDay.men} Women ${fetchData.audience.twentyEightDay.women}`;
    audienceInsights.lifeTime.statusCard = `Status Card for age group: ${fetchData.audience.lifeTime.ageGroup} Gender Neutral ${fetchData.audience.lifeTime.genderNeutral} Men ${fetchData.audience.lifeTime.men} Women ${fetchData.audience.lifeTime.women}`;

    fetchData.audience.sevenDay.topEngagements.forEach(
      (engagement) => {
        engagement.createdDate = convertToMonDDYYYY(
          engagement.createdDate
        );
        audienceInsights.sevenDay.insights = [
          ...audienceInsights.sevenDay.insights,
          { ...engagement },
        ];
      }
    );

    fetchData.audience.twentyEightDay.topEngagements.forEach(
      (engagement) => {
        engagement.createdDate = convertToMonDDYYYY(
          engagement.createdDate
        );
        audienceInsights.twentyEightDay.insights = [
          ...audienceInsights.twentyEightDay.insights,
          { ...engagement },
        ];
      }
    );

    fetchData.audience.lifeTime.topEngagements.forEach(
      (engagement) => {
        engagement.createdDate = convertToMonDDYYYY(
          engagement.createdDate
        );
        audienceInsights.lifeTime.insights = [
          ...audienceInsights.lifeTime.insights,
          { ...engagement },
        ];
      }
    );

    setAudienceData(audienceInsights);
    fetchData ? setChange(!change) : console.log('no audience');
  };

  const getFbMetrics = async () => {
    let fetchData = await fetchFbMetrics();
    setMetricsData(fetchData);
    fetchData ? setChange(!change) : console.log('no metrics');
  };

  const getFbpageSources = async () => {
    let fetchData = await fetchFbPageSources();
    setPageSourcesData(fetchData);
    fetchData ? setChange(!change) : console.log('no pagesources');
  };

  const fetchData = async () => {
    let fetchData = await fetchHighlightsData();
    setHighlightsData(
      fetchData.insights.map((el) => {
        el.createdDate = convertToMonDDYYYY(el.createdDate);
        return el;
      })
    );
    fetchData.insights.length > 0
      ? setChange(!change)
      : console.log('no highlights');
  };

  const getDaysKey = (key) => {
    return key === '7 Days'
      ? 'sevenDay'
      : key === '28 Days'
      ? 'twentyEightDay'
      : 'lifeTime';
  };

  const onClickBookingTab = (event) => {
    const updatedTabs = bookingTabsTitle.map((tab) => {
      if (tab.title === event.target.value) tab.isActive = true;
      else tab.isActive = false;
      return tab;
    });
    setBookingTabsTitle(updatedTabs);
    setDaysFilterKey(getDaysKey(event.target.value));
  };

  return (
    <Fragment>
      <Row>
        <Col
          xs="12"
          style={{ ...styles.centerFlex, ...styles.pageHeader }}
        >
          <h4>Insights</h4>
        </Col>

        <Col xs="12" style={{ ...styles.pageHeader }}>
          <div style={styles.buttonGroup}>
            {bookingTabsTitle.map((bookingTabs) => (
              <KnktButton
                key={bookingTabs.title}
                value={bookingTabs.title}
                type={bookingTabs.color}
                isGrouped={true}
                isActive={bookingTabs.isActive}
                parentCallBack={onClickBookingTab}
              />
            ))}
          </div>
        </Col>
      </Row>
      <div>
        {change && (
          <Fragment>
            <Row style={styles.searchBarRow}>
              <Col xs="12">
                <SearchBar
                  data={highlightsData}
                  getResult={(result) => setContent(result)}
                  isFiltered={(isFiltered) =>
                    setIsFiltered(isFiltered)
                  }
                />
              </Col>
              <Col xs="12" style={{ ...styles.brandGroup }}>
                <FontAwesomeIcon icon={faFacebook} size={'2x'} />
                <FontAwesomeIcon icon={faTwitter} size={'2x'} />
                <FontAwesomeIcon icon={faYoutube} size={'2x'} />
                <FontAwesomeIcon icon={faInstagram} size={'2x'} />
                <FontAwesomeIcon icon={faSpotify} size={'2x'} />
                <FontAwesomeIcon icon={faDeezer} size={'2x'} />
                <FontAwesomeIcon icon={faSoundcloud} size={'2x'} />
              </Col>
            </Row>

            <AudienceCards data={audienceData[daysFilterKey]} />
            {!isFiltered && <RenderCards data={highlightsData} />}

            {isFiltered && <RenderCards data={content} />}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Metrics;
