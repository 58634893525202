
/* The same component is used for rendering following routes

    www.knkt.io/fans/:socialmedia/likes
                                /followers
                                /shares
                                /comments

*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import DataModel from './ChartDataModel';

import PAGE_CONFIG from './page-config.json';
// import DEFAULT_CHART_OPTIONS from './chart-options.json';

import DATA from '../__mocks__/engagement-data.json';

import './engagement.scss'

class Engagement extends Component {

    getEngagementData(props) {

        let { match: { params: { socialmedia, attribute } } } = props;

        // fetch data now...
        let ApiData = DATA[socialmedia][attribute];

        let chartOptions = new DataModel(ApiData.graphData);
        let highlight = { ...ApiData.highlight };
        let data = { chartOptions, highlight };

        return data;
    }

    formatter(value, index) {

        let result = value;

        if (value > 1000) {
            result = (value / 1000).toString() + 'K';
        }

        return result;
    }

    componentWillMount() {

        let data = this.getEngagementData(this.props);
        this.setState(data);
    }

    componentWillReceiveProps(newProps) {

        let data = this.getEngagementData(newProps);
        this.setState(data);

    }

    render() {
        const { chartOptions, highlight } = this.state;
        return (
            <div className="engagement">
                <div className="chart">
                    <ReactEcharts
                        option={chartOptions}
                        style={PAGE_CONFIG.chartStyle}
                        opts={{ renderer: 'svg' }} />
                </div>
                <div className="highlight">
                    <div className="details">
                        <span className="date">{highlight.date}</span>
                        <a href={highlight.link} rel="noopener noreferrer" target="_blank" className="open-social-media">{highlight.detais}</a>
                    </div>
                </div>
            </div>
        );
    }
}

Engagement.propTypes = {
    match: PropTypes.object.isRequired
};

export default Engagement;