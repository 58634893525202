import React from 'react';
import FacebookProvider, { Login } from 'react-facebook';
import { Button } from 'react-bootstrap';

import {
  getFBAppId,
  getFBLoginEnabled
} from '../../config/siteConfig';

const FacebookLoginButton = props => {
  if (getFBLoginEnabled()) {
    const scope = `
    email,
    user_age_range,
    user_friends,
    user_gender,
    user_likes,
    user_birthday, 
    user_location,
    user_posts,
    ads_management, 
    business_management,
    groups_access_member_info,
    manage_pages, 
    pages_manage_cta,
    pages_show_list,
    publish_pages, 
    publish_to_groups,
    user_events,
    instagram_basic,
    instagram_manage_comments,
    read_insights, 
    instagram_manage_insights,
    instagram_basic`;

    return (
      <React.Fragment>
        <FacebookProvider appId={getFBAppId()}>
          <Login
            scope={scope}
            onResponse={props.handleFBResponse}
            onError={props.handleFBError}
          >
            <Button type="submit" variant="light" style={props.styles.loginButton}>
              <i className="fab fa-facebook-f" style={props.styles.icon}></i>
            </Button>
          </Login>
        </FacebookProvider>
      </React.Fragment>
    )
  }
  return null;
};

export default FacebookLoginButton;