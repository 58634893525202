import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import Paper from 'material-ui/Paper';

import styles from '../style/profile';

class UserContainer extends Component {
  render() {
    return (
      <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}>
        <Col sm={6} xs={12}>
          <Paper style={styles.profileItem} zDepth={3}>
            What's popping...
          </Paper>
        </Col>

        <Col sm={6} xs={12}>
          <Paper style={styles.profileItem} zDepth={3}>
            My Artist
          </Paper>
        </Col>

        <Col sm={6} xs={12}>
          <Paper style={styles.profileItem} zDepth={3}>
            Other
          </Paper>
        </Col>
      </MuiThemeProvider>
    )
  }
}

export default UserContainer;