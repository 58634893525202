import Radium from 'radium';
import React from 'react';
const buttonStyles = {
  button: {
    borderWidth: '0px 0px 2px 2px',
    borderRightStyle: 'initial',
    borderRightColor: 'initial',
    borderTopStyle: 'initial',
    borderTopColor: 'initial',
    color: 'white',
    display: 'inline-block',
    fontWeight: '400',
    textAlign: 'center',
    verticalAlign: 'middle',
    userSelect: 'none',
    backgroundColor: 'transparent',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    borderRadius: '.25rem',
    transition:
      'all .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
  },
  twoStates: {
    knktBlue: {
      borderColor: '#09c6f9',
      ':hover': {
        boxShadow: '#09c6f9 0px 0px 20px',
        backgroundColor: '#045de9',
        backgroundImage:
          'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
      },
      ':active': {
        backgroundColor: '#00f9ff',
      },
      ':disabled': {
        backgroundColor: '#ccc',
      },
      ':focus': {
        outline: '0',
      },
    },
    knktNeon: {
      borderColor: '#21ff00',
      ':hover': {
        boxShadow: '#21ff00 0px 0px 20px',
        backgroundColor: '#21ff00',
        backgroundImage:
          'linear-gradient(315deg, #f8ef42 0%, #0fd64f 100%)',
      },
      ':active': {
        backgroundColor: '#7cffcb',
        backgroundImage:
          'linear-gradient(315deg, #7cffcb 0%, #74f2ce 74%)',
      },
      ':disabled': {
        backgroundColor: '#ccc',
      },
      ':focus': {
        outline: '0',
      },
    },
    knktRed: {
      borderColor: '#e70256',
      ':hover': {
        boxShadow: '#e70256 0px 0px 20px',
        backgroundColor: '#e70256',
        backgroundImage:
          'linear-gradient(100deg,#aa039f,#ed014d,#f67916)',
      },
      ':active': {
        backgroundColor: '#e70256',
        backgroundImage:
          'linear-gradient(100deg,#aa039f,#ed014d,#f67916)',
      },
      ':disabled': {
        backgroundColor: '#ccc',
      },
      ':focus': {
        outline: '0',
      },
    },
    knktYellow: {
      borderColor: '#fbd72b',
      ':hover': {
        boxShadow: '#fbd72b 0px 0px 15px',
        backgroundColor: '#f9484a',
        backgroundImage:
          'linear-gradient(315deg, #f9484a 0%, #fbd72b 74%)',
      },
      ':active': {
        backgroundColor: '#f9484a',
        backgroundImage:
          'linear-gradient(315deg, #f9484a 0%, #fbd72b 74%)',
      },
      ':disabled': {
        backgroundColor: '#ccc',
      },
      ':focus': {
        outline: '0',
      },
    },
    knktBlurple: {
      borderColor: 'rgba(31,212,248,1)',
      ':hover': {
        boxShadow: 'rgba(31,212,248,1) 0px 0px 15px',
        backgroundColor: 'rgba(31,212,248,1)',
        backgroundImage:
          'linear-gradient( 105.5deg,  rgba(31,212,248,1) 11%, rgba(218,15,183,1) 74.9% )',
      },
      ':active': {
        backgroundColor: '#f9484a',
        backgroundImage:
          'linear-gradient( 105.5deg,  rgba(31,212,248,1) 11%, rgba(218,15,183,1) 74.9% )',
      },
      ':disabled': {
        backgroundColor: '#ccc',
      },
      ':focus': {
        outline: '0',
      },
    },
    knktOcean: {
      borderColor: 'rgba(99,251,215,1)',
      ':hover': {
        boxShadow: 'rgba(99,251,215,1) 0px 0px 15px',
        backgroundColor: ' rgba(99,251,215,1)',
        backgroundImage:
          'linear-gradient( 68.4deg,  rgba(99,251,215,1) -0.4%, rgba(5,222,250,1) 100.2% )',
      },
      ':active': {
        backgroundColor: '#f9484a',
        backgroundImage:
          'linear-gradient( 68.4deg,  rgba(99,251,215,1) -0.4%, rgba(5,222,250,1) 100.2% )',
      },
      ':disabled': {
        backgroundColor: '#ccc',
      },
      ':focus': {
        outline: '0',
      },
    },
  },
  active: {
    knktBlue: {
      borderColor: '#09c6f9',
      backgroundColor: '#045de9',
      backgroundImage:
        'linear-gradient(315deg, #045de9 0%, #09c6f9 74%)',
      ':hover': {
        boxShadow: '#09c6f9 0px 0px 20px',
      },
    },
    knktNeon: {
      borderColor: '#21ff00',
      backgroundColor: '#21ff00',
      backgroundImage:
        'linear-gradient(315deg, #f8ef42 0%, #0fd64f 100%)',
      ':hover': {
        boxShadow: '#21ff00 0px 0px 20px',
      },
    },
    knktRed: {
      borderColor: '#e70256',
      backgroundColor: '#e70256',
      backgroundImage:
        'linear-gradient(100deg,#aa039f,#ed014d,#f67916)',
      ':hover': {
        boxShadow: '#e70256 0px 0px 20px',
      },
    },
    knktYellow: {
      borderColor: '#fbd72b',
      backgroundColor: '#f9484a',
      backgroundImage:
        'linear-gradient(315deg, #f9484a 0%, #fbd72b 74%)',
      ':hover': {
        boxShadow: '#fbd72b 0px 0px 15px',
      },
    },
    knktBlurple: {
      borderColor: 'rgba(31,212,248,1)',
      backgroundColor: 'rgba(31,212,248,1)',
      backgroundImage:
        'linear-gradient( 105.5deg,  rgba(31,212,248,1) 11%, rgba(218,15,183,1) 74.9% )',
      ':hover': {
        boxShadow: 'rgba(31,212,248,1) 0px 0px 15px',
      },
    },
    knktOcean: {
      borderColor: 'rgba(99,251,215,1)',
      backgroundColor: 'rgba(99,251,215,1)',
      backgroundImage:
        'linear-gradient( 68.4deg,  rgba(99,251,215,1) -0.4%, rgba(5,222,250,1) 100.2% )',
      ':hover': {
        boxShadow: 'rgba(99,251,215,1) 0px 0px 15px',
      },
    },
  },
};

const prepCustomColor = (customColor, isActive, isGrouped) => {
  return isActive && isGrouped
    ? {
        borderColor: customColor.baseColor,
        backgroundColor: customColor.baseColor,
        backgroundImage: customColor.gradientColor,
        ':hover': {
          boxShadow: customColor.baseColor + ' 0px 0px 15px',
        },
      }
    : {
        borderColor: customColor.baseColor,
        ':hover': {
          boxShadow: customColor.baseColor + ' 0px 0px 20px',
          backgroundColor: customColor.baseColor,
          backgroundImage: customColor.gradientColor,
        },
        ':active': {
          backgroundColor: customColor.baseColor,
        },
        ':disabled': {
          backgroundColor: '#ccc',
        },
        ':focus': {
          outline: '0',
        },
      };
};

const prepDefaultColor = (type, isActive, isGrouped) => {
  return isActive && isGrouped
    ? buttonStyles.active[type]
    : buttonStyles.twoStates[type];
};

const KnktButton = (props) => {
  const {
    type,
    value,
    isGrouped,
    parentCallBack,
    isActive,
    customColor,
  } = props;

  const isOnClickProps = !!parentCallBack;

  const buttonTheme = customColor
    ? prepCustomColor(customColor, isActive, isGrouped)
    : prepDefaultColor(type, isActive, isGrouped);

  const targetValue = props.targetValue != null ? props.targetValue : value
  const onClick = (event) => {
    props.parentCallBack(event);
  };
  return (
    <button
      style={[buttonStyles.button, buttonTheme]}
      onClick={isOnClickProps ? onClick : undefined}
      value={targetValue}
    >
      {value}
    </button>
  );
};

export default Radium(KnktButton);
