// import { callApi } from '../../utils/utils';

const POPULATE_DATA = 'POPULATE_DATA';
const ACTIVATE_EVENT = 'ACTIVATE_EVENT';
const DEACTIVATE_EVENT = 'DEACTIVATE_EVENT';
const ADD_TO_TOUR = 'ADD_TO_TOUR';
const REMOVE_FROM_TOUR = 'REMOVE_FROM_TOUR';
const ADD_EVENT = "ADD_EVENT";
const ADD_TOUR = 'ADD_TOUR';
const UPDATE_SHOW_ARTIST = 'UPDATE_SHOW_ARTIST';
const UPDATE_TOUR_NAME = 'UPDATE_TOUR_NAME';
const ACTIVATE_TOUR = 'ACTIVATE_TOUR';
const DEACTIVATE_TOUR = 'DEACTIVATE_TOUR';
const DELETE_TOUR = 'DELETE_TOUR';

function populateData(data) {
  return function(dispatch) {
    dispatch({
      type: POPULATE_DATA,
      calendar: data
    });
  }
}

function activateEvent(event) {
  return function(dispatch) {
    dispatch({
      type: ACTIVATE_EVENT,
      event: event
    })
  }
}

function deactivateEvent(event) {
  return function(dispatch) {
    dispatch({
      type: DEACTIVATE_EVENT,
      event: event
    })
  }
}

function addToTour(tour, event) {
  return function(dispatch) {
    dispatch({
      type: ADD_TO_TOUR,
      tourEvent: event,
      tour: tour
    })
  }
}

// async function callApiToAddTour({artistId, active, tourName, backgroundImage,
//   events}) {
//   // const newTour = await new Promise(resolve => {
//   //   let response = await callApi('/api/v1/tour', 'post', {
//   //     artistId, active, tourName, backgroundImage,
//   //     events: events || []
//   //   });
//
//   let response = await new Promise(resolve => {
//     callApi('/api/v1/tour', 'post', {
//       artistId, active, tourName, backgroundImage,
//       events: events || []
//     }).then(res => {
//       if (res.data && res.data.success == true) {
//         console.log('------:', res.data.tour);
//         resolve(res.data.tour);
//       } else {
//         resolve(null);
//       }
//     });
//   })
//
//
//
//     //   .then(res => {
//     //   if (res.data && res.data.success == true) {
//     //     resolve(res.data.tour);
//     //   }
//     // })
//   // });
//
//   if (response) {
//     console.log('------:', response);
//     return response;
//   }
//
//   return null;
// }

function addTour(tour) {
  return function(dispatch) {
    dispatch({
      type: ADD_TOUR,
      tour: tour
    })
  }
}

function updateTourNameInStore(tourId, tourName) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_TOUR_NAME,
      tourId,
      tourName
    })
  }
}

function removeFromTour(tour, event) {
  return function(dispatch) {
    dispatch({
      type: REMOVE_FROM_TOUR,
      tourEvent: event,
      tour: tour
    })
  }
}

function updateTour(add, tour, event) {
  // TODO: need to update server side
  return add ? addToTour(tour, event) : removeFromTour(tour, event);
}

function updateEvent(activate, event) {
  // TODO: need to update server side
  return activate ? activateEvent(event) : deactivateEvent(event);
}

function addEvent(event) {
  return function(dispatch) {
    dispatch({
      type: ADD_EVENT,
      event: event
    })
  }
}

function updateShowArtistId(showArtistId) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_SHOW_ARTIST,
      showArtistId
    })
  }
}

function activateTour(activate, tourId) {
  if (activate) {
    return function (dispatch) {
      dispatch({
        type: ACTIVATE_TOUR,
        tourId
      });
    }
  } else {
    return function (dispatch) {
      dispatch({
        type: DEACTIVATE_TOUR,
        tourId
      });
    }
  }
}

function deleteTour(tourId) {
  return function(dispatch) {
    dispatch({
      type: DELETE_TOUR,
      tourId
    })
  }
}

export {
  POPULATE_DATA,
  ACTIVATE_EVENT,
  DEACTIVATE_EVENT,
  ADD_TO_TOUR,
  REMOVE_FROM_TOUR,
  ADD_EVENT,
  ADD_TOUR,
  UPDATE_SHOW_ARTIST,
  UPDATE_TOUR_NAME,
  ACTIVATE_TOUR,
  DEACTIVATE_TOUR,
  DELETE_TOUR,

  populateData,
  updateEvent,
  updateTour,
  addEvent,
  addTour,
  activateTour,
  updateTourNameInStore,
  updateShowArtistId,
  deleteTour
};