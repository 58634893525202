import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  useId,
} from "react";
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Form,
  FloatingLabel,
  useAccordionButton,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import Calendar from "../../common/Calender";
import KnktButton from "../../common/KnktButton";
import SelectComboBox from "../Promote/Promote";
import { isProduction } from "../../../utils/utils";

const styles = {
  header: {
    minHeight: "30vh",
    background: "#009FFF",
    background:
      "linear-gradient(to right, rgb(67 47 236), rgb(255 165 0),rgb(255 0 0))",
    color: "white",
    /* clipPath: "ellipse(100vw 60vh at 50% 50%)", */
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  details: {
    textAlign: "center",
    color: "#fff",
    marginTop: "-45px",
  },
  profilePic: {
    height: "6rem",
    width: "6rem",
    objectFit: "center",
    borderRadius: "50%",
    border: "2px solid #fff",
  },
  stats: {
    display: "flex",
  },
  statsDetail: {
    width: "10rem",
    textAlign: "center",
  },
  heading: {
    fontWeight: "400",
    fontSize: "1.3rem",
    margin: "1rem 0",
  },
  location: {
    svg: {
      display: "inline-block",
    },
    p: {
      verticalAlign: "middle",
    },
  },
  hr: {
    backgroundColor: "#ffffff",
  },
  button: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  card: {
    padding: "10px",
  },
  cardBg: {
    backgroundColor: "rgb(29 30 30)",
    boxShadow: "rgb(44 44 44) 5px 6px 16px 5px", //"5px 5px 15px 5px #2c2c2c"
    marginBottom: "5vh",
    color: "#FFFFFF",
  },
  bg: {
    backgroundColor: "rgb(26, 29, 36)",
    color: "rgb(255,255,255)",
    margin: "1rem",
  },
  label: {
    display: "inline-block",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
    margin: "1.5rem",
  },
  buttonGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.5rem",
  },
  oneColumnGrid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    justifyContent: "center",
    justifyItems: "center",
  },
  textArea: {
    width: "500px",
    height: "95px",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  radio: {
    gap: "12px",
    paddingBottom: "3rem",
  },
};

const RenderCalendar = () => {
  return (
    <>
      <Calendar />
    </>
  );
};

const FormatTypes = {
  audio: ["live", "recorded"],
  video: ["live", "recorded"],
  message: ["live", "recorded"],
};

const Catalog = (props) => {
  const id = useId();
  const { firstName, lastName, email, account } = props.user;
  const [catalogData, setCatalogData] = useState();
  const [selectedCatalogType, setselectedCatalogType] = useState();
  const [categories, setCategories] = useState();
  const [AccordionState, setAccordionState] = useState(2);
  const [revceivedList, setReceivedList] = useState([]);
  const [description, setDescription] = useState("");
  const [schedularTypes, setschedularTypes] = useState([
    {
      title: "Pre-Scheduled",
      isActive: true,
      color: "warning",
    },
    {
      title: "RSV P By Event",
      isActive: false,
      color: "info",
    },
  ]);
  const [schedularTypeelected, setschedularTypeSelected] = useState(
    schedularTypes[0].title
  );

  const getCatalog = async () => {
    try {
      let response = await fetch(
        "https://catalog.knkt.io/v1/accounts", //https://159.65.70.2/v1/accounts:55280
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
          },
          method: "GET",
        }
      );
      let data = await response.json();
      if (data) {
        setCatalogData(data);
      }
    } catch (error) {
      console.log(
        "api-error-todo v1/accounts error show error message to redirect back to previous page or refresh" +
          error
      );
    }
  };

  function CustomToggle({ children, eventKey, style, isHidden }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setAccordionState(eventKey);
    });

    const hideForProduction = {
      display: isProduction && isHidden ? "none" : "flex",
    };
    const customStyle = { ...style, ...hideForProduction };

    return (
      <Button
        id={eventKey + "toggle"}
        variant="outline-info"
        style={customStyle}
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    );
  }

  useEffect(() => {
    if (!catalogData) {
      getCatalog();
    } else {
      console.log(catalogData);
    }
  }, [catalogData]);

  useEffect(() => {
    if (AccordionState == 2) {
      console.log("accordian state", AccordionState);
    }
  }, [AccordionState]);

  const onClickBookingTab = (event) => {
    const updatedTabs = schedularTypes.map((tab) => {
      if (tab.title === event.target.value) tab.isActive = true;
      else tab.isActive = false;
      return tab;
    });
    setschedularTypes(updatedTabs);
    setschedularTypeSelected(event.target.value);
  };

  const getSelectedComboList = (list) => {
    const updatedList = list.map((i) => {
      return { tier: i, price: 10 };
    });
    setReceivedList(updatedList);
  };

  const onchangeTierPricing = (event) => {
    const updatedList = revceivedList.map((item, key) => {
      if (key.toString() === event.currentTarget.dataset.id) {
        item.price = parseFloat(event.currentTarget.value);
      }
      return item;
    });
    setReceivedList(updatedList);
  };

  const onChangeDescription = (event) => {
    setDescription(event.currentTarget.value);
  };

  const onClickFinish = (event) => {
    console.log(event);
  };

  const onClickCatalogType = (event) => {
    document.getElementById("1toggle").click();
    if (catalogData == null) return;
    setCategories(
      catalogData[0].categories.find(
        (cat) => cat.categoryName === event.target.value
      )
    );
    setselectedCatalogType(event.target.value);
    console.log(event.target.value);
  };

  const onClickProductType = (event) => {
    document.getElementById("2toggle").click();
  };

  console.log("catalogData", categories);
  return (
    <Accordion defaultActiveKey="0">
      <div style={styles.button}>
        <h4>{firstName + "'s Catalog"}</h4>
        <p></p>
      </div>
      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">
            Choose the products or services you plan to offer{" "}
          </h5>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div style={styles.buttonGroup}>
              <KnktButton
                type="knktNeon"
                isGrouped={true}
                targetValue="Video"
                value="Video"
                isActive={selectedCatalogType === "Video" ? true : false}
                parentCallBack={onClickCatalogType}
              />
              <KnktButton
                type="knktYellow"
                isGrouped={true}
                targetValue="Audio"
                value="Audio"
                isActive={selectedCatalogType === "Audio" ? true : false}
                parentCallBack={onClickCatalogType}
              />
              <KnktButton
                type="knktRed"
                isGrouped={true}
                targetValue="Message"
                value="Message"
                isActive={selectedCatalogType === "Message" ? true : false}
                parentCallBack={onClickCatalogType}
              />
            </div>
            <div style={styles.buttonGroup}>
              <CustomToggle eventKey="1" isHidden={true}>
                Next
              </CustomToggle>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">Select Format</h5>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            {categories && (
              <div style={styles.buttonGroup}>
                {categories.products.map((product) => (
                  <KnktButton
                    key={product.productId}
                    type="knktBlurple"
                    isGrouped={false}
                    targetValue={product.productName}
                    value={product.productName}
                    parentCallBack={onClickProductType}
                  />
                ))}
              </div>
            )}
            <div style={styles.buttonGroup}>
              <CustomToggle eventKey="0">Previous</CustomToggle>
              <CustomToggle eventKey="2" isHidden={true}>
                Next
              </CustomToggle>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">Select Tiers</h5>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <SelectComboBox getSelectedList={getSelectedComboList} />
            <div style={styles.buttonGroup}>
              <CustomToggle eventKey="1">Previous</CustomToggle>
              <CustomToggle eventKey="3" isHidden={true}>
                Next
              </CustomToggle>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">Select Pricing</h5>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            <div style={styles.oneColumnGrid}>
              {revceivedList.map((val, k) => {
                return (
                  <div
                    key={k}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 5% 70%",
                      alignItems: "center",
                    }}
                  >
                    <div>{val.tier}</div>
                    <span>$</span>
                    <input
                      type="number"
                      step={0.5}
                      style={{ borderRadius: "0.5rem" }}
                      data-id={k}
                      value={val.price}
                      onChange={onchangeTierPricing}
                    />
                    <br></br>
                  </div>
                );
              })}
              <div style={{ margin: "1rem" }}></div>
              <textarea
                placeholder="Description..."
                value={description}
                onChange={onChangeDescription}
                style={styles.textArea}
              ></textarea>
              <div style={{ margin: "1rem" }}></div>
              <KnktButton
                type="knktNeon"
                onClick={onClickFinish}
                isGrouped={false}
                targetValue="Finish"
                value="Finish"
              />
            </div>
            <div style={styles.buttonGroup}>
              <CustomToggle eventKey="2">Previous</CustomToggle>
              <CustomToggle eventKey="0" isHidden={true}>
                Next
              </CustomToggle>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Catalog;
