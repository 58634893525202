import React from 'react';
import { useState, useEffect } from 'react';

const styles = {
  comboBoxWrapper: {
    width: '100px',
    overflow: 'auto',
    borderRadius: '0.5rem',
    padding: '0.5rem',
  },
};

const Promote = (props) => {
  const [list, setList] = useState([]);
  const [ogList, setOgList] = useState([
    'Tier 1',
    'Tier 2',
    'Tier 3',
    'Tier 4',
  ]);

  const onSelectChange = (event) => {
    console.log('select change', event.currentTarget.value);
    setList([...list, event.currentTarget.value]);

    const filteredOgList = ogList.filter(
      (item) => item !== event.currentTarget.value
    );
    setOgList(filteredOgList);
  };

  const onSelectChange2 = (event) => {
    console.log('select change 2', event.currentTarget.value);
    setOgList([...ogList, event.currentTarget.value]);
    const filteredOgList = list.filter(
      (item) => item !== event.currentTarget.value
    );
    setList(filteredOgList);
  };

  useEffect(() => {
    props.getSelectedList(list);
  }, [list]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>Available Tiers</p>
        <select
          multiple
          onChange={onSelectChange}
          style={styles.comboBoxWrapper}
        >
          {ogList.map((item) => {
            return <option key={item}>{item}</option>;
          })}
        </select>
      </div>
      <div style={{ margin: '1rem' }} />
      <div
        style={{
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
        }}
      >
        <p>Selected Tiers</p>
        <select
          multiple
          onChange={onSelectChange2}
          style={styles.comboBoxWrapper}
        >
          {list.map((item) => {
            return <option key={item}>{item}</option>;
          })}
        </select>
      </div>
    </div>
  );
};

export default Promote;
