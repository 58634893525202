import React, { useState, useEffect } from 'react';

import TabHeader from './TabHeader';
import SocialInsights from './SocialInsights';

import pageConfig from './data/pageConfig.json';

const SocialSection = props => {
  const [ selectedSocialTab, setSelectedSocialTab ] = useState('');
  const [ tabData, setTabData ] = useState([]);

  useEffect(() => {
    let activeTab = '';
    const tabsData = pageConfig.socialTabHeadings.map(item => {
      const active = item.default;
      if (active) {
        activeTab = item.key;
      }
      return item;
    });

    setTabData(tabsData);
    setSelectedSocialTab(activeTab);
  }, []);

  const onFansTabSelected = tab => {
    setSelectedSocialTab(tab.key);
    // setActivityData(activitySummaryData[tab.key]);
  };

  const customStyle = {
    tabHeaders: {
      justifyContent: 'left'
    },
    tabHeading: {
      width: 150,
      marginTop: 5
    }
  };

  return (
    <React.Fragment>
      <TabHeader
        onFansTabSelected={onFansTabSelected}
        selectedFansTab={selectedSocialTab}
        tabData={tabData}
        customStyle={customStyle}
      />
      <SocialInsights
        selectedSocialTab={selectedSocialTab}
      />
    </React.Fragment>
  )
};

export default SocialSection;

// {/*<SocialHeader
//  tabsData={tabData}
//  selectedSocialTab={selectedSocialTab}
//  />*/}