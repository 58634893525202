import React  from 'react';
import { Marker } from "react-simple-maps";

const KnktMarker = props => {
  // const [ type, setType ] = useState(props.type || 'circle');

  const styles = {
    marker: {
      color: props.markerColor || '#FF5533'
    }
  };

  const getDropper = _ => {
    let marker = {};

    switch (props.type) {
      case 'dropper': {
        marker = {
          fill: styles.marker.color,
          stroke: styles.marker.color
        };
        break;
      }
      case 'dropperWithHole': {
        marker = {
          fill: 'none',
          stroke: styles.marker.color
        };
        break;
      }
      default: {
        return <circle r={8} fill={styles.marker.color} />
      }
    }

    return (
      <>
      <g
        fill={marker.fill}
        stroke={marker.stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(-12, -24)"
      >
        <circle cx="12" cy="10" r="3" />
        <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
      </g>
      <text
        textAnchor="middle"
        y="15"
        style={{ fontFamily: "system-ui", fill: styles.marker.color }}
      >
        { props.text }
      </text>
      </>
    )
  };

  return (
    <Marker coordinates={props.coordinate}
      onMouseEnter={() => {
        props.setTooltipContent(props.name);
      }}
      onMouseLeave={() => {
        props.setTooltipContent("");
      }}
    >
      { getDropper() }
    </Marker>
  )
};

export default KnktMarker;
