import React from 'react';
import { Nav } from 'react-bootstrap';

const TabLineHeader = props => {
  // let customTabHeaders = {};
  // let customTabHeading = {};

  const styles = {
    tabHeaders: {
      display: 'flex',
      height: 54
    },
    tabHeading: {
      flexDirection: 'row',
      flexWrap: 'no-wrap',
      width: '100%',
      outline: 'none',
      textDecoration: 'none',

      color: '#ffffff',
      display: 'inline-block',
      marginLeft: 5,
      marginTop: 5,
      textAlign: 'center',
      padding: 5
    },
    tabHeaderFirst: {
      marginLeft: 0
    },
    btnLink: {
      color: '#ffffff',
      textTransform: 'uppercase',
      backgroundColor: '#666666'
    },
    btnActive: {
      border: '0 solid #ffffff',
      borderBottom: '3px solid #ffffff',
      color: '#ffffff'
    },
    btnInActive: {
      color: '#aaaaaa'
    }
  };

  const tabsHeadingOnClick = (e, item) => {
    e.preventDefault();

    if (props.onFansTabSelected) {
      props.onFansTabSelected(item);
    }
  };

  // if (props.customStyle) {
  //   if (props.customStyle.tabHeaders) {
  //     customTabHeaders = props.customStyle.tabHeaders;
  //   }
  //   if (props.customStyle.tabHeading) {
  //     customTabHeading = props.customStyle.tabHeading;
  //   }
  // }

  return (
      <Nav defaultActiveKey={props.selectedFansTab} as="ul">
        {
          props.tabData && props.tabData.map((item, i) => {
            const active = item.key === props.selectedFansTab ? styles.btnActive : styles.btnInActive;

            return (
              <Nav.Item as="li" key={i}>
                <Nav.Link
                  href="#"
                  eventKey={item.key}
                  onClick={(e) => tabsHeadingOnClick(e, item)}
                  style={{...active}}
                >
                  {item.text}
                </Nav.Link>
              </Nav.Item>
            )
          })
        }
      </Nav>
  )
};

export default TabLineHeader;