import {CANNOT_BE_EMPTY, MINIMUM_6_CHARACTERS, PASSWORD_DO_NOT_MATCH, ENTER_VALID_EMAIL} from '../../utils/constants';
import history from '../../redux/manageHistory';
import {validateEmail, callApi} from '../../utils/utils';
import {signUpKeys, minimum_password_length} from '../../utils/constants';
import { loginUser } from '../Login/loginAction';
import { pageAfterSignup } from '../../config/siteConfig';

const SIGNUP_USER = 'SIGNUP_USER';
const UPDATE_SELECTED_TAB = 'UPDATE_SELECTED_TAB';
const UPDATE_FIELD = 'UPDATE_FIELD';
const UPDATE_FIELD_ERROR = 'UPDATE_FIELD_ERROR';
const SIGNUP_CONFIRMATION = 'SIGNUP_CONFIRMATION';
const SIGNUP_FAILED = 'Sign Up Failed';
const SIGNUP_SUCCESSFUL = 'All Set. Please go to Login Page';
const SIGNUP_FACEBOOK_SUCCESSFUL = 'All Set.';

const REDIRECT_AFTER_SIGNUP = pageAfterSignup;

const formFields = [
  {id: "company", floatingLabelText: "Company Name (Optional)", errField: 'companyErr', hintText: '', type: 'text'},
  {id: "firstName", floatingLabelText: "First Name", errField: 'firstNameErr', hintText: '', type: 'text'},
  {id: "lastName", floatingLabelText: "Last Name", errField: 'lastNameErr', hintText: '', type: 'text'},
  {id: "username", floatingLabelText: "Email (Username)", errField: 'usernameErr', hintText: '', type: 'text'},
  {
    id: "password",
    floatingLabelText: "Password",
    errField: 'passwordErr',
    hintText: 'Minimum 6 characters',
    type: 'password'
  },
  {
    id: "cPassword",
    floatingLabelText: "Confirm Password",
    errField: 'cPasswordErr',
    hintText: 'Same as Password field',
    type: 'password'
  }
]

function getAccountType(selectedTab) {
  return (signUpKeys.find(key => {
    return key.key === selectedTab;
  })).code;
}

function signUpUser({ 
  username, password, firstName, lastName, accountType, registrationCode, artistName, location, 
  foundingYear, birthday, companyName, labelName, agencyName, venueName, publisherName }) {
  let params = {};
  params.accountType = accountType || 'user';
  params.username = username;
  params.password = password;
  params.firstName = firstName;
  params.lastName = lastName;
  params.registrationCode = registrationCode;
  params.artistName = artistName;
  params.location = location;
  params.foundingYear = foundingYear;
  params.birthday = birthday;
  params.companyName = companyName;
  params.labelName = labelName;
  params.agencyName = agencyName;
  params.venueName = venueName;
  params.publisherName = publisherName;

  const resp = callApi('/api/v1/signup', 'post', params);

  return function (dispatch) {
    resp.then((res) => {
      let data = res.data;
      if (data && data.signup === 'successful') {
        window.localStorage.setItem('token', data.token);
        dispatch(loginUser(null, data.user));
        console.log("----redirecting to ---", REDIRECT_AFTER_SIGNUP);
        history.push(REDIRECT_AFTER_SIGNUP);
      } else {
        const errorMessage = res.err.response.data.message || SIGNUP_FAILED;
        console.log('----errorMessage:', errorMessage);
        dispatch({
          type: SIGNUP_CONFIRMATION,
          value: errorMessage || SIGNUP_FAILED
        });
      }
    }).catch(res => {
      console.error('---re:', res);
      dispatch({
        type: SIGNUP_CONFIRMATION,
        value: (res.data && res.data.message) || SIGNUP_FAILED
      });
    })
  }
}

function signUpWithGoogle(googleData) {
  // googleData = {tokenId: 'dfasdfwerjlcxzvealkfjsdbdfsgs'}
  // let params = { googleData };
  const resp = callApi('/api/v1/signup/google', 'post', googleData);

  return function (dispatch) {
    resp.then((res) => {
      if (res.data && res.data.signup === 'successful') {
        const data = res.data;
        dispatch(loginUser(null, data.user));
        window.localStorage.setItem('token', data.token);
        history.push(REDIRECT_AFTER_SIGNUP);
      } else {
        let errorMessage = SIGNUP_FAILED;
        if (res.err && res.err.response) {
          errorMessage = res.err.response.data.message;
        }
        dispatch({
          type: SIGNUP_CONFIRMATION,
          value: errorMessage
        });
      }
    })
  };
}

function signUpWithFacebook(data) {
  // const accountType = getAccountType(selectedTab) || 'user';
  // data.accountType = 'artist';
  // let params = data;
  const resp = callApi('/api/v1/signup/facebook', 'post', data);

  return function (dispatch) {
    resp.then((res) => {
      if (res.data && res.data.signup === 'successful') {
        const data = res.data;
        dispatch(loginUser(null, data.user));
        window.localStorage.setItem('token', data.token);
        history.push(REDIRECT_AFTER_SIGNUP);
      } else {
        let errorMessage = SIGNUP_FAILED;
        if (res.err && res.err.response) {
          errorMessage = res.err.response.data.message;
        }
        dispatch({
          type: SIGNUP_CONFIRMATION,
          value: errorMessage
        });
      }
    })
  }
}

function signUpError(message) {
  return function (dispatch) {
    dispatch({
      type: SIGNUP_CONFIRMATION,
      value: message || SIGNUP_FAILED
    });
  }
}

function clearConfirmationMessage() {
  return {
    type: SIGNUP_CONFIRMATION,
    value: ''
  }
}

function updateSelectedTab(selectedTab) {
  // console.log(selectedTab);
  return {
    type: UPDATE_SELECTED_TAB,
    selectedTab
  };
}

function updateField(event) {
  let field = event.target.id;
  let value = event.target.value;

  return {
    type: UPDATE_FIELD,
    field,
    value
  }
}

function updateErrorField(event) {
  let field = event.target.id;
  let value = event.target.value;

  return {
    type: UPDATE_FIELD_ERROR,
    field,
    value
  }
}

function validateField(field, value, password) {
  // console.log('--- validate field');
  // console.log('---field', field);
  // console.log('---value', value);
  let lengthCheck = value.trim().length > 0;
  let err = lengthCheck ? '' : CANNOT_BE_EMPTY;

  // console.log('---lengthCheck', lengthCheck);
  // console.log('---err', err);

  let checked = false;

  switch (field) {
    case 'firstName':
    {
      checked = true;
      break;
    }
    case 'lastName':
    {
      checked = true;
      break;
    }
    case 'username':
    {
      if (lengthCheck) {
        lengthCheck = lengthCheck && validateEmail(value);
        err = lengthCheck ? '' : ENTER_VALID_EMAIL
      } else {
        err = '';
        lengthCheck = true;
      }

      checked = true;
      break;
    }
    case 'company':
    {
      lengthCheck = true;
      err = '';
      checked = true;
      break;
    }
    case 'password':
    {
      if (lengthCheck) {
        lengthCheck = value.trim().length >= minimum_password_length;
        err = lengthCheck ? '' : MINIMUM_6_CHARACTERS;
      } else {
        err = '';
        lengthCheck = true;
      }
      checked = true;
      break;
    }
    case 'cPassword':
    {
      // console.log('---password: ', password)
      // console.log('---value: ', value)
      lengthCheck = (value.trim().length >= minimum_password_length && value === password);
      err = lengthCheck ? '' : PASSWORD_DO_NOT_MATCH;
      checked = true;
      break;
    }
    default:
    {

    }
  }

  // console.log('---lengthCheck', lengthCheck);
  // console.log('---err', err);

  if (checked) {
    return {
      good: lengthCheck,
      err: err
    }
  }
}

export {
  SIGNUP_USER,
  UPDATE_SELECTED_TAB,
  UPDATE_FIELD,
  UPDATE_FIELD_ERROR,
  SIGNUP_CONFIRMATION,
  SIGNUP_SUCCESSFUL,
  SIGNUP_FACEBOOK_SUCCESSFUL,

  formFields,

  updateSelectedTab,
  signUpUser,
  updateField,
  updateErrorField,
  clearConfirmationMessage,

  signUpWithFacebook,
  signUpError,

  signUpWithGoogle,

  validateField
};