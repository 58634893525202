import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import BookingHome from '../components/pages/booking/BookingHome';
import { userTypePerPage } from '../utils/constants';
import history from '../redux/manageHistory';

const styles = {
  parentContainer: {
    height: '100%',
    color: '#fff',
    textAlign: 'center',
  },
};

const BookingHomeContainer = (props) => {
  const [bookingData, setBookingData] = useState();
  const [bookingHistoryData, setBookingHistoryData] = useState();

  if (props.user && props.user.account) {
    if (!userTypePerPage['bookinghome'].includes(props.user.account)) {
      return history.push('/dashboard');
    }
  }

  useEffect(() => {
    bookingAll();
    bookingHistoryPost();
  }, []);

  const bookingAll = async () => {
    try {
      let response = await fetch(
        ' https://booking.knkt.io/v1/booking/all',
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
            'Access-Control-Allow-Headers':
              'Content-Type, Authorization',
          },
          method: 'GET',
        }
      );
      let data = await response.json();
      if (data) {
        console.log('bookingAllResponse', data);
        setBookingData(data);
        //updateBookingPayment(data) //TODO
      }
    } catch (error) {
      console.log(
        'api-error-todo v1/booking/all error show error message to redirect back to previous page or refresh' +
          error
      );
    }
  };

  const bookingHistoryPost = async () => {
    const body = {
      reqKnktId: props.user.knktId
    }
    try {
      let response = await fetch(
        ' https://booking.knkt.io/v1/booking/history',
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
            'Access-Control-Allow-Headers':
              'Content-Type, Authorization',
          },
          method: 'POST',
          body: JSON.stringify(body)
        }
      );
      let data = await response.json();
      if (data) {
        console.log('bookingAllResponse', data);
        setBookingHistoryData(data);
        //updateBookingPayment(data) //TODO
      }
    } catch (error) {
      console.log(
        'api-error-todo v1/booking/history error show error message to redirect back to previous page or refresh' +
          error
      );
    }
  };

  console.log(bookingData);
  console.log(bookingHistoryData)

  return (
    <Row style={styles.parentContainer}>
      <Col xs="12">
        <BookingHome user={props.user} bookingData={bookingData} bookingHistoryData={bookingHistoryData}/>
      </Col>
    </Row>
  );
};

export default BookingHomeContainer;
