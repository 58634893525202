import React, {Component} from 'react';
import {connect} from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';

import {updatePasscode, updatePasscodeAction } from '../actions/Login/loginAction';

class PasscodeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passcode: 0
    };
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  componentDidMount() {
    document.getElementById('passcode').focus();
  }

  handleSubmit() {
    if (this.state.passcode) {
      this.props.updatePasscodeAction(this.state.passcode);
    }
  }

  handleInput(e) {
    this.setState({
      passcode: e.target.value
    });
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  }

  render() {
    const styles = {
      passcodeContainer: {
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#000000',
        backgroundColor: '#000000'
      },
      passcodeParent: {
        width: '100%',
        textAlign: 'center'
      },
      passcode: {
        height: '3em',
        width: '50%',
        marginBottom: 20,
        borderRadius: 10,
        textAlign: 'center'
      },
      submitButton: {
        borderRadius: 5
      }
    };

    return (
      <div style={styles.passcodeContainer}>
        <div style={styles.passcodeParent}>
          <input id="passcode" style={styles.passcode} type='number' onKeyUp={this.handleInput}/>
          <br />
          <RaisedButton label="Let Me In" style={styles.submitButton} onClick={this.handleSubmit}/>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePasscode: (passcode) => dispatch(updatePasscode(passcode)),
    updatePasscodeAction: (passcode) => dispatch(updatePasscodeAction(passcode))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasscodeContainer);