import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FacebookProvider, { Login } from 'react-facebook';
import Snackbar from 'material-ui/Snackbar';
import { GoogleLogin } from 'react-google-login';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Radium from 'radium';

import {
  getUserProfile,
  connectFacebookAction,
  clearConfirmationMessage,
  clearShowConnectOptions,
  clearShowSoundCloudModal,
  connectFacebookOptionAction,
} from '../actions/Login/loginAction';

import {
  connectGoogleAction,
  disconnectPlatformAction,
  connectSoundCloudAction,
  showSoundCloudModal,
} from '../actions/Connection/connectionAction';

import {
  getDeezerConnectAppId,
  getFBAppId,
  getFBLoginEnabled,
  getInstagramClientId,
  getInstagramRedirect,
  getGoogleClientId,
  getSoundCloudLoginEnabled,
} from '../config/siteConfig';
import { Fragment } from 'react';

import '../styles/ConnectionContainer.css';

const styles = {
  connection: {
    color: '#ffffff',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    minHeight: '94vh',
  },
  connectText: {
    fontSize: '16px',
  },
  connectionTitle: {
    textAlign: 'center',
    fontSize: 20,
    padding: 30,
  },
  socialParent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  socialContainer: {
    borderRadius: '1rem',
    height: 217,
    background: '#2c2c2c',
    margin: 15,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    gap: '3px',
  },
  hover: {
    fb: {
      ':hover': {
        background: '#0B84EE',
        cursor: 'pointer',
        boxShadow: '0px 0px 15px 6px #0B84EE',
      },
    },
    yt: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px #FF0000',
        background: '#FF0000',
        cursor: 'pointer',
      },
    },
    ig: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px gray',
        background:
          'linear-gradient(to right, rgb(236, 47, 75), rgb(0, 159, 255))',
        cursor: 'pointer',
      },
    },
    apple: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px gray',
        background:
          'linear-gradient(304deg, rgba(71,71,71,1) 0%, rgba(0,0,0,1) 43%)',
        cursor: 'pointer',
      },
    },
    spotify: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px #1ED760',
        background: '#1ED760',
        cursor: 'pointer',
      },
    },
    tiktok: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px #e5004e',
        background: '#0000',
        cursor: 'pointer',
      },
    },
    twitter: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px #1A91DA',
        background: '#1A91DA',
        cursor: 'pointer',
      },
    },
    eventBrite: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px #F05537',
        background: '#F05537',
        cursor: 'pointer',
      },
    },
    soundCloud: {
      ':hover': {
        boxShadow: `0px 0px 15px 6px #FF551A`,
        background: '#FF551A',
        cursor: 'pointer',
      },
    },
    deezer: {
      ':hover': {
        boxShadow: '0px 0px 15px 6px gray',
        background: '#000',
        cursor: 'pointer',
      },
    },
  },
  optionsTitle: {
    marginBottom: 30,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  optionsError: {
    color: '#ff0000',
    marginTop: 20,
  },
  soundCloudError: {
    marginTop: 30,
    color: '#ff0000',
  },
  soundCloudForm: {
    marginBottom: 20,
    color: '#ffffff',
  },
  soundCloudFormBackground: {
    backgroundColor: '#7a7a7a',
  },
  soundCloudModalHeader: {
    borderBottom: 'none',
  },
  soundCloudFooter: {
    textAlign: 'center',
  },
  soundCloudConnectButton: {
    backgroundColor: '#000000',
  },
  soundCloudModal: {},
};

class ConnectionContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.getPlatformButton = this.getPlatformButton.bind(this);
    this.getDeezerButton = this.getDeezerButton.bind(this);
    this.getFacebookLoginButton =
      this.getFacebookLoginButton.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.onGoogleResponse = this.onGoogleResponse.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleFBOption = this.handleFBOption.bind(this);
    this.handleFBOptionSubmit = this.handleFBOptionSubmit.bind(this);
    this.handleConnectSoundCloud =
      this.handleConnectSoundCloud.bind(this);

    this.state = {
      openSnack:
        this.props.user.confirmation.length > 0 ? true : false,
      fbOption: null,
      fbOptionError: null,
      isHover: false,
      hoverCard: '',

      showSoundCloudModal: false,
      soundCloudUrl: null,
      soundCloudError: null,
    };
  }

  styles = {
    disconnectButton: {
      cursor: 'pointer',
    },
  };

  getUrl = () => {
    const { protocol, hostname, port } = window.location;
    let portString = port;
    if (port === 80 || port == '') {
      portString = '';
    } else {
      portString = ':' + port;
    }
    return protocol + '//' + hostname + portString;
  };

  handleFacebookResponse = (data) => {
    this.props.connectFacebookAction(data);
  };

  handleFacebookError = (err) => {
    console.log('-----handleFacebookError:', err);
    // this.props.loginFailedAction("Can't login at this time.");
  };

  onHoverIn = (e) => {
    // console.log(e.target.id, "mouse enter");
    this.setState({ isHover: true });
    this.setState({ hoverCard: e.target.id });
  };

  onHoverOut = (e) => {
    // console.log(e.target.id, "mouse exit");
    this.setState({ isHover: false });
    this.setState({ hoverCard: '' });
  };

  getFacebookLoginButton = (connectedDiv) => {
    let fbCardStyle = {
      borderRadius: '1rem',
      height: 217,
      background: '#2c2c2c',
      margin: 15,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      gap: '3px',
    };
    connectedDiv
      ? (fbCardStyle.background = '#0B84EE')
      : (fbCardStyle.background = '#2c2c2c');
    const scope = `
      email,
      ads_management, 
      business_management,
      pages_manage_cta,
      pages_show_list,
      instagram_basic,
      instagram_manage_comments,
      read_insights, 
      instagram_manage_insights,
      instagram_basic,
      pages_read_engagement,
      pages_manage_metadata,
      pages_read_user_content,
      pages_manage_ads,
      pages_manage_posts,
      pages_manage_engagement,
      public_profile,
      ads_read,
      page_events
    `;

    if (getFBLoginEnabled()) {
      return (
        <React.Fragment>
          <FacebookProvider>
            {!connectedDiv && (
              <Login
                scope={scope}
                onResponse={this.handleFacebookResponse}
                onError={this.handleFacebookError}
              >
                <div
                  id="fb"
                  key={'1'}
                  style={[fbCardStyle, styles.hover.fb]}
                  onMouseEnter={this.onHoverIn.bind(this)}
                  onMouseLeave={this.onHoverOut.bind(this)}
                >
                  <i className="fab fa-facebook-f fa-3x brand-opacity"></i>
                  <h4 className="text-uppercase">Facebook</h4>
                  <p style={styles.connectText}>
                    {this.state.isHover &&
                    this.state.hoverCard === 'fb'
                      ? 'Connect'
                      : ''}{' '}
                  </p>
                </div>
              </Login>
            )}
            {connectedDiv && (
              <div
                id="fb"
                style={[fbCardStyle, styles.hover.fb]}
                onClick={this.handleDisconnect.bind(this, 'facebook')}
                onMouseEnter={this.onHoverIn.bind(this)}
                onMouseLeave={this.onHoverOut.bind(this)}
              >
                <i className="fab fa-facebook-f fa-3x brand-opacity"></i>
                <h4 className="text-uppercase">Facebook</h4>
                {connectedDiv}
                <p style={styles.connectText}>
                  {this.state.isHover && this.state.hoverCard === 'fb'
                    ? 'Disconnect'
                    : ''}
                </p>
              </div>
            )}
          </FacebookProvider>
        </React.Fragment>
      );
    }
    return null;
  };

  handleInstagramResponse = (response) => {
    console.log(response);
  };

  getInstagramLoginButton = (connectedDiv) => {
    let igStyle = {
      borderRadius: '1rem',
      height: 217,
      background:
        'linear-gradient(to right, rgb(191, 71, 89), rgb(0, 111, 179))',
      margin: 15,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      gap: '3px',
    };

    const reconnectIG =
      window.localStorage.getItem('instagram.reconnect') === 'true';

    if (
      this.props.user &&
      this.props.user.facebook &&
      this.props.user.facebook.id
    ) {
      if (connectedDiv && !reconnectIG) {
        return (
          <div
            key={'3'}
            id="ig"
            style={[igStyle, styles.hover.ig, igStyle]}
            onClick={this.handleDisconnect.bind(this, 'instagram')}
            onMouseEnter={this.onHoverIn.bind(this)}
            onMouseLeave={this.onHoverOut.bind(this)}
          >
            <i class="fab fa-instagram fa-3x brand-opacity"></i>
            <h4 className="text-uppercase">Instagram</h4>
            {connectedDiv}
            <p style={styles.connectText}>
              {this.state.isHover && this.state.hoverCard === 'ig'
                ? 'Disconnect'
                : ''}
            </p>
          </div>
        );
      } else {
        const link =
          'https://api.instagram.com/oauth/authorize?client_id=' +
          getInstagramClientId() +
          '&redirect_uri=' +
          getInstagramRedirect() +
          '&scope=user_profile,user_media&response_type=code';
        return (
          <div
            key={'3'}
            id="ig"
            style={[styles.socialContainer, styles.hover.ig]}
            onClick={() => {
              window.location = link;
            }}
            onMouseEnter={this.onHoverIn.bind(this)}
            onMouseLeave={this.onHoverOut.bind(this)}
          >
            <i class="fab fa-instagram fa-3x brand-opacity"></i>
            <h4 className="text-uppercase">Instagram</h4>
            <p style={styles.connectText}>
              {this.state.isHover && this.state.hoverCard === 'ig'
                ? 'Connect'
                : ''}
            </p>
          </div>
        );
      }
    } else {
      return (
        <div
          key={'3'}
          id="ig"
          style={[styles.socialContainer, styles.hover.ig]}
          onMouseEnter={this.onHoverIn.bind(this)}
          onMouseLeave={this.onHoverOut.bind(this)}
        >
          <i class="fab fa-instagram fa-3x brand-opacity"></i>
          <h4 className="text-uppercase">Instagram</h4>
          <a
            href="#"
            style={{ pointerEvents: 'none', cursor: 'default' }}
          >
            <div
              className="knkt-button knkt-button-disabled"
              style={{ fontSize: '1.3em', color: 'white' }}
            >
              Connect Facebook First
            </div>
          </a>
        </div>
      );
    }
  };

  getSoundCloudLoginButton = (connectedDiv) => {
    let scCardStyle = {
      borderRadius: '1rem',
      height: 217,
      background: '#2c2c2c',
      margin: 15,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    };
    connectedDiv
      ? (scCardStyle.background = '#FF551A')
      : (scCardStyle.background = '#2c2c2c');

    if (getSoundCloudLoginEnabled()) {
      const reconnectSC =
        window.localStorage.getItem('soundcloud.reconnect') ===
        'true';

      const alreadyConnected = !reconnectSC && connectedDiv;
      return (
        <React.Fragment>
          {!alreadyConnected && (
            <div
              id="soundCloud"
              key={'8'}
              style={[scCardStyle, styles.hover.soundCloud]}
              onClick={this.handleSoundCloudShowModal.bind(this)}
              onMouseEnter={this.onHoverIn.bind(this)}
              onMouseLeave={this.onHoverOut.bind(this)}
            >
              <i class="fab fa-soundcloud fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">SoundCloud</h4>
              <p style={styles.connectText}>
                {this.state.isHover &&
                this.state.hoverCard === 'soundCloud'
                  ? 'Connect'
                  : ''}
              </p>
            </div>
          )}
          {alreadyConnected && (
            <div
              id="soundCloud"
              key={'8'}
              style={[scCardStyle, styles.hover.soundCloud]}
              onClick={this.handleDisconnect.bind(this, 'soundCloud')}
              onMouseEnter={this.onHoverIn.bind(this)}
              onMouseLeave={this.onHoverOut.bind(this)}
            >
              <i class="fab fa-soundcloud fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">SoundCloud</h4>
              {connectedDiv}
              <p style={styles.connectText}>
                {this.state.isHover &&
                this.state.hoverCard === 'soundCloud'
                  ? 'Disconnect'
                  : ''}
              </p>
            </div>
          )}
        </React.Fragment>
      );
    }
    return null;
  };

  handleDisconnect(platform) {
    this.props.disconnectPlatformAction(platform);
  }

  handleConnectSoundCloud() {
    this.setState({
      soundCloudError: '',
    });

    const { soundCloudUrl } = this.state;
    if (!soundCloudUrl) {
      this.setState({
        soundCloudError: 'SoundCloud Url is required.',
      });
      return;
    }
    this.props.connectSoundCloudAction({
      soundCloudUrl: soundCloudUrl,
      platform: 'soundCloud',
    });
  }

  handleSoundCloudShowModal = () => {
    this.setState({
      // showSoundCloudModal: true,
      soundCloudError: null,
    });
    this.props.showSoundCloudModal();
  };

  handleHideSoundCloudModal() {
    this.setState({
      // showSoundCloudModal: false,
      soundCloudError: null,
    });
    this.props.clearShowSoundCloudModal();
  }

  updateSoundCloudVariables(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  getPlatformButton = (platform) => {
    const reconnectFB =
      window.localStorage.getItem('facebook.reconnect') === 'true';
    let connectedDiv;
    if (
      !reconnectFB &&
      this.props &&
      this.props.user &&
      this.props.user[platform] &&
      this.props.user[platform].id
    ) {
      let name =
        this.props.user[platform].pageName ||
        this.props.user[platform].name ||
        this.props.user[platform].username;
      connectedDiv = (
        <div>
          {name}{' '}
          <span
            style={this.styles.disconnectButton}
            className="knkt-button knkt-button-connected"
          >
            <i class={'fas fa-check-circle'}></i>
          </span>
        </div>
      );
    }
    if (platform === 'soundCloud') {
      return this.getSoundCloudLoginButton(connectedDiv);
    }
    if (platform === 'facebook') {
      return this.getFacebookLoginButton(connectedDiv);
    }
    if (platform === 'instagram') {
      return this.getInstagramLoginButton(connectedDiv);
    }
    if (platform === 'youtube') {
      return this.getYoutubeButton(connectedDiv);
    }
    return <React.Fragment></React.Fragment>;
  };

  onGoogleResponse(googleUser) {
    let code = googleUser.code;
    let error = googleUser.error;

    console.log('----error:', error);

    if (code) {
      this.props.connectGoogleAction({ code });
      return;
    }

    if (error) {
      // this.props.connectGoogleAction({error});
    }
  }

  getYoutubeButton = (connectedDiv) => {
    let connectedClass = connectedDiv ? 'knkted' : '';
    let connectText = connectedDiv ? 'Disconnect' : 'Connect';
    let rthis = this;
    return (
      <GoogleLogin
        clientId={getGoogleClientId()}
        buttonText="Login"
        onSuccess={this.onGoogleResponse}
        onFailure={this.onGoogleResponse}
        cookiePolicy={'single_host_origin'}
        responseType="code"
        accessType="offline"
        scope="profile email https://www.googleapis.com/auth/youtube.readonly"
        render={(renderProps) => (
          <div
            id="yt"
            onClick={
              connectedDiv
                ? rthis.handleDisconnect.bind(rthis, 'youtube')
                : renderProps.onClick
            }
            disabled={renderProps.disabled}
            className={'yt ' + connectedClass}
            onMouseEnter={this.onHoverIn.bind(this)}
            onMouseLeave={this.onHoverOut.bind(this)}
          >
            <i className="fab fa-youtube fa-3x brand-opacity"></i>
            <h4 className="text-uppercase">Youtube</h4>
            {connectedDiv}
            <p style={styles.connectText}>
              {this.state.isHover && this.state.hoverCard === 'yt'
                ? connectText
                : ''}
            </p>
          </div>
        )}
      />
    );
  };

  getDeezerButton = (_) => {
    const getDeezerConnectUrl = `https://connect.deezer.com/oauth/auth.php?app_id=${getDeezerConnectAppId()}&redirect_uri=${this.getUrl()}/oauth/deezer/connect&perms=basic_access,email,offline_access`;

    if (
      this.props &&
      this.props.user &&
      this.props.user.deezer &&
      this.props.user.deezer.id
    ) {
      return this.getPlatformButton('deezer');
    } else {
      return (
        <a href={getDeezerConnectUrl}>
          {this.getPlatformButton('deezer')}
        </a>
      );
    }
  };

  handleRequestClose() {
    this.props.clearConfirmationMessage();
  }

  handleCloseModal() {
    this.props.clearShowConnectOptions();
  }

  handleFBOption(id) {
    this.setState({
      fbOption: id,
    });
  }

  handleFBOptionSubmit() {
    const { fbOption } = this.state;
    if (!fbOption) {
      this.setState({
        fbOptionError:
          'Please select the Facebook page you want to connect!',
      });
    } else {
      this.setState({
        fbOptionError: null,
      });

      this.props.connectFacebookOptionAction(fbOption);
    }
  }

  render() {
    const { fbOptionError } = this.state;

    const scope = `
      email,
      user_age_range,
      user_friends,
      user_gender,
      user_likes,
      user_birthday, 
      user_location,
      user_posts,
      ads_management, 
      business_management,
      groups_access_member_info,
      manage_pages, 
      pages_manage_cta,
      pages_show_list,
      publish_pages, 
      publish_to_groups,
      user_events,
      instagram_basic,
      instagram_manage_comments,
      read_insights, 
      instagram_manage_insights,
      instagram_basic
    `;

    return (
      <div style={styles.connection}>
        <Row nogutters="true">
          <Col sm={12} style={styles.connectionTitle}>
            CONNECTIONS
          </Col>
        </Row>
        <Row style={{ display: 'none' }}>
          <Col md={4} xs={12}>
            <span className="pull-left">
              <Link className="breadcrumb-text" to="/dashboard">
                <i class="fas fa-caret-left fa-1x"></i> BACK TO
                DASHBOARD
              </Link>
            </span>
          </Col>
          <Col md={5} xs={12}>
            {' '}
            <span>
              Sync your profile information, promote your page!
            </span>
          </Col>
          <Col md={3} xs={false}></Col>
        </Row>
        <Row nogutters="true" style={styles.socialParent}>
          <Col xs={6} sm={4} md={3}>
            {this.getPlatformButton('facebook')}
          </Col>
          <Col xs={6} sm={4} md={3}>
            {this.getPlatformButton('youtube')}
          </Col>
          <Col xs={6} sm={4} md={3}>
            {this.getPlatformButton('instagram')}
          </Col>
          <Col xs={6} sm={4} md={3}>
            <div
              id="apple"
              key={'5'}
              style={[styles.socialContainer, styles.hover.apple]}
              onMouseEnter={this.onHoverIn.bind(this)}
              onMouseLeave={this.onHoverOut.bind(this)}
            >
              <i class="fab fa-apple fa-3x brand-opacity"></i>
              <h4 className="text-uppercase text-center">
                Apple Music
              </h4>
              {this.getPlatformButton('apple')}
              <p style={styles.connectText}>
                {this.state.isHover &&
                this.state.hoverCard === 'apple'
                  ? 'Connect'
                  : ''}
              </p>
            </div>
          </Col>

          <Col xs={6} sm={4} md={3}>
            <div
              id="spotify"
              key={'6'}
              style={[styles.socialContainer, styles.hover.spotify]}
              onMouseEnter={this.onHoverIn.bind(this)}
              onMouseLeave={this.onHoverOut.bind(this)}
            >
              <i class="fab fa-spotify fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">Spotify</h4>

              <p style={styles.connectText}>
                {this.state.isHover &&
                this.state.hoverCard === 'spotify'
                  ? 'Connect'
                  : ''}
              </p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3}>
            <div
              id="tikok"
              key={'7'}
              style={[styles.socialContainer, styles.hover.tiktok]}
              onMouseEnter={this.onHoverIn.bind(this)}
              onMouseLeave={this.onHoverOut.bind(this)}
            >
              <i class="fab fa-tikok fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">TikTok</h4>
              {this.getPlatformButton('tikok')}
              <p style={styles.connectText}>
                {this.state.isHover &&
                this.state.hoverCard === 'tikok'
                  ? 'Connect'
                  : ''}
              </p>
            </div>
          </Col>
          {/*           <Col xs={6} sm={4} md={3}>
            <div id="eventbrite" key={"8"} style={[styles.socialContainer, styles.hover.eventBrite]} onMouseEnter={this.onHoverIn.bind(this)} onMouseLeave={this.onHoverOut.bind(this)}>
              <i class="fab fa-etsy fa-3x brand-opacity"></i>
              <h4 className="text-uppercase">EventBrite</h4>
              {this.getPlatformButton('eventbrite')}
              <p>{this.state.isHover && this.state.hoverCard === "eventbrite" ? "Connect" : ""}</p>
            </div>
          </Col> */}
          <Col xs={6} sm={4} md={3}>
            {this.getPlatformButton('soundCloud')}
          </Col>
          <Col xs={6} sm={4} md={3}>
            <div
              id="deezer"
              key={'9'}
              style={[styles.socialContainer, styles.hover.deezer]}
              onMouseEnter={this.onHoverIn.bind(this)}
              onMouseLeave={this.onHoverOut.bind(this)}
            >
              <i class="fab fa-3x brand-opacity fa-deezer"></i>
              <h4 className="text-uppercase">Deezer</h4>
              {this.getDeezerButton()}
              <p style={styles.connectText}>
                {this.state.isHover &&
                this.state.hoverCard === 'deezer'
                  ? 'Connect'
                  : ''}
              </p>
            </div>
          </Col>
        </Row>

        <Snackbar
          open={
            this.props.user.confirmation &&
            this.props.user.confirmation.length > 0
              ? true
              : false
          }
          message={this.props.user.confirmation}
          autoHideDuration={8000}
          onRequestClose={this.handleRequestClose}
        />

        <Modal
          show={
            this.props.user.showConnectOptions &&
            this.props.user.showConnectOptions.length > 0
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Your Facebook Pages</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={styles.optionsTitle}>
              Please pick the Facebook page you want to connect
            </div>
            <div>
              {this.props.user.showConnectOptions &&
                this.props.user.showConnectOptions.length > 0 &&
                this.props.user.showConnectOptions.map(
                  (option, i) => {
                    return (
                      <Form.Check
                        type="radio"
                        name="FBOptions"
                        id={option.id}
                        label={`${option.name} - ${option.category}`}
                        onChange={this.handleFBOption.bind(
                          this,
                          option.id
                        )}
                      />
                    );
                  }
                )}
            </div>
            {fbOptionError && (
              <div style={styles.optionsError}>{fbOptionError}</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.handleFBOptionSubmit}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          centered
          show={this.props.user.showSoundCloudModal}
          onHide={this.handleHideSoundCloudModal.bind(this)}
        >
          <Modal.Body
            style={{
              ...styles.soundCloudFormBackground,
              ...styles.soundCloudModal,
            }}
          >
            <Modal.Header
              closeButton
              style={{
                ...styles.soundCloudFormBackground,
                ...styles.soundCloudModalHeader,
              }}
            ></Modal.Header>
            <h3 style={styles.optionsTitle}>SOUNDCLOUD</h3>
            <div>
              <InputGroup>
                <FormControl
                  placeholder="Soundcloud Profile Link"
                  aria-label="Soundcloud Profile Link"
                  aria-describedby="scLink"
                  name="soundCloudUrl"
                  onChange={this.updateSoundCloudVariables.bind(this)}
                  style={{
                    ...styles.soundCloudForm,
                    ...styles.soundCloudFormBackground,
                  }}
                />
              </InputGroup>
            </div>
            <div style={styles.soundCloudError}>
              {this.state.soundCloudError ||
                this.props.user.confirmation}
            </div>
            <div
              style={{
                ...styles.soundCloudFormBackground,
                ...styles.soundCloudFooter,
              }}
            >
              <Button
                variant="dark"
                style={{ ...styles.soundCloudConnectButton }}
                onClick={this.handleConnectSoundCloud.bind(this)}
              >
                CONNECT
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserProfile: (token) => dispatch(getUserProfile(token)),
    connectFacebookAction: (data) =>
      dispatch(connectFacebookAction(data)),
    clearConfirmationMessage: () =>
      dispatch(clearConfirmationMessage()),
    connectGoogleAction: (data) =>
      dispatch(connectGoogleAction(data)),
    disconnectPlatformAction: (platform) =>
      dispatch(disconnectPlatformAction(platform)),
    clearShowConnectOptions: () =>
      dispatch(clearShowConnectOptions()),
    showSoundCloudModal: () => dispatch(showSoundCloudModal()),
    clearShowSoundCloudModal: () =>
      dispatch(clearShowSoundCloudModal()),

    connectFacebookOptionAction: (data) =>
      dispatch(connectFacebookOptionAction(data)),
    connectSoundCloudAction: (obj) =>
      dispatch(connectSoundCloudAction(obj)),
  };
}

ConnectionContainer = Radium(ConnectionContainer);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionContainer);
