const protectedPages = [
  '/settings',
  '/profile',
  '/social',
  '/admin',
  '/dashboard',
  '/promolink',
  '/connection',
  '/insights',
  '/tour/0'
];

export default protectedPages;