import React, { useState } from 'react';
import { Button, Modal, Input, Checkbox } from 'antd';

import './styles.css';
import { validateEmail } from '../../../utils/utils';

const AddContactFormModal = ({ isOpen, handleSave, handleCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    city: '',
    isCommunicationAccepted: false,
  });
  const [errors, setErrors] = useState({});

  const handleChangeInput = (field, value) => {
    if (errors[field]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: ''
      }))
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value
    }))

  }

  const validateForm = () => {
    const { name, email, phoneNumber, city } = formData;
    const validationErrors = {};

    if (!name) validationErrors.name = 'Name is required.';
    if (!email) {
      validationErrors.email = 'E-mail is required.';
    } else if (!validateEmail(email)) {
      validationErrors.email = 'Please enter a valid e-mail.';
    }
    if (!phoneNumber) validationErrors.phoneNumber = 'Phone number is required.';
    if (!city) validationErrors.city = 'City is required.';

    return validationErrors;
  };

  const handleSaveContactForm = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    handleSave(formData);
    setFormData({})
  }

  return (
    <Modal
      open={isOpen}
      title='Create a Contact Form'
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' onClick={handleSaveContactForm}>
          Next
        </Button>
      ]}
      maskClosable={false}
    >
      <>
        <label htmlFor='name-input'>Name</label>
        <Input
          className={`${errors.name ? 'input-error' : ''}`}
          id='name-input'
          placeholder='Add a name'
          value={formData.name}
          size='large'
          onChange={(event) => handleChangeInput('name', event.target.value)}
          maxLength={35}
        />
        <p className='error-message'>{errors.name}</p>

        <label htmlFor='email-input'>E-mail</label>
        <Input
          className={`${errors.email ? 'input-error' : ''}`}
          id='email-input'
          placeholder='Add an e-mail'
          value={formData.email}
          size='large'
          onChange={(event) => handleChangeInput('email', event.target.value)}
          maxLength={35}
        />
        <p className='error-message'>{errors.email}</p>

        <label htmlFor='phone-number-input'>Phone Number</label>
        <Input
          className={`${errors.phoneNumber ? 'input-error' : ''}`}
          id='phone-number-input'
          placeholder='Add a phone nummber'
          value={formData.phoneNumber}
          size='large'
          onChange={(event) => handleChangeInput('phoneNumber', event.target.value)}
          maxLength={35}
        />
        <p className='error-message'>{errors.phoneNumber}</p>

        <label htmlFor='city-input'>Title</label>
        <Input
          className={`${errors.city ? 'input-error' : ''}`}
          id='city-input'
          placeholder='Add a city'
          value={formData.city}
          size='large'
          onChange={(event) => handleChangeInput('city', event.target.value)}
          maxLength={35}
        />
        <p className='error-message'>{errors.city}</p>

        <Checkbox onChange={(event) => handleChangeInput('isCommunicationAccepted', event.target.checked)}>
          I accept to receive communication from KNKT.
        </Checkbox>
      </>
    </Modal>
  )
}

export default AddContactFormModal;
