const commonStyles = {
  loginButton: {
    marginTop: 20,
    // marginBottom: 20,
    width: 215,
    height: 60,
    border: '1px solid #ffffff',
    backgroundColor: 'transparent',
    marginLeft: 15,
    marginRight: 15
  },
  facebookLoginButton: {
    padding: '5px 30px'
  },
  twitterLoginButton: {
    padding: '5px 30px'
  },
  soundCloudLoginButton: {
    padding: '5px 30px'
  },
  emailLoginButton: {
    padding: '5px 30px'
  },
  googleCloudLoginButton: {
    padding: '5px 30px'
  }
};

export default commonStyles;