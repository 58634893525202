function site(state = [], action) {
  switch (action.type) {
    case 'INIT_DATA':
    {
      return action.data;
    }
    case 'UPDATE_BRAND':
    {
      let newState = Object.assign({}, state);
      if (action.brand) {
        newState.brand = action.brand;
      }
      if (action.dashboardMenuIndex) {
        newState.dashboardMenuIndex = action.dashboardMenuIndex;
      }
      return newState;
    }
    default:
    {
      return state;
    }
  }
}

export default site;