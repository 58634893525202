import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

import HighlightsModal from './HighlightsModal';
import HighlightsItem from './HighlightsItem';

import { fetchHighlightsData } from '../../../utils/insights';

const Highlights = props => {
  const [ showModal, setShowModal ] = useState(false);
  const [ highlightsData, setHighlightsData ] = useState({});
  const [ change, setChange ] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let fetchData = await fetchHighlightsData();
    // console.log('----dddd:', fetchData);
    setHighlightsData(fetchData);
    setChange(!change);
  };

  const styles = {
    highlights: {
      padding: 20,
      width: '100%',
      height: '100%',
      backgroundColor: '#1a1d24',//'#2c2c2c',
      color: '#ffffff',

      // display: 'flex',
      margin: '5px 0px',
      overflowY: 'auto',
      boxShadow: '15px 15px 15px #111111',
      borderRadius: 10
    },
    heading: {
      textTransform: 'uppercase'
      // margin: 20
    },
    i: {
      marginLeft: 10,
      color: 'rgba(255, 235, 30, 0.35)'
    },
    highlightsText: {
      lineHeight: 2
    },
    expand: {
      cursor: 'pointer',
      // backgroundImage: 'linear-gradient(to right, #00d5fd, #01fed4)',
      backgroundColor: '#071b14',
      color: '#05c2fb',
      borderStyle: 'solid',
      border: 'solid #05c2fb 1px',
      // borderRadius: 10,
      padding: '10px 15px',
      display: 'inline-block'
    },
    modal: {
      color: '#ffffff',
      marginTop: 80,
      boxShadow: '15px 15px 15px #111111'
    },
    modalBody: {
      backgroundColor: '#000000',
      border: '1px solid #cccccc'
    },
    modalBodyFooter: {
      backgroundColor: '#000000'  //2c2c2c
    },
    title: {
      textTransform: 'uppercase'
    },
    closeButton: {
      backgroundColor: '#2c2c2c',
      textOutline: 'none',
      textDecoration: 'none',
      color: 'white'
    },
    legends: {
      textAlign: 'left'
    }
  };

  const handleDismissInsights = (type, id) => {
    let allData = highlightsData;
    // let newType = [];
    // allData[type].forEach(one => {
    //   if (one.id !== id) {
    //     newType.push(one);
    //   }
    // });
    // allData[type] = newType;
    setHighlightsData(allData);
    // setChange(!change);
  };

  return (
    <div style={styles.highlights}>
      <div style={styles.heading}>
        Highlights
        <i style={styles.i} className="fas fa-question-circle"></i>
      </div>
      <div>
        <ul>
          {
            // highlightsData && highlightsData[props.selectedTimeLine] && highlightsData[props.selectedTimeLine].map((hl, i) => {
            //   return (
            //     <HighlightsItem
            //       data={hl}
            //       key={i}
            //       inModal={false}
            //     />
            //   )
            // })

            highlightsData && highlightsData.insights && highlightsData.insights.map((hl, i) => {
              return (
                <HighlightsItem
                  data={hl}
                  key={i}
                  inModal={false}
                />
              )
            })
          }
        </ul>
      </div>
      <div style={styles.expand}>
        <i className="fas fa-expand" onClick={setShowModal.bind(this, true)}> Expand</i>
      </div>

      <Modal size='lg' style={styles.modal} show={showModal} onHide={setShowModal.bind(this, false)}>
        <Modal.Body style={styles.modalBody}>
          <HighlightsModal
            change={change}
            highlightsData={highlightsData}
            handleDismissInsights={handleDismissInsights}
            selectedTimeLine={props.selectedTimeLine}
            timeline={props.timeline}
          />
        </Modal.Body>

        <Modal.Footer style={styles.modalBodyFooter}>
          <Row>
            <Col xs={12} md={10} style={styles.legends}>

            </Col>
            <Col xs={12} md={2}>
              <button
                style={styles.closeButton}
                className="btn"
                onClick={setShowModal.bind(this, false)}
              >
                Close
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
};

export default Highlights;