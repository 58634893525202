import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import WOW from "wow.js";

import PasscodeContainer from '../../containers/PasscodeContainer';
import BasicPage from './BasicPage';
import { getApiUrl } from '../../config/siteConfig';

import {
  getUserProfile,
  logoutUserAction,
  verifyPasscodeAction
} from '../../actions/Login/loginAction';

class MainRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doNotShowPasscodePage: false
    }
    this.verifyPasscode = this.verifyPasscode.bind(this);
  }

  componentDidMount() {
    const wow = new WOW();
    wow.init();

    this.verifyPasscode();
  }

  verifyPasscode() {
    axios({
      url: getApiUrl() + '/api/v1/verifyPasscode',
      method: 'get',
      headers: {
        // token: window.localStorage.getItem('token') || '',
        passcodeToken: window.localStorage.getItem('passcodeToken') || ''
      }
    }).then((res) => {
      let data = res.data;

      if (data && data.passcode && data.passcode === true) {
        this.setState({
          doNotShowPasscodePage: true
        });
      }
    }).catch((err) => {
      console.log('passcode error: ', err);
    })
  };

  render() {
    let { doNotShowPasscodePage } = this.state;

    const redirectToPasscode = () => {
      return !(this.props.user.passcode || this.props.user.passcode === true);
    };

    // const isLogin = () => {
    //   return this.props.user && this.props.user.knktId !== undefined;// && this.props.user.knktId.length > 0;
    // };

    // const fontSize = {
    //   fontSize: 16
    // };

    return (
      <Container fluid style={{paddingRight: 0, paddingLeft: 0}}>
        { (doNotShowPasscodePage || (!doNotShowPasscodePage && !redirectToPasscode())) && (
          <BasicPage />
        )}

        { !doNotShowPasscodePage && redirectToPasscode() && (
          <PasscodeContainer />
        )}
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserProfile: (token) => dispatch(getUserProfile(token)),
    logoutUserAction: (token) => dispatch(logoutUserAction(token)),
    verifyPasscodeAction: _ => dispatch(verifyPasscodeAction())
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainRouter));


// <Route key="1.6"  path='/insights-old' component={OldInsights} />

// <Route key="1.1"  path='/dashboard' component={DashboardContainer} />
// <Route key="1.2"  path='/events' component={Sam} />
//   <Route key="1.3"  path='/friends' component={Dashboard} />
//   <Route key="1.4"  path='/profile' component={ProfileContainer} />
//   <Route key="1.5"  path='/settings' render={() => <SettingsContainer {...this.props} />} />
// <Route key="1.6"  path='/insights' component={DashboardContainer} />
//   <Route key="1.21" path='/connectInstagram' component={ConnectionInstagramContainer}  />
//   <Route key="1.7"  exact path='/connection' render={() => <ConnectionContainer {...this.props} />}  />
// <Route key="1.18" exact path='/calendar/:artistId' component={ CalendarContainer } />
//   <Route key="1.20" exact path='/calendar' component={ CalendarContainer } />
//   <Route key="1.19"  path='/tour/:tourId' component={ TourContainer } />
//   <Route key="1.8"  path='/board' component={Sam} />
//   {/* <Route path='/aboutMe' render={() => <AboutMe {...this.props} />} /> */}
// {/* <Route path='/login' render={() => <Login {...this.props} />} /> */}
// {/* <Route key="1.9" path='/login' render={() => <LoginContainer />} /> */}
// <Route key="1.10" path="/user/:username" component={Dashboard} />
// <Route key="1.11" path="/signup" render={() => <SignUpContainer {...this.props} signup={true} />} />
// <Route key="1.12" path="/social" render={() => <SocialContainer {...this.props} />} />
// <Route key="1.13" path='/privacy' component={PrivacyAndTermsPage} />
//   <Route key="1.14" path='/terms' component={PrivacyAndTermsPage} />
//   <Route key="1.15" path='/admin' component={AdminContainer} />
//   <Route key="1.16" path='/oauth/:platform/:action' component={OauthHandler}/>
//   <Route key="1.17" path="/fans/:socialmedia" component={FansHome} />
//
//   <Route key="999" path="/home" component={HomePage} />
//   <Route key="999" path="/" component={LoginContainer} />