import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';

import { connectSocial } from '../../actions/Social/socialAction';
import { loginDeezerAction } from '../../actions/Login/loginAction';
import { clearSocialErrorMessage, addSocialError } from '../../actions/Social/socialAction';

class OauthHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      redirectTo: '/dashboard'
    }
    this.showMessage = this.showMessage.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.getWorkingOnItStyle = this.getWorkingOnItStyle.bind(this);
  }
  componentDidMount() {
    const { platform, action } = this.props.match.params;

    // possible values
    // platform = deezer | ...
    // action = connect | login | ...

    if (!platform || !action) {
      this.setState({
        redirect: true
      });
    }
    if ((platform !== 'deezer')
      && (action !== 'connect' || action !== 'login')) {
      // if platform is not any of the above, redirect user to the dashboard page
      this.setState({
        redirect: true
      });
    }

    if (platform === 'deezer') {
      // after deezer redirect user back to knkt, forward to api.knkt.io to verify data
      const url = new URL(window.location.href);
      const code = url.searchParams.get('code');

      if (!code) {
        this.props.addSocialError('deezer', 'Failed to veify with Deezer');
      }

      if (action === 'connect') {
        // redirect to verify connect
        if (code) {
          this.props.connectSocial('deezer', {code: code});
        }
      } else if (action === 'login') {
        // continue to login the user

        if (code) {
          this.props.loginDeezerAction({code: code});
        }
      }
    }
  }

  getWorkingOnItStyle() {
    return {
      fontSize: '1.5em',
      position: 'fixed',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    };
  }

  handleRequestClose() {
    this.props.clearSocialErrorMessage();
  };

  showMessage() {
    const { redirect, redirectTo } = this.state;
    
    if (redirect) {
      return <Redirect to={redirectTo} />;
    } else {
      if (this.props.social.errMessage) {
        return (
          <Snackbar
            open={this.props.social.errMessage}
            message={this.props.social.errMessage}
            autoHideDuration={5000}
            onRequestClose={this.handleRequestClose}
          />
        )
      } else {
        return <div className="text-center" style={this.getWorkingOnItStyle()}>... working on it ...</div>;
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        { this.showMessage() }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    social: state.social
  };
}

function mapDispatchToProps(dispatch) {
  return {
    connectSocial: (site ,data) => dispatch(connectSocial(site, data)),
    loginDeezerAction: (data) => dispatch(loginDeezerAction(data)),
    clearSocialErrorMessage: (site) => dispatch(clearSocialErrorMessage(site)),
    addSocialError: (site, errMessage) => dispatch(addSocialError(site, errMessage))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OauthHandler);