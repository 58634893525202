const styles = {
  calendarBackground: {
    height: '80vh',
    paddingTop: 20,
    color: '#fffffff',
    width: '100%'
  },
  tourAlert: {
    position: 'relative',
    marginTop: 30,
    border: '1px gray solid',
    backgroundColor: '#333333',
    padding: 10,
    color: '#ffffff'
  },
  tourAlertClose: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    textDecoration: 'none',
    color: '#777777',
    cursor: 'pointer',
    padding: 3
  },
  calendarCarousel: {
    marginTop: 20,
    position: 'relative',
    color: '#ffffff'
  },
  calendarTourButton: {
    textAlign: 'center',
    marginBottom: 50,
    padding: 10
  },
  calendarCreateNewParent: {
    border: 'red solid 3px'
  },
  calendarCreateNewButton: {
    cursor: 'pointer'
  },
  calendarCreateNewChild: {
    position: 'absolute',
    height: '50%',
    transform: 'translateY(50%)',
    width: '100%'
  },
  calendarTourImage: {
    position: 'relative'
  },
  calendarTourUsageText: {
    position: 'absolute',
    bottom: 20
  },
  calendarTourActiveStatus: {
    color: '#ff0000',
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(33, 33, 33, 0.6)',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  calendarTourDeactiveButton: {
    color: '#555555',
    backgroundColor: 'rgba(cc, cc, cc, 0.6)',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  calendarTourActiveButton: {
    color: '#ff0000',
    backgroundColor: 'rgba(cc, cc, cc, 0.6)',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer'
  },
  calendarTourDeactiveButtonHover: {
    color: '#ffffff'
  },
  calendarTourName: {
    paddingTop: 3,
    paddingBottom: 3
  },
  calendarTourInactiveStatus: {
    opacity: 0.3
  },
  calendarTourShareButton_hidden: {
    visibility: 'hidden'
  },
  calendarTourShareButton: {
    marginTop: 3,
    marginBottom: 3,
    // backgroundColor: '#444444'
  },
  calendarTourDeleteButton: {
    marginTop: 3,
    marginBottom: 3,
    // backgroundColor: '#000000'
  },
  calendarEventTitle: {
    paddingTop: 10,
    paddingBottom: 5
  },
  gigBackground: {
    border: '1px solid #666666',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '80vh',
    paddingTop: 20
  },
  gigGroup: {
    backgroundColor: '#2c2c2c',
    opacity: 0.65,
    marginTop: 5,
    marginBottom: 10,
    paddingTop: 5,
    paddingBottom: 5,
    minHeight: 85
  },
  gigTourName: {
    textAlign: 'center',
    paddingBottom: 20,
    paddingTop: 20
  },
  gigEdit: {
    cursor: 'pointer'
  },
  gigDate: {
    marginRight: 5
  },
  gigDateMonth: {
    fontSize: '0.9em'
  },
  gigDateYear: {
    fontSize: '0.9em',
    marginTop: -5
  },
  gigDateDay: {
    fontSize: '1.8em',
    marginTop: -5
  },
  gigTile: {
    paddingLeft: 10,
    height: 70
  },
  gigTicket: {
    position: 'absolute',
    top: 10,
    right: 10,
    minWidth: 70,
    minHeight: 25,
    backgroundImage: 'linear-gradient(256deg, #fe79cf, #fc444e)'
  },
  gigTicketLink: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  gigAttending: {
    position: 'absolute',
    bottom: 5,
    right: 10
  },
  gigAttendingFont: {
    color: '#a8a8a8'
  },
  gigClose: {
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)'
  },
  gigPageClose: {
    position: 'absolute',
    top: 10,
    right: 10,
    textDecoration: 'none',
    color: '#ffffff',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 0
  },
  gigAddEvent: {
    width: '100%',
    textAlign: 'right'
  },
  gigAddEventButton: {
    cursor: 'pointer'
  },
  powerByKnkt: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: '0.9em',
    position: 'absolute',
    bottom: 5,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  tourImg: {
    height: 100,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
    cursor: 'pointer',
    border: '1px #444444 solid'
  }
};

export default styles;