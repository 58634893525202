import { callApi } from './utils';
  
// Function to fetch profiles
  const fetchArtistProfiles = async (page, limit) => {
    try {
        const response = await callApi(`/api/v1/artists/profiles?page=${page}&limit=${limit}`, 'get')
        if (response.err){
          console.error('API fetch error', response.err);
        } else {
          const result = response.data;
          console.log("fetchArtistProfiles Result", result)
          return result
        }
    } catch (error) {
        console.error('Error fetching artist profiles:', error);
    }
};

export { fetchArtistProfiles }
