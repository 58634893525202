import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';

import GigGroup from '../components/pages/calendar/GigGroup';
import PoweredByKnkt from '../components/pages/calendar/PoweredByKnkt';
import styles from '../style/calendar';
import history from '../redux/manageHistory';
import TourForm from '../components/pages/calendar/TourForm';
import {
  getTourDataByTourId,
  updateEventsOfTour,
  updateTourName
} from '../utils/calendar';
import { callApi } from '../utils/utils';
import { populateData, updateEvent, updateTour, addTour, updateTourNameInStore } from '../actions/Calendar/calendarAction';

class TourContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tourId: '',
      tourName: '',
      tour: {},
      artistId: '',
      edit: false,
      tourEvents: [],
      otherEvents: [],
      createNew: false,
      showError: false,
      errorMessage: '',
      editTitle: false,
      oldTourName: ''
    };

    this.isEditable = this.isEditable.bind(this);
    this.updateTour = this.updateTour.bind(this);
    this.handleClosePage = this.handleClosePage.bind(this);
    this.prepareTour = this.prepareTour.bind(this);
    this.isOwner = this.isOwner.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangeTitle = this.handleChangeTitle.bind(this);
    this.handleChangeTitleClick = this.handleChangeTitleClick.bind(this);
  }

  componentDidMount() {
    const {match: {params: {tourId}}} = this.props;
    this.setState({
      tourId: tourId
    });

    if (tourId == 0) {
      this.setState({
        createNew: true
      });
    }

    if (this.props.tours.length < 1) {
      getTourDataByTourId(tourId).then(data => {
        this.props.populateData(data.data);
        this.prepareTour(tourId);
      });
    } else {
      this.prepareTour(tourId);
    }
  }

  prepareTour(tourId) {
    setTimeout(_ => {
      let thisTour = {};
      this.props.tours.forEach(tour => {
        if (tour.tourId == tourId) {
          thisTour = tour;
        }
      });

      let includedEvents = [];
      let otherEvents = [];

      if (thisTour.tourId) {
        const tourEvents = thisTour.tourEvents;

        // console.log('-----tourEvents:', tourEvents);

        this.props.tourEvents.forEach(event => {
          // console.log('-----event:', event);
          if (tourEvents.indexOf(event.tourEventId) > -1) {
            includedEvents.push(event);
          } else {
            if (event.active) {
              otherEvents.push(event);
            }
          }
        });

        // console.log('----otherEvents:', otherEvents.length);

        // console.log('-----includedEvents:', includedEvents);

        this.setState({
          tourName: thisTour.tourName,
          artistId: thisTour.artistId,
          tourEvents: includedEvents,
          otherEvents: otherEvents,
          tour: thisTour
        })
      }
    }, 0);
  }

  handleClosePage() {
    let returnId = this.props.showArtistId;

    if (!returnId) {
      returnId = this.state.artistId;
    }

    history.push('/calendar/'+returnId);
  }

  isEditable() {
    return this.props.user && this.props.user.account === 'artist' && this.isOwner();
  }

  isOwner() {
    const { artistId } = this.state;
    const thisArtistId = this.props.user ? this.props.user.artistId : '';
    return artistId == thisArtistId;
  }

  handleCloseModal() {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  };

  updateTour(add, tour, event) {
    const updateThisTour = _ => {
      this.props.updateTour(add, tour, event);
      let newEvents = [];
      let otherEvents = [];
      if (add) {
        newEvents = this.state.tourEvents;
        this.props.tourEvents.forEach(oneEvent => {
          if (oneEvent.tourEventId === event.tourEventId) {
            newEvents.push(oneEvent);
          } else {
            otherEvents.push(oneEvent);
          }
        });
      } else {
        otherEvents = this.state.otherEvents;
        this.state.tourEvents.forEach(oneEvent => {
          if (oneEvent.tourEventId !== event.tourEventId) {
            newEvents.push(oneEvent);
          } else {
            otherEvents.push(oneEvent);
          }
        });
      }
      this.setState({
        tourEvents: newEvents,
        otherEvents: otherEvents
      })
    };

    const { tourEvents } = this.state;
    let events = [];
    tourEvents.forEach(thisEvent => {
      if (!add) {
        if (thisEvent.tourEventId == event.tourEventId) {
          return;
        }
      }
      events.push(thisEvent.tourEventId);
    });

    if (add) {
      events.push(event.tourEventId);
    }

    updateEventsOfTour(tour.tourId, events)
      .then(tour => {
        updateThisTour();
      })
      .catch(err => {
        // console.error(err);

        this.setState({
          showError: true,
          errorMessage: err.message
        })
      });
  }

  addNewTour(tour) {
    const { artistId, active, tourName, backgroundImage, events } = tour;
    callApi('/api/v1/tour', 'post', {
      artistId, active, tourName, backgroundImage,
      events: events || []
    }).then(res => {
      if (res.data && res.data.success == true) {
        const newTour = res.data.tour;
        // console.log('------newTour---:', newTour);

        this.props.addTour(newTour);
        this.handleClosePage();
      }
    });
  }

  handleEditTitle() {
    this.setState({
      editTitle: true
    })
  }

  handleChangeTitle(event, value) {
    this.setState({
      tourName: value
    });
    if (this.state.oldTourName == '') {
      this.setState({
        oldTourName: this.state.tourName
      });
    }
  }

  handleChangeTitleClick(update) {
    if (update) {
      this.setState({
        editTitle: false,
        oldTourName: ''
      });

      const { tourId, tourName } = this.state;
      updateTourName(tourId, tourName)
        .then(tour => {
          this.props.updateTourNameInStore(tourId, tourName);
        })
        .catch(err => {
          this.setState({
            showError: true,
            errorMessage: err.message
          })
        });
    } else {
      this.setState({
        editTitle: false,
        oldTourName: '',
        tourName: this.state.oldTourName
      });
    }
  }

  render() {
    const { createNew, editTitle, tourName } = this.state;

    const actions = [
      <FlatButton
        label="Dismiss"
        primary={true}
        onClick={this.handleCloseModal}
      />
    ];
    
    if (createNew) {
      return (
        <Container>
          <Row style={{height: '100%'}}>
            <Col xs={10} xsOffset={1} md={8} mdOffset={2}
                 style={{...styles.gigBackground, backgroundImage: 'url("/img/logo/word.png")', overflow: 'scroll'}}>
              <div>&nbsp;</div>
              <TourForm user={this.props.user} addEvent={this.props.addEvent} addTour={this.addNewTour.bind(this)} handleClosePage={this.handleClosePage} />
              <button style={styles.gigPageClose} onClick={this.handleClosePage.bind(this)}>
                Cancel
              </button>
            </Col>
          </Row>
        </Container>
      )
    } else {
      return (
        <Container>
          <Row style={{height: '100%'}}>
            <Col xs={10} xsOffset={1} md={8} mdOffset={2}
                 style={{...styles.gigBackground, backgroundImage: 'url("'+(this.state.tour.backgroundImage || '/img/logo/word.png')+'")', overflow: 'scroll'}}>
              { this.isEditable() && (
                <Row>
                  <Col xs={12} style={styles.gigEdit}>
                    <FontAwesomeIcon icon={faPencilAlt}/> Change the image
                  </Col>
                </Row>
              )}

              <Row style={{position: 'relative'}}>
                <Col xs={12} className="text-center" style={styles.gigTourName}>
                  {
                    !editTitle && (
                      <React.Fragment>
                        <span>{tourName + ' '}</span>
                        { this.isEditable() && (
                          <FontAwesomeIcon icon={faPencilAlt} onClick={this.handleEditTitle.bind(this)} />
                        )}
                      </React.Fragment>
                    )
                  }

                  {
                    editTitle && (
                      <React.Fragment>
                        <TextField
                          defaultValue={tourName}
                          floatingLabelText="Edit Title"
                          style={{ backgroundColor: '#aaaaaa'}}
                          onChange={this.handleChangeTitle}
                        />
                        <FlatButton label="Update" onClick={this.handleChangeTitleClick.bind(this, true)} />
                        <FlatButton label="Cancel" onClick={this.handleChangeTitleClick.bind(this, false)} />
                      </React.Fragment>
                    )
                  }
                </Col>
              </Row>
              {
                this.state.tourEvents.map(event => {
                  return (
                    <Row key={event.tourEventId}>
                      <Col xs={12} sm={10} smOffset={1}>
                        <GigGroup event={event} active={true} user={this.props.user} tour={this.state.tour}
                                  updateTour={this.updateTour}/>
                      </Col>
                    </Row>
                  );
                })
              }

              {
                this.isEditable() && (
                  <hr />
                )
              }

              {
                this.isEditable() && this.state.otherEvents.map(event => {
                  return (
                    <Row key={event.tourEventId}>
                      <Col xs={12} sm={10} smOffset={1}>
                        <GigGroup event={event} active={false} user={this.props.user} tour={this.state.tour}
                                  updateTour={this.updateTour}/>
                      </Col>
                    </Row>
                  );
                })
              }

              <button style={styles.gigPageClose} onClick={this.handleClosePage.bind(this)}>
                Close X
              </button>
              
            </Col>
          </Row>

          <Dialog
            title="Oops! Something went wrong!"
            actions={actions}
            modal={true}
            open={this.state.showError}
            onRequestClose={this.handleCloseModal}
          >
            { this.state.errorMessage }
          </Dialog>

          <PoweredByKnkt style={{...styles.powerByKnkt, bottom: 20}}/>
        </Container>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    tours: state.calendar.tours,
    tourEvents: state.calendar.tourEvents,
    edit: state.calendar.edit,
    user: state.user,
    showArtistId: state.calendar.showArtistId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    populateData: (data) => dispatch(populateData(data)),
    updateEvent: (activate, event) => dispatch(updateEvent(activate, event)),
    updateTour: (add, tour, event) => dispatch(updateTour(add, tour, event)),
    updateTourNameInStore: (tourId, tourName) => dispatch(updateTourNameInStore(tourId, tourName)),
    addTour: (tour, event) => dispatch(addTour(tour, event))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TourContainer);

// const tempData = {
//   tours: [
//     {
//       tourId: 'tid000000',
//       tourName: 'Tour0',
//       active: true,
//       backgroundImage: '/img/sample/002-sample.jpg',
//       tourEvents: ['12345', '12347', '12348'],
//       artistId: 2
//     },
//     {
//       tourId: 'tid0111111',
//       tourName: 'Tour1',
//       active: true,
//       backgroundImage: '/img/sample/001-sample.jpg',
//       tourEvents: ['12346', '12349', '12350'],
//       artistId: 2
//     },
//     {
//       tourId: 'tid022222',
//       tourName: 'Tour2',
//       active: true,
//       // backgroundImage: '/img/sample/002-sample.jpg',
//       tourEvents: ['12346', '12350', '12352'],
//       artistId: 2
//     },
//     {
//       tourId: 'tid003333',
//       tourName: 'Tour3',
//       active: true,
//       backgroundImage: '/img/sample/002-sample.jpg',
//       tourEvents: ['12348', '12349', '12351'],
//       artistId: 2
//     },
//     {
//       tourId: 'tid0114444',
//       tourName: 'Tour4',
//       active: false,
//       backgroundImage: '/img/sample/001-sample.jpg',
//       tourEvents: ['12348', '12349', '12350'],
//       artistId: 2
//     },
//     {
//       tourId: 'tid025555',
//       tourName: 'Tour5',
//       active: false,
//       backgroundImage: '/img/sample/002-sample.jpg',
//       tourEvents: ['12350', '12351', '12352'],
//       artistId: 2
//     }
//   ],
//   tourEvents: [
//     {
//       tourEventId: '12345',
//       name: 'Event1',
//       eventStartDate: (new Date()).getTime(),
//       eventEndDate: (new Date()).getTime(),
//       country: 'France',
//       countryCode: 'fr',
//       city: 'TheCity',
//       venue: 'TheVenue',
//       attending: 5500,
//       ticketLink: '//yahoo.com',
//       active: true,
//       source: 'fb',
//       artistId: 2
//     },
//     {
//       tourEventId: '12346',
//       name: 'Event2',
//       eventStartDate: (new Date()).getTime(),
//       eventEndDate: (new Date()).getTime(),
//       country: 'USA',
//       countryCode: 'us',
//       city: 'TheCity2',
//       venue: 'TheVenue2',
//       attending: 5,
//       ticketLink: '//google.com',
//       active: true,
//       source: 'fb',
//       artistId: 2
//     },
//     {
//       tourEventId: '12347',
//       name: 'Event3',
//       eventStartDate: (new Date()).getTime(),
//       eventEndDate: (new Date()).getTime(),
//       country: 'Taiwan',
//       countryCode: 'tw',
//       city: 'TheCity',
//       venue: 'TheVenue',
//       attending: 5123,
//       ticketLink: '//yahoo.com',
//       active: true,
//       source: 'fb',
//       artistId: 2
//     },
//     {
//       tourEventId: '12348',
//       name: 'Event4',
//       eventStartDate: (new Date()).getTime(),
//       eventEndDate: (new Date()).getTime(),
//       country: 'China',
//       countryCode: 'cn',
//       city: 'TheCity2',
//       venue: 'TheVenue2',
//       attending: 4440,
//       ticketLink: '//google.com',
//       active: false,
//       source: 'tw',
//       artistId: 2
//     },
//     {
//       tourEventId: '12349',
//       name: 'Event5',
//       eventStartDate: (new Date()).getTime(),
//       eventEndDate: (new Date()).getTime(),
//       country: 'India',
//       countryCode: 'in',
//       city: 'TheCity',
//       venue: 'TheVenue',
//       attending: 15500,
//       ticketLink: '//yahoo.com',
//       active: false,
//       source: 'tw',
//       artistId: 2
//     },
//     {
//       tourEventId: '12350',
//       name: 'Event6',
//       eventStartDate: (new Date()).getTime(),
//       eventEndDate: (new Date()).getTime(),
//       country: 'China',
//       countryCode: 'cn',
//       city: 'TheCity2',
//       venue: 'TheVenue2',
//       attending: 300,
//       ticketLink: '//google.com',
//       active: false,
//       source: 'knkt',
//       artistId: 2
//     },
//     {
//       tourEventId: '12351',
//       name: 'Event7',
//       eventStartDate: (new Date()).getTime(),
//       eventEndDate: (new Date()).getTime(),
//       country: 'Mexico',
//       countryCode: 'mx',
//       city: 'TheCity',
//       venue: 'TheVenue',
//       attending: 500,
//       ticketLink: '//yahoo.com',
//       active: false,
//       source: 'knkt',
//       artistId: 2
//     },
//     {
//       tourEventId: '12352',
//       name: 'Event8',
//       eventStartDate: (new Date()).getTime(),
//       eventEndDate: (new Date()).getTime(),
//       country: 'Malaysia',
//       countryCode: 'my',
//       city: 'TheCity2',
//       venue: 'TheVenue2',
//       attending: 1500,
//       ticketLink: '//google.com',
//       active: true,
//       source: 'knkt',
//       artistId: 2
//     }
//   ],
//   edit: true   // user is artist, non-artist=false
// }