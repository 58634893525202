
import EChartDataModel from '../../shared-components/EChartDataModel';

class DataSeriesDataModel {
    constructor(data = []) {
        this.type = "bar";
        this.itemStyle = {
            color: "#6676f5"
        };
        this.barWidth = 20;
        this.stack = "one";
        this.data = data;
    }
}

class ChartDataModel extends EChartDataModel {
    constructor({ xAxis, yAxis }) {
        super({ xAxis });
        this.animationDurationUpdate = 500;
        this.visualMap = null;

        let total = new DataSeriesDataModel(yAxis.total);
        total.itemStyle.barBorderRadius = [0, 0, 50, 50];
        this.series.push(total);

        let increase = new DataSeriesDataModel(yAxis.increase);
        increase.itemStyle.barBorderRadius = [50, 50, 0, 0];
        increase.itemStyle.color = '#ff8200';
        this.series.push(increase);
    }
}

export default ChartDataModel;