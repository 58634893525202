import Radium from 'radium';


const EventOrganizersPage = () => {
  const styles = {
    heading: {
      color: 'white'
    }
  };
  return (
    <>
    <h1 style={styles.heading}>This is the Event Organizers Page.</h1>
    </>
  )
}

export default Radium(EventOrganizersPage);