import Radium from 'radium';


const FansPage = () => {
  const styles = {
    heading: {
      color: 'white'
    }
  };
  return (
    <>
    <h1 styles={styles.heading}>This is the Fans Page with Contacts Table</h1>
    </>
  )
}

export default Radium(FansPage);