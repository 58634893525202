const insightsAgeGender = {
  "lifeTime": {
    "ageGroup_13_17": 240,
    "ageGroup_18_24": 350,
    "ageGroup_25_34": 223,
    "ageGroup_35_44": 234,
    "ageGroup_45_54": 345,
    "ageGroup_55_64": 260,
    "gender_neutral": 253,
    "men": 263,
    "women": 243
  },
  "createdDate": "2020-07-23T05:03:15.262Z",
  "id": {
    "counter": 0,
    "date": "2020-07-23T05:03:15.262Z",
    "machineIdentifier": 0,
    "processIdentifier": 0,
    "time": 0,
    "timeSecond": 0,
    "timestamp": 0
  },
  "knktId": 2,
  "sevenDay": {
    "ageGroup_13_17": 40,
    "ageGroup_18_24": 50,
    "ageGroup_25_34": 23,
    "ageGroup_35_44": 34,
    "ageGroup_45_54": 45,
    "ageGroup_55_64": 60,
    "gender_neutral": 53,
    "men": 63,
    "women": 43
  },
  "twentyEightDay": {
    "ageGroup_13_17": 140,
    "ageGroup_18_24": 150,
    "ageGroup_25_34": 123,
    "ageGroup_35_44": 134,
    "ageGroup_45_54": 145,
    "ageGroup_55_64": 160,
    "gender_neutral": 153,
    "men": 163,
    "women": 143
  }
};

const insightsLocation = {
  "createdDate": "2020-07-24T05:03:15.262Z",
  "id": {
    "counter": 0,
    "date": "2020-07-30T02:06:28.380Z",
    "machineIdentifier": 0,
    "processIdentifier": 0,
    "time": 0,
    "timeSecond": 0,
    "timestamp": 0
  },
  "knktId": 2,
  "lifeTime": {
    "cities": [
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      }
    ],
    "countries": [
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      }
    ]
  },
  "sevenDay": {
    "cities": [
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      }
    ],
    "countries": [
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      }
    ]
  },
  "twentyEightDay": {
    "cities": [
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      }
    ],
    "countries": [
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      }
    ]
  }
};

export {
  insightsAgeGender,
  insightsLocation
};