import React, { Component } from 'react';
import { FormGroup, Form, FormLabel, FormControl } from 'react-bootstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';

import TextInputGroup from '../../common/TextInputGroup';
import countries from '../../../utils/country';
import { isEmpty } from '../../../utils/formUtils';

import '../../../style/calendar.css';

const ct = require('countries-and-timezones');

class EventFrom extends Component {

  constructor(props, context) {
    super(props, context);

    const now = new Date();

    this.state = {
      name: null,
      country: '',
      countryCode: '',
      city: null,
      venue: null,
      ticketLink: '',
      active: true,
      source: 'knkt',
      artistId: this.props.artistId,
      eventStartDate: now,
      eventEndDate: now,
      errors: [],
      now: now
    };

    this.handleEventDateChange = this.handleEventDateChange.bind(this);
    this.updateField = this.updateField.bind(this);
    this.getCountryName = this.getCountryName.bind(this);
    this.buildErrorList = this.buildErrorList.bind(this);
  }

  componentDidMount() {
    if (this.props.user && this.props.user.artistId) {
      this.setState({
        artistId: this.props.user.artistId
      })
    }
  }

  // shouldComponentUpdate (nextProps, nextState) {
  //   return this.state.errors == nextState.errors;
  // }

  // country: 'France',
  // countryCode: 'fr',

  // name: 'Event1',
  // city: 'TheCity',
  // venue: 'TheVenue',
  // ticketLink: '//yahoo.com',
  // active: true,
  // source: 'fb',
  // artistId: 'ar123456'
  // eventDate: (new Date()).getTime(),


  handleEventDateChange(start, date) {
    if (start) {
      this.setState({
        eventStartDate: date
      });
    } else {
      this.setState({
        eventEndDate: date
      });
    }
  }

  getValidationState() {
    const length = this.state.value.length;
    if (length > 10) return 'success';
    else if (length > 5) return 'warning';
    else if (length > 0) return 'error';
    return null;
  }

  getCountryName(code) {
    const theCountry = countries.find(country => {
      return country.code.toLowerCase() === code
    });
    if (theCountry) {
      return theCountry.name;
    }
    return '';
  }

  getCountries() {
    let allCountries = [];

    const availableCountries = ct.getAllCountries();

    if (availableCountries) {
      const allCountryCodes = Object.keys(availableCountries);

      const countryMap = [];

      allCountryCodes.forEach(countryCode => {
        countryMap.push({code: countryCode, name: availableCountries[countryCode].name});
      });

      countryMap.sort((a, b) => {
        let nameA = a.name.toUpperCase();
        let nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      countryMap.forEach(con => {
        allCountries.push(
          <option key={con.code} value={con.code.toLowerCase()}>{con.name}</option>
        );
      })
    }

    return (
      <FormControl componentClass="select">
        <option key='0000' value="0000">Select A Country</option>
        { allCountries }
      </FormControl>
    )
  }

  updateField(e) {
    let field = '';
    // console.log('---e.target.id:', e.target);
    switch(e.target.id) {
      case 'formControlsEventName': {
        field = 'name';
        break;
      }
      case 'formControlsVenue': {
        field = 'venue';
        break;
      }
      case 'formControlsCity': {
        field = 'city';
        break;
      }
      case 'formControlsTicketLink': {
        field = 'ticketLink';
        break;
      }
      default: {}
    }
    if (field) {
      this.setState({
        [field]: e.target.value
      })
    }

    // special case - handle checkbox
    if (e.target.id === 'formControlsActive') {
      this.setState({
        active: e.target.checked
      })
    }
    // special case - handle country code and country name
    if (e.target.id === 'formControlsCountry') {
      this.setState({
        countryCode: e.target.value,
        country: this.getCountryName(e.target.value)
      })
    }
  }

  submitForm(event) {
    event.preventDefault();

    let allErrors = [];
    let alertOK = true;

    let {
      name, country, countryCode, city, venue, ticketLink, active, eventStartDate, eventEndDate, now
    } = this.state;

    // console.log('name-', name);
    // console.log('country-', country);
    // console.log('countryCode-', countryCode);
    // console.log('city-', city);
    // console.log('venue-', venue);
    // console.log('ticketLink-', ticketLink);
    // console.log('active-', active);
    // console.log('source-', source);
    // console.log('artistId-', artistId);
    // console.log('eventStartDate-', eventStartDate);
    // console.log('eventEndDate-', eventEndDate);

    console.log('---- event from state:', this.state);

    // const { eventStartDate, name, country, city, venue, attending, countryCode, ticketLink, active } = event;
    if (isEmpty(name)) {
      allErrors.push('Please give the event a name');
    }

    if (isEmpty(country) || isEmpty(countryCode)) {
      allErrors.push('Please select a country');
    }

    if (isEmpty(city)) {
      allErrors.push('Please provide the event\'s city');
    }

    if (isEmpty(venue)) {
      allErrors.push('Please provide the event\'s venue');
    }

    if (isEmpty(ticketLink)) {
      allErrors.push('Enter the link to your ticketing website');
    }

    if (alertOK && eventStartDate === now) {
      alertOK = window.confirm('Are you sure the Start Date is correct?');
    }

    if (alertOK && eventEndDate === now) {
      alertOK = window.confirm('Are you sure the End Date is correct?');
    }

    this.setState({
      errors: allErrors
    });

    if (allErrors.length === 0 && alertOK) {

      if (!(ticketLink.indexOf('https') === 0) && !(ticketLink.indexOf('http') === 0)) {
        ticketLink = 'http://' + ticketLink;
      }

      let event = {};
      event.tourEventId = now.getTime();
      event.name = name;
      event.eventStartDate = eventStartDate;
      event.eventEndDate = eventEndDate;
      event.country = country;
      event.countryCode = countryCode;
      event.city = city;
      event.venue = venue;
      event.attending = 0;
      event.ticketLink = ticketLink;
      event.active = active;
      event.source = 'knkt';
      event.artistId = this.props.user.artistId;

      this.props.addEvent(event);
    }
  }

  buildErrorList() {
    let errorLists =[];
    this.state.errors.forEach((err, index) => {
      errorLists.push(<li key={index} style={{color: '#a94442'}}>{err}</li>);
    });

    return (
      <div>
        {errorLists.length > 0 && (
          <div>Please check the followings:</div>
        )}
        <ul>
          { errorLists }
        </ul>
      </div>
    )
  }

  render() {
    const CustomDateInput = ({ start, value, onClick }) => (
      <span style={{width: '100%', textAlign: 'center'}} onClick={onClick.bind(this, start, value)}>
        <FontAwesomeIcon icon={faCalendarAlt}/>  {value}
      </span>
    );

    const CustomTimeInput = ({ start, value, onClick }) => (
      <span style={{width: '100%', textAlign: 'center'}} onClick={onClick.bind(this, start, value)}>
        {value}  <FontAwesomeIcon icon={faClock}/>
      </span>
    );

    return (
      <form className="eventForm">
        <TextInputGroup
          id="formControlsEventName"
          type="text"
          label="Event Name"
          placeholder="Enter name of event"
          onChange={this.updateField}
          validationState={this.state.name === null ? null : (this.state.name.length > 0 ? "success" : "error")}
          help={this.state.name === null ? null : (this.state.name.length > 0 ? null : "Please give the event a name")}
        />

        <TextInputGroup
          id="formControlsVenue"
          type="text"
          label="Venue"
          placeholder="Enter the Name of the Venue"
          onChange={this.updateField}
          validationState={this.state.venue === null ? null : (this.state.venue.length > 0 ? "success" : "error")}
          help={this.state.venue === null ? null : (this.state.venue.length > 0 ? null : "Please provide the event's venue")}
        />

        <TextInputGroup
          id="formControlsCity"
          type="text"
          label="City"
          placeholder="Enter the City of the Venue"
          onChange={this.updateField}
          validationState={this.state.city === null ? null : (this.state.city.length > 0 ? "success" : "error")}
          help={this.state.city === null ? null : (this.state.city.length > 0 ? null : "Please provide the event's city")}
        />

        <FormGroup controlId="formControlsCountry" onChange={this.updateField}
                   validationState={this.state.countryCode === '' ? null : (this.state.countryCode === '0000' ? "error" : "success")}>
          <FormLabel>Country</FormLabel>
          { this.getCountries() }
        </FormGroup>

        <div style={{padding: 8, width: '100%'}}>
          <div style={{minWidth: '15%', display: 'inline-block', textAlign: 'right', paddingRight: 20}}>Start</div>
          <div style={{display: 'inline-block', width: '50%', textAlign: 'center', padding: 15, border: '1px solid', marginLeft: 10}}>
            <DatePicker
              selected={this.state.eventStartDate}
              onChange={this.handleEventDateChange.bind(this, true)}
              customInput={<CustomDateInput />}
              dateFormat="MMMM d, yyyy"
            />
          </div>
          <div style={{display: 'inline-block', width: '25%',textAlign: 'center', padding: 15, border: '1px solid', marginLeft: 10}}>
            <DatePicker
              selected={this.state.eventStartDate}
              onChange={this.handleEventDateChange.bind(this, true)}
              customInput={<CustomTimeInput />}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              showTimeSelect
              showTimeSelectOnly
            />
          </div>
        </div>

        <div style={{padding: 8}}>
          <div style={{minWidth: '15%', display: 'inline-block', textAlign: 'right', paddingRight: 20}}>End</div>
          <div style={{display: 'inline-block', width: '50%', textAlign: 'center', padding: 15, border: '1px solid', marginLeft: 10}}>
            <DatePicker
              selected={this.state.eventEndDate}
              onChange={this.handleEventDateChange.bind(this, false)}
              customInput={<CustomDateInput />}
              dateFormat="MMMM d, yyyy"
            />
          </div>
          <div style={{display: 'inline-block', width: '25%',textAlign: 'center', padding: 15, border: '1px solid', marginLeft: 10}}>
            <DatePicker
              selected={this.state.eventEndDate}
              onChange={this.handleEventDateChange.bind(this, false)}
              customInput={<CustomTimeInput />}
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              showTimeSelect
              showTimeSelectOnly
            />
          </div>
        </div>

        <FormGroup>
          <Form.Check type="checkbox" id="formControlsActive" onChange={this.updateField} checked={this.state.active}>
            Mark active
          </Form.Check>
        </FormGroup>

        <TextInputGroup
          id="formControlsTicketLink"
          type="text"
          label="Ticket Link"
          placeholder="Enter the link to your ticketing website"
          onChange={this.updateField}
        />

        <div style={{position: 'relative'}}>
          <div className="text-left">
            {this.buildErrorList()}
          </div>
          <div style={{position: 'absolute', top: 5, right: 5}} className="text-right">
            <button type="submit" className="submitButton" onClick={this.submitForm.bind(this)}>Submit</button>
          </div>
        </div>
      </form>
    )
  }
}

export default EventFrom;