import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Row, Button, Carousel } from 'react-bootstrap';
import axios from 'axios'
import { getApiUrl } from '../../../config/siteConfig';

import ArtistContainer from '../../../containers/ArtistContainer';
import UserContainer from '../../../containers/UserContainer';
import SpotifyPlayer from './SpotifyPlayer';
import SpotifyFollow from './SpotifyFollow';
import { Link } from 'react-router-dom';
import Radium from 'radium';
import KnktButton from '../../common/KnktButton';
import history from '../../../redux/manageHistory';

const ProfilePage = (props) => {
  let profileName, place, knktId;
  let avatar = '../img/logo/knkt-logo.svg';
  const { user, location } = props;
  const [isLoggedIn, setIsLoggedIn] = useState(user ? 1 : 0);
  const [subButtonTxt, setSubButtonTxt] = useState('');
  const [bookNowTxt, setBookNowTxt] = useState('');
  const [isMyProfile, setIsMyProfile] = useState(1);
  const [artistProfile, setArtistProfile] = useState({ profileName: '', place: '', description: '', followers: '20k', following: '1k', subscribers: '100k', profilePic: '' })
  const [artistProfileResult, setArtistProfileResult] = useState(null);

  // if (location.state) {
  //    profileName = location.state.profileName;
  //    place = location.state.place;
  //    knktId = location.state.knktId;
  // }

  if (props.user){
    profileName = props.user.firstName + ' ' + props.user.lastName
    knktId = props.user.knktId
    if (user.avatar) {
      avatar = user.avatar;
    }
  } else {
    profileName = props.artist.name;
  }



  
  const checkMyprofileState = () => {
    return false;
  };

  const getArtistProfileDetails = () => {
    axios({
      url: getApiUrl() + '/api/v1/artists/' + knktId,
      method: 'get',
      headers: {
        token: window.localStorage.getItem('token') || ''
      }
    }).then((res) => {
      setArtistProfileResult(res.data)

    }).catch((err) => {
      console.log('artist api error: ', err);
    })
  }

  useEffect(() => {
    if (user) {
      setSubButtonTxt('Susbscribe');
      setBookNowTxt('Book Now');
      setIsMyProfile(checkMyprofileState);
    }

    if (knktId) {
      getArtistProfileDetails();
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      setSubButtonTxt('Login To Susbscribe');
      setBookNowTxt('Login To Book');
      setArtistProfile({ profileName, place, description: '', followers: '20k', following: '1k', subscribers: '100k', profilePic: '' })
    }
  }, [isLoggedIn]);


  // console.log('profile page logs: ', profileName, isLoggedIn, user, location, artistProfileResult);

  const styles = {
    header: {
      minHeight: '30vh',
      background: '#009FFF',
      background: 'linear-gradient(to right, #ec2F4B, #009FFF)',
      color: 'white',
      // clipPath: "ellipse(100vw 60vh at 50% 50%)",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    hover: {
      fb: {
        ':hover': {
          background: '#0B84EE',
          cursor: 'pointer',
          boxShadow: '0px 0px 15px 6px #0B84EE',
        },
      },
      yt: {
        ':hover': {
          boxShadow: '0px 0px 15px 6px #FF0000',
          background: '#FF0000',
          cursor: 'pointer',
        },
      },
      ig: {
        ':hover': {
          boxShadow: '0px 0px 15px 6px gray',
          background:
            'linear-gradient(to right, rgb(236, 47, 75), rgb(0, 159, 255))',
          cursor: 'pointer',
        },
      },
      apple: {
        ':hover': {
          boxShadow: '0px 0px 15px 6px gray',
          background:
            'linear-gradient(304deg, rgba(71,71,71,1) 0%, rgba(0,0,0,1) 43%)',
          cursor: 'pointer',
        },
      },
      spotify: {
        ':hover': {
          boxShadow: '0px 0px 15px 6px #1ED760',
          background: '#1ED760',
          cursor: 'pointer',
        },
      },
      twitter: {
        ':hover': {
          boxShadow: '0px 0px 15px 6px #1A91DA',
          background: '#1A91DA',
          cursor: 'pointer',
        },
      },
      eventBrite: {
        ':hover': {
          boxShadow: '0px 0px 15px 6px #F05537',
          background: '#F05537',
          cursor: 'pointer',
        },
      },
      soundCloud: {
        ':hover': {
          boxShadow: `0px 0px 15px 6px #FF551A`,
          background: '#FF551A',
          cursor: 'pointer',
        },
      },
      deezer: {
        ':hover': {
          boxShadow: '0px 0px 15px 6px gray',
          background: '#000',
          cursor: 'pointer',
        },
      },
    },
    details: {
      textAlign: 'center',
      color: '#fff',
      marginTop: '-80px',
      maxWidth: '100%',
      width: '100%',
      
    },
    noleftRightPadding: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    buttonGroup: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '12px',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0.5rem',
    },
    profilePic: {
      height: '10rem',
      width: '10rem',
      objectFit: 'cover',
      borderRadius: '50%',
      border: '2px solid #fff',
    },
    stats: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    statsDetail: {
      width: '10rem',
      textAlign: 'center',
    },
    heading: {
      fontWeight: '400',
      fontSize: '1.3rem',
      margin: '1rem 0',
    },
    location: {
      svg: {
        display: 'inline-block',
      },
      p: {
        verticalAlign: 'middle',
      },
    },
    hr: {
      backgroundColor: '#ffffff',
    },
    button: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    card: {
      padding: '10px',
      maxWidth: '800px',
      margin: '0 auto',
      justifyContent: 'center',
    },
    bg: {
      backgroundColor: 'rgb(26, 29, 36)',
      color: 'rgb(255,255,255)',
    },
    bgCardBorder: {
      backgroundColor: 'rgb(26, 29, 36)',
      color: 'rgb(255,255,255)',
      borderColor: 'darkslategray',
    },
    label: {
      display: 'inline-block',
      width: '150px',
      height: '150px',
      borderRadius: '50%',
      objectFit: 'cover',
      margin: '1.5rem',
    },
  };

  return (
    <div>
      {/*    <Row>
        {
          (props.user.account === 'artist') && (
            <ArtistContainer
              user
            />
          )
        }
        {
          (props.user.account === 'organizer') && (
            <ArtistContainer
              user
            />
          )
        }
        {
          (props.user.account === 'user') && (
            <UserContainer
              user
            />
          )
        }
      </Row> */}
      <section className="profile">
        <div className="header" style={styles.header}></div>
        <div className="details" style={styles.details}>
          {
            avatar && (
              <img
                style={styles.profilePic}
                src={avatar}
                alt="John Doe"
                className="profile-pic"
              />
            )
          }
          
          <h1 className="heading" style={styles.heading}>
            {profileName}
          </h1>
          <div className="location">
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="currentColor"
              style={styles.location.svg}
            >
              <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12 ,2Z"></path>
            </svg>
            <span style={styles.location.p}>{place}</span>
          </div>
          <hr style={styles.hr}></hr>
          <div className="stats" style={styles.stats}>
            <div className="col-4" style={styles.statsDetail}>
              <h4>20k</h4>
              <p>Followers</p>
            </div>
            <div className="col-4" style={styles.statsDetail}>
              <h4>1k</h4>
              <p>Following</p>
            </div>
            <div className="col-4" style={styles.statsDetail}>
              <h4>100k</h4>
              <p>Subscribers</p>
            </div>
          </div>
        </div>
        <div style={styles.buttonGroup}>
          <KnktButton
            type="knktBlue"
            isGrouped={false}
            value="Subscribe"
          />
          <KnktButton
            type="knktRed"
            isGrouped={false}
            value="Message"
          />
          <KnktButton
            type="knktYellow"
            isGrouped={false}
            value="Follow"
          />
          <KnktButton type="knktNeon" value="Book Now"  parentCallBack={()=>history.push({
            pathname: '/booking',
            state: { profileName, knktId }
        })}/>
        </div>
      </section>
      <div style={styles.button}>
        {isMyProfile === true && (
          <Button
            variant="outline-primary"
            className="container-fluid"
          >
            {subButtonTxt}
          </Button>
        )}
      </div>
      <section className="about" style={styles.card}>
        <div className="card" style={styles.bg}>
          <div className="card-body">
            <h5 className="card-title">Meet The Artist</h5>
            <h6 className="card-subtitle mb-2 text-muted">
              About Me
            </h6>
            <p className="card-text">
              Some quick example text to build on the card title and
              make up the bulk of the card's content.
            </p>
          </div>
        </div>
      </section>
      <section className="soclialProfile" style={styles.card}>
        <div
          className="card"
          style={{ backgroundColor: 'inherit', color: '#fff' }}
        >
          <div
            className="card-body"
            style={styles.noleftRightPadding}
          >
            <div className="card-columns" style={{ columnCount: 6 }}>
              <div className="card" style={styles.bgCardBorder}>
                <div
                  className="card-body text-center"
                  style={styles.noleftRightPadding}
                >
                  <i class="fab fa-instagram fa-2x brand-opacity"></i>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div
                  className="card-body text-center"
                  style={styles.noleftRightPadding}
                >
                  <i class="fab fa-twitter fa-2x brand-opacity"></i>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div
                  className="card-body text-center"
                  style={styles.noleftRightPadding}
                >
                  <i className="fab fa-facebook-f fa-2x brand-opacity"></i>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div
                  className="card-body text-center"
                  style={styles.noleftRightPadding}
                >
                  <i class="fab fa-soundcloud fa-2x brand-opacity"></i>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div
                  className="card-body text-center"
                  style={styles.noleftRightPadding}
                >
                  <i className="fab fa-youtube fa-2x brand-opacity"></i>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div
                  className="card-body text-center"
                  style={styles.noleftRightPadding}
                >
                  <i class="fab fa-spotify fa-2x brand-opacity"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="featuredSkills" style={styles.card}>
        <div className="card" style={styles.bg}>
          <div className="card-body">
            <h5 className="card-title">Super Powers</h5>
            <div className="card-columns">
              <div className="card" style={styles.bgCardBorder}>
                <div className="card-body text-center">
                  <p className="card-text">Power 1</p>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div className="card-body text-center">
                  <p className="card-text">Power 2</p>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div className="card-body text-center">
                  <p className="card-text">Power 3</p>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div className="card-body text-center">
                  <p className="card-text">Power 4</p>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div className="card-body text-center">
                  <p className="card-text">Power 5</p>
                </div>
              </div>
              <div className="card" style={styles.bgCardBorder}>
                <div className="card-body text-center">
                  <p className="card-text">Power 6</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section style={styles.card}>
        <Carousel indicators="false" interval={null} style={{ maxWidth: '800px', width: '100%' }}>
          <Carousel.Item style={{ width: '100%' }}>
            <div
              className="card flex-row flex-wrap"
              style={{ ...styles.bg, maxWidth: '800px', width: '100%' }}
            >
              <div className="card-header border-0">
                <img src="//placehold.it/200" alt="" />
              </div>
              <div className="card-block px-2 text-left w-50">
                <h4 className="card-title">First Item</h4>
                <p className="card-text">
                  Track and measure people visiting any webpage or
                  link
                </p>
              </div>
            </div>
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>
                Nulla vitae elit libero, a pharetra augue mollis
                interdum.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item style={{ width: '100%' }}>
            <div
              className="card flex-row flex-wrap"
              style={{ ...styles.bg, maxWidth: '800px', width: '100%' }}
            >
              <div className="card-header border-0">
                <img src="//placehold.it/200" alt="" />
              </div>
              <div className="card-block px-2 text-left w-50">
                <h4 className="card-title">Second Item</h4>
                <p className="card-text">
                  Track and measure people visiting any webpage or
                  link
                </p>
              </div>
            </div>

            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item style={{ width: '100%' }}>
            <div
              className="card flex-row flex-wrap"
              style={{ ...styles.bg, maxWidth: '800px', width: '100%' }}
            >
              <div className="card-header border-0">
                <img src="//placehold.it/200" alt="" />
              </div>
              <div className="card-block px-2 text-left w-50">
                <h4 className="card-title">Third Item</h4>
                <p className="card-text">
                  Track and measure people visiting any webpage or
                  link
                </p>
              </div>
            </div>

            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl
                consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      
      <div style={styles.button}>
        {isMyProfile === true && (
          <Button
            variant="outline-primary"
            className="container-fluid"
          >
            {bookNowTxt}
          </Button>
        )}
      </div>
      <section style={styles.card}>
        <div className="card" style={styles.bg}>
          <div className="card-body">
            <h5 className="card-title">Labels</h5>
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
          </div>
        </div>
      </section>

      <section style={styles.card}>
        <div className="card" style={styles.bg}>
          <div className="card-body">
            <h5 className="card-title">NFT</h5>
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
          </div>
        </div>
      </section>

      <section style={styles.card}>
        <div className="card" style={styles.bg}>
          <div className="card-body">
            <h5 className="card-title">Reels/Videos</h5>
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
            <img
              src="http://placehold.it/400x200"
              style={styles.label}
            />
          </div>
        </div>
      </section>

      <section style={styles.card}>
        <div className="card" style={styles.bg}>
          <div className="card-body">
            <h5 className="card-title">
              Music - Powered by Spotify™
            </h5>
            <div
              style={{ paddingTop: '10px', paddingBottom: '10px' }}
            ></div>
            <SpotifyFollow
              url={
                'https://open.spotify.com/follow/1/?uri=spotify:artist:5vdjF79d5d2m12FOkJhxHB&size=detail&theme=dark'
              }
              width="300"
              height="100"
            />
            <SpotifyPlayer
              url="https://open.spotify.com/embed/album/1g8jwbj5z26gzrEHYIdvgd" //https://open.spotify.com/embed/artist/5vdjF79d5d2m12FOkJhxHB
              width="100%"
              height="350"
            />
          </div>
        </div>
      </section>

      <section style={styles.card}>
        <div className="card" style={styles.bg}>
          <div className="card-body">
            <h5 className="card-title">Upcoming Events</h5>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Radium(ProfilePage);
