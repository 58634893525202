import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Image } from 'react-bootstrap';
import history from '../../../redux/manageHistory';
import Radium from 'radium';
import { fetchArtistProfiles } from '../../../utils/communityUtils'


const styles = {
    community: {
        color: '#ffffff',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        minHeight: '94vh',
      },

    socialParent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignContent: 'center',
        flexWrap: 'wrap',
        maxWidth: '1200px', // Add this to control overall width
        margin: '0 auto', // Center the container
    },
    socialContainer: {
        borderRadius: '1rem',
        height: 217,
        width: '280px',
        maxWidth: '100%',
        background: '#2c2c2c',
        margin: 15,
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '3px',
    },
    connectText: {
        fontSize: '16px',
    },
    hover: {
        tiktok: {
            ':hover': {
                boxShadow: '0px 0px 15px 6px #e5004e',
                background: '#0000',
                cursor: 'pointer',
            },
        },
        fb: {
            ':hover': {
              background: '#0B84EE',
              cursor: 'pointer',
              boxShadow: '0px 0px 15px 6px #0B84EE',
            },
          },
          spotify: {
            ':hover': {
              boxShadow: '0px 0px 15px 6px #1ED760',
              background: '#1ED760',
              cursor: 'pointer',
            },
          }
    }
};

const RenderArtistCards = ({ artistProfiles, isHover, hoverCard, onHoverIn, onHoverOut, onClickSelectArtist }) => {
    useEffect(()=>{
        console.log("RenderArtistCards Filtered Content,",artistProfiles)
    },[artistProfiles])
    return(
        <div className='artist-cards-container' style={styles.socialParent}>
            {artistProfiles.map((artist,index) => (
                <div
                    id={artist.name}
                    key={index}
                    style={{...styles.socialContainer, ...styles.hover.tiktok}}
                    onMouseEnter={onHoverIn}
                    onMouseLeave={onHoverOut}
                    onClick={(e) => onClickSelectArtist(e, artist)}
                >
                    <i class="fab fa-tikok fa-3x brand-opacity"></i>
                    <h4 className="text-uppercase">{artist.name}</h4>
                    <p style={styles.connectText}>
                        {isHover && hoverCard === artist.name ? 'View Profile' : ''}
                    </p>
                </div>
            ))}
                
        </div>
        
    )
};



const ArtistHome = (props) => {

    const [artistProfiles, setArtistProfiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Start from page 1
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0); // To check if there are more profiles to load
    const [isHover, setIsHover] = useState(false)
    const [hoverCard, setHoverCard] = useState('')
    
    const pageLimit = 10;

    // Trigger fetch on component mount and when page changes
    useEffect(() => {
        console.log("Filtered Content", props.filteredContent)
        const fetchData = async () => {
            setIsLoading(true)
   
            try{
                console.log("Current Page value inside the fetch request", currentPage)
                const result = await fetchArtistProfiles(currentPage,pageLimit);
                if (result && result.artistProfiles.length > 0) {
                    // If there are profiles, add them to state
                    setArtistProfiles(prevArtistProfiles => [
                        ...result.artistProfiles
                    ]);
                    props.getArtistProfileData(prevArtistProfiles => [
                        ...result.artistProfiles
                    ]);
                    setTotalPages(result.totalPages);
                    console.log("fetchArtistProfiles in ArtistHome", result);
                } else if (result && result.artistProfiles.length === 0) {
                    // Handle the case where the database has no profiles
                    console.log("No artist profiles found.");
                    setArtistProfiles([]); // Ensures state is set to an empty array
                    setTotalPages(0);
                }
            } catch (error){
                console.error('Error fetching artist profiles:', error);
            } finally {
                setIsLoading(false);
            }
        };
        console.log("Current page number on load", currentPage)
        fetchData();
    }, [currentPage,props.isFiltered]);

    const handleFirstPageClick = () => {
        setCurrentPage(0);
    }

    const handlePrevPageClick = () => {
        if (currentPage > 1){
            setCurrentPage(prevPage => prevPage - 1)
        }
    }
    const handleNextPageClick = () => {
        if (currentPage < totalPages){
            setCurrentPage(prevPage => prevPage + 1)
        }
    }
    const handleLastPageClick = () => {
        setCurrentPage(totalPages);
    }
    const onHoverIn = (e) => {
        // console.log(e.target.id, "mouse enter");
        setIsHover(true)
        setHoverCard(e.target.id)
    };

    const onHoverOut = (e) => {
        // console.log(e.target.id, "mouse exit");
        setIsHover(false)
        setHoverCard('')
    };

    const onClickSelectArtist = (e, artist) => {
        console.log(artist.profileName, artist.place, artist.knktId)
        props.onSelectArtist(artist);
        
    };

    const PageNavigation = () => {

  
        return (
          <div className="page-navigation">
            <button className="first-last-page-btn" onClick={handleFirstPageClick} disabled={currentPage === 1 || isLoading}>First</button>
            <button className="prev-next-page-btn" onClick={handlePrevPageClick} disabled={currentPage === 1 || isLoading}>Previous</button>
            <button className="prev-next-page-btn" onClick={handleNextPageClick} disabled={currentPage === totalPages || isLoading}>Next</button>
            <button className="first-last-page-btn" onClick={handleLastPageClick} disabled={currentPage === totalPages || isLoading}>Last</button>
          </div>
        )
      };

    return (
        <>
            <PageNavigation/>
            <div style={{...{ margin: '1rem' }, ...styles.community}}>
                {!props.isFiltered && <RenderArtistCards artistProfiles={artistProfiles}
                isHover={isHover}
                hoverCard={hoverCard}
                onHoverIn={onHoverIn}
                onHoverOut={onHoverOut}
                onClickSelectArtist={onClickSelectArtist}/>}

                {props.isFiltered && <RenderArtistCards artistProfiles ={props.filteredContent}
                isHover={isHover}
                hoverCard={hoverCard}
                onHoverIn={onHoverIn}
                onHoverOut={onHoverOut}
                onClickSelectArtist={onClickSelectArtist} />}
            </div>
        </>
        
    )
};

export default Radium(ArtistHome);