import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ChangePassword = ({props})=> {
    const [ currentPassword, setCurrentPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ confirmNewPassword, setConfirmNewPassword ] = useState('');
    const [ showPasswordError, setShowPasswordError ] = useState('');
    const [ showUpdatePassword, setShowUpdatePassword ] = useState(false);
    // console.log('Props inside of ChangePassword',props);
    useEffect(() => {
        if (props.user.updatePasswordSuccessful != null) {
            setShowUpdatePassword(props.user.updatePasswordSuccessful);
        }
    }, [props.user.updatePasswordSuccessful]);

    useEffect(() => {
        if (props.user.updatePasswordError != null) {
            setShowPasswordError(props.user.updatePasswordError);
        }
    }, [props.user.updatePasswordError]);

    const updateForm = event => {
        switch (event.target.name) {
            case 'currentPassword': {
                setCurrentPassword(event.target.value);
                break;
            }
            case 'newPassword': {
                setNewPassword(event.target.value);
                break;
            }
            case 'confirmNewPassword': {
                setConfirmNewPassword(event.target.value);
                break;
            }
            default: {}
        }
        // console.log(event.target.value, event.target.name);
    };

    const submitChangePassword = _ => {
        if (newPassword !== confirmNewPassword) {
            setShowPasswordError('New Password does not match!');
        } else {
          props.submitChangePassword({
            newPassword, confirmNewPassword
          });
        }
    };

    const toggleShowPasswordError = _ => {
        setShowPasswordError('');
    };

    const toggleShowUpdatePassword = _ => {
        setShowUpdatePassword(false);
        props.resetUpdatePassword();
    };

    return (
        <div>
            <Form>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Current Password</InputGroup.Text>
                    <Form.Control
                        type="password"
                        aria-label="Current Password"
                        name="currentPassword"
                        value={currentPassword}
                        onChange={updateForm}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>New Password</InputGroup.Text>
                    <Form.Control
                        type="password"
                        aria-label="New Password"
                        name="newPassword"
                        value={newPassword}
                        onChange={updateForm}
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text>Retype New Password</InputGroup.Text>
                    <Form.Control
                        type="password"
                        aria-label="Retype New Password"
                        name="confirmNewPassword"
                        value={confirmNewPassword}
                        onChange={updateForm}
                    />
                </InputGroup>
                
                <div className="button-group">
                    <Button variant="outline-info" onClick={submitChangePassword.bind(this)}>
                        Submit
                    </Button>
                </div>
                
            </Form>

            { showPasswordError && (
              <Modal show={showPasswordError} onHide={toggleShowPasswordError}>
                  <Modal.Header closeButton>
                      <Modal.Title style={{ color: '#00ff00'}}>Oops!</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ color: '#ffffff'}}>{showPasswordError}</Modal.Body>
                  <Modal.Footer>
                      <Button variant="secondary" onClick={toggleShowPasswordError}>
                          Close
                      </Button>
                  </Modal.Footer>
              </Modal>
            )}

          { showUpdatePassword && (
            <Modal show={showUpdatePassword} onHide={toggleShowUpdatePassword}>
              <Modal.Header closeButton>
                <Modal.Title style={{ color: '#00ff00'}}>Yay!</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ color: '#ffffff'}}>All set!</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={toggleShowUpdatePassword}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
    )
}

export default ChangePassword;