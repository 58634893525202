import { callApi } from '../../utils/utils';

const UPDATE_BRAND = 'UPDATE_BRAND';
const UPDATE_PROFILE = 'UPDATE_PROFILE';
const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';
const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';
const RESET_UPDATE_PASSWORD = 'RESET_UPDATE_PASSWORD';

const UPDATE_PROFILE_FAILED_MESSAGE = 'Sorry, profile failed to be updated!';
const UPDATE_PASSWORD_MESSAGE = 'Sorry, password failed to be updated!';

function updateDashboardMenuIndex(dashboardMenuIndex) {
  return {
    type: UPDATE_BRAND,
    dashboardMenuIndex
  }
}

function updateProfile(profile) {
  const { firstName, lastName, profileUrl, account, email, phone, knktId } = profile;

  let params = { firstName, lastName, profileUrl, account, email, phone, knktId };

  const resp = callApi('/api/v1/updateProfile', 'post', params);

  return function (dispatch) {
    resp.then((res) => {
      let data = res.data;
      if (data && data.updateProfile === true) {
        window.localStorage.setItem('token', data.token);

        // console.log('---data.user:', data.profile)

        const updatedProfile = data.profile;

        const newProfile = {
          firstName: updatedProfile.firstName,
          lastName: updatedProfile.lastName,
          profileUrl: '',
          account: updatedProfile.account,
          email: updatedProfile.email,
          phone: updatedProfile.phone,
          knktId: updatedProfile.knktId
        };

        dispatch({
          type: UPDATE_PROFILE,
          profile: newProfile
        });
      } else {
        const errorMessage = res.err.response.data.message || UPDATE_PROFILE_FAILED_MESSAGE;
        console.log('----errorMessage:', errorMessage);
        dispatch({
          type: UPDATE_PROFILE_FAILED,
          err: errorMessage
        });
      }
    }).catch(res => {
      console.error('---re:', res);
      dispatch({
        type: UPDATE_PROFILE_FAILED,
        err: UPDATE_PROFILE_FAILED_MESSAGE
      });
    })
  }
}

function submitChangePassword({ newPassword, confirmNewPassword }) {
  const resp = callApi('/api/v1/updatePassword', 'post', { newPassword, confirmNewPassword });

  return function (dispatch) {
    resp.then((res) => {
      let data = res.data;
      if (data && data.updatePassword === true) {
        window.localStorage.setItem('token', data.token);

        dispatch({
          type: UPDATE_PASSWORD
        });
      } else {
        const errorMessage = res.err.response.data.message || UPDATE_PASSWORD_MESSAGE;
        console.log('----errorMessage:', errorMessage);
        dispatch({
          type: UPDATE_PASSWORD_FAILED,
          err: errorMessage
        });
      }
    }).catch(res => {
      console.error('---re:', res);
      dispatch({
        type: UPDATE_PASSWORD_FAILED,
        err: UPDATE_PASSWORD_MESSAGE
      });
    })
  }
}

function resetUpdatePassword() {
  return function(dispatch) {
    dispatch({
      type: RESET_UPDATE_PASSWORD
    });
  }
}

export {
  updateDashboardMenuIndex,
  updateProfile,
  submitChangePassword,
  resetUpdatePassword,

  UPDATE_BRAND,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAILED,

  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAILED,
  RESET_UPDATE_PASSWORD
};