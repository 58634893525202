import {callApi} from '../../utils/utils';

const UPDATE_INIT = 'UPDATE_INIT';
const UPDATE_GENRE = 'UPDATE_GENRE';
const UPDATE_LOCATION = 'UPDATE_LOCATION';

function updateInit(value) {
  const resp = callApi('/api/v1/updateUser', 'post', {
    init: value
  });

  return function (dispatch) {
    resp.then((res) => {
      const data = res.data;
      if (data && data.update === 'successful') {
        if (data.token) {
          window.localStorage.setItem('token', data.token);
        }
        dispatch({
          type: UPDATE_INIT,
          value: value
        });
      } else {
        console.log('--failed to update user init');
      }
    })
  }
}

function updateGenre(values) {
  const resp = callApi('/api/v1/updateUser', 'post', {
    genre: values
  });

  return function (dispatch) {
    resp.then((res) => {
      const data = res.data;
      if (data && data.update === 'successful') {
        if (data.token) {
          window.localStorage.setItem('token', data.token);
        }
        dispatch({
          type: UPDATE_GENRE,
          value: data.user.genre
        });
      } else {
        console.log('--failed to update user genre');
      }
    })
  }
}

function updateLocation(values) {
  let {city, state, zip, country} = values;
  const resp = callApi('/api/v1/updateUser', 'post', {
    city,
    state,
    zip,
    country
  });

  return function (dispatch) {
    resp.then((res) => {
      const data = res.data;
      if (data && data.update === 'successful') {
        dispatch({
          type: UPDATE_LOCATION,
          city: data.user.city,
          state: data.user.state,
          zip: data.user.zip,
          country: data.user.country
        });
      } else {
        console.log('--failed to update user genre');
      }
    })
  }
}

export {
  updateInit,
  updateGenre,
  updateLocation,

  UPDATE_INIT,
  UPDATE_GENRE,
  UPDATE_LOCATION
};