import React from 'react';

import SocialInsights from './SocialInsights';

const InstagramInsights = props => {
  return (
    <SocialInsights social="instagram" />
  )
};

export default InstagramInsights;
