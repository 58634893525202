import React, { Fragment, useState, useRef, useEffect } from 'react';

const SpotifyPlayer = (props) => {
    return (
        <iframe 
        src={props.url}
        width={props.width}
        height={props.height} 
        frameBorder="0" 
        allowtransparency="true" 
        allow="encrypted-media" 
        />
    );

}

export default SpotifyPlayer;