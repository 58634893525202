import React, { Component } from 'react';
import FacebookProvider, {Login} from 'react-facebook';
import { Modal, Button } from 'react-bootstrap';

import { getFBAppId } from '../../../config/siteConfig';
import styles from '../../../style/social';

class FacebookConnect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false
    }
    this.hideModal = this.hideModal.bind(this);
  }

  handleResponse = (data) => {
    console.log(data);
    let facebookData = {};

    facebookData.email = this.props.user.email;

    let { id, email, name, link } = data.profile;
    facebookData.id = id;
    facebookData.fbEmail = email;
    facebookData.link = link;
    facebookData.name = name;

    let { accessToken, expiresIn, signedRequest } = data.tokenDetail;
    facebookData.accessToken = accessToken;
    facebookData.expiresIn = expiresIn;
    facebookData.signedRequest = signedRequest;

    this.props.connectSocial(this.props.socialSite, facebookData);
  };

  handleError = (error) => {
    this.props.loginFailedAction("Something went wrong!");
  };

  componentDidMount() {
    if (this.props.socialErrMessage)  {
      this.setState({
        modalShow: true
      })
    }
  }

  hideModal() {
    this.setState({
      modalShow: false
    });
    this.props.clearSocialErrorMessage();
  }

  getFacebookLoginButton = () => {
    return (
      <p style={{padding: 100}}>
        <FacebookProvider appId={getFBAppId()}>
          <Login
            scope="email"
            onResponse={this.handleResponse}
            onError={this.handleError}
          >
            <button style={styles.facebookLoginButton}>KNKT to Facebook</button>
          </Login>
        </FacebookProvider>
      </p>
    )
  };

  render() {
    return (
      <div>
        {this.props.user.facebook && this.props.user.facebook.id && (
          <div>You are connected!</div>
        )}
        {this.props.user.facebook && !this.props.user.facebook.id && (
          <div>
            { this.getFacebookLoginButton() }
          </div>
        )}

          <div>
            <Modal
              {...this.props}
              show={this.state.modalShow}
              onHide={this.hideModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{color:'black'}}
            >
              <Modal.Header closeButton>

              </Modal.Header>.
              <Modal.Body>
                <p>
                  { this.props.socialErrMessage }
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.hideModal}>Close</Button>
              </Modal.Footer>
            </Modal>
          </div>

      </div>
    )
  }
}

export default FacebookConnect;