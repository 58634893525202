import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Form,
  FloatingLabel,
  useAccordionButton,
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import Calendar from '../../common/Calender';
import KnktButton from '../../common/KnktButton';
import { isProduction } from '../../../utils/utils';

const styles = {
  header: {
    minHeight: '30vh',
    background: '#009FFF',
    background:
      'linear-gradient(to right, rgb(67 47 236), rgb(255 165 0),rgb(255 0 0))',
    color: 'white',
    /* clipPath: "ellipse(100vw 60vh at 50% 50%)", */
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  details: {
    textAlign: 'center',
    color: '#fff',
    marginTop: '-45px',
  },
  profilePic: {
    height: '6rem',
    width: '6rem',
    objectFit: 'center',
    borderRadius: '50%',
    border: '2px solid #fff',
  },
  stats: {
    display: 'flex',
  },
  statsDetail: {
    width: '10rem',
    textAlign: 'center',
  },
  heading: {
    fontWeight: '400',
    fontSize: '1.3rem',
    margin: '1rem 0',
  },
  location: {
    svg: {
      display: 'inline-block',
    },
    p: {
      verticalAlign: 'middle',
    },
  },
  hr: {
    backgroundColor: '#ffffff',
  },
  button: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  card: {
    padding: '10px',
  },
  cardBg: {
    backgroundColor: 'rgb(29 30 30)',
    boxShadow: 'rgb(44 44 44) 5px 6px 16px 5px', //"5px 5px 15px 5px #2c2c2c"
    marginBottom: '5vh',
    color: "#FFFFFF",
  },
  bg: {
    backgroundColor: 'rgb(26, 29, 36)',
    color: 'rgb(255,255,255)',
    margin: '1rem',
  },
  label: {
    display: 'inline-block',
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    objectFit: 'cover',
    margin: '1.5rem',
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
  },
  radio: {
    gap: '12px',
    paddingBottom: '3rem',
  }
};

const RenderCalendar = () => {
  return (
    <>
      <Calendar />
    </>
  );
};

const KnktCalendarPage = (props) => {
  const { firstName, lastName, email, account } = props.user;
  const [AccordionState, setAccordionState] = useState(2);
  const [schedularTypes, setschedularTypes] = useState([
    {
      title: 'Pre-Scheduled',
      isActive: true,
      color: 'warning',
    },
    {
      title: 'RSVP By Event',
      isActive: false,
      color: 'info',
    },
  ]);
  const [schedularTypeelected, setschedularTypeSelected] = useState(
    schedularTypes[0].title
  );

  function CustomToggle({ children, eventKey, style, isHidden }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setAccordionState(eventKey);
    });
    const hideForProduction = {
      display: isProduction && isHidden ? "none" : "flex",
    };
    const customStyle = { ...style, ...hideForProduction };

    return (
      <Button
        id={eventKey + 'toggle'}
        variant="outline-info"
        style={customStyle}
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    );
  }

  useEffect(() => {
    if (AccordionState == 2) {
      console.log('accordian state', AccordionState);
    }
  }, [AccordionState]);

  const onClickBookingTab = (event) => {
    const updatedTabs = schedularTypes.map((tab) => {
      if (tab.title === event.target.value) tab.isActive = true;
      else tab.isActive = false;
      return tab;
    });
    setschedularTypes(updatedTabs);
    setschedularTypeSelected(event.target.value);
    document.getElementById("1toggle").click();
  };

  const onClickSocialCalendar = (event) => {
    console.log("here")
    document.getElementById("2toggle").click();
  }

  return (
    <Accordion defaultActiveKey="0">
      <div style={styles.button}>
        <h4>{firstName + "'s Calendar"}</h4>
        <p></p>
      </div>
      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">Connect To Sync Calendar</h5>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
        <Card.Body>
            <div style={styles.buttonGroup}>
              {schedularTypes.map((schedularType) => (
                <Button
                  style={styles.buttonTabs}
                  onClick={onClickBookingTab}
                  key={schedularType.title}
                  value={schedularType.title}
                  active={schedularType.isActive}
                  variant={`outline-${schedularType.color}`}
                >
                  {schedularType.title}
                </Button>
              ))}
            </div>
            <p>{schedularTypeelected + ' Selected'}</p>
            <div style={styles.buttonGroup}>
            <CustomToggle eventKey="1" isHidden={true}>Next</CustomToggle>
          </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">
            Select Availability Scheduler
          </h5>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <div style={styles.buttonGroup}>
              <KnktButton
                type="knktBlue"
                isGrouped={false}
                parentCallBack={onClickSocialCalendar}
                value="Connect Google Calendar"
              />
              <KnktButton
                type="knktRed"
                isGrouped={false}
                parentCallBack={onClickSocialCalendar}
                value="Connect Apple Calendar"
              />
            </div>
            <div style={styles.buttonGroup}>
            <CustomToggle eventKey="2">Skip</CustomToggle>
          </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">Calendar</h5>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
         
            <RenderCalendar />
            <div style={styles.buttonGroup}>
            <CustomToggle eventKey="1">Previous</CustomToggle>
            <KnktButton
                type="knktNeon"
                isGrouped={false}
                targetValue="Finish"
                value="Finish"
              />
            </div>
        </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default KnktCalendarPage;
