import Radium from 'radium';

const styles = {
  heading: {
    color: 'white'
  }
};
const LabelsPage = () => {
  
  return (
    <>
    <h1 style = {styles.heading}>This is the Labels Page.</h1>
    </>
  )
}

export default Radium(LabelsPage);