import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from "react-router-dom";
// import axios from 'axios';

import history from '../redux/manageHistory';
import { validateEmail, callApi } from '../utils/utils';

const styles = {
  forgotPassword: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  pageTitle: {
    color: '#cccccc',
    marginBottom: 50
  },
  modal: {
    color: '#ffffff'
  }
};

const ResetPasswordContainer = props => {

  const [ isInvalid, setIsInvalid ] = useState(null);
  const [ showConfirmation, setShowConfirmation ] = useState(false);
  const [ password, setPassword ] = useState(null);
  const [ confirmPassword, setConfirmPassword ] = useState(null);
  const [ email, setEmail ] = useState(null);
  const [ myCode, setMyCode ] = useState(null);

  const queryParameters = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (queryParameters) {
      const email = queryParameters.get("email");
      const myCode = queryParameters.get("myCode");
      setEmail(email);
      setMyCode(myCode);

      // console.log('---email:', email, ', myCode:', myCode);
    }
  }, []);



  const updatePassword = event => {
    if (event.target.name === 'password') {
      const password = event.target.value;
      setPassword(password);
    }
    if (event.target.name === 'confirmPassword') {
      const password = event.target.value;
      setConfirmPassword(password);
    }
  };

  const handleCloseConfirmation = _ => {
    setShowConfirmation(false);
    history.push('/');
  };

  const backToHome = _ => {
    handleCloseConfirmation();
  };

  const sendResetEmail = async() => {
    const resetEmail = await callApi('/api/v1/resetPassword', 'post', {
      email,
      newPassword: password,
      newPasswordConfirm: confirmPassword,
      myCode
    });

    const { data } = resetEmail;

    // console.log( '----data---:', data);

    if (data.updatePassword === true) {
      setShowConfirmation(true);
    }
  };

  return (
    <Row style={styles.forgotPassword}>
      <Col xs={{ span: 8, offset: 2 }}>
        <h2 style={styles.pageTitle}>Set New Password</h2>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              name="password"
              placeholder="Enter your new password"
              onChange={updatePassword}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formConfirmPassword">
            <Form.Control
                type="password"
                name="confirmPassword"
                placeholder="Please re-enter the password"
                onChange={updatePassword}
            />
          </Form.Group>
          <Button variant="primary" onClick={sendResetEmail}>
            Submit
          </Button>
        </Form>
      </Col>

      <Modal
        show={showConfirmation}
        onHide={handleCloseConfirmation}
        style={styles.modal}
      >
        <Modal.Header closeButton>
          <Modal.Title>All Set!</Modal.Title>
        </Modal.Header>
        <Modal.Body>All set. Please login with your new password.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={backToHome}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  )
};

export default ResetPasswordContainer;
