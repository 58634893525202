import React from 'react';

const KnktAvatar = props => {
  const styles = {
    avatarParent: {
      display: 'flex',
      justifyContent: 'center',
      width: 40,
      height: 40,
      backgroundColor: props.bgColor || '#6414db',
      borderRadius: 20,
      alignItems: 'center',
      color: '#ffffff',
      overflow: 'hidden',
      border: props.bgColor ? `2px solid ${props.bgColor}` : '2px solid #6414db'
    }
  };

  return (
    <div style={styles.avatarParent}>
      <div>{ props.initials }</div>
    </div>
  )
};

export default KnktAvatar;