import { callApi } from './utils';

const monthString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const getCalendarDataByArtistId = artistId => {
  return callApi('/api/v1/calendar?artistId=' + artistId, 'get', {});
};

const getTourDataByTourId = tourId => {
  return callApi('/api/v1/tour/' + tourId, 'get', {});
};

const activateTour = (tourId, activate) => {
  let activateIt = 'deactivate';
  if (activate) {
    activateIt = 'activate';
  }
  return callApi(`/api/v1/tour/${tourId}/${activateIt}`, 'put', {});
};

const activateEvent = (tourEventId, activate) => {
  let activateIt = 'deactivate';
  if (activate) {
    activateIt = 'activate';
  }
  return callApi(`/api/v1/tourEvent/${tourEventId}/${activateIt}`, 'put', {});
};

const convertToUTCDate = date => {
  let utcdate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  return utcdate;
};

const convertUTCDateToLocalDate = date => {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

const updateEventsOfTour = (tourId, events) => {
  return callApi(`/api/v1/tour/${tourId}/events`, 'put', { events });
};

const updateTourName = (tourId, tourName) => {
  return callApi(`/api/v1/tour/${tourId}/tourName`, 'put', { tourName });
};

const deleteTourByTourId = tourId => {
  return callApi('/api/v1/tour/' + tourId, 'delete', {});
};

const getDateForLineGraph = date => {
  return monthString[date.getMonth()] + ' ' + date.getDate();
};

const getDateForTopEngagement = dateString => {
  const dString = dateString.split(' ')[0];
  const date = new Date(dString);
  return padZero(date.getMonth() + 1) + '.' + padZero(date.getDate()) + '.' + date.getFullYear();
};

const padZero = num => {
  return num < 10 ? '0' + num : '' + num;
}

const convertToMonDDYYYY = date => new Date(date).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', timeZoneName: 'short' });


export {
  getCalendarDataByArtistId,
  getTourDataByTourId,

  activateTour,
  activateEvent,
  updateEventsOfTour,
  updateTourName,
  deleteTourByTourId,

  convertToUTCDate,
  convertUTCDateToLocalDate,
  convertToMonDDYYYY,

  getDateForLineGraph,
  getDateForTopEngagement
};