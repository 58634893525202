import {
  SIGNUP_USER,
  UPDATE_SELECTED_TAB,
  UPDATE_FIELD,
  UPDATE_FIELD_ERROR,
  SIGNUP_CONFIRMATION,
  SIGNUP_SUCCESSFUL,
  SIGNUP_FACEBOOK_SUCCESSFUL
} from '../actions/SignUp/signUpActions';

const initialState = {
  selectedTab: 0,
  formData: {
    username: {value: '', isValid: null, err: null},
    password: {value: '', isValid: null, err: null},
    cPassword: {value: '', isValid: null, err: null},
    account: {value: 'artist', isValid: true, err: null},
    lastName: {value: '', isValid: null, err: null},
    firstName: {value: '', isValid: null, err: null},
    company: {value: '', isValid: true, err: null},
    street1: {value: '', isValid: null, err: null},
    street2: {value: '', isValid: null, err: null},
    city: {value: '', isValid: null, err: null},
    state: {value: '', isValid: null, err: null},
    zip: {value: '', isValid: null, err: null},
    genre: {value: '', isValid: true, err: null},
    goal: {value: '', isValid: true, err: null}
  },
  fbData: {},
  confirmation: '',
  signUpSuccessful: false
};

function signUpPage(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_USER:
    {
      let newState = Object.assign({}, state);
      return newState;
    }
    case UPDATE_SELECTED_TAB:
    {
      let newState = Object.assign({}, state);
      newState.selectedTab = action.selectedTab;
      return newState;
    }
    case UPDATE_FIELD:
    {
      let newState = Object.assign({}, state);
      newState.formData[action.field].value = action.value;
      newState.formData[action.field].isValid = true;
      newState.formData[action.field].err = null;
      return newState;
    }
    case UPDATE_FIELD_ERROR:
    {
      let newState = Object.assign({}, state);
      newState.formData[action.field].err = action.value;
      newState.formData[action.field].isValid = false;
      return newState;
    }
    case SIGNUP_CONFIRMATION:
    {
      let newState = Object.assign({}, state);
      // newState.confirmation = action.value;
      if (action.value === SIGNUP_SUCCESSFUL) {
        newState.signUpSuccessful = true;
      } else if (action.value === SIGNUP_FACEBOOK_SUCCESSFUL) {
        newState.signUpSuccessful = true;
        newState.fbData = action.fbData;
      } else {
        newState.confirmation = action.value;
        newState.signUpSuccessful = false;
      }
      return newState;
    }
    default:
    {
      return state;
    }
  }
}

export default signUpPage;