import React from 'react';

// import TabHeader from './TabHeader';
// import TabLineHeader from './TabLineHeader';
// import TabButtonHeader from './TabButtonHeader';

const TabMenuSelector = props => {
  const styles = {
    timelineSelector: {
      // borderBottom: 'solid 1px white',
      marginBottom: 10
    },
    btnLink: {
      color: '#a8a8a8',
      textTransform: 'uppercase',
      outline: 'none',
      textDecoration: 'none'
    },
    btnActive: {
      borderBottom: 'solid 2px white',
      color: '#ffffff'
    }
  };

  return (
    <div style={styles.timelineSelector}>
      {
        props.children
      }
    </div>
  );
};

export default TabMenuSelector;