import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import KnktBarChart from '../../common/charts/KnktChart';

import { mockData } from './data/fansSampleData';

const SocialInsights = props => {
  const styles = {
    fansInsights: {
      backgroundColor: '#1a1d24',//'#2c2c2c',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      boxShadow: '15px 15px 15px #111111',
      borderRadius: '0 10px 10px 10px'
    },
    chartStyle: {
      width: '100%',
      height: 305,
      overflowX: 'auto'
    },
    socialMediaList: {
      width: 250,
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'none'
    },
    mediaItem: {
      margin: '5px 25px'
    },
    iconWrapper: {
      display: 'inline-block',
      height: 28,
      borderRadius: 6,
      width: 44,
      textAlign: 'center',
      verticalAlign: 'middle',
      margin: '3px 10px',
      paddingTop: 3
    },
    facebook: {
      backgroundColor: '#6676f5'
    },
    youtube: {
      backgroundColor: '#ff44a4'
    },
    instagram: {
      backgroundColor: '#ffc80a'
    },
    twitter: {
      backgroundColor: '#00c9ed'
    },
    appleMusic: {
      backgroundColor: '#00f3a7'
    },
    engagementGraph: {
      fleGgrow: 1,
      width: '100%',
      maxWidth: 1000
    },
    iconLink: {
      color: '#ffffff',
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontSize: 14
    },
    addPlatform: {
      display: 'flex',
      flexDirection: 'row',
      height: 100
    },
    addPlatformButton: {
      marginLeft: 20,
      backgroundColor: '#071b14',
      color: '#05c2fb',
      borderStyle: 'solid',
      border: 'solid #05c2fb 1px',
      padding: '10px 15px',
      display: 'inline-block'
    }
  };

  const getChartData = type => {
    if (type) {
      return mockData[type];
    }
  };

  const handleClick = click => {
    const { history } = props;
    const { seriesName } = click;
    let redirect = seriesName.toLowerCase();
    if (redirect === 'apple music') {
      redirect = 'applemusic'
    }
    history.push(`/insights/${redirect}`);
  };

  let onEvents = {
    'click': handleClick.bind(this)
  }

  return (
    <div style={styles.fansInsights}>
      <div style={styles.engagementGraph}>
        { props.selectedSocialTab && (
          <KnktBarChart
            option={getChartData(props.selectedSocialTab)}
            style={styles.chartStyle}
            opts={{ renderer: 'svg' }}
            onEvents={onEvents}
          />
        )}


        <div style={styles.addPlatform}>
          <Link to='/connection'>
            <i className="fas fa-plus" style={styles.addPlatformButton}> Add Platform</i>
          </Link>
        </div>
      </div>
    </div>
  )
};

export default withRouter(SocialInsights);


// <div style={styles.socialMediaList}>
//   <div style={styles.mediaItem}>
//     <div style={{...styles.iconWrapper, ...styles.facebook}}>
//       <i class="fas fa-thumbs-up"></i>
//     </div>
//     <Link style={styles.iconLink} to="/fans/facebook">facebook</Link>
//   </div>
//   <div style={styles.mediaItem}>
//     <div style={{...styles.iconWrapper, ...styles.youtube}}>
//       <i class="fab fa-youtube"></i>
//     </div>
//     <Link style={styles.iconLink} to="/fans/youtube">youtube</Link>
//   </div>
//   <div style={styles.mediaItem}>
//     <div style={{...styles.iconWrapper, ...styles.instagram}}>
//       <i class="fab fa-instagram"></i>
//     </div>
//     <Link style={styles.iconLink} to="/fans/instagram">instagram</Link>
//   </div>
//   <div style={styles.mediaItem}>
//     <div style={{...styles.iconWrapper, ...styles.twitter}}>
//       <i class="fab fa-twitter"></i>
//     </div>
//     <Link style={styles.iconLink} to="/fans/twitter">twitter</Link>
//   </div>
//   <div style={styles.mediaItem}>
//     <div style={{...styles.iconWrapper, ...styles.appleMusic}}>
//       <i class="fab fa-apple"></i>
//     </div>
//     <Link style={styles.iconLink} to="/fans/apple-music">apple-music</Link>
//   </div>
// </div>