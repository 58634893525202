import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { goals } from '../../../utils/constants';

const SignupStepSelectGoal = props => {
  const { selectedGoals, selectGoal, goalError } = props;

  const styles = {
    selectGoal: {
      fontSize: '150%'
    },
    goalButtons: {
      margin: 5
    },
    goalError: {
      textAlign: 'center',
      color: '#ff0000',
      marginTop: 10,
      fontSize: '1.2em'
    }
  }

  return (
    <Row>
      <Col xs={12} style={styles.selectGoal}>
        Select your favorite goals
      </Col>
      <Col xs={12}>
        {
          goals.map((gen, i) => {
            const bg = (selectedGoals.indexOf(gen) > -1) ? 'success' : 'secondary';
            return (
              <Button 
                style={styles.goalButtons}
                variant={bg}
                key={i}
                onClick={selectGoal.bind(this, { type: gen })}
              >{gen}</Button>
            )
          })
        }
      </Col>
      {
        goalError && (
          <Col xs={12} style={styles.goalError}>
            { goalError }
          </Col>
        )
      }
    </Row>
  )
}

export default SignupStepSelectGoal;