import React, { Component } from 'react';
import {
  FaTimesCircle,
  FaTwitterSquare,
  FaFacebookSquare,
  FaSoundcloud,
  FaInstagram,
  FaYoutubeSquare,
  FaSpotify,
  FaUser
} from 'react-icons/lib/fa';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import {Card, CardText} from 'material-ui/Card';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';

import styles from '../../../style/admin';
import { SOCIAL_TYPES } from '../../../utils/constants';
import { isEmptyObject } from '../../../utils/utils';

class Artist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      screenName: [],
      editMode: true,
      submitButtonText: 'Submit',
      submitButtonDisabled: false,
      cancelButtonText: 'Cancel',
      cancelButtonDisabled: false,
      loadingText: '',
      showResult: false,
      submissionResultText: 'All Set!',
      social: '',
      showSocials: false,
      showSocialsIndex: 0,
      showDeleteButton: false,

      screenNameErrorText: [],
      currentArtist: {},
      changedArtist: {},

      showAddMenu: false,

      newCount: 1,
      maxNewCountAllow: 5,

      artists: [{
        screenName: '',
        name: '',
        screenNameErrorText: '',
        nameErrorText: ''
      }]
    };

    this.resetAll = this.resetAll.bind(this);
    this.openEdit = this.openEdit.bind(this);
    this.openDelete = this.openDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCancelButton = this.handleCancelButton.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.getArtistInfo = this.getArtistInfo.bind(this);
    this.openSocials = this.openSocials.bind(this);
    this.buildAddNewBlock = this.buildAddNewBlock.bind(this);
  }

  resetAll = () => {
    this.setState({
      submitButtonText: 'Submit',
      submitButtonDisabled: false,
      cancelButtonText: 'Cancel',
      cancelButtonDisabled: false,
      loadingText: '',
      showResult: false,
      modalOpen: false,
      screenName: [],
      editMode: true,
      submissionResultText: 'All Set!',
      screenNameErrorText: [],
      currentArtist: {},
      changedArtist: {},

      newCount: 1,
      artists: [{
        screenName: '',
        name: '',
        screenNameErrorText: '',
        nameErrorText: ''
      }]
    })
  };

  showAddNewMenu(show) {
    this.setState({
      showAddMenu: show
    });
  }

  clearSocials() {
    this.setState({
      showSocials: false
    });
  }

  openSocials = (index) => {
    this.setState({
      showSocials: true,
      showSocialsIndex: index
    });
  };

  openEdit = (artist, artistIndex, social) => {
    const allArtists = this.props.artists;
    let screenNameField = 'screenName';
    if (social.toLowerCase() === 'youtube') {
      screenNameField = 'channelId';
    }
    let changedArtist = {};
    let currentArtist = {};
console.log('-----social:', social);
    console.log('---artist', artist);
    console.log('----screenNameField:', screenNameField);

    if (artist) {
      changedArtist = {
        name: allArtists.names[artistIndex],
        screenName: artist[screenNameField]
      }

      currentArtist = Object.assign({}, artist);
      currentArtist.screenName = artist[screenNameField];

      console.log('----artist[screenNameField]', artist[screenNameField]);
    }

    console.log('---currentArtist', currentArtist);

    this.setState({
      editMode: true,
      currentArtist: currentArtist,
      modalOpen: true,
      showDeleteButton: true,
      changedArtist: changedArtist,
      artists: [{
        screenName: artist ? artist[screenNameField] : '',
        name: artist ? artist.name : '',
        screenNameErrorText: '',
        nameErrorText: ''
      }],
      social: social
    });
  };

  openDelete = (name, screenName) => {
    this.setState({
      editMode: false,
      currentArtist: { screenName: screenName, name: name },
      modalOpen: true,
      showDeleteButton: false
    });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
    this.resetAll();
  };

  handleCancelButton() {
    this.setState({
      editMode: true,
      screenName: [],
      modalOpen: false
    });
    this.resetAll();
  }

  patchArtists(artists) {
    return artists.map(thisArtist => {
      return Object.assign(thisArtist, {changeName: thisArtist.name, changeScreenName: thisArtist.screenName});
    })
  }

  handleSubmitButton() {
    this.setState({
      submitButtonText: ' ',
      cancelButtonText: ' ',
      cancelButtonDisabled: true,
      submitButtonDisabled: true,
      loadingText: '... updating ...'
    });

    const { currentArtist, changedArtist, social, artists } = this.state;

    if (this.state.editMode) {
      // in edit mode
      if (!changedArtist.screenName || changedArtist.screenName.length === 0) {

        // adding new artists
        let twitter = [];
        let facebook = [];
        let soundCloud = [];
        let youtube = [];
        let spotify = [];
        let instagram = [];

        let dArtists = this.patchArtists(artists);

        switch (social) {
          case SOCIAL_TYPES.facabook:
          {
            facebook = dArtists;
            break;
          }
          case SOCIAL_TYPES.twitter:
          {
            twitter = dArtists;
            break;
          }
          case SOCIAL_TYPES.youtube:
          {
            youtube = dArtists;
            break;
          }
          case SOCIAL_TYPES.soundCloud:
          {
            soundCloud = dArtists;
            break;
          }
          case SOCIAL_TYPES.spotify:
          {
            spotify = dArtists;
            break;
          }
          case SOCIAL_TYPES.instagram:
          {
            instagram = dArtists;
            break;
          }
          default: {}
        }

        let allArtists = {name: dArtists[0].name, twitter, facebook, youtube, soundCloud, spotify, instagram };
        this.props.addArtists(allArtists);
      } else {
        // update existing artists
        let twitter = [];
        let facebook = [];
        let soundCloud = [];
        let youtube = [];
        let spotify = [];
        let instagram = [];

        let thisArtist = {changeScreenName: artists[0].screenName, changeName: artists[0].name};

        if (currentArtist.screenName) {
          Object.assign(thisArtist, {name: currentArtist.name, screenName: currentArtist.screenName});
        }

        switch (social) {
          case SOCIAL_TYPES.facabook:
          {
            facebook = [thisArtist];
            break;
          }
          case SOCIAL_TYPES.twitter:
          {
            twitter = [thisArtist];
            break;
          }
          case SOCIAL_TYPES.youtube:
          {
            youtube = [thisArtist];
            break;
          }
          case SOCIAL_TYPES.soundCloud:
          {
            soundCloud = [thisArtist];
            break;
          }
          case SOCIAL_TYPES.spotify:
          {
            spotify = [thisArtist];
            break;
          }
          case SOCIAL_TYPES.instagram:
          {
            instagram = [thisArtist];
            break;
          }
          default: {}
        }

        let allArtists = { name: currentArtist.name, twitter, facebook, youtube, soundCloud, spotify, instagram };
        this.props.updateArtists(allArtists);
      }
    } else {
      // in delete mode
      let artists = {};

      if (currentArtist.name) {
        artists.names = [currentArtist.name];
      } else if (currentArtist.screenName) {
        artists[social.toLocaleLowerCase()] = [{screenName: currentArtist.screenName}];
      }

      this.props.deleteArtists(artists);
    }
    setTimeout(_ => {
      this.resetAll();
      this.props.fetchArtists();
    }, 800);
  }

  onTextChange(e, value) {
    const field = e.target.id;
    const index = field.slice(-1)-1;
    const fieldName = field.slice(0, -1);

    let { artists } = this.state;

    artists[index][fieldName] = value;

    this.setState({
      artists: artists
    });
  }

  getArtistInfo() {
    let theArtist = this.props.artists.find(artist => {
      return artist.twitter.screenName === this.state.screenName
    });

    if (!theArtist) {
      return {
        name: '',
        screenName: ''
      }
    }
  }

  getTheArtist(key, index) {
    if (this.props.artists.names[index] && this.props.artists[key]) {
      return this.props.artists[key].find(artist => {
        return artist.nameIndex === index;
      })
    }
    return null;
  }

  getArtistRow(index) {
    const { showSocialsIndex } = this.state;

    if (index === showSocialsIndex) {
      if (this.props.artists && this.props.artists.names) {
        let artistRow = [];

        Object.keys(SOCIAL_TYPES).forEach(key => {
          // key - facebook, twitter, soundCloud, ...
          // index - index of artist where the mouse is hovering

          const artist = this.getTheArtist(key, showSocialsIndex);

          if (artist) {
            // artist for social is available - show image
            artistRow.push(
              <div style={styles.artistImage}>
                <img src={artist.profileImageUrl} alt={artist.name} onClick={this.openEdit.bind(this, artist, index, SOCIAL_TYPES[key])}/>
              </div>
            )
          } else {
            // artist for social is NOT available - show social logo
            const artistName = (this.props.artists.names && this.props.artists.names[index]) || '';
            const artist = { name: artistName };
            // when nothing to show
            switch(key) {
              case ('facabook'): {
                artistRow.push(
                  <div style={styles.artistSocialIcon} onClick={this.openEdit.bind(this, artist, index, SOCIAL_TYPES.facabook)}>
                    <span style={styles.addArtistPlussButton}>+</span>
                    <FaFacebookSquare style={styles.addArtistButton}/>
                  </div>
                );
                break;
              }
              case ('twitter'): {
                artistRow.push(
                  <div style={styles.artistSocialIcon} onClick={this.openEdit.bind(this, artist, index, SOCIAL_TYPES.twitter)}>
                    <span style={styles.addArtistPlussButton}>+</span>
                    <FaTwitterSquare style={styles.addArtistButton}/>
                  </div>
                );
                break;
              }
              case ('instagram'): {
                artistRow.push(
                  <div style={styles.artistSocialIcon} onClick={this.openEdit.bind(this, artist, index, SOCIAL_TYPES.instagram)}>
                    <span style={styles.addArtistPlussButton}>+</span>
                    <FaInstagram style={styles.addArtistButton}/>
                  </div>
                );
                break;
              }
              case ('youtube'): {
                artistRow.push(
                  <div style={styles.artistSocialIcon} onClick={this.openEdit.bind(this, artist, index, SOCIAL_TYPES.youtube)}>
                    <span style={styles.addArtistPlussButton}>+</span>
                    <FaYoutubeSquare style={styles.addArtistButton}/>
                  </div>
                );
                break;
              }
              case ('spotify'): {
                artistRow.push(
                  <div style={styles.artistSocialIcon} onClick={this.openEdit.bind(this, artist, index, SOCIAL_TYPES.spotify)}>
                    <span style={styles.addArtistPlussButton}>+</span>
                    <FaSpotify style={styles.addArtistButton}/>
                  </div>
                );
                break;
              }
              case ('soundCloud'): {
                artistRow.push(
                  <div style={styles.artistSocialIcon} onClick={this.openEdit.bind(this, artist, index, SOCIAL_TYPES.soundCloud)}>
                    <span style={styles.addArtistPlussButton}>+</span>
                    <FaSoundcloud style={styles.addArtistButton}/>
                  </div>
                );
                break;
              }
              default: {}
            }
          }
        });
        return <div style={styles.artistRows}>{artistRow}</div>;
      }
    }
  }

  buildAddNewBlock() {
    let blocks = [];

    for (let i=0; i<this.state.newCount; i++) {
      if (i>0) {
        blocks.push(<hr />)
      }
      blocks.push(
        <div style={styles.addNewArtistBlock}>
          <TextField
            id={'screenName'+(i+1)}
            hintText="Screen Name"
            floatingLabelText="Screen Name (required)"
            errorText={this.state.artists[i].screenNameErrorText}
            defaultValue={this.state.artists[i].screenName}
            onChange={this.onTextChange.bind(this) }
          />
          <br />
          <TextField
            id={'name'+(i+1)}
            hintText="Name"
            floatingLabelText="Name"
            errorText={this.state.artists[i].nameErrorText}
            defaultValue={this.state.artists[i].name}
            disabled={this.state.currentArtist.name ? true : false }
            onChange={this.onTextChange.bind(this) }
          />
        </div>
      )
    }

    return blocks;
  }

  addNewBlock() {
    const { newCount, artists } = this.state;
    artists.push({
      screenName: '',
      name: '',
      screenNameErrorText: '',
      nameErrorText: ''
    });
    this.setState({
      newCount: newCount+1,
      artists: artists,
      changedArtist: {}
    })
  }

  render() {
    const actions = [
      <FlatButton
        label={this.state.cancelButtonText}
        primary={true}
        disabled={this.state.cancelButtonDisabled}
        onClick={this.handleCancelButton.bind(this)}
      />,
      <FlatButton
        label={this.state.submitButtonText}
        primary={true}
        disabled={this.state.submitButtonDisabled}
        keyboardFocused={true}
        onClick={this.handleSubmitButton.bind(this)}
      />
    ];

    if (this.state.showDeleteButton) {
      actions.unshift(
        <FlatButton
          label='Delete'
          primary={true}
          onClick={this.openDelete.bind(this, null, this.state.currentArtist.screenName)}
        />
      );
    }

    return (
      <div className="text-left">

        { this.props.artists && this.props.artists.names && this.props.artists.names.map((name, index) => {
          return (
            <Card style={styles.artistCard} onMouseEnter={this.openSocials.bind(this, index)} onMouseLeave={this.clearSocials.bind(this)}>
              <div className="pull-right" style={{position: 'relative'}} >
                <div style={styles.artistControls}>
                  <FaTimesCircle onClick={this.openDelete.bind(this, name, null)} />
                </div>
              </div>

              <CardText>
                <div style={styles.artistDescription}>
                  <FaUser style={styles.artistImageIcon} />
                  <span>{name}</span>
                </div>
                { this.state.showSocials && this.getArtistRow(index)}
              </CardText>
            </Card>
          )
        })}
        
        { this.props.artists && this.props.artists.names && this.props.artists.names.length === 0 && (
          <Card className="wow flipInX" style={styles.artistCard}>
            <CardText style={styles.artistNoArtist}>
              No Artist Yet<br />Hey, why don't add some!
            </CardText>
          </Card>
        )}

        <Dialog
          title=""
          actions={actions}
          modal={false}
          open={this.state.modalOpen}
          onRequestClose={this.closeModal}
          autoScrollBodyContent={true}
        >
          { !this.state.editMode && (
            <div className="text-center">
              <div>Are you sure you want to delete?</div>
              <div style={{fontSize: '1.5em'}}>{this.state.currentArtist.screenName}</div>
              <div style={{marginTop: 30}}>{this.state.loadingText}</div>
            </div>
          )}

          { this.state.editMode && (
            <div className="text-center">
              <div>Add {this.state.social} Artist</div>

              { this.buildAddNewBlock() }

              { isEmptyObject(this.state.changedArtist) && this.state.newCount <= this.state.maxNewCountAllow && (
                <div style={styles.addNewArtistPlus}>
                  <FloatingActionButton mini={true} style={{display: 'inline-block', float: 'right', margin: 3}} onClick={this.addNewBlock.bind(this)}>
                    <ContentAdd />
                  </FloatingActionButton>
                </div>
              )}

              <div style={{marginTop: 30}}>{this.state.loadingText}</div>
            </div>
          )}
        </Dialog>

        <div style={{marginRight: 20, position: 'fixed', left: 20, bottom: 85 }} onClick={this.showAddNewMenu.bind(this, true)} onMouseLeave={this.showAddNewMenu.bind(this, false)}>
          { this.state.showAddMenu && (
            <div className="wow slideInUp" style={{backgroundColor: '#333333', padding: 5, borderRadius: 5, border: 1}}>
              <div style={styles.addArtistDiv} onClick={this.openEdit.bind(this, null, -1, SOCIAL_TYPES.twitter)}>
                <span style={styles.addArtistPlussButton}>+</span>
                <FaTwitterSquare style={styles.addArtistButton} />
              </div>
              <div style={styles.addArtistDiv} onClick={this.openEdit.bind(this, null, -1, SOCIAL_TYPES.facabook)}>
                <span style={styles.addArtistPlussButton}>+</span>
                <FaFacebookSquare style={styles.addArtistButton} />
              </div>
              <div style={styles.addArtistDiv} onClick={this.openEdit.bind(this, null, -1, SOCIAL_TYPES.instagram)}>
                <span style={styles.addArtistPlussButton}>+</span>
                <FaInstagram style={styles.addArtistButton} />
              </div>
              <div style={styles.addArtistDiv} onClick={this.openEdit.bind(this, null, -1, SOCIAL_TYPES.youtube)}>
                <span style={styles.addArtistPlussButton}>+</span>
                <FaYoutubeSquare style={styles.addArtistButton} />
              </div>
              <div style={styles.addArtistDiv} onClick={this.openEdit.bind(this, null, -1, SOCIAL_TYPES.spotify)}>
                <span style={styles.addArtistPlussButton}>+</span>
                <FaSpotify style={styles.addArtistButton} />
              </div>
              <div style={styles.addArtistDiv} onClick={this.openEdit.bind(this, null, -1, SOCIAL_TYPES.soundCloud)}>
                <span style={styles.addArtistPlussButton}>+</span>
                <FaSoundcloud style={styles.addArtistButton} />
              </div>
            </div>
          )}
          <div>
            <FloatingActionButton style={{display: 'inline-block', float: 'left'}}>
              <ContentAdd />
            </FloatingActionButton>
          </div>
        </div>
      </div>
    )
  }
}

export default Artist;