import React, { memo, useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps";

import KnktMarker from './KnktMarker';


const geoUrl = require('./mapChartData.json');
  // "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const rounded = num => {
  if (num > 1000000000) {
    return Math.round(num / 100000000) / 10 + "Bn";
  } else if (num > 1000000) {
    return Math.round(num / 100000) / 10 + "M";
  } else {
    return Math.round(num / 100) / 10 + "K";
  }
};

const MapChart = props => {
  let setTooltipContent = props.setTooltipContent;

  const [ markers, setMarkers ] = useState([]);

  useEffect(() => {
    let marks = [];
    marks.push({
      name: "NY",
      coordinate: [-74.006, 40.7128]
    });
    marks.push({
      name: "SL",
      coordinate: [-122.158, 37.718]
    });

    setMarkers(marks);
  }, []);

  const styles = {
    composableMap: {
      height: 350,
      width: '60%',
      border: '2px #2c2c2c solid',
      borderRadius: 20
    },
    geography: {
      default: {
        fill: "#D6D6DA",
        outline: "none"
      },
      hover: {
        fill: "#D6D6DA",
        stroke: "#F53",
        strokeWidth: 2
        // outline: "none"
      },
      pressed: {
        fill: "#E42",
        outline: "none"
      }
    }
  };


  return (
    <>
    <ComposableMap data-tip="" projectionConfig={{ scale: props.scale }} style={{...styles.composableMap, ...props.customMapStyle}}>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              onMouseEnter={() => {
                  const { NAME, POP_EST } = geo.properties;
                  setTooltipContent(`${NAME} — ${rounded(POP_EST)}`);
                }}
              onMouseLeave={() => {
                  setTooltipContent("");
                }}
              style={styles.geography}
            />
          ))
        }
      </Geographies>

      {
        markers.map(marker => (
          <KnktMarker
            key={marker.name}
            text={marker.name}
            coordinate={marker.coordinate}
            setTooltipContent={setTooltipContent}
            type={props.markerType}
            markerColor={props.markerColor}
          />
        ))
      }

    </ComposableMap>
    </>
  );
};

export default memo(MapChart);
