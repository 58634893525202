import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';

const { TextArea } = Input;

const AddTextModal = ({ isOpen, handleSave, handleCancel }) => {
  const [text, setText] = useState('');

  const handleTextareaSave = () => {
    handleSave(text);
    setText('');
  }

  return (
    <Modal
      open={isOpen}
      title='Add New Text'
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          disabled={!text}
          key='submit'
          type='primary'
          onClick={handleTextareaSave}
        >
          Done
        </Button>
      ]}
    >
      <label htmlFor='textarea'>Text</label>
      <TextArea
        id='textarea'
        rows={4}
        placeholder='maximum length is 1024'
        maxLength={1024}
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
    </Modal>
  )
}

export default AddTextModal;
