import React from 'react';

import ArtistSignupPage from './ArtistSignupPage';
import UserSignupPage from './UserSignupPage';
import ArtistManagerSignupPage from './ArtistManagerSignupPage';
import LabelSignupPage from './LabelSignupPage';
import AgentSignupPage from './AgentSignupPage';
import VenueSignupPage from './VenueSignupPage';
import PublisherSignupPage from './PublisherSignupPage';
import MediaSignupPage from './MediaSignupPage';
import PromoterSignupPage from './PromoterSignupPage';

const SignUpRedirect = props => {
  const { selectedAccountType } = props;

  switch(selectedAccountType) {
    case 'artist': {
      return <ArtistSignupPage {...props} />
    }
    case 'user': {
      return <UserSignupPage {...props} />
    }
    case 'artistManager': {
      return <ArtistManagerSignupPage {...props} />
    }
    case 'label': {
      return <LabelSignupPage {...props} />
    }
    case 'agent': {
      return <AgentSignupPage {...props} />
    }
    case 'venue': {
      return <VenueSignupPage {...props} />
    }
    case 'publisher': {
      return <PublisherSignupPage {...props} />
    }
    case 'media': {
      return <MediaSignupPage {...props} />
    }
    case 'promoter': {
      return <PromoterSignupPage {...props} />
    }
  }
}

export default SignUpRedirect;