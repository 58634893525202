import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { Button } from 'react-bootstrap';

import {
  getGoogleClientId,
  getGoogleLoginEnabled
} from '../../config/siteConfig';

const GoogleLoginButton = props => {
  if (getGoogleLoginEnabled()) {
    return (
      <GoogleLogin
        clientId={getGoogleClientId()}
        buttonText="Login"
        onSuccess={props.handleGoogleResponse}
        onFailure={props.handleGoogleResponse}
        className="google-login"
        style={props.styles.googleLoginButton}
        responseType="id_token"
        accessType="online"
        prompt="consent"
        scope="profile email"
        render={renderProps => (
            <Button
              type="button"
              variant="light"
              style={props.styles.loginButton}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <i className="fab fa-google" style={props.styles.icon}></i>
            </Button>
          )}
      />
    )
  }
  return null;
};

export default GoogleLoginButton;