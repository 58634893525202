
import EChartDataModel from '../../shared-components/EChartDataModel';

const AXIS_COLOR = '#4a676e';
const LINE_COLOR_BELOW_ZERO = '#ff0000';
const LINE_COLOR_ABOVE_ZERO = '#00c9ed';

class ChartDataModel extends EChartDataModel {
    constructor({ xAxis = [], yAxis = [] }) {
        super({ xAxis, yAxis });

        this.xAxis.axisLine.lineStyle.color = AXIS_COLOR;
        this.yAxis.axisLine.lineStyle.color = AXIS_COLOR;

        this.visualMap.pieces = [
            {
                gt: null,
                lte: 0,
                color: LINE_COLOR_BELOW_ZERO
            },
            {
                gt: 0,
                lte: 100,
                color: LINE_COLOR_ABOVE_ZERO
            }
        ];

        this.series.push({ data: yAxis, type: "line" });

    }
}

export default ChartDataModel;