import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import SlickCarousel from '../components/pages/calendar/SlickCarousel';

import CalendarEventsContainer from './CalendarEventsContainer';
import styles from '../style/calendar';
import history from '../redux/manageHistory';
import {
  getCalendarDataByArtistId,
  activateTour as activateTheTour,
  deleteTourByTourId,
} from '../utils/calendar';

import '../style/calendar.css';

import {
  populateData,
  updateEvent,
  updateShowArtistId,
  activateTour,
  deleteTour,
} from '../actions/Calendar/calendarAction';

class CalendarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tours: [],
      gigs: [],
      artistName: '',
      edit: true,
      showArtistId: '',
      isOwner: false,
      theTours: [],
      activeCount: 0,
      showAlert: false,
      removedTours: [],
    };

    this.buildTours = this.buildTours.bind(this);
  }

  componentDidMount() {
    let {
      match: {
        params: { artistId },
      },
    } = this.props;

    // save the showArtistId in the store
    this.props.updateShowArtistId(artistId);

    this.setState({
      showArtistId: artistId,
    });

    let isOwner = artistId == this.props.user.artistId;

    // console.log('----artistId:', artistId);

    if (artistId == undefined || artistId == '' || artistId == 0) {
      isOwner = false;
      if (this.props.user.artistId == '') {
        history.push('/dashboard');
      }
    }

    // console.log('----this.props.user.artistId:', this.props.user.artistId);

    if (!artistId) {
      artistId = this.props.user.artistId;
    }

    // let thisArtist = 0;
    // if (this.props.user && this.props.user.artistId) {
    //   thisArtist = this.props.user.artistId;
    // }

    // console.log('----isOwner:', isOwner);

    this.setState({
      isOwner: isOwner,
    });

    if (artistId) {
      // console.log('----artistId:', artistId);
      getCalendarDataByArtistId(artistId).then((data) => {
        // console.log('----data:', data);
        this.props.populateData(data.data);
        this.buildTours(isOwner);
      });
    }
  }

  // TODO
  handleChangeImage() {
    history.push('/tour/sdsdsd');
  }

  handleClosePage() {
    history.push('/dashboard');
  }

  handleSelectTour(tourId) {
    history.push(`/tour/${tourId}`);
  }

  isEditable() {
    return (
      this.props.user &&
      this.props.user.account === 'artist' &&
      this.props.edit
    );
  }

  handleEnterDeactiveButton(event) {
    event.target.style.color = '#ffffff';
  }

  handleLeaveDeactiveButton(event) {
    event.target.style.color = '#555555';
  }

  handleActivateTour(activate, tourId) {
    activateTheTour(tourId, activate).then((_) => {
      // console.log('---tourId===', tourId);
      this.props.activateTour(activate, tourId);
      this.buildTours(this.state.isOwner);
    });
  }

  handleDeleteTour(tourId) {
    deleteTourByTourId(tourId).then((_) => {
      this.props.deleteTour(tourId);
      this.buildTours(this.state.isOwner);
    });
  }

  buildTours(isOwner) {
    // const { showArtistId } = this.state;
    const { tours, user } = this.props;

    // console.log('tours---==', tours);
    var theTours = [];
    let active = [];
    let inactive = [];
    let createNew = [];
    let activeCount = 0;
    // let inactiveCount = 0;
    let showAlert = false;
    let removedTours = [];
    let allActiveTours = [];

    if (tours.length > 0) {
      tours.forEach((tour) => {
        if (tour.active) {
          // && showArtistId == tour.artistId
          allActiveTours.push(tour);
          active.push(
            <div key={tour.tourId}>
              <div
                style={styles.calendarTourImage}
                onClick={this.handleSelectTour.bind(
                  this,
                  tour.tourId
                )}
              >
                <img
                  style={styles.tourImg}
                  alt=""
                  src={
                    tour.backgroundImage || '/img/logo/knkt-logo.svg'
                  }
                />
                <div style={styles.calendarTourActiveStatus}>
                  Active
                </div>
              </div>
              <div style={styles.calendarTourButton}>
                <div style={styles.calendarTourName}>
                  {tour.tourName}
                </div>
                <div>
                  <Button
                    variant="outline-light"
                    size="sm"
                    style={styles.calendarTourShareButton}
                    onClick={this.handleSelectTour.bind(
                      this,
                      tour.tourId
                    )}
                  >
                    Share
                  </Button>
                </div>
                {isOwner && (
                  <div
                    style={styles.calendarTourDeactiveButton}
                    onMouseEnter={this.handleEnterDeactiveButton.bind(
                      this
                    )}
                    onMouseLeave={this.handleLeaveDeactiveButton.bind(
                      this
                    )}
                    onClick={this.handleActivateTour.bind(
                      this,
                      false,
                      tour.tourId
                    )}
                  >
                    Deactive it!
                  </div>
                )}
              </div>
            </div>
          );
        } else {
          if (isOwner) {
            inactive.push(
              <div key={tour.tourId}>
                <img
                  style={{
                    ...styles.tourImg,
                    ...styles.calendarTourInactiveStatus,
                  }}
                  alt=""
                  src={
                    tour.backgroundImage || '/img/logo/knkt-logo.svg'
                  }
                  onClick={this.handleSelectTour.bind(
                    this,
                    tour.tourId
                  )}
                />
                <div style={styles.calendarTourButton}>
                  <div style={styles.calendarTourName}>
                    {tour.tourName}
                  </div>
                  <div style={styles.calendarTourShareButton}>
                    <Button
                      variant="outline-light"
                      size="sm"
                      style={styles.calendarTourDeleteButton}
                      onClick={this.handleDeleteTour.bind(
                        this,
                        tour.tourId
                      )}
                    >
                      Delete
                    </Button>
                  </div>
                  {isOwner && (
                    <div
                      style={styles.calendarTourActiveButton}
                      onMouseEnter={this.handleEnterDeactiveButton.bind(
                        this
                      )}
                      onMouseLeave={this.handleLeaveDeactiveButton.bind(
                        this
                      )}
                      onClick={this.handleActivateTour.bind(
                        this,
                        true,
                        tour.tourId
                      )}
                    >
                      Active it!
                    </div>
                  )}
                </div>
              </div>
            );
          }
        }
      });

      activeCount = active.length;
      // inactiveCount = inactive.length;

      // adding new event block
      if (isOwner) {
        createNew.push(
          <div key="noTourId">
            <img
              style={{
                ...styles.tourImg,
                ...styles.calendarCreateNewParent,
              }}
              alt=""
              src="/img/logo/knkt-logo.svg"
              onClick={this.handleSelectTour.bind(this, 0)}
            />

            <div style={styles.calendarTourButton}>
              <div
                style={{
                  ...styles.calendarTourName,
                  ...styles.calendarCreateNewButton,
                }}
                onClick={this.handleSelectTour.bind(this, 0)}
              >
                Create
                <br />
                New
              </div>
              <div style={styles.calendarTourShareButton_hidden}>
                <Button style={styles.calendarTourShareButton}>
                  None
                </Button>
              </div>
            </div>
          </div>
        );
      }

      if (isOwner) {
        const userAllowedCount =
          (user && user.tier && user.tier.tour) || 3;
        if (activeCount > userAllowedCount) {
          const removeCount = activeCount - userAllowedCount;

          for (let i = 0; i < removeCount; i++) {
            let tour = allActiveTours.shift();
            active.shift();
            inactive.push(
              <div
                key={tour.tourId}
                style={styles.calendarTourInactiveStatus}
              >
                <img
                  style={styles.tourImg}
                  alt=""
                  src={
                    tour.backgroundImage || '/img/logo/knkt-logo.svg'
                  }
                  onClick={this.handleSelectTour.bind(
                    this,
                    tour.tourId
                  )}
                />
                <div style={styles.calendarTourButton}>
                  <div style={styles.calendarTourName}>
                    {tour.tourName}
                  </div>
                  <div style={styles.calendarTourShareButton_hidden}>
                    <Button
                      variant="outline-light"
                      size="sm"
                      style={styles.calendarTourShareButton}
                    >
                      &nbsp;
                    </Button>
                  </div>
                </div>
              </div>
            );
            // console.log(tour);
            removedTours.push(tour);
          }

          showAlert = true;
        }
      }

      // console.log('----showAlert---:', showAlert);
      // console.log('----removedTours---:', removedTours);

      active.forEach((t) => {
        theTours.push(t);
      });

      createNew.forEach((t) => {
        theTours.push(t);
      });

      inactive.forEach((t) => {
        theTours.push(t);
      });

      // console.log('----theTours---:', theTours);
      // console.log('----active---:', active);
      // console.log('----createNew---:', createNew);
      // console.log('----inactive----:', inactive);

      activeCount = active.length;

      if (!isOwner) {
        if (theTours.length < 3) {
          if (theTours.length < 3) {
            theTours.push(<div key="00003"></div>);
          }
          if (theTours.length < 3) {
            theTours.unshift(<div key="00001"></div>);
          }
        }
      }

      this.setState({
        theTours,
        activeCount,
        showAlert,
        removedTours,
      });
    }
  }

  dismissTourAlert() {
    const { removedTours } = this.state;

    removedTours.forEach((tour) => {
      this.handleActivateTour(false, tour.tourId);
    });
    this.setState({
      showAlert: false,
      removedTours: [],
    });
  }

  render() {
    const {
      showAlert,
      removedTours,
      theTours,
      isOwner,
      showArtistId,
      activeCount,
    } = this.state;
    return (
      <Row nogutters="true">
        <Col
          xs={{ span: 10, offset: 1 }}
          md={{ span: 8, offset: 2 }}
          style={styles.calendarBackground}
        >
          <CSSTransition
            in={showAlert}
            timeout={300}
            classNames="removedTours"
            unmountOnExit
          >
            <div style={styles.tourAlert}>
              Maximum active tours reached.
              <br />
              The following tours (oldest) will be marked as inactive:
              <br />
              <ul>
                {removedTours.length > 0 &&
                  removedTours.map((tour) => (
                    <li key={tour.tourId}>{tour.tourName}</li>
                  ))}
              </ul>
              <div
                style={styles.tourAlertClose}
                onClick={this.dismissTourAlert.bind(this)}
              >
                Dismiss
              </div>
            </div>
          </CSSTransition>

          <div
            className="calendar-carousel"
            style={styles.calendarCarousel}
          >
            {this.props.tours.length > 0 && (
              <SlickCarousel
                tours={this.props.tours}
                user={this.props.user}
                showArtistId={showArtistId}
                isOwner={isOwner}
                theTours={theTours}
                activeCount={activeCount}
              />
            )}
          </div>

          <CalendarEventsContainer
            tourEvents={this.props.tourEvents}
            user={this.props.user}
            updateEvent={this.props.updateEvent}
            isOwner={this.state.isOwner}
          />

          {this.isEditable() && (
            <div
              style={styles.gigPageClose}
              onClick={this.handleClosePage.bind(this)}
            >
              Close X
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    tours: state.calendar.tours,
    tourEvents: state.calendar.tourEvents,
    edit: state.calendar.edit,
    user: state.user,
    showArtistId: state.calendar.showArtistId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    populateData: (data) => dispatch(populateData(data)),
    updateEvent: (activate, event) =>
      dispatch(updateEvent(activate, event)),
    updateShowArtistId: (showArtistId) =>
      dispatch(updateShowArtistId(showArtistId)),
    activateTour: (activate, tourId) =>
      dispatch(activateTour(activate, tourId)),
    deleteTour: (tourId) => dispatch(deleteTour(tourId)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarContainer);
