export function fetchData() {
    return [
    {
        "accountId": "artist1",
        "accountName": "Artist 1",
        "accountDescription": "Artist 1",
        "accounType": "ARTIST",
        "genre": "Pop",
        "country": "US",
        "categories": [
            {
                "categoryId": "cat001",
                "categoryName": "Audio",
                "categoryDescription": "Audio",
                "products": [
                    {
                        "productId": "prod000001",
                        "productName": "Live",
                        "productDescription": "Live",
                        "skus": [
                            {
                                "skuId": "sku000001",
                                "skuName": "15 Minutes",
                                "skuDescription": "15 Minutes",
                                "stripeProductId": "prod_JpmRXwBDghrKzF",
                                "listPrice": 10.0,
                                "salePrice": 10.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000002",
                                "skuName": "30 Minutes",
                                "skuDescription": "30 Minutes",
                                "stripeProductId": "prod_JpmRyiOL74WTwt",
                                "listPrice": 15.0,
                                "salePrice": 15.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000003",
                                "skuName": "45 Minutes",
                                "skuDescription": "45 Minutes",
                                "stripeProductId": "prod_JpmRJTPKLg43Rw",
                                "listPrice": 20.0,
                                "salePrice": 20.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000004",
                                "skuName": "60 Minutes",
                                "skuDescription": "60 Minutes",
                                "stripeProductId": "prod_JpmRH0njnnRx2D",
                                "listPrice": 25.0,
                                "salePrice": 25.0,
                                "currency": "USD"
                            }
                        ],
                        "imageUrls": [
                            "https://previews.123rf.com/images/aalbedouin/aalbedouin1708/aalbedouin170801640/83486899-audio-icon-symbol.jpg"
                        ]
                    },
                    {
                        "productId": "prod000002",
                        "productName": "Recorded",
                        "productDescription": "Recorded",
                        "skus": [
                            {
                                "skuId": "sku000005",
                                "skuName": "Minutes",
                                "skuDescription": "Minutes",
                                "stripeProductId": "prod_JpmRyRIi1Im6B4",
                                "listPrice": 30.0,
                                "salePrice": 30.0,
                                "currency": "USD"
                            }
                        ],
                        "imageUrls": [
                            "https://previews.123rf.com/images/aalbedouin/aalbedouin1708/aalbedouin170801640/83486899-audio-icon-symbol.jpg"
                        ]
                    }
                ]
            },
            {
                "categoryId": "cat002",
                "categoryName": "Video",
                "categoryDescription": "Video",
                "products": [
                    {
                        "productId": "prod000003",
                        "productName": "Live",
                        "productDescription": "Live",
                        "skus": [
                            {
                                "skuId": "sku000006",
                                "skuName": "15 Minutes",
                                "skuDescription": "15 Minutes",
                                "stripeProductId": "prod_JpmR3U5wyOXoji",
                                "listPrice": 35.0,
                                "salePrice": 35.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000007",
                                "skuName": "30 Minutes",
                                "skuDescription": "30 Minutes",
                                "stripeProductId": "prod_JpmRlzvdinwp8S",
                                "listPrice": 40.0,
                                "salePrice": 40.0,
                                "currency": "USD"
                            }
                        ],
                        "imageUrls": [
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Video_camera_icon.svg/512px-Video_camera_icon.svg.png"
                        ]
                    },
                    {
                        "productId": "prod000004",
                        "productName": "Recorded",
                        "productDescription": "Recorded",
                        "skus": [
                            {
                                "skuId": "sku000010",
                                "skuName": "Minutes",
                                "skuDescription": "Minutes",
                                "stripeProductId": "prod_JpmRfvCJIg3iPf",
                                "listPrice": 55.0,
                                "salePrice": 55.0,
                                "currency": "USD"
                            }
                        ],
                        "imageUrls": [
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Video_camera_icon.svg/512px-Video_camera_icon.svg.png"
                        ]
                    }
                ]
            }
        ]
    },
    {
        "accountId": "artist2",
        "accountName": "Artist 2",
        "accountDescription": "Artist 2",
        "accounType": "ARTIST",
        "genre": "Rock",
        "country": "US",
        "categories": [
            {
                "categoryId": "cat001",
                "categoryName": "Audio",
                "categoryDescription": "Audio",
                "products": [
                    {
                        "productId": "prod000001",
                        "productName": "Live",
                        "productDescription": "Live",
                        "skus": [
                            {
                                "skuId": "sku000001",
                                "skuName": "15 Minutes",
                                "skuDescription": "15 Minutes",
                                "stripeProductId": "prod_JpmRryyXGe6Ug4",
                                "listPrice": 10.0,
                                "salePrice": 10.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000002",
                                "skuName": "30 Minutes",
                                "skuDescription": "30 Minutes",
                                "stripeProductId": "prod_JpmRtQQnYhfWvw",
                                "listPrice": 15.0,
                                "salePrice": 15.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000003",
                                "skuName": "45 Minutes",
                                "skuDescription": "45 Minutes",
                                "stripeProductId": "prod_JpmRuBANFtA0me",
                                "listPrice": 20.0,
                                "salePrice": 20.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000004",
                                "skuName": "60 Minutes",
                                "skuDescription": "60 Minutes",
                                "stripeProductId": "prod_JpmRtadspZ0SjC",
                                "listPrice": 25.0,
                                "salePrice": 25.0,
                                "currency": "USD"
                            }
                        ],
                        "imageUrls": [
                            "https://previews.123rf.com/images/aalbedouin/aalbedouin1708/aalbedouin170801640/83486899-audio-icon-symbol.jpg"
                        ]
                    },
                    {
                        "productId": "prod000002",
                        "productName": "Recorded",
                        "productDescription": "Recorded",
                        "skus": [
                            {
                                "skuId": "sku000005",
                                "skuName": "Minutes",
                                "skuDescription": "Minutes",
                                "stripeProductId": "prod_JpmRjY5kUl16HE",
                                "listPrice": 30.0,
                                "salePrice": 30.0,
                                "currency": "USD"
                            }
                        ],
                        "imageUrls": [
                            "https://previews.123rf.com/images/aalbedouin/aalbedouin1708/aalbedouin170801640/83486899-audio-icon-symbol.jpg"
                        ]
                    }
                ]
            },
            {
                "categoryId": "cat002",
                "categoryName": "Video",
                "categoryDescription": "Video",
                "products": [
                    {
                        "productId": "prod000003",
                        "productName": "Live",
                        "productDescription": "Live",
                        "skus": [
                            {
                                "skuId": "sku000006",
                                "skuName": "15 Minutes",
                                "skuDescription": "15 Minutes",
                                "stripeProductId": "prod_JpmRpQgu50Zcz7",
                                "listPrice": 35.0,
                                "salePrice": 35.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000007",
                                "skuName": "30 Minutes",
                                "skuDescription": "30 Minutes",
                                "stripeProductId": "prod_JpmRohdRMkajej",
                                "listPrice": 40.0,
                                "salePrice": 40.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000008",
                                "skuName": "45 Minutes",
                                "skuDescription": "45 Minutes",
                                "stripeProductId": "prod_JpmRF9e3nu9MZ9",
                                "listPrice": 45.0,
                                "salePrice": 45.0,
                                "currency": "USD"
                            },
                            {
                                "skuId": "sku000009",
                                "skuName": "60 Minutes",
                                "skuDescription": "60 Minutes",
                                "stripeProductId": "prod_JpmRPScZtaFsm9",
                                "listPrice": 50.0,
                                "salePrice": 50.0,
                                "currency": "USD"
                            }
                        ],
                        "imageUrls": [
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Video_camera_icon.svg/512px-Video_camera_icon.svg.png"
                        ]
                    },
                    {
                        "productId": "prod000004",
                        "productName": "Recorded",
                        "productDescription": "Recorded",
                        "skus": [
                            {
                                "skuId": "sku000010",
                                "skuName": "Minutes",
                                "skuDescription": "Minutes",
                                "stripeProductId": "prod_JpmRmSZMMioYuI",
                                "listPrice": 55.0,
                                "salePrice": 55.0,
                                "currency": "USD"
                            }
                        ],
                        "imageUrls": [
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Video_camera_icon.svg/512px-Video_camera_icon.svg.png"
                        ]
                    }
                ]
            }
        ]
    }
]
}