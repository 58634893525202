import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Booking from '../components/pages/booking/Booking';

const BookingContainer =  (props) =>{

    const styles = {
        parentContainer: {
            height: "100%", color: "#fff", textAlign: "center"
        }
    };

    return(
    <Row style={styles.parentContainer}>
       <Col xs="12"><Booking user={props.user} location={props.location}/></Col>

    </Row>
    );
}

export default BookingContainer;