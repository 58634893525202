const styles = {
  artistImage: {
    display: 'inline-block',
    marginLeft: 10,
    marginRight: 10,
    border: '1px solid #ffffff'
  },
  artistDescription: {
    display: 'inline-block'
  },
  artistImageIcon: {
    display: 'inline-block',
    fontSize: '3.5em',
    color: '#ffffff',
    paddingRight: 5
  },
  artistSocialIcon: {
    display: 'inline-block',
    paddingRight: 10,
    paddingLeft: 10
  },
  artistRows: {
    display: 'inline-block',
    paddingLeft: 10
  },
  // artistRow: {
  //   display: 'inline-block',
  //   paddingLeft: 2,
  //   paddingRight: 2
  // },
  artistControls: {
    display: 'inline-block',
    fontSize: '3em',
    color: '#ffffff',
    position: 'absolute',
    top: 0,
    right: 20
  },
  addArtistDiv: {
    // display: 'inline-block',
    paddingRight: 10,
    paddingLeft: 10
  },
  addArtistButton: {
    fontSize: '2.5em'
  },
  addArtistPlussButton: {
    fontSize: '1.5em'
  },
  artistCard: {
    marginTop: 5,
    marginBottom: 10,
    borderRadius: 10
  },
  artistNoArtist: {
    fontSize: '1.5em'
  },
  addNewArtistBlock: {
    padding: 5,
    // border: '1px grey solid',
    // backgroundColor: 'rgb(100, 100, 100)',
    // borderRadius: 5,
    marginBottom: 5
  },
  addNewArtistPlus: {
    textAlign: 'right'
  }
};

export default styles;