// import { highlightsListData } from '../components/pages/insights/data/theHighlightsListData';
import { callApi } from './utils';

const sortSocialData = apiIgData => {
  if (apiIgData.oneDay) {
    apiIgData.oneDay.sort((a,b) => (new Date(a.createdDate)) - (new Date(b.createdDate)));
  }

  return apiIgData;
};

const fetchHighlightsData = async _ => {
  // return highlightsListData;
  
  const insights = await callApi('/api/v1/insights/highlights', 'get');
  return insights.data || {};
};

const fetchPageSources = async socialMedia => {
  const pageSources = await callApi(`/api/v1/insights/${socialMedia}/pageSources`, 'get');
  return pageSources.data || {};
};

const fetchMetrics= async socialMedia => {
  const metrics = await callApi(`/api/v1/insights/${socialMedia}/metrics`, 'get');
  return metrics.data || {};
};

const fetchLocation= async socialMedia => {
  const location = await callApi(`/api/v1/insights/${socialMedia}/location`, 'get');
  return location.data || {};
};

const fetchAudience= async socialMedia => {
  const audience = await callApi(`/api/v1/insights/${socialMedia}/audience`, 'get');
  return audience.data || {};
};




const fetchFbPageSources = async _ => {
  return fetchPageSources('fb');
};

const fetchFbLocation= async _ => {
  return fetchLocation('fb');
};

const fetchFbMetrics= async _ => {
  return fetchMetrics('fb');
};

const fetchFbAudience= async _ => {
  return fetchAudience('fb');
};



const fetchIgLocation= async _ => {
  return fetchLocation('ig');
};

const fetchIgMetrics= async _ => {
  return fetchMetrics('ig');
};

const fetchIgAudience= async _ => {
  return fetchAudience('ig');
};




export {
  sortSocialData,

  fetchHighlightsData,
  fetchFbPageSources,
  fetchFbLocation,
  fetchFbMetrics,
  fetchFbAudience,

  fetchIgLocation,
  fetchIgMetrics,
  fetchIgAudience
};