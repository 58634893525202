import React, { Component } from 'react';
import { getApiUrl } from '../../../config/siteConfig';

import styles from '../../../style/social';
import TwitterLogin from '../../../components/common/TwitterLogin';


class TwitterConnect extends Component {

  onTwitterSuccess = (data) => {
    this.props.loginTwitterAction(data);
  };

  onTwitterFailed = (error) => {
    this.props.loginFailedAction("Can't login at this time.");
  };

  getTwitterLoginButton = () => {
    const customHeader = {};
    customHeader['token'] = window.localStorage.getItem('token');
    
    return (
      <p style={{padding: 100}}>
        <TwitterLogin
          loginUrl={getApiUrl()+"/api/v1/twitter/connect"}
          onFailure={this.onTwitterFailed}
          onSuccess={this.onTwitterSuccess}
          requestTokenUrl={getApiUrl()+"/api/v1/login/twitter/reverse"}
          showIcon={true}
          customHeaders={customHeader}
          text="Login with Twitter"
          style={styles.twitterLoginButton}
        />
      </p>
    )
  };

  render() {
    return (
      <div>
        {this.props.user.twitter && this.props.user.twitter.id && (
          <div>You are connected!</div>
        )}
        {this.props.user.twitter && !this.props.user.twitter.id && (
          <div>
            { this.getTwitterLoginButton() }
          </div>
        )}
      </div>
    )
  }
}

export default TwitterConnect;