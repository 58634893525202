import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';

class AllEngagements extends Component {

    render() {
        return (
            <div className="chart-container">
                <ReactEcharts
                    option={this.props.chartOptions}
                    style={this.props.chartStyle}
                    opts={{ renderer: 'svg' }} />
            </div>
        )
    }
}

export default AllEngagements;                    