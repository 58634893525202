import React from 'react';

import Privacy from '../pages/privacy/Privacy';
import Terms from '../pages/privacy/Terms';

const PrivacyAndTermsPage = props => {
  window.scrollTo(0, 0);

  if (window.location.pathname.indexOf('privacy') >= 0) {
    return <Privacy />
  } else {
    return <Terms />
  }
};

export default PrivacyAndTermsPage;