import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {List, ListItem, makeSelectable} from 'material-ui/List';
import Subheader from 'material-ui/Subheader';

import {MENU_ITEMS} from '../../../utils/constants';

let SelectableList = makeSelectable(List);

function wrapState(ComposedComponent) {
  return class SelectableList extends Component {
    static propTypes = {
      children: PropTypes.node.isRequired
    };

    handleRequestChange = (event, index) => {
      this.props.updateDashboardMenuIndex(index);
    };

    render() {
      return (
        <ComposedComponent
          value={this.props.dashboardMenuIndex}
          onChange={this.handleRequestChange}
          updateDashboardMenuIndex={this.props.updateDashboardMenuIndex}
        >
          {this.props.children}
        </ComposedComponent>
      );
    }
  };
}

SelectableList = wrapState(SelectableList);

const buildSubMenu = (index, subMenu) => {
  return (
    subMenu.map((sub) => {
      return (
        <ListItem
          value={parseInt(index, 10)+parseInt(sub.index, 10)/10}
          primaryText={sub.title}
        />
      )
    })
  )
};

const buildMenueItems = (
  MENU_ITEMS.map((menu) => {
    return (
      menu.subMenu ? (
        <ListItem
          value={menu.index}
          primaryText={menu.title}
          nestedItems={ menu.subMenu ? (
            menu.subMenu && buildSubMenu(menu.index, menu.subMenu)) : null}
        />
      ) : (
        <ListItem
          value={menu.index}
          primaryText={menu.title}
        />
      )
    )
  })
);

const SettingMenuList = ({owner, updateDashboardMenuIndex, defaultMenuIndex}) => (
  <SelectableList defaultValue={defaultMenuIndex} updateDashboardMenuIndex={updateDashboardMenuIndex}>
    <Subheader>Hey! {owner}</Subheader>
    { buildMenueItems }
  </SelectableList>
);

export default SettingMenuList;