import React from 'react';
import '../../../style/settings.css'
import ContactsPage from './ContactsPage'

export default function Team({props}) {
    
    return (
        <>
            <div className="settings-tab"></div>
            <ContactsPage props={props} />
        </>

    )
};