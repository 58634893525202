import React from 'react';

import SocialInsights from './SocialInsights';

const FacebookVideoInsights = props => {
  return (
    <SocialInsights social="facebookvideo" />
  )
};

export default FacebookVideoInsights;
