import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ConnectionInstagram from '../components/pages/connection/ConnectionInstagram';

import {
  connectInstagramAction
} from '../actions/Connection/connectionAction';

class ConnectionInstagramContainer extends PureComponent {
  constructor(props) {
    super(props);
  }
  
  render() {
    return <ConnectionInstagram { ...this.props } />
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    connectInstagramAction: code => dispatch(connectInstagramAction(code))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionInstagramContainer);