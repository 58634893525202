import React from 'react';

import SocialInsights from './SocialInsights';

const FacebookInsights = props => {
  return (
    <SocialInsights social="facebook" />
  )
};

export default FacebookInsights;
