import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import AddAsWeeklyGoalInsights from './legends/AddAsWeeklyGoalInsights';
import DismissInsights from './legends/DismissInsights';
import InformationalInsights from './legends/InformationalInsights';
import UsefulInsights from './legends/UsefulInsights';
import UselessInsights from './legends/UselessInsights';

import { actionTypes } from '../../../config/insightsConfig';

const HighlightsItem = props => {
  const [ isHover, setIsHover ] = useState(false);

  const styles = {
    highlightsText: {
      lineHeight: 2,
      display: 'inline-block'
    },
    legends: {
      textAlign: 'right'
    },
    hover: {
      backgroundColor: '#1c1c1c'
    },
    legendItem: {
      display: 'inline-block'
    }
  };

  const onHover = event => {
    setIsHover(true);
  };

  const offHover = event => {
    setIsHover(false);
  };

  const handleClick = (type, id, action) => {
    //TODO: need to handle API call
    switch(action) {
      case actionTypes.ADD:
        console.log('---addd');
        break;
      case actionTypes.DISMISS:
        console.log('---dis', type, id);
        props.handleDismissInsights(type, id);
        break;
      case actionTypes.USEFUL:
        console.log('---useful');
        break;
      case actionTypes.USELESS:
        console.log('---less');
        break;
      default:
        console.log('---no')
    }
  };

  if (props.data) {
    const data = props.data;
    // console.log('---data:', data);

    return (
      <Row onMouseEnter={onHover} onMouseLeave={offHover} style={isHover ? styles.hover : {}}>
        <Col xs={12} md={props.inModal ? 10 : 12}>
          {
            // data.type === 'action' && (
            //   <ActionableInsights noText={true}/>
            // )
          }
          {
            // data.type === 'info' && (
            //   <InformationalInsights noText={true}/>
            // )
          }
          {
            <InformationalInsights noText={true}/>
          }
          <div
            key={data.insightId}
            style={styles.highlightsText}
          >
            {data.text}
          </div>
        </Col>
        { props.inModal && (
          <Col xs={12} md={2} style={styles.legends}>
            {
              data.type === 'action' && (
                <div style={styles.legendItem} onClick={handleClick.bind(this, props.selectedTab, data.id, actionTypes.ADD)}>
                  <AddAsWeeklyGoalInsights noText={true}/>
                </div>
              )
            }
            <div style={styles.legendItem} onClick={handleClick.bind(this, props.selectedTab, data.id, actionTypes.DISMISS)}>
              <DismissInsights noText={true}/>
            </div>
            <div style={styles.legendItem} onClick={handleClick.bind(this, props.selectedTab, data.id, actionTypes.USELESS)}>
              <UselessInsights noText={true}/>
            </div>
            <div style={styles.legendItem} onClick={handleClick.bind(this, props.selectedTab, data.id, actionTypes.USEFUL)}>
              <UsefulInsights noText={true}/>
            </div>
          </Col>
        )}
      </Row>
    )
  } else {
    return null;
  }
};

export default HighlightsItem;