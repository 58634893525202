import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import '../../style/booking.css';

let eventGuid = 0
const todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today



function createEventId() {
  return String(eventGuid++)
}

export default class Calendar extends React.Component {

    state = {
        weekendsVisible: true,
        currentEvents: []
    
      }


  calendarRef = React.createRef();
  render() {
    return (
      <div style={{maxHeight: '740px'}}>
        <FullCalendar
          height='730px'
          // timeZone='UTC'
          themeSystem='bootstrap'
          ref={this.calendarRef}
          plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin]}
          editable={true}
          events={this.state.currentEvents}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
          initialView={(this.props.isUserArtist)?'timeGridWeek': 'timeGridWeek'}
          nowIndicator='true'
          weekends={this.state.weekendsVisible}
          slotDuration={'00:60:00'}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          select={this.handleDateSelect}
          eventContent={renderEventContent}
          dateClick={this.handleDateClick}
          eventClick={this.handleEventClick}
          initialEvents={this.props.events} // alternatively, use the `events` setting to fetch from a feed
          eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        />
      </div>
    )
  }

  handleDateSelect = (selectInfo) => {
    //let title = prompt('Please enter a new title for your event')
    let title = prompt('Event Title:', 'Event', { buttons: { Ok: true, Cancel: false} });
    let calendarApi = selectInfo.view.calendar
    if(!title) {title = 'Untitled'}

    calendarApi.unselect() // clear date selection

    if (title) {
      console.log('new event')
      const newEvent ={
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      }
      calendarApi.addEvent(newEvent)
      this.props.eventTitle(newEvent);
    }
  }

  handleDateClick = (arg) => { // bind with an arrow function
    //alert(arg.dateStr)
  }
  handleEventClick = (clickInfo) => {
  /*  if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove()
    } */
   this.props.selectedEventTimePill(clickInfo.event);
  }

  handleEvents = (events) => {
    console.log(events);
    // this.state.currentEvents = events;
  }
}

function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{" "+eventInfo.event.title}</i>
      </>
    )
  }