const fbCategories = [
  {
    text: 'Fans',
    key: 'pageFans',
    apiKey: 'pageFans'
  },
  {
    text: 'Engaged Fans',
    key: 'users',
    apiKey: 'users'
  },
  {
    text: 'Shares',
    key: 'shares',
    apiKey: 'shares'
  },
  {
    text: 'Comments',
    key: 'comments',
    apiKey: 'comments'
  },
  {
    text: 'Reactions',
    key: 'reactions',
    apiKey: 'reactions'
  }
];

const sampleFbData = {
  "createdDate": "2020-07-14T00:27:47.065Z",
  "knktId": 0,
  "comments": {
    "lifeTime": 350,
    "sevenDay": 134,
    "twentyEightDay": 245
  },
  "engagements": {
    "lifeTime": 100,
    "sevenDay": 40,
    "twentyEightDay": 65
  },
  "pageFans": {
    "lifeTime": 200,
    "sevenDay": 98,
    "twentyEightDay": 145
  },
  "pageLike": {
    "lifeTime": 500,
    "sevenDay": 298,
    "twentyEightDay": 345
  },
  "reactions": {
    "lifeTime": 420,
    "sevenDay": 209,
    "twentyEightDay": 374
  },
  "shares": {
    "lifeTime": 440,
    "sevenDay": 298,
    "twentyEightDay": 345
  },
  "users": {
    "lifeTime": 200,
    "sevenDay": 98,
    "twentyEightDay": 145
  },
  "oneDay": [
    {
      "createdDate": "2020-07-14T00:27:47.065Z",
      "pageFans": 566,
      'users': 200,
      'shares': 440,
      "comments": 350,
      'reactions': 420
    },
    {
      "createdDate": "2020-07-13T00:27:47.065Z",
      "pageFans": 566,
      'users': 210,
      'shares': 440,
      "comments": 360,
      'reactions': 420
    },
    {
      "createdDate": "2020-07-12T00:27:47.065Z",
      "pageFans": 556,
      'users': 201,
      'shares': 540,
      "comments": 360,
      'reactions': 420
    },
    {
      "createdDate": "2020-07-11T00:27:47.065Z",
      "pageFans": 506,
      'users': 191,
      'shares': 504,
      "comments": 320,
      'reactions': 365
    },
    {
      "createdDate": "2020-07-10T00:27:47.065Z",
      "pageFans": 506,
      'users': 191,
      'shares': 504,
      "comments": 320,
      'reactions': 365
    },
    {
      "createdDate": "2020-07-09T00:27:47.065Z",
      "pageFans": 506,
      'users': 191,
      'shares': 504,
      "comments": 320,
      'reactions': 365
    },
    {
      "createdDate": "2020-07-08T00:27:47.065Z",
      "pageFans": 502,
      'users': 178,
      'shares': 498,
      "comments": 306,
      'reactions': 325
    },
    {
      "createdDate": "2020-07-07T00:27:47.065Z",
      "pageFans": 502,
      'users': 178,
      'shares': 498,
      "comments": 306,
      'reactions': 325
    },
    {
      "createdDate": "2020-07-06T00:27:47.065Z",
      "pageFans": 502,
      'users': 178,
      'shares': 498,
      "comments": 306,
      'reactions': 325
    },
    {
      "createdDate": "2020-07-05T00:27:47.065Z",
      "pageFans": 542,
      'users': 198,
      'shares': 478,
      "comments": 286,
      'reactions': 305
    },
    {
      "createdDate": "2020-07-04T00:27:47.065Z",
      "pageFans": 552,
      'users': 198,
      'shares': 478,
      "comments": 286,
      'reactions': 325
    },
    {
      "createdDate": "2020-07-03T00:27:47.065Z",
      "pageFans": 542,
      'users': 198,
      'shares': 478,
      "comments": 286,
      'reactions': 305
    },
    {
      "createdDate": "2020-07-02T00:27:47.065Z",
      "pageFans": 512,
      'users': 198,
      'shares': 468,
      "comments": 246,
      'reactions': 299
    },
    {
      "createdDate": "2020-07-01T00:27:47.065Z",
      "pageFans": 512,
      'users': 198,
      'shares': 468,
      "comments": 246,
      'reactions': 299
    },
    {
      "createdDate": "2020-06-30T00:27:47.065Z",
      "pageFans": 502,
      'users': 179,
      'shares': 458,
      "comments": 236,
      'reactions': 292
    },
    {
      "createdDate": "2020-06-29T00:27:47.065Z",
      "pageFans": 502,
      'users': 179,
      'shares': 458,
      "comments": 236,
      'reactions': 292
    },
    {
      "createdDate": "2020-06-28T00:27:47.065Z",
      "pageFans": 502,
      'users': 179,
      'shares': 458,
      "comments": 236,
      'reactions': 292
    },
    {
      "createdDate": "2020-06-27T00:27:47.065Z",
      "pageFans": 502,
      'users': 179,
      'shares': 458,
      "comments": 236,
      'reactions': 292
    },
    {
      "createdDate": "2020-06-26T00:27:47.065Z",
      "pageFans": 492,
      'users': 179,
      'shares': 448,
      "comments": 232,
      'reactions': 282
    },
    {
      "createdDate": "2020-06-25T00:27:47.065Z",
      "pageFans": 502,
      'users': 179,
      'shares': 458,
      "comments": 236,
      'reactions': 292
    },
    {
      "createdDate": "2020-06-24T00:27:47.065Z",
      "pageFans": 488,
      'users': 169,
      'shares': 418,
      "comments": 216,
      'reactions': 262
    },
    {
      "createdDate": "2020-06-23T00:27:47.065Z",
      "pageFans": 488,
      'users': 169,
      'shares': 418,
      "comments": 216,
      'reactions': 262
    },
    {
      "createdDate": "2020-06-22T00:27:47.065Z",
      "pageFans": 478,
      'users': 159,
      'shares': 408,
      "comments": 206,
      'reactions': 272
    },
    {
      "createdDate": "2020-06-21T00:27:47.065Z",
      "pageFans": 471,
      'users': 151,
      'shares': 401,
      "comments": 201,
      'reactions': 271
    },
    {
      "createdDate": "2020-06-20T00:27:47.065Z",
      "pageFans": 471,
      'users': 151,
      'shares': 401,
      "comments": 201,
      'reactions': 271
    },
    {
      "createdDate": "2020-06-19T00:27:47.065Z",
      "pageFans": 477,
      'users': 157,
      'shares': 407,
      "comments": 207,
      'reactions': 277
    },
    {
      "createdDate": "2020-06-18T00:27:47.065Z",
      "pageFans": 477,
      'users': 157,
      'shares': 407,
      "comments": 207,
      'reactions': 277
    },
    {
      "createdDate": "2020-06-17T00:27:47.065Z",
      "pageFans": 477,
      'users': 157,
      'shares': 407,
      "comments": 207,
      'reactions': 277
    },
    {
      "createdDate": "2020-06-16T00:27:47.065Z",
      "pageFans": 467,
      'users': 147,
      'shares': 397,
      "comments": 197,
      'reactions': 267
    }
  ]
};

const sampleFbTopEngagement = {
  "lifeTime": {
    "ads": 120,
    "newsFeed": 90,
    "other": 60,
    "pageSuggestion": 80,
    "restored": 40,
    "search": 20,
    "yourPage": 30,

    "ageGroup_13_17": 240,
    "ageGroup_18_24": 350,
    "ageGroup_25_34": 223,
    "ageGroup_35_44": 234,
    "ageGroup_45_54": 345,
    "ageGroup_55_64": 260,
    "gender_neutral": 253,
    "men": 263,
    "women": 243,

    "topEngagements": [
      {
        "createdDate": "2020-07-23T05:03:15.262Z",
        "message": "top lifeTime1",
        "link": 'http://yahoo.com',
        "changePercentage": -5
      },
      {
        "createdDate": "2020-07-21T05:03:15.262Z",
        "message": "top lifeTime2",
        "link": 'http://yahoo.com',
        "changePercentage": 15
      },
      {
        "createdDate": "2020-07-12T05:03:15.262Z",
        "message": "top lifeTime3",
        "link": 'http://yahoo.com',
        "changePercentage": 25
      },
      {
        "createdDate": "2020-07-05T05:03:15.262Z",
        "message": "top lifeTime4",
        "link": 'http://yahoo.com',
        "changePercentage": -13
      },
      {
        "createdDate": "2020-07-24T05:03:15.262Z",
        "message": "top lifeTime5",
        "link": 'http://yahoo.com',
        "changePercentage": 20
      }
    ]
  },
  "createdDate": "2020-07-23T05:03:15.262Z",
  "id": {
    "counter": 0,
    "date": "2020-07-23T05:03:15.262Z",
    "machineIdentifier": 0,
    "processIdentifier": 0,
    "time": 0,
    "timeSecond": 0,
    "timestamp": 0
  },
  "knktId": 2,
  "sevenDay": {
    "ads": 10,
    "newsFeed": 16,
    "other": 20,
    "pageSuggestion": 23,
    "restored": 8,
    "search": 4,
    "yourPage": 6,

    "ageGroup_13_17": 40,
    "ageGroup_18_24": 50,
    "ageGroup_25_34": 23,
    "ageGroup_35_44": 34,
    "ageGroup_45_54": 45,
    "ageGroup_55_64": 60,
    "gender_neutral": 53,
    "men": 63,
    "women": 43,

    "topEngagements": [
      {
        "createdDate": "2020-07-23T05:03:15.262Z",
        "message": "top sevenDay1",
        "link": 'http://yahoo.com',
        "changePercentage": 9
      },
      {
        "createdDate": "2020-07-21T05:03:15.262Z",
        "message": "top sevenDay2",
        "link": 'http://yahoo.com',
        "changePercentage": 19
      },
      {
        "createdDate": "2020-07-12T05:03:15.262Z",
        "message": "top sevenDay3",
        "link": 'http://yahoo.com',
        "changePercentage": -9
      },
      {
        "createdDate": "2020-07-05T05:03:15.262Z",
        "message": "top sevenDay4",
        "link": 'http://yahoo.com',
        "changePercentage": -10
      },
      {
        "createdDate": "2020-07-24T05:03:15.262Z",
        "message": "top sevenDay5",
        "link": 'http://yahoo.com',
        "changePercentage": 10
      }
    ]
  },
  "twentyEightDay": {
    "ads": 40,
    "newsFeed": 50,
    "other": 23,
    "pageSuggestion": 34,
    "restored": 45,
    "search": 60,
    "yourPage": 43,

    "ageGroup_13_17": 140,
    "ageGroup_18_24": 150,
    "ageGroup_25_34": 123,
    "ageGroup_35_44": 134,
    "ageGroup_45_54": 145,
    "ageGroup_55_64": 160,
    "gender_neutral": 153,
    "men": 163,
    "women": 143,

    "topEngagements": [
      {
        "createdDate": "2020-07-23T05:03:15.262Z",
        "message": "top twentyEightDay1",
        "link": 'http://yahoo.com',
        "changePercentage": 9
      },
      {
        "createdDate": "2020-07-21T05:03:15.262Z",
        "message": "top twentyEightDay2",
        "link": 'http://yahoo.com',
        "changePercentage": -9
      },
      {
        "createdDate": "2020-07-12T05:03:15.262Z",
        "message": "top twentyEightDay3",
        "link": 'http://yahoo.com',
        "changePercentage": 12
      },
      {
        "createdDate": "2020-07-05T05:03:15.262Z",
        "message": "top twentyEightDay4",
        "link": 'http://yahoo.com',
        "changePercentage": -10
      },
      {
        "createdDate": "2020-07-24T05:03:15.262Z",
        "message": "top twentyEightDay5",
        "link": 'http://yahoo.com',
        "changePercentage": 9
      }
    ]
  }
};

const sampleFbLocation = {
  "createdDate": "2020-07-24T05:03:15.262Z",
  "id": {
    "counter": 0,
    "date": "2020-07-30T02:06:28.380Z",
    "machineIdentifier": 0,
    "processIdentifier": 0,
    "time": 0,
    "timeSecond": 0,
    "timestamp": 0
  },
  "knktId": 2,
  "lifeTime": {
    "cities": [
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "LA",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SF",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      },
      {
        "increasePercentage": -10,
        "name": "NY",
        "totPercentage": 8
      }
    ],
    "countries": [
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "USA",
        "totPercentage": 20,
        "countryCode": "us"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 10,
        "countryCode": "in"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      },
      {
        "increasePercentage": -10,
        "name": "China",
        "totPercentage": 8,
        "countryCode": "cn"
      }
    ]
  },
  "sevenDay": {
    "cities": [
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 30,
        "name": "MN",
        "totPercentage": 25
      },
      {
        "increasePercentage": -10,
        "name": "San Jose",
        "totPercentage": 15
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      },
      {
        "increasePercentage": 10,
        "name": "NV",
        "totPercentage": 5
      }
    ],
    "countries": [
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": -40,
        "name": "UAE",
        "totPercentage": 40,
        "countryCode": "ua"
      },
      {
        "increasePercentage": 20,
        "name": "China",
        "totPercentage": 18,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 10,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      }
    ]
  },
  "twentyEightDay": {
    "cities": [
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": 40,
        "name": "SF",
        "totPercentage": 20
      },
      {
        "increasePercentage": 10,
        "name": "SJ",
        "totPercentage": 10
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      },
      {
        "increasePercentage": -10,
        "name": "LA",
        "totPercentage": 8
      }
    ],
    "countries": [
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 38,
        "name": "USA",
        "totPercentage": 30,
        "countryCode": "us"
      },
      {
        "increasePercentage": -20,
        "name": "China",
        "totPercentage": 28,
        "countryCode": "cn"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      },
      {
        "increasePercentage": 40,
        "name": "India",
        "totPercentage": 15,
        "countryCode": "in"
      }
    ]
  }
};

export {
  fbCategories,
  sampleFbData,
  sampleFbTopEngagement,
  sampleFbLocation
};