import { forEach } from "lodash";
import React from "react";
import { Button } from "react-bootstrap";

import calendarData from "../../demoData/calender.json"
import catalogData from "../../demoData/catalog.json"

const TimeSlotPills = (props) => {
  const styles = {
    layout: {
      display: "flex",
      flexDirection: "row",
      placeItems: "center",
      placeContent: "center",
      gap: "1rem",
    },
  };
  const catalog = catalogData.find(i=>i.knktId==props.knktId)
  const calendar = calendarData.find(i=>i.knktId==props.knktId)
  if(catalog == null || calendar == null) return (<>No Available Time Slots</>)
  const catalogType = catalog.type.find(i=>i.category === props.category)

  const minutesDivider = 60 * 1000;
  let minutesInterval = catalogType.intervalMins;
  const startTime = new Date(calendar.StartTime);
  const endTime = new Date(calendar.EndTime);
  const hours = (endTime - startTime) / minutesDivider;
  const totalPills = hours / minutesInterval;
  
  const pills = [];
  let firstPillTime = startTime.getTime();
  pills.push(firstPillTime)
  for(let i=1; i<totalPills; i++){
    let nextPillTime=pills[i-1]+ minutesInterval*60000
    pills.push(nextPillTime)
  }
  // const pills = ["3:45 AM", "4:00 AM", "4:15 AM", "4:30 AM"];

  return (
    <div style={styles.layout}>
      {pills.map((pill) => {
        const formattedPill = new Date(pill)
        console.log(formattedPill)
        return (
          <Button variant="outline-info" key={pill} onClick={()=>props.onClick()}>
            {formattedPill.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
          </Button>
        );
      })}
    </div>
  );
};

export default TimeSlotPills;
