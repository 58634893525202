import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Upload } from 'antd';

import './styles.css';
import { isValidUrl, getBase64, checkImageFormatAndSize } from '../../../utils/utils';

const AddLinkModal = ({ isOpen, handleSave, handleCancel }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    url: '',
    imageUrl: ''
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: field === 'url' && !value.startsWith('https://') ? `https://${value}` : value,
    }));

  };

  const validateForm = () => {
    const { title, url, imageUrl } = formData;
    const validationErrors = {};

    if (!title) validationErrors.title = 'Title is required.';
    if (!url) {
      validationErrors.url = 'URL is required.';
    } else if (!isValidUrl(url)) {
      validationErrors.url = 'Please enter a valid URL.';
    }
    if (!imageUrl) validationErrors.imageUrl = 'Image URL is required.';

    return validationErrors;
  };

  const handleSaveLink = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    handleSave(formData);
    setFormData({})
  };

  const customRequest = ({ file, onSuccess }) => {
    getBase64(file, (url) => {
      setLoading(false);
      handleInputChange('imageUrl', url);
      onSuccess('ok');
    });
  };
  
  const handleUploadPhoto = (info) => {
    if (errors.imageUrl) {
      setErrors((prevErrors) => ({ ...prevErrors, imageUrl: ''}));
    }

    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
  };

  const uploadButton = (
    <button className='upload-button' type='button'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className='upload-text'>
        Upload
      </div>
    </button>
  );

  return (
    <Modal
      open={isOpen}
      title='Add New Link'
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' onClick={handleSaveLink}>
          Done
        </Button>
      ]}
    >
      <Upload
        name='avatar'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        customRequest={customRequest}
        beforeUpload={checkImageFormatAndSize}
        onChange={handleUploadPhoto}
      >
        {formData.imageUrl ? (
          <img
            src={formData.imageUrl}
            alt='avatar'
            className='link-picture'
          />
        ) : (
          uploadButton
        )}
      </Upload>
      {errors.imageUrl && <p className='error-message error-message-image'>{errors.imageUrl}</p>}

      <label htmlFor='url-input' className='url-label'>URL</label>
      <Input
        className={`input ${errors.url ? 'input-error' : ''}`}
        id='url-input'
        placeholder='https://example.com'
        value={formData.url}
        size='large'
        onChange={(e) => handleInputChange('url', e.target.value)}
      />
      {errors.url && <p className='error-message'>{errors.url}</p>}

      <label htmlFor='title-input'>Title</label>
      <Input
        className={`input ${errors.title ? 'tiinput-error': ''}`}
        id='title-input'
        placeholder='Enter title of the link'
        value={formData.title}
        size='large'
        onChange={(e) => handleInputChange('title', e.target.value)}
      />
      {errors.title && <p className='error-message'>{errors.title}</p>}
    </Modal>
  )
}

export default AddLinkModal;