import React, { useState } from 'react';
// import { Nav, Navbar, NavDropdown, Overlay, Tooltip, Collapse, OverlayTrigger, Popover } from 'react-bootstrap';
import { NavLink, withRouter } from 'react-router-dom';

import KnktLogo from './KnktLogo';
import { userTypePerPage } from '../../utils/constants';

const LeftMenu = (props) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const styles = {
    brand: {
      width: '100%',
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
    navlink: {
      textDecoration: 'none',
      color: 'rgba(255,255,255)',
      display: 'flex',
      flexDirection: props.smallScreen ? 'row' : 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: props.smallScreen ? 20 : 30,
      width: '100%',
      opacity: 0.5,
    },
    profileNavLink: {
      textDecoration: 'none',
      color: 'rgba(255,255,255)',
      display: 'flex',
      flexDirection: props.smallScreen ? 'row' : 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: props.smallScreen ? 30 : 40,
      width: '100%',
      opacity: 1,
      marginTop: '1rem',
    },
    navLinkSmallScreen: {
      flexDirection: 'row',
    },
    navItem: {
      paddingBottom: 20,
      width: props.smallScreen ? 'unset' : '100%',
    },
    navItemFirst: {
      marginTop: 30,
    },
    navItemLogout: {
      marginTop: 30,
      cursor: 'pointer',
    },
    icon: {
      fontSize: '1.5em',
      width: '100%',
    },
    iconText: props.smallScreen ? { marginLeft: 15 } : {},
  };

  const handleRedirect = (event) => {
    event.stopPropagation();
    // event.preventDefault();
    try {
      props.hideMenu();
    } catch (e) {}
  };

  const isSelected = (link) => {
    let selected = props.location.pathname.indexOf(link) > -1;

    if (Array.isArray(link)) {
      for (let i = 0; i < link.length; i++) {
        if (props.location.pathname.indexOf(link[i]) > -1) {
          selected = true;
          break;
        }
      }
    }

    return selected
      ? { opacity: 1, fontSize: '1.2em' }
      : { opacity: 0.5 };
  };

  const isAllowed = pageName => {
    if (props.user && props.user.account) {
      return userTypePerPage[pageName].includes(props.user.account);
    }

    return false;
  }

  return (
    <React.Fragment>
      <KnktLogo imgStyle={{ width: 60 }} divStyle={styles.brand} />
      
      <NavLink
        to="/dashboard"
        onClick={handleRedirect.bind(this)}
        style={{
          ...styles.navItem,
          ...styles.navItemFirst,
          ...styles.navlink,
          ...isSelected(['/dashboard', '/insights']),
        }}
      >
        <div>
          <i className="fas fa-chart-pie" style={styles.icon}></i>
        </div>
        {!props.menuCollapse && (
          <div style={styles.iconText}>Dashboard</div>
        )}
      </NavLink>
      
      {
        isAllowed('score') && (
          <NavLink
            to="/score"
            onClick={handleRedirect.bind(this)}
            style={{
              ...styles.navItem,
              ...styles.navItemFirst,
              ...styles.navlink,
              ...isSelected('/score'),
            }}
          >
            <div>
              <i className="fas fa-chart-line" style={styles.icon}></i>
            </div>
            {!props.menuCollapse && (
              <div style={styles.iconText}>KNKTScore</div>
            )}
          </NavLink>
        )
      }

      {/*       <NavLink
        to="/promolink"
        onClick={handleRedirect.bind(this)}
        style={{...styles.navItem, ...styles.navItemFirst, ...styles.navlink, ...isSelected("/promolink")}}
      >
        <div><i className="fas fa-link" style={styles.icon}></i></div>
        {
          (!props.menuCollapse) && (
            <div style={styles.iconText}>PromoLinks</div>
          )
        }
      </NavLink> */}

      {
        isAllowed('promote') && (
          <NavLink
            to="/promote"
            onClick={handleRedirect.bind(this)}
            style={{
              ...styles.navItem,
              ...styles.navItemFirst,
              ...styles.navlink,
              ...isSelected('/promote'),
            }}
          >
            <div>
              <i className="fas fa-bullhorn" style={styles.icon}></i>
            </div>
            {!props.menuCollapse && (
              <div style={styles.iconText}>Promote</div>
            )}
          </NavLink>
        )
      }
      
      {
        isAllowed('insightx') && (
          <NavLink
            to="/insightx"
            onClick={handleRedirect.bind(this)}
            style={{
              ...styles.navItem,
              ...styles.navItemFirst,
              ...styles.navlink,
              ...isSelected('/insightx'),
            }}
          >
            <div>
              <i className="fas fa-chart-area" style={styles.icon}></i>
            </div>
            {!props.menuCollapse && (
              <div style={styles.iconText}>Insights</div>
            )}
          </NavLink>
        )
      }
      
      {
        isAllowed('bookinghome') && (
          <NavLink
            to="/bookinghome"
            onClick={handleRedirect.bind(this)}
            style={{
              ...styles.navItem,
              ...styles.navItemFirst,
              ...styles.navlink,
              ...isSelected('/booking'),
            }}
          >
            <div>
              <i className="fas fa-sitemap" style={styles.icon}></i>
            </div>
            {!props.menuCollapse && (
              <div style={styles.iconText}>Booking</div>
            )}
          </NavLink>
        )
      }
      
      {
        isAllowed('knktcalendar') && (
          <NavLink
            to="/knktcalendar"
            onClick={handleRedirect.bind(this)}
            style={{
              ...styles.navItem,
              ...styles.navItemFirst,
              ...styles.navlink,
              ...isSelected('/knktcalendar'),
            }}
          >
            <div>
              <i className="fas fa-calendar" style={styles.icon}></i>
            </div>
            {!props.menuCollapse && (
              <div style={styles.iconText}>Calendar</div>
            )}
          </NavLink>
        )
      }

      {
        isAllowed('catalog') && (
          <NavLink
            to="/catalog"
            onClick={handleRedirect.bind(this)}
            style={{
              ...styles.navItem,
              ...styles.navItemFirst,
              ...styles.navlink,
              ...isSelected('/catalog'),
            }}
          >
            <div>
              <i className="fas fa-database" style={styles.icon}></i>
            </div>
            {/*  */}
            {!props.menuCollapse && (
              <div style={styles.iconText}>Catalog</div>
            )}
          </NavLink>
        )
      }
      
      

      {/*       <NavLink
        to="/charts"
        onClick={handleRedirect.bind(this)}
        style={{...styles.navItem, ...styles.navItemFirst, ...styles.navlink, ...isSelected("/charts")}}
      >
        <div><i className="fas fa-headphones" style={styles.icon}></i></div>
        {
          (!props.menuCollapse) && (
            <div style={styles.iconText}>Charts</div>
          )
        }
      </NavLink> */}

      {/*       <NavLink
        to={`/calendar/${props.user ? props.user.artistId : 0}`}
        onClick={handleRedirect.bind(this)}
        style={{...styles.navItem, ...styles.navItemFirst, ...styles.navlink, ...isSelected("/calendar")}}
      >
        <div><i className="fas fa-ticket-alt" style={styles.icon}></i></div>
        {
          (!props.menuCollapse) && (
            <div style={styles.iconText}>ShowLinks</div>
          )
        }
      </NavLink> */}

      {/*       <NavLink
        to="/presaves"
        onClick={handleRedirect.bind(this)}
        style={{...styles.navItem, ...styles.navItemFirst, ...styles.navlink, ...isSelected("/presaves")}}
      >
        <div><i className="fas fa-music" style={styles.icon}></i></div>
        {
          (!props.menuCollapse) && (
            <div style={styles.iconText}>PreSaves</div>
          )
        }
      </NavLink> */}

      <NavLink
        to="/connection"
        onClick={handleRedirect.bind(this)}
        style={{
          ...styles.navItem,
          ...styles.navItemFirst,
          ...styles.navlink,
          ...isSelected('/connection'),
        }}
      >
        <div>
          <i className="fas fa-plug" style={styles.icon}></i>
        </div>
        {!props.menuCollapse && (
          <div style={styles.iconText}>Connections</div>
        )}
      </NavLink>

      <NavLink
        to="/community"
        onClick={handleRedirect.bind(this)}
        style={{
          ...styles.navItem,
          ...styles.navItemFirst,
          ...styles.navlink,
          ...isSelected('/community'),
        }}
      >
        <div>
          <i className="fas fa-user-circle" style={styles.icon}></i>
        </div>
        {!props.menuCollapse && (
          <div style={styles.iconText}>Community</div>
        )}
      </NavLink>
      
      <NavLink
        to="/settings"
        onClick={handleRedirect.bind(this)}
        style={{
          ...styles.navItem,
          ...styles.navItemFirst,
          ...styles.navlink,
          ...isSelected('/settings'),
        }}
      >
        <div>
          <i className="fas fa-cog" style={styles.icon}></i>
        </div>
        {!props.menuCollapse && (
          <div style={styles.iconText}>Settings</div>
        )}
      </NavLink>

      <div
        style={{
          ...styles.navItem,
          ...styles.navItemLogout,
          ...styles.navlink,
        }}
        onClick={props.handleLogout}
      >
        <div>
          <i className="fas fa-sign-out-alt" style={styles.icon}></i>
        </div>
        {!props.menuCollapse && (
          <div style={styles.iconText}>Logout</div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(LeftMenu);
