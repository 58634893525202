import { callApi } from '../../utils/utils';

const JUST_RETURN = 'JUST_RETURN';
const PICK_ARTIST = 'PICK_ARTIST';
const GET_ARTISTS = 'GET_ARTISTS';

function pickArtist(data) {
  return function(dispatch) {
    dispatch({
      type: PICK_ARTIST,
      site: data
    });
  }
}

function fetchArtists() {
  console.log('---fetching artists...');
  const resp = callApi('/api/v1/artists', 'get', {});

  return function (dispatch) {
    resp.then((res) => {
      const data = res.data ? res.data.artists : [];
      dispatch({
        type: GET_ARTISTS,
        data
      });
    })
  };
}

function addArtists(artists) {
  const resp = callApi('/api/v1/artists', 'post', {artists});

  return function (dispatch) {
    resp.then((res) => {
      const data = res.data ? res.data.artists : [];
      dispatch({
        type: GET_ARTISTS,
        data
      });
    })
  };
}

function updateArtists(artists) {
  const resp = callApi('/api/v1/artists', 'PUT', {update: true, artists});

// console.log('----updating....')
  return function (dispatch) {
    // console.log('---done updating...')
    resp.then((res) => {
      console.log('res.status:', res.status);
      dispatch({
        type: JUST_RETURN
      });
      // return fetchArtists();
    })
  };
}

function deleteArtists(artists) {
  console.log('--- deleting artists...');
  
  const resp = callApi('/api/v1/artists', 'delete', {artists});

  return function (dispatch) {
    resp.then((res) => {
      const data = res.data ? res.data.artists : [];
      dispatch({
        type: GET_ARTISTS,
        data
      });
    })
  };
}

export {
  JUST_RETURN,
  PICK_ARTIST,
  GET_ARTISTS,

  pickArtist,
  fetchArtists,
  addArtists,
  updateArtists,
  deleteArtists
};