import {
  POPULATE_DATA,
  ACTIVATE_EVENT,
  DEACTIVATE_EVENT,
  ADD_TO_TOUR,
  REMOVE_FROM_TOUR,
  ADD_EVENT,
  ADD_TOUR,
  UPDATE_SHOW_ARTIST,
  UPDATE_TOUR_NAME,
  ACTIVATE_TOUR,
  DEACTIVATE_TOUR,
  DELETE_TOUR
} from '../actions/Calendar/calendarAction';

const initialState = {
  tours: [],
  tourEvents: [],
  edit: false,
  showArtistId: undefined
};

function calendar(state = initialState, action) {
  switch (action.type) {
    case POPULATE_DATA:
    {
      let newState = Object.assign({}, state);

      newState.tours = action.calendar.tours;
      newState.tourEvents = action.calendar.tourEvents;
      newState.edit = action.calendar.edit;

      return newState;
    }
    case ACTIVATE_EVENT:
    {
      let newState = Object.assign({}, state);
      const tourEvents = newState.tourEvents;
      newState.tourEvents = [];

      tourEvents.forEach(event => {
        if (event.tourEventId === action.event.tourEventId) {
          event.active = true;
        }
        newState.tourEvents.push(event);
      });
      return newState;
    }
    case DEACTIVATE_EVENT:
    {
      let newState = Object.assign({}, state);
      const tourEvents = newState.tourEvents;
      newState.tourEvents = [];

      tourEvents.forEach(event => {
        if (event.tourEventId === action.event.tourEventId) {
          event.active = false;
        }
        newState.tourEvents.push(event);
      });
      return newState;
    }
    case ADD_TO_TOUR:
    {
      let newState = Object.assign({}, state);

      const tours = newState.tours;
      newState.tours = [];

      tours.forEach(tour => {
        let newTour = tour;
        if (action.tour && action.tour.tourId) {
          let tourEvents = tour.tourEvents;
          tourEvents.push(action.tourEvent.tourEventId+'');
          newTour.tourEvents = tourEvents;
        }
        newState.tours.push(newTour);
      });

      return newState;
    }
    case REMOVE_FROM_TOUR:
    {
      let newState = Object.assign({}, state);
      const tours = newState.tours;
      newState.tours = [];

      tours.forEach(tour => {
        let newTour = tour;
        if (tour.tourId === action.tour.tourId) {
          let tourEvents = tour.tourEvents;
          let newEvents = [];
          tourEvents.forEach(event => {
            if (event !== action.tourEvent.tourEventId) {
              newEvents.push(event);
            }
          });
          newTour.tourEvents = newEvents;
        }
        newState.tours.push(newTour);
      });
      return newState;
    }
    case ADD_EVENT:
    {
      let newState = Object.assign({}, state);
      newState.tourEvents.push(action.event);
      return newState;
    }
    case ADD_TOUR:
    {
      let newState = Object.assign({}, state);

      const tours = newState.tours;
      
      if (action.tour) {
        tours.push(action.tour);
      }

      newState.tours = tours;
      return newState;
    }
    case UPDATE_SHOW_ARTIST:
    {
      let newState = Object.assign({}, state);
      newState.showArtistId = action.showArtistId;
      return newState;
    }
    case UPDATE_TOUR_NAME:
    {
      let newState = Object.assign({}, state);
      newState.tours.forEach(tour => {
        if (tour.tourId == action.tourId) {
          tour.tourName = action.tourName;
        }
      });
      return newState;
    }
    case ACTIVATE_TOUR:
    {
      let newState = Object.assign({}, state);
      // console.log(action.tourId, true);
      newState.tours.forEach(tour => {
        if (tour.tourId == action.tourId) {
          tour.active = true;
        }
      });
      // console.log(newState);
      return newState;
    }
    case DEACTIVATE_TOUR:
    {
      let newState = Object.assign({}, state);
      // console.log(action.tourId, false);
      newState.tours.forEach(tour => {
        if (tour.tourId == action.tourId) {
          tour.active = false;
        }
      });
      // console.log(newState);
      return newState;
    }
    case DELETE_TOUR:
    {
      let newState = Object.assign({}, state);
      let tours = newState.tours;

      if (tours.length > 0) {
        let newTours = [];
        tours.forEach(tour => {
          if (tour.tourId != action.tourId) {
            newTours.push(tour);
          }
        });
        newState.tours = newTours;
      }

      return newState;
    }
    default:
    {
      return state;
    }
  }
}

export default calendar;