import React, { PureComponent } from 'react';

class ConnectionInstagram extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      code: null
    }
  }

  componentDidMount() {
    this.extractCode();
  }

  extractCode() {
    let code = window.location.search;

    if (code) {
      if (code.indexOf('code=') > -1) {
        code = code.substring(code.indexOf('code=') + 5);
      }
      // console.log('----code:', code);
      this.props.connectInstagramAction(code);
    }
  }
  
  render() {
    // console.log('------window.location.search:', window.location.search);
    return (
      <div>{this.state.code}</div>
    )
  }
}


export default ConnectionInstagram;