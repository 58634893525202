import React, { Fragment, useState, useRef, useEffect } from 'react';

const SpotifyFollow = (props) => {
    return (
        <iframe
            src={props.url}
            width={props.width}
            height={props.height}
            scrolling="no"
            frameBorder="0"
            style={{border: 'none', overflow: "hidden"}}
            allowtransparency="true" 
        />
    );
}

export default SpotifyFollow;