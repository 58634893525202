import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import {
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Form,
  FloatingLabel,
  useAccordionButton,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import Calender from "../../common/Calender";
import KnktButton from "../../common/KnktButton";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { fetchData } from "./data.js";
import { getUserProfile } from "../../../actions/Login/loginAction";
import { callApi, isDevelopement, isProduction } from "../../../utils/utils";
/* const stripe = Stripe("pk_test_51HaoQcL7xmokt94PmlJrzkxerXfPxPxjakVH4xQjdkAIoHtWH6PFbt8P4fFW9pXAE0I6mhUh2K1Rk627eokM9OXU00voPxy7Gn"); */

import TimeSlotPills from "../../common/TimeSlotPills.js";

const styles = {
  header: {
    minHeight: "30vh",
    background: "#009FFF",
    background:
      "linear-gradient(to right, rgb(67 47 236), rgb(255 165 0),rgb(255 0 0))",
    color: "white",
    /* clipPath: "ellipse(100vw 60vh at 50% 50%)", */
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  details: {
    textAlign: "center",
    color: "#fff",
    marginTop: "-45px",
  },
  profilePic: {
    height: "6rem",
    width: "6rem",
    objectFit: "center",
    borderRadius: "50%",
    border: "2px solid #fff",
  },
  stats: {
    display: "flex",
  },
  statsDetail: {
    width: "10rem",
    textAlign: "center",
  },
  heading: {
    fontWeight: "400",
    fontSize: "1.3rem",
    margin: "1rem 0",
  },
  location: {
    svg: {
      display: "inline-block",
    },
    p: {
      verticalAlign: "middle",
    },
  },
  hr: {
    backgroundColor: "#ffffff",
  },
  button: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  card: {
    padding: "10px",
  },
  cardBg: {
    backgroundColor: "rgb(29 30 30)",
    boxShadow: "rgb(44 44 44) 5px 6px 16px 5px", //"5px 5px 15px 5px #2c2c2c"
    marginBottom: "5vh",
    color: "#FFFFFF",
  },
  bg: {
    backgroundColor: "rgb(26, 29, 36)",
    color: "rgb(255,255,255)",
    margin: "1rem",
  },
  label: {
    display: "inline-block",
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    objectFit: "cover",
    margin: "1.5rem",
  },
  buttonGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.5rem",
  },
  radio: {
    gap: "12px",
    paddingBottom: "3rem",
  },
};
const radios = [
  { name: "Active", value: "1" },
  { name: "Radio", value: "2" },
  { name: "Radio", value: "3" },
];

let eventGuid = 0;
const todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

function createEventId() {
  return String(eventGuid++);
}

const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: "All-day event",
    start: todayStr,
  },
  {
    id: createEventId(),
    title: "Available",
    start: todayStr + "T12:00:00",
    end: todayStr + "T12:30:00",
  },
  {
    id: createEventId(),
    title: "Available",
    start: todayStr + "T13:00:00",
    end: todayStr + "T13:30:00",
  },
  {
    id: createEventId(),
    title: "Available",
    start: todayStr + "T14:00:00",
    end: todayStr + "T14:30:00",
  },
  {
    id: createEventId(),
    title: "Available",
    start: todayStr + "T15:00:00",
    end: todayStr + "T15:30:00",
  },
];

const Booking = (props) => {
  const [productData, setProductData] = useState(null);
  const [timePillsData, setTimePillsData] = useState([]);
  const [checkoutData, setCheckoutData] = useState([]);
  const [message, setMessage] = useState("");
  const [AccordionState, setAccordionState] = useState(0);
  const [bookingTabsTitle, setBookingTabsTitle] = useState([
    {
      title: "Video",
      isActive: true,
      color: "knktOcean",
      icon: "fas fa-video",
    },
    {
      title: "Audio",
      isActive: false,
      color: "knktBlurple",
      icon: "fas fa-headphones",
    },
    {
      title: "Message",
      isActive: false,
      color: "knktRed",
      icon: "fas fa-envelope-open",
    },
  ]);
  const [bookingTabSelected, setBookingTabSelected] = useState(
    bookingTabsTitle[0].title
  );
  const [bookingSubmitState, setBookingSubmitState] = useState();
  const [events, setEvents] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const successRef = useRef(null);
  const cancelRef = useRef(null);
  const { firstName, lastName, email, account, knktId } = props.user;
  const {profileName} = props.location.state;

  function CustomToggle({ children, eventKey, style, isHidden }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      console.log("totally custom!", eventKey);
      setAccordionState(eventKey);
    });

    const hideForProduction = {
      display: isProduction && isHidden ? "none" : "flex",
    };

    const customStyle = { ...style, ...hideForProduction };
    return (
      <Button
        id={eventKey + "toggle"}
        variant="outline-info"
        style={customStyle}
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    );
  }

  function CheckoutButton() {
    return (
      <Button variant="outline-info" onClick={onClickCheckout}>
        {"Check Out"}
      </Button>
    );
  }

  const scrollToBottom = (ref) => {
    console.log("scroll", ref.current);
    setTimeout(() => {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

  const bookingSubmit = async () => {
    const body = {
      provider: {
        firstName: firstName,
        lastName: firstName,
        knktId: knktId,
        email: email,
        phone: "string",
      },
      requestor: {
        firstName: firstName,
        lastName: firstName,
        knktId: knktId,
        email: email,
        phone: "string",
      },
      items: [
        {
          itemId: checkoutData.skuId,
          skuId: checkoutData.skuId,
          productId: "prod000001",
          description: checkoutData.skuDescription,
          quantity: checkoutData.listPrice,
        },
      ],
      payment: {
        token: "string",
      },
    };
    try {
      let response = await fetch(
        " https://booking.knkt.io/v1/booking/submit", //booking.knkt.io:55480
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
          },
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status) {
        // console.log('bookingSubmitResponse', data);
        setBookingSubmitState(data);
      }
    } catch (error) {
      console.log(
        "api-error-todo v1/booking/submit error show error message to redirect back to previous page or refresh" +
          error
      );
    }
  };

  const onClickCheckout = useCallback(async (event) => {
    const stripe = await loadStripe(
      "pk_test_51HaoQcL7xmokt94PmlJrzkxerXfPxPxjakVH4xQjdkAIoHtWH6PFbt8P4fFW9pXAE0I6mhUh2K1Rk627eokM9OXU00voPxy7Gn"
    );
    console.log(stripe);
    console.log(checkoutData);
    bookingSubmit();
    checkoutStripe(stripe);
  });

  const getData = async () => {
    try {
      let response = await fetch(
        "https://catalog.knkt.io/v1/accounts", //https://159.65.70.2/v1/accounts:55280
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
          },
          method: "GET",
        }
      );
      let data = await response.json();
      if (data.status) {
        data = fetchData();
      }
      setProductData(data);
    } catch (error) {
      console.log(
        "api-error-todo v1/accounts error show error message to redirect back to previous page or refresh" +
          error
      );
    }
  };

  function checkoutStripe(stripe) {
    fetch("https://payment.knkt.io/v1/payment/initiate", {
      ////booking.knkt.io:55580 to-do redirect to knkt page instead of example page
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
      },
      method: "POST",
      body: JSON.stringify({
        orderId: checkoutData.stripeProductId,
        orderDescription: checkoutData.skuDescription,
        knktId: "knkt123",
        accountId: checkoutData.accountId,
        emailId: "dinojohny.work@gmail.com",
        orderItems: [
          {
            itemId: "item123",
            itemDescription: checkoutData.skuDescription,
            skuId: checkoutData.skuId, // document.querySelector('input[name="slectedproduct"]:checked').value,
            quantity: 1,
            listPrice: checkoutData.listPrice,
            currency: checkoutData.currency,
          },
        ],
        paymentInfo: {
          orderTotal: checkoutData.salePrice,
        },
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (session) {
        //console.log(stripe.redirectToCheckout({ sessionId: session.paymentSessionId }), session);
        alert(session);
        return stripe.redirectToCheckout({
          sessionId: session.paymentSessionId,
        });
      })
      .then(function (result) {
        // If redirectToCheckout fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using error.message.
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function (error) {
        console.log(
          "api-error-todo v1/payment/initiate error show error message to redirect back to previous page or refresh" +
            error
        );
      });
  }

  /* currently not using this, will do in the future   
const checkPaymentBooking = async () => {
    try {
      let response = await fetch(
        ' http://booking.knkt.io:55480/v1/booking/' + knktId,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
            'Access-Control-Allow-Headers':
              'Content-Type, Authorization',
          },
          method: 'GET',
        }
      );
      let data = await response.json();
      if (data.status) {
        console.log('bookingPaymentResponse', data);
      }
    } catch (error) {
      console.log(
        'api-error-todo v1/booking error show error message to redirect back to previous page or refresh' +
          error
      );
    }
  }; */

  const bookingLatest = async () => {
    try {
      let response = await fetch(
        " https://booking.knkt.io/v1/booking/latest/" + knktId, //:55480
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
          },
          method: "GET",
        }
      );
      let data = await response.json();
      if (data.status) {
        console.log("bookingLatestResponse", data);
        updateBookingStatus(data);
        //updateBookingPayment(data) //TODO
      }
    } catch (error) {
      console.log(
        "api-error-todo v1/booking/latest error show error message to redirect back to previous page or refresh" +
          error
      );
    }
  };

  const updateBookingStatus = async (data) => {
    const body = {
      bookingId: data.bookingId,
      requestKnktId: knktId,
      provKnktId: knktId,
      status: "Success",
      userType: account /*optional */,
    };
    try {
      let response = await fetch(
        " https://booking.knkt.io/v1/booking/status", //:55480
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers": "Content-Type, Authorization",
          },
          method: "POST",
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status) {
        console.log("bookingSubmitResponse", data);
      }
    } catch (error) {
      console.log(
        "api-error-todo v1/booking/status error show error message to redirect back to previous page or refresh" +
          error
      );
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      setMessage("");
      document.getElementById("3toggle").click();
      scrollToBottom(successRef);
      bookingLatest();
    } else if (query.get("canceled")) {
      setMessage("Order canceled, checkout when you're ready");
      document.getElementById("2toggle").click();
      scrollToBottom(cancelRef);
    }
    getData();

    getEvents().then((res) => {
      setEvents(res.data);
    });
  }, []);

  useEffect(() => {
    if (!events) {
      return;
    }
    if (events.length > 1) {
      /* {
        id: createEventId(),
        title: 'Available',
        start: todayStr + 'T15:00:00',
        end: todayStr + 'T15:30:00'
      } */
      const getEventCalendar = events.map((event) => {
        return {
          id: event.eventId,
          title: event.summary,
          start: event.start.dateTime,
          end: event.end.dateTime,
        };
      });
      setCalendarEvents(getEventCalendar);
    }
  }, [events]);
  console.log(calendarEvents);

  const getEvents = async () => {
    try {
      return await axios({
        url: "http://localhost:8090/v2/calendar/events",
        method: "GET",
        headers: {
          reqknktID: knktId,
        },
      });
    } catch (err) {
      return { err };
    }
  };

  const postNewEventAPI = async (params) => {
    const body = {
      reqKnktId: knktId,
      hostKnktId: knktId,
      summary: params.title,
      location: "1927 Trenton St., Cranberry, PA 16066",
      description: "event description",
      start: {
        dateTime: params.start,
        timeZone: "America/Los_Angeles",
      },
      end: {
        dateTime: params.end,
        timeZone: "America/Los_Angeles",
      },
      attendees: [
        {
          email: email,
        },
      ],
    };
    try {
      return await axios({
        url: "http://localhost:8090/v2/calendar/events",
        method: "POST",
        data: body,
        headers: {
          email: email,
        },
      });
    } catch (err) {
      return { err };
    }
  };

  useEffect(() => {
    if (productData) {
      let result = [];
      let skus = [];
      let products;
      productData.map((prod) => {
        result = [...result, { accountId: prod.accountId }];
        products = prod.categories.reduce((cat) => cat.products);
      });
      products.map((prod, i) => {
        result[i]["skus"] = prod.skus;
        prod.skus.map((sku, j) => {
          sku["accountId"] = result[i].accountId;
          skus = [...skus, sku];
        });
      });

      console.log(result, skus);
      setTimePillsData(skus);
    }
  }, [productData]);

  useEffect(() => {
    console.log("accordian state", AccordionState);
  }, [AccordionState]);

  const onClickTimePillButton = (event, timePill) => {
    setMessage("");
    setCheckoutData(timePill);
    document.getElementById("1toggle").click();
  };

  const TimePills = () => {
    return timePillsData.map((timePill) => {
      return (
        <Button
          variant="outline-info"
          onClick={(event) => onClickTimePillButton(event, timePill)}
          key={timePill.skuId}
        >
          {timePill.skuName}
        </Button>
      );
    });
  };

  const YourInformation = () => {
    return (
      <>
        <Form>
          <br />
          <Row>
            <Col>
              <Form.Control placeholder="First name" defaultValue={firstName} />
            </Col>
            <Col>
              <Form.Control placeholder="Last name" defaultValue={lastName} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Control
                type="email"
                placeholder="Enter email"
                defaultValue={email}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Control as="select">
                <option>
                  What would you like to talk about during your call?
                </option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Add specific details/personal message here..."
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col style={{ textAlign: "left", fontSize: "1rem" }}>
              {[
                "Save Recording to Profile (Additional charges may apply)",
                "Terms & Conditions",
              ].map((type) => (
                <div key={`default-checkbox-${type}`} className="mb-3">
                  <Form.Check
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={`${type}`}
                  />
                </div>
              ))}
            </Col>
          </Row>
          <br />
        </Form>
      </>
    );
  };

  const getEventTitle = (eventTitle) => {
    if (eventTitle) {
      //document.getElementById('1toggle').click();
      console.log(eventTitle);
      postNewEventAPI(eventTitle);
    }
  };

  const getSelectedEventTimePill = (selectedEventTimePill) => {
    console.log(selectedEventTimePill);
    if (selectedEventTimePill) {
      alert(
        "selected Event Id: " +
          selectedEventTimePill.id +
          " From: " +
          selectedEventTimePill.startStr +
          " To: " +
          selectedEventTimePill.endStr
      );
      document.getElementById("1toggle").click();
    }
  };

  const onClickBookingTab = (event) => {
    const updatedTabs = bookingTabsTitle.map((tab) => {
      if (tab.title === event.target.value) tab.isActive = true;
      else tab.isActive = false;
      return tab;
    });
    setBookingTabsTitle(updatedTabs);
    setBookingTabSelected(event.currentTarget.value);
    // document.getElementById("2toggle").click();
  };

  const onTimeSlotPillsClick = () => {
    document.getElementById("2toggle") != null &&
      document.getElementById("2toggle").click();
  };

  console.log(styles.buttonGroup);
  return (
    <Accordion defaultActiveKey="0">
      <section className="profile">
        <div className="header" style={styles.header}></div>
        <div className="details" style={styles.details}>
          <img
            style={styles.profilePic}
            src="https://www.arizonafoothillsmagazine.com/aznightlife/wp-content/uploads/2012/07/Armin-3.jpeg"
            alt="Artist picture"
            className="profile-pic"
          />
          <h1 className="heading" style={styles.heading}>
            {profileName}
          </h1>
        </div>
      </section>
      <div style={styles.button}>
        <h4>Select Service</h4>
        <p></p>
      </div>
      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">1. Book Service</h5>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div style={{ marginBottom: "5vh" }}>
              <div style={styles.buttonGroup}>
                {bookingTabsTitle.map((bookingTabs) => (
                  <KnktButton
                    key={bookingTabs.title}
                    targetValue={bookingTabs.title}
                    value={
                      <span
                        style={{
                          display: "flex",
                          gap: "2px",
                          alignItems: "center",
                        }}
                      >
                        <i className={bookingTabs.icon}></i>
                        {bookingTabs.title}
                      </span>
                    }
                    type={bookingTabs.color}
                    isGrouped={true}
                    isActive={bookingTabs.isActive}
                    parentCallBack={onClickBookingTab}
                  />
                ))}
              </div>
            </div>
            <div>
              {timePillsData && timePillsData.length > 1 && (
                <div style={styles.buttonGroup}>
                  {" "}
                  <TimePills />
                </div>
              )}
            </div>
            {bookingTabSelected !== "Message" && calendarEvents.length > 1 && (
              <div className="booking-calender">
                <Calender
                  eventTitle={getEventTitle}
                  isUserArtist={account === "artist"}
                  selectedEventTimePill={getSelectedEventTimePill}
                  events={calendarEvents}
                />
              </div>
            )}
            <div style={styles.buttonGroup}>
              <CustomToggle eventKey="1" isHidden={true}>
                Next
              </CustomToggle>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">2. Pick Time </h5>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <div>
              <TimeSlotPills
                knktId={knktId}
                category={bookingTabSelected}
                onClick={onTimeSlotPillsClick}
              />
              <div style={styles.buttonGroup}>
                <CustomToggle eventKey="0" isHidden={false}>
                  Previous
                </CustomToggle>
                <CustomToggle eventKey="2" isHidden={true}>
                  Next
                </CustomToggle>
              </div>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">3. Your Information</h5>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <div>
              <YourInformation />
            </div>
            <div style={styles.buttonGroup}>
              <CustomToggle eventKey="1" isHidden={false}>
                Previous
              </CustomToggle>
              <CustomToggle eventKey="3" isHidden={false}>
                Next
              </CustomToggle>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">4. Payment Information</h5>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body ref={cancelRef}>
            <p className="card-text" style={{ color: "rgb(255 207 71)" }}>
              {message}
            </p>
            <CheckoutButton />
            <div style={styles.buttonGroup}>
              <CustomToggle eventKey="2" isHidden={false}>
                Previous
              </CustomToggle>
              <CustomToggle eventKey="4" isHidden={true}>
                Next
              </CustomToggle>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card style={styles.cardBg}>
        <Card.Header>
          <h5 className="card-title">5. Confirmation </h5>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            <h6 className="card-subtitle mb-2 text-muted">
              Your video consultation request has been submitted for:
            </h6>
            <img
              style={styles.profilePic}
              src="https://www.arizonafoothillsmagazine.com/aznightlife/wp-content/uploads/2012/07/Armin-3.jpeg"
              alt="John Doe"
              className="profile-pic"
            />
            <p className="card-text">Armin Van Buuren</p>
            <p className="card-text">Sunday, June 10, 2021</p>
            <p className="card-text">4:00 PM PST</p>
            <p className="card-text">
              <a href="#">Join the meeting here </a>
            </p>
            <div style={styles.buttonGroup} ref={successRef}>
              <Button variant="outline-primary">Cancel</Button>
              <Button variant="outline-primary">Reschedule</Button>
              <Button variant="outline-primary">Edit Forms</Button>
            </div>
            <div style={styles.buttonGroup}>
              <Button variant="outline-primary">Sync to iCal/Outlook</Button>
              <Button variant="outline-primary">Sync to Google Calender</Button>
            </div>
            <div style={styles.buttonGroup}>
              <CustomToggle eventKey="3" isHidden={false}>
                Previous
              </CustomToggle>
              <CustomToggle eventKey="0">Restart</CustomToggle>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Booking;
