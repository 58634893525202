import React, { useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Link } from 'react-router-dom';

import GoogleLoginButton from '../../common/GoogleLoginButton';
import FacebookLoginButton from  '../../common/FacebookLoginButton';
// import { signUpKeys } from '../../../utils/constants';
import SignUpRedirect from './SignUpRedirect';

const signUpKeys = [
  {key: 1, code: 'artist', value: 'Artist'},
  {key: 2, code: 'user', value: 'Fan/User'},
  {key: 3, code: 'promoter', value: 'Event Organizer'},
  {key: 4, code: 'label', value: 'Label'},
  {key: 5, code: 'agent', value: 'Agent'},
  {key: 6, code: 'media', value: 'Media'},
  {key: 7, code: 'venue', value: 'Venue'},
  {key: 8, code: 'publisher', value: 'Publisher'},
  {key: 9, code: 'artistManager', value: 'Artist Manager'}
];

const SignUp = props => {

  const {
    handleUpdateField, handleFormSubmit, isValid, isAllValid,
    submitFacebook, signUpError, submitGoogle, signUpPageMessage,
    clearConfirmationMessage
  } = props;

  const [ selectedAccountType, setSelectedAccountType ] = useState('');

  const styles = {
    loginLeft: {
      backgroundColor: '#1a1d24',
      height: '100vh',
      color: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    loginRight: {
      height: '100vh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alginItems: 'center',
      color: '#ffffff'
    },
    loginRightBackground: {
      background: "url(/img/background/loginBgObject.png) no-repeat",
      backgroundSize: '60%',
      backgroundPosition: 'center center',
      transform: 'rotate(-45deg)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1
    },
    loginRightContent: {
      width: '100%',
      zIndex: 100
    },
    logo: {
      marginTop: 30
    },
    signinText: {
      fontSize: '1.5em',
      marginTop: 50,
      fontWeight: 'bold'
    },
    formInputColumn: {
      marginTop: 10
    },
    formInput: {
      backgroundColor: 'transparent',
      color: '#ffffff'
    },
    forgotPassword: {
      display: 'flex',
      height: '100%',
      alignItems: 'center'
    },
    forgotPasswordText: {
      color: '#ffffff'
    },
    loginButton: {
      width: '100%'
    },
    loginRightTexts: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      fontSize: '1.5em'
    },
    errorMessage: {
      color: '#ff0000',
      textAlign: 'center',
      border: '1px solid #cccccc',
      borderRadius: 5,
      paddingTop: 10,
      paddingBottom: 10,
      marginTop: 10
    }
  };

  const handleGoogleResponse = response => {
    let tokenId = response.tokenId;
    if (tokenId) {
      submitGoogle({tokenId});
    }
  };

  const handleFBResponse = (data) => {
    // console.log('----handleFBResponse:', data);
    submitFacebook(data);
  };

  const handleFBError = (error) => {
    signUpError("Can't login at this time.");
  };

  const clearMessage = _ => {
    setTimeout(_ => { props.clearConfirmationMessage() }, 5000)
  };

  const updateAccountType = event => {
    setSelectedAccountType(event.target.value);
    handleUpdateField(event);
  }

  return (
    <Row>
      <Col xs={{ span: 8, offset: 2 }} style={{zIndex: 100}}>
        <Row>
          <Col xs={12}>
            <div style={styles.signinText}>Sign Up</div>
          </Col>
          <Col xs={12}  >
            <Form noValidate onSubmit={props.handleFormSubmit}>
              <Row>
                <Col xs={12} style={styles.formInputColumn}>
                  <FloatingLabel
                    label="Registration Code"
                  >
                    <Form.Control
                      placeholder="Registration Code"
                      id="registrationCode"
                      type="text"
                      style={styles.formInput}
                      onChange={props.handleUpdateField}
                      required
                      isInvalid={isValid('registrationCode')}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter your username (email)
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col xs={12} style={styles.formInputColumn}>
                  <Form.Select
                    id="accountType"
                    onChange={updateAccountType}
                    isInvalid={isValid('accountType')}
                  >
                    <option value=' '>Select Account Type</option>
                    {
                      signUpKeys.map(type => {
                        return <option value={type.code}>{type.value}</option>
                      })
                    }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please select an Account Type
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
          {
            selectedAccountType.trim().length > 0 && (
              <>
                <Row>
                  <Col
                    style={{
                      borderBottom: '1px solid white',
                      marginBottom: 8,
                      marginTop: 20
                    }}
                  />
                </Row>
                <Row>
                  <SignUpRedirect
                    selectedAccountType={selectedAccountType} 
                    handleFormSubmit={handleFormSubmit}
                    handleUpdateField={handleUpdateField} 
                    isValid={isValid}
                    isAllValid={isAllValid}
                    signUpPageMessage={signUpPageMessage}
                    clearConfirmationMessage={clearConfirmationMessage}
                  />
                </Row>
              </>
            )
          }

          { 
            !(selectedAccountType.trim().length > 0) && (
              <Row>
                <Col xs={12}>
                  <Row style={{margin: '20px 0'}}>
                    <Col style={{borderBottom: '1px solid white', marginBottom: 8}}></Col>
                    <Col xs="auto">or sign up with</Col>
                    <Col style={{borderBottom: '1px solid white', marginBottom: 8}}></Col>
                  </Row>
                </Col>
                <Col xs={{span: 6}} style={styles.formInputColumn}>
                  <GoogleLoginButton
                    handleGoogleResponse={handleGoogleResponse}
                    styles={styles}
                  />
                </Col>
                <Col xs={{span: 6}} style={styles.formInputColumn}>
                  <FacebookLoginButton
                    handleFBResponse={handleFBResponse}
                    handleFBError={handleFBError}
                    styles={styles}
                  />
                </Col>
              </Row>
            )
          }
          <Row>
          <Col xs={12}>
            <Row style={{ margin: '20px 0' }}>
              <Col
                style={{
                  borderBottom: '1px solid white',
                  marginBottom: 8,
                }}
              />
              <Col xs="auto">or</Col>
              <Col
                style={{
                  borderBottom: '1px solid white',
                  marginBottom: 8,
                }}
              />
            </Row>
          </Col>
          </Row>

          <Row>
          <Col xs={12} style={{...styles.formInputColumn, textAlign: 'center'}}>
            <Link to='/'>
              <Button
                variant="light"
              >
                Login
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

export default SignUp;