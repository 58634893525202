import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import {connect} from 'react-redux';
import WOW from "wow.js";
import { FaFacebookSquare, FaSpotify, FaPieChart, FaTwitter, FaSoundcloud, FaInstagram } from 'react-icons/lib/fa';

import { pickSocialSite, connectSocial, clearSocialErrorMessage } from '../actions/Social/socialAction';
import { loginTwitterAction, loginFailedAction } from '../actions/Login/loginAction';
import FacebookConnect from '../components/pages/social/FacebookConnect';
import TwitterConnect from '../components/pages/social/TwitterConnect';
import InstagramConnect from '../components/pages/social/InstagramConnect';

class SocialContainer extends Component {
  constructor(props) {
    super(props);

    this.selectSocialSite = this.selectSocialSite.bind(this);
  }

  componentDidMount() {
    const wow = new WOW();
    wow.init();

    var url = new URL(window.location.href);
    var code = url.searchParams.get("code");

    if (code) {
      this.props.connectSocial('instagram', {code});
    }
  }

  selectSocialSite(site) {
    this.props.pickSocialSite(site);
  }

  render() {
    return (
      <div>
        <Row>
          <Col
            xs={1}
            className="text-center"
            style={{fontSize: '2em'}}
          >
            <div style={{paddingTop: 50, paddingBottom: 50, paddingLeft: 5, paddingRight: 5}}>
              <div>
                <FaPieChart onClick={this.selectSocialSite.bind(this, 'main')} />
              </div>
              <div>
                <FaFacebookSquare onClick={this.selectSocialSite.bind(this, 'facebook')} style={{color: '#4267b2'}} />
              </div>
              <div>
                <FaTwitter onClick={this.selectSocialSite.bind(this, 'twitter')} style={{color: '#1da1f2'}} />
              </div>
              <div>
                <FaInstagram onClick={this.selectSocialSite.bind(this, 'instagram')} style={{color: '#1da1f2'}} />
              </div>
              <div>
                <FaSoundcloud onClick={this.selectSocialSite.bind(this, 'soundCloud')} />
              </div>
              <div>
                <FaSpotify onClick={this.selectSocialSite.bind(this, 'spotify')} style={{color: '#1ed760'}} />
              </div>
            </div>
          </Col>
          <Col xs={8} xsOffset={2} sm={6} smOffset={3} className="text-center">
            {this.props.socialSite === 'facebook' && <FacebookConnect {...this.props} />}
            {this.props.socialSite === 'twitter' && <TwitterConnect {...this.props} />}
            {this.props.socialSite === 'instagram' && <InstagramConnect {...this.props} />}
          </Col>
        </Row>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    socialSite: state.social.site,
    socialErrMessage: state.social.errMessage,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    pickSocialSite: (socialSite) => dispatch(pickSocialSite(socialSite)),
    connectSocial: (site ,data) => dispatch(connectSocial(site, data)),
    loginTwitterAction: (data) => dispatch(loginTwitterAction(data)),
    loginFailedAction: (message) => dispatch(loginFailedAction(message)),
    clearSocialErrorMessage: () => dispatch(clearSocialErrorMessage())
    // fetchSocialData: (socialSite) => dispatch(fetchSocialData(socialSite))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialContainer);