import protectedPages from './protectedPages';

const pageAfterLogin = '/dashboard';
const connectionPage = '/connection';
const pageAfterConnect = '/connection';
const pageAfterSignup = '/dashboard?postSignup=true';

const site = {
  apiUrlLocalhost: 'http://localhost:3001',
  apiUrl: 'https://api.knkt.io'
};

const googleAnalytics = {
  prod: 'UA-114180633-1'
};

const facebook = {
  loginEnabled: {
    local: true,
    production: true
  },
  fbId: {
    local: '2034345900180835',
    production: '2034345900180835'
  }
};

const instagram = {
  loginEnabled: {
    local: true,
    production: true
  },
  igClientId: {
    local: '1276577222536572',
    production: '1276577222536572'
  },
  redirect: {
    local: 'https://localhost/connectInstagram',
    production: 'https://knkt.io/connectInstagram'
  }
};

const twitter = {
  loginEnabled: {
    local: true,
    production: true
  },
  twitterId: {
    local: '166814233945152',
    production: '2034345900180835'
  }
};

const deezer = {
  loginEnabled: {
    local: true,
    production: true
  },
  deezerId: {
    connect: {
      local: '346804',
      production: '348884'
    },
    login: {
      local: '346824',
      production: '348864'
    }
  }
};

const google = {
  loginEnabled: {
    local: true,
    production: true
  },
  googleClientId: {
    local: '667326106144-ujojttu9tn73vuec3uuc3fkaemoe9kna.apps.googleusercontent.com',
    production: '717311028485-412ionpfp37v98o2f9hgsisff4199ttq.apps.googleusercontent.com'
  }
};

const soundCloud = {
  loginEnabled: {
    local: true,
    production: true
  },
  soundCloudId: {
    local: '166814233945152',
    production: '2034345900180835'
  }
};

const isProduction = () => {
  if (window.location.hostname.indexOf('localhost') > -1 || window.location.hostname.indexOf('mac-hors.aeo.ae.com') > -1) {
    return false;
  } else {
    return true;
  }
}

const getGoogleAnalytics = () => {
  if (!isProduction()) {
    return googleAnalytics.prod;
  }
  return googleAnalytics.prod;
};

const getApiUrl = () => {
  if (!isProduction()) {
    return site.apiUrlLocalhost;
  }
  return site.apiUrl;
};

const getFBAppId = () => {
  if (!isProduction()) {
    return facebook.fbId.local;
  }
  return facebook.fbId.production;
};

const getInstagramClientId = () => {
  if (!isProduction()) {
    return instagram.igClientId.local;
  }
  return instagram.igClientId.production;
};

const getInstagramRedirect = () => {
  if (!isProduction()) {
    return instagram.redirect.local;
  }
  return instagram.redirect.production;
};

const getTwitterAppId = () => {
  if (!isProduction()) {
    return twitter.twitterId.local;
  }
  return twitter.twitterId.production;
};

const getGoogleClientId = () => {
  if (!isProduction()) {
    return google.googleClientId.local;
  }
  return google.googleClientId.production;
};

const getDeezerConnectAppId = () => {
  if (!isProduction()) {
    return deezer.deezerId.connect.local;
  }
  return deezer.deezerId.connect.production;
};

const getDeezerLoginAppId = () => {
  if (!isProduction()) {
    return deezer.deezerId.login.local;
  }
  return deezer.deezerId.login.production;
};

const getFBLoginEnabled = () => {
  if (isProduction()) {
    return facebook.loginEnabled.production;
  }
  return facebook.loginEnabled.local;
};

const getTwitterLoginEnabled = () => {
  if (isProduction()) {
    return twitter.loginEnabled.production;
  }
  return twitter.loginEnabled.local;
};

const getDeezerLoginEnabled = () => {
  if (isProduction()) {
    return deezer.loginEnabled.production;
  }
  return deezer.loginEnabled.local;
};

const getGoogleLoginEnabled = () => {
  if (isProduction()) {
    return google.loginEnabled.production;
  }
  return google.loginEnabled.local;
};

const getSoundCloudLoginEnabled = () => {
  if (isProduction()) {
    return soundCloud.loginEnabled.production;
  }
  return soundCloud.loginEnabled.local;
};

const passwordLength = 8;

export {
  pageAfterLogin,
  connectionPage,
  pageAfterConnect,
  pageAfterSignup,

  site,
  getApiUrl,
  getGoogleAnalytics,
  getFBAppId,
  getFBLoginEnabled,

  getInstagramClientId,
  getInstagramRedirect,

  getTwitterAppId,
  getTwitterLoginEnabled,

  getGoogleClientId,
  getGoogleLoginEnabled,

  getSoundCloudLoginEnabled,

  getDeezerLoginEnabled,
  getDeezerConnectAppId,
  getDeezerLoginAppId,

  protectedPages,

  passwordLength
};