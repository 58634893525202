const signUpKeys = [
  {key: 1, code: 'artist', value: 'Artist'},
  {key: 2, code: 'user', value: 'Fan/User'},
  {key: 3, code: 'organizer', value: 'Event Organizer'},
  {key: 4, code: 'label', value: 'Label'}
];

const genre = [
  '140/Deep Dubstep / Grime', 'Afro House', 'Amapiano', 'Bass / Club', 'Bass House',
  'Breaks / Breakbeat / UK Bass', 'Dance/Electro Pop', 'Deep House', 'DJ Tools',
  'Drum & Bass', 'Dubstep', 'Electro (Classic / Detroid / Modern)', 'Electronica', 
  'Funky House', 'Hard Dance / Hardcore / Neo Rave', 'Hard Techno', 'House', 'Indie Dance', 
  'Jackin House', 'Mainstage', 'Melodic House & Techno', 'Minimal / Deep Tech', 
  'Nu Disco / Disco', 'Organic House / Downtempo', 'Progressive House', 'Psy-Trance', 'Tech House', 
  'Techno (Peak Time/ Driving)', 'Techno (Raw / Deep / Hypnotic)', 'Trance (Main Floor)', 
  'Trance (Raw / Deep / Hypnotic)', 'Trap / Wave', 'UK Garage / Bassline'
];

const goals = [
  'Fan Acquisition and Engagement', 'Perform and Earn More', 'Build Connections', 'Improve Specific Skills'
];

const states = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AS': 'American Samoa',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District of Columbia',
  'FM': 'Federated States of Micronesia',
  'FL': 'Florida',
  'GA': 'Georgia',
  'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MH': 'Marshall Islands',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'MP': 'Northern Mariana Islands',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PW': 'Palau',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VI': 'Virgin Islands',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming',
  'AA': 'Armed Forces Americas',
  'AEA': 'Armed Forces Africa',
  'AEC': 'Armed Forces Canada',
  'AEE': 'Armed Forces Europe',
  'AEM': 'Armed Forces Middle East',
  'AP': 'Armed Forces Pacific'
}

const accountTypes = {
  'artist': 'Artist',
  'user': 'Fan (User)',
  'organizer': 'Event Organizer'
};

const minimum_password_length = 6;
const CANNOT_BE_EMPTY = 'Can NOT be empty';
const MINIMUM_6_CHARACTERS = 'Minimum ' + minimum_password_length + ' characters';
const PASSWORD_DO_NOT_MATCH = 'Password Do Not Match';
const ENTER_VALID_EMAIL = 'Enter a valid email';
const SOCIAL_TYPES = {
  facabook: 'Facebook',
  twitter: 'Twitter',
  soundCloud: 'SoundCloud',
  youtube: 'YouTube',
  instagram: 'Instagram',
  spotify: 'Spotify'
};

const MENU_ITEMS = [
  {
    index: 1,
    title: 'Dashboard'
  },
  {
    index: 2,
    title: 'Advertising'
  },
  {
    index: 3,
    title: 'Marketing'
  },
  {
    index: 4,
    title: 'Social Media',
    subMenu: [
      {
        index: 1,
        title: 'Facebook'
      },
      {
        index: 2,
        title: 'Twitter'
      },
      {
        index: 3,
        title: 'Instagram'
      },
      {
        index: 4,
        title: 'KNKT'
      }
    ]
  },
  {
    index: 5,
    title: 'Streaming',
    subMenu: [
      {
        index: 1,
        title: 'Sportify'
      },
      {
        index: 2,
        title: 'SoundCloud'
      },
      {
        index: 3,
        title: 'YouTube'
      },
      {
        index: 4,
        title: 'iTunes'
      }
    ]
  }
];

const MONTH_STRING = [
  '', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
];

const userTypePerPage = {
  bookinghome: [
    'artist', 'fan', 'promoter', 'label', 'agent', 'media', 'venue', 'publisher', 'artistManager'
  ],
  socialInsight: [
    'artist', 'promoter', 'label', 'venue', 'artistManager'
  ],
  insightx: [
    'artist', 'label', 'artistManager'
  ],
  promote: [
    'artist', 'promoter', 'label', 'agent', 'media', 'venue', 'publisher', 'artistManager'
  ],
  score: [
    'artist', 'label', 'artistManager'
  ],
  catalog: [
    'artist', 'promoter', 'label', 'venue', 'artistManager'
  ],
  knktcalendar: [
    'artist', 'fan', 'promoter', 'label', 'agent', 'media', 'venue', 'publisher', 'artistManager'
  ],
  wallet: [
    'artist', 'promoter', 'label', 'agent', 'media', 'venue', 'publisher', 'artistManager'
  ]
}

module.exports = {
  signUpKeys,
  genre,
  goals,
  states,
  accountTypes,

  minimum_password_length,
  CANNOT_BE_EMPTY,
  MINIMUM_6_CHARACTERS,
  PASSWORD_DO_NOT_MATCH,
  ENTER_VALID_EMAIL,

  SOCIAL_TYPES,

  MENU_ITEMS,

  MONTH_STRING,

  userTypePerPage
}