import axios from 'axios';

import { getApiUrl } from '../../config/siteConfig';
import history from '../../redux/manageHistory';
import { connectionPage } from '../../config/siteConfig';

const JUST_RETURN = 'JUST_RETURN';
const PICK_SOCIAL_SITE = 'PICK_SOCIAL_SITE';
const FETCH_SOCIAL_DATA = 'FETCH_SOCIAL_DATA';
const UPDATE_FACEBOOK = 'UPDATE_FACEBOOK';
const UPDATE_INSTAGRAM = 'UPDATE_INSTAGRAM';
const UPDATE_DEEZER = 'UPDATE_DEEZER';
const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
const ADD_SOCIAL_ERROR_MESSAGE = 'ADD_SOCIAL_ERROR_MESSAGE';
const CONNECTION_PAGE = connectionPage;

function pickSocialSite(data, errMessage) {
  return function(dispatch) {
    dispatch({
      type: PICK_SOCIAL_SITE,
      site: data,
      errMessage: errMessage || ''
    });
  }
}

function clearSocialErrorMessage() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_ERROR_MESSAGE
    });

    history.push(CONNECTION_PAGE);
  }
}

function connectSocial(site, data) {
  let dispatchType = UPDATE_FACEBOOK;
  if (site === 'deezer') {
    dispatchType = UPDATE_DEEZER;
  }

  return function(dispatch) {
    // console.log('---calling getSocialFetchUrl: ',getSocialFetchUrl('v1', site));
    return axios({
      method: 'post',
      url: getSocialFetchUrl('v1', site),
      data: {
        data
      },
      headers: {
        'token': window.localStorage.getItem('token')
      }
    }).then(res => {
      if (res && res.data && res.data.status === 'successful') {
        if (res.data.token) {
          window.localStorage.setItem('token', res.data.token);
        }
        
        dispatch({
          type: dispatchType,
          site: site,
          data: res.data.user
        });

        if (site === 'deezer') {
          history.push(CONNECTION_PAGE);
        }
      } else {
        dispatch({
          type: dispatchType,
          site: site,
          errMessage: res.data.message
        });
      }
    }).catch(err => {
      // console.log(err.response.data || err);
      // let justReturn = true;
      if (err.response.status === 401) {
        const response = err.response;

        dispatch({
          type: ADD_SOCIAL_ERROR_MESSAGE,
          site: site,
          errMessage: response.data.message
        });
      }
    });
  }
}

function addSocialError(site, errMessage) {
  return function (dispatch) {
    dispatch({
      type: ADD_SOCIAL_ERROR_MESSAGE,
      site: site,
      errMessage: errMessage
    });
  }
}

function getSocialFetchUrl(version, site) {
  return `${getApiUrl()}/api/${version}/${site}/connect`;
}

export {
  JUST_RETURN,
  PICK_SOCIAL_SITE,
  FETCH_SOCIAL_DATA,
  UPDATE_FACEBOOK,
  UPDATE_INSTAGRAM,
  UPDATE_DEEZER,
  CLEAR_ERROR_MESSAGE,
  ADD_SOCIAL_ERROR_MESSAGE,

  pickSocialSite,
  connectSocial,
  clearSocialErrorMessage,
  addSocialError
};