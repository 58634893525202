import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import KnktLogo from '../components/common/KnktLogo';
import SignUp from '../components/pages/signUp/SignUp';

import { validateEmail } from '../utils/utils';
import { passwordLength } from '../config/siteConfig';

import {
  loginFacebookAction,
  loginFailedAction,
  loginGoogleAction,
} from '../actions/Login/loginAction';

import {
  signUpUser,
  updateSelectedTab,
  updateField,
  updateErrorField,
  clearConfirmationMessage,
  signUpWithFacebook,
  signUpError,
  signUpWithGoogle,
} from '../actions/SignUp/signUpActions';

const styles = {
  loginLeft: {
    backgroundColor: '#1a1d24',
    height: '100vh',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loginRight: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alginItems: 'center',
    color: '#ffffff',
  },
  loginRightBackground: {
    backgroundSize: '60%',
    backgroundPosition: 'center center',
    transform: 'rotate(-45deg)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  loginRightContent: {
    width: '100%',
    zIndex: 100,
  },
  logo: {
    marginTop: 30,
  },
  signinText: {
    fontSize: '1.5em',
    marginTop: 50,
    fontWeight: 'bold',
  },
  formInputColumn: {
    marginTop: 10,
  },
  formInput: {
    backgroundColor: 'transparent',
    color: '#ffffff',
  },
  forgotPassword: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  forgotPasswordText: {
    color: '#ffffff',
  },
  loginButton: {
    width: '100%',
  },
  loginRightTexts: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: '1.5em',
  },
  errorMessage: {
    textAlign: 'center',
  },
};

class SignUpContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideRight: false,
      extraLarge: false,
      
      accountType: null,
      registrationCode: null,
      username: null,
      password: null,
      artistName: null,
      firstName: null,
      lastName: null,
      location: null,
      foundingYear: 0,
      birthday: null,
      companyName: null,
      labelName: null,
      agencyName: null,
      venueName: null,
      publisherName: null
    };

    this.handleUpdateField = this.handleUpdateField.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.isValid = this.isValid.bind(this);
    this.isAllValid = this.isAllValid.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.submitFacebook = this.submitFacebook.bind(this);
    this.submitGoogle = this.submitGoogle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ hideRight: window.innerWidth <= 768 });
    this.setState({ extraLarge: window.innerWidth >= 1200 });
  }

  handleUpdateField(event) {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const {
      username,
      password,
      firstName,
      lastName,
      accountType,
      registrationCode,
      artistName,
      location,
      foundingYear,
      birthday,
      companyName,
      labelName,
      agencyName,
      venueName,
      publisherName
    } = this.state;
    let formIsGood = this.isAllValid();

    if (formIsGood) {
      console.log(
        username,
        password,
        firstName,
        lastName,
        accountType,
        registrationCode,
        artistName,
        location,
        foundingYear,
        birthday,
        companyName,
        labelName,
        agencyName,
        venueName,
        publisherName
      );
      this.props.signUpUser({
        username,
        password,
        firstName,
        lastName,
        accountType,
        registrationCode,
        artistName,
        location,
        foundingYear,
        birthday,
        companyName,
        labelName,
        agencyName,
        venueName,
        publisherName
      });
    }
  }

  handleRequestClose() {
    this.props.clearConfirmationMessage();
  }

  isValid(type) {
    const {
      username,
      password,
      firstName,
      lastName,
      accountType,
      registrationCode,
      companyName,
      labelName,
      agencyName,
      venueName,
      publisherName
    } = this.state;
    switch (type) {
      case 'username': {
        return (
          username !== null &&
          username.length < 1 &&
          !validateEmail(username)
        );
      }
      case 'password': {
        return password !== null && password.length < passwordLength;
      }
      case 'firstName': {
        return firstName !== null && firstName.length < 1;
      }
      case 'lastName': {
        return lastName !== null && lastName.length < 1;
      }
      case 'accountType': {
        return accountType !== null && accountType.length < 1;
      }
      case 'registrationCode': {
        return registrationCode !== null && registrationCode.length < 1;
      }
      case 'companyName': {
        return companyName !== null && companyName.length < 1;
      }
      case 'labelName': {
        return labelName !== null && labelName.length < 1;
      }
      case 'agencyName': {
        return agencyName !== null && agencyName.length < 1;
      }
      case 'venueName': {
        return venueName !== null && venueName.length < 1;
      }
      case 'publisherName': {
        return publisherName !== null && publisherName.length < 1;
      }
    }
  }

  isAllValid() {
    const {
      username,
      password,
      firstName,
      lastName,
      accountType,
      registrationCode,
    } = this.state;
    return (
      username != null &&
      username.length > 1 &&
      validateEmail(username) &&
      password != null &&
      password.length >= passwordLength &&
      firstName != null &&
      firstName.length > 1 &&
      lastName != null &&
      lastName.length > 1 &&
      accountType != null &&
      accountType.length > 1 &&
      registrationCode != null &&
      registrationCode.length > 1
    );
  }

  submitFacebook(data) {
    const { registrationCode } = this.state;
    const submit = data;
    submit.registrationCode = registrationCode;
    this.props.signUpWithFacebook(submit);
  }

  submitGoogle(tokenId) {
    const { registrationCode } = this.state;
    const submit = {};
    submit.googleData = {};
    submit.googleData = tokenId;
    submit.registrationCode = registrationCode;
    this.props.signUpWithGoogle(submit);
  }

  render() {
    const { hideRight, extraLarge } = this.state;

    return (
      <Row nogutters="true">
        <Col xs={12} md={5} style={styles.loginLeft}>
          <Row>
            <Col xs={{ span: 2 }}>
              <KnktLogo
                imgStyle={{ width: 80 }}
                divStyle={{ marginLeft: 50 }}
              />
            </Col>
          </Row>
          <SignUp
            handleUpdateField={this.handleUpdateField}
            handleFormSubmit={this.handleFormSubmit}
            isValid={this.isValid}
            isAllValid={this.isAllValid}
            submitFacebook={this.submitFacebook}
            signUpError={this.props.signUpError}
            submitGoogle={this.submitGoogle}
            signUpPageMessage={this.props.signUpPage}
            clearConfirmationMessage={
              this.props.clearConfirmationMessage
            }
          />
        </Col>
        

        {!hideRight && (
          <Col md={7} style={styles.loginRight}>
            <div style={styles.loginRightBackground}></div>
            <div style={styles.loginRightContent}>
              <Row nogutters="true">
                <Col md={12} xl={6}>
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '10.0em',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    KNKT
                  </div>
                </Col>
                <Col md={12} xl={6} style={styles.loginRightTexts}>
                  <div
                    style={{
                      textAlign: extraLarge ? 'left' : 'center',
                    }}
                  >
                    <div>Every Fan.</div>
                    <div>Every Performance.</div>
                    <div>Every Data Point.</div>
                    <div>Everything Music.</div>
                    <div>
                      <img
                        src="/img/background/ctaButton.png"
                        style={{ width: '100%', marginTop: 20 }}
                        alt="CTAbutton"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    brand: state.site.brand,
    user: state.user,
    signUpPage: state.signUpPage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signUpUser: (formData, selectedTab) =>
      dispatch(signUpUser(formData, selectedTab)),
    updateSelectedTab: (selectedTab) =>
      dispatch(updateSelectedTab(selectedTab)),
    updateField: (event) => dispatch(updateField(event)),
    updateErrorField: (event) => dispatch(updateErrorField(event)),
    clearConfirmationMessage: () =>
      dispatch(clearConfirmationMessage()),
    signUpWithFacebook: (data, selectedTab) =>
      dispatch(signUpWithFacebook(data)),
    signUpError: (message) => dispatch(signUpError(message)),
    loginFacebookAction: (data) =>
      dispatch(loginFacebookAction(data)),
    loginFailedAction: (message) =>
      dispatch(loginFailedAction(message)),
    signUpWithGoogle: (data) => dispatch(signUpWithGoogle(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpContainer);
