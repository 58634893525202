import React from 'react';
import {Link} from 'react-router-dom';

const Footer = props => {
  const getYear = _ => {
    let year = (new Date()).getFullYear();
    // if (year <= 2017) {
    //   return 2017;
    // }
    return year;
  };

  const styles = {
    footer: {
      textAlign: 'center',
      color: '#ffffff',
      fontSize: '0.9rem',
      display: 'flex',
      gap: '1rem',
    },
    privacy_terms: {
      textDecoration: 'none',
      color: '#ffffff',
      marginLeft: 20
    }
  };

  return (
    <div>
      <div style={styles.footer}>
       <div> &copy; { getYear() } KNKT</div>
       <div>
          <Link style={styles.privacy_terms} to="/privacy">Privacy</Link> &nbsp;
          <Link style={styles.privacy_terms} to="/terms">Terms</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;