import React, { useState } from 'react';
import { Button, Modal, Input, DatePicker, Checkbox } from 'antd';

import './styles.css';
import { isValidUrl } from '../../../utils/utils';

const AddEventModal = ({ isOpen, handleSave, handleCancel }) => {
  const [formData, setFormData] = useState({
    eventDate: '',
    venueName: '',
    location: '',
    ticketLink: '',
    isSoldOut: false,
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (field, value) => {
    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSaveEvent = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors({});
    handleSave(formData);
    setFormData({});
  };

  const validateForm = () => {
    const { eventDate, venueName, location, ticketLink } = formData;
    const validationErrors = {};

    if (!eventDate) validationErrors.eventDate = 'Event date is required.';
    if (!venueName) validationErrors.venueName = 'Venue name is required.';
    if (!location) validationErrors.location = 'Location is required.';
    if (!ticketLink) {
      validationErrors.ticketLink = 'Ticket Link is required.';
    } else if (!isValidUrl(ticketLink)) {
      validationErrors.ticketLink = 'Please enter a valid ticket link.';
    }

    return validationErrors;
  };

  return (
    <Modal
      open={isOpen}
      title='Add New Link'
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' onClick={handleSaveEvent}>
          Done
        </Button>
      ]}
    >
      <label htmlFor='event-date-input'>Event Date</label>
      <DatePicker
        size='large'
        className={`event-date-input ${errors.eventDate ? 'input-error' : ''}`}
        id='event-date-input'
        value={formData.eventDate}
        placeholder='Select the date of your event'
        onChange={(date) => handleInputChange('eventDate', date)}
      />
      {errors.eventDate && <p className='error-message'>{errors.eventDate}</p>}

      <label htmlFor='venue-name-input'>Venue Name</label>
      <Input
        className={`input ${errors.venueName ? 'input-error' : ''}`}
        id='venue-name-input'
        placeholder='Enter the venue name'
        value={formData.venueName}
        size='large'
        onChange={(e) => handleInputChange('venueName', e.target.value)}
      />
      {errors.venueName && <p className='error-message'>{errors.venueName}</p>}

      <label htmlFor='location-input'>Location</label>
      <Input
        className={`input ${errors.location ? 'input-error' : ''}`}
        id='location-input'
        placeholder='Enter the location of your event'
        value={formData.location}
        size='large'
        onChange={(e) => handleInputChange('location', e.target.value)}
      />
      {errors.location && <p className='error-message'>{errors.location}</p>}

      <label htmlFor='ticket-link-input'>URL</label>
      <Input
        className={`input ${errors.ticketLink ? 'input-error' : ''}`}
        id='ticket-link-input'
        placeholder='https://example.com'
        value={formData.ticketLink}
        size='large'
        onChange={(e) => {
          const updatedLink = e.target.value.startsWith('https://') ? e.target.value : `https://${e.target.value}`;
          handleInputChange('ticketLink', updatedLink);
        }}
      />
      {errors.ticketLink && <p className='error-message'>{errors.ticketLink}</p>}

      <Checkbox onChange={(e) => handleInputChange('isSoldOut', e.target.checked)}>
          Mark the event as sold out
        </Checkbox>
    </Modal>
  )
}

export default AddEventModal;
