import axios from 'axios';

import history from '../../redux/manageHistory';
import { getApiUrl } from '../../config/siteConfig';
import { isProtectedPage } from '../../utils/utils';
import { pageAfterLogin } from '../../config/siteConfig';

const USER_LOGIN = 'USER_LOGIN';
const UPDATE_ERROR_FIELD = 'UPDATE_ERROR_FIELD';
const UPDATE_FIELD = 'UPDATE_FIELD';
const LOGIN_CONFIRMATION = 'LOGIN_CONFIRMATION';
const LOGIN_FAILED = 'LOGIN_FAILED';
const CONNECT_FAILED = 'CONNECT_FAILED';
const UPDATE_PASSCODE = 'UPDATE_PASSCODE';
const SHOW_CONNECT_OPTIONS = 'SHOW_CONNECT_OPTIONS';
const CLEAR_SHOW_CONNECT_OPTIONS = 'CLEAR_SHOW_CONNECT_OPTIONS';
const SHOW_SOUNDCLOUD_MODAL = 'SHOW_SHOW_SOUNDCLOUD_MODAL';
const CLEAR_SHOW_SOUNDCLOUD_MODAL = 'CLEAR_SHOW_SOUNDCLOUD_MODAL';


const REDIRECT_AFTER_LOGIN = pageAfterLogin;

function initData(dataType, data) {
  return {
    type: 'INIT_DATA',
    dataType,
    data
  }
}

function logoutUser() {
  return {
    type: 'USER_LOGOUT'
  }
}

function logoutUserAction() {
  return function (dispatch) {
    axios({
      url: getApiUrl() + '/api/v1/logout',
      method: 'get',
      headers: {
        token: window.localStorage.getItem('token') || ''
      }
    }).then((res) => {
      if (res.data.logout) {
        window.localStorage.setItem('token', '');
        dispatch(logoutUser());
      } else {
        console.log('logout failed');
      }
      history.push('/');
    }).catch(err => {
      console.log('logout error: ', err);
    })
  }
}

const verifyToken = (validateOnly) => {
  return function (dispatch) {
    axios({
      url: getApiUrl() + '/api/v1/profile',
      method: 'get',
      headers: {
        token: window.localStorage.getItem('token') || ''
      }
    }).then((res) => {
      let data = res.data;

      if (validateOnly) {
        if (!(data && data.profile && data.profile.knktId)) {
          dispatch(logoutUser());
          return;
        }
      }

      if (data && data.profile && data.profile.knktId) {
        console.log('---redirecting......:', data.profile);

        dispatch(loginUser(null, data.profile, null, null));
      } else {
        dispatch(logoutUser());
      }
    }).catch((err) => {
      console.log('token error: ', err);
    })
  }
};

function loginUser(err, user, brand, token) {
  return function(dispatch) {
    if (token) {
      dispatch(getUserProfile(token));
    }
    dispatch(returnLoginUser(err, user, brand));
  }
}

function returnLoginUser(err, user, brand) {
  return {
    type: 'USER_LOGIN',
    user,
    brand,
    err
  }
}

function loginTwitterAction(data) {
  return function(dispatch) {
    if (data.login === true || data.status === 'successful') {
      dispatch(loginUser(null, data.user, null, data.token));

      window.localStorage.setItem('token', data.token);
      history.push(REDIRECT_AFTER_LOGIN);
    } else {
      dispatch({
        type: LOGIN_CONFIRMATION,
        value: (data.message) || LOGIN_FAILED
      });
    }
  }
}

function loginInstagramAction(code) {
  return function (dispatch) {
    axios.post(getApiUrl() + '/api/v1/login/instagram', {
      code
    }).then((res) => {
      let data = res.data;

      if (data.login === true || data.status === 'successful') {
        dispatch(loginUser(null, data.user, null, data.token));

        window.localStorage.setItem('token', data.token);
        history.push(REDIRECT_AFTER_LOGIN);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: (data && data.message) || LOGIN_FAILED
        });
      }
    }).catch((err) => {
      if (err && err.response && err.response.status === 401) {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: err.response.data.message || LOGIN_FAILED
        });
      }
    })
  }
}

function connectFacebookAction(data) {
  return function (dispatch) {
    const token = window.localStorage.getItem('token');

    axios({
      url: getApiUrl() + '/api/v1/facebook/connect',
      method: 'post',
      headers: {
        'token': token
      },
      data: data
    }).then((res) => {
      let data = res.data;

      if (data.status === 'successful') {
        dispatch(loginUser(null, data.user, null, data.token));

        window.localStorage.setItem('token', data.token);
        window.localStorage.removeItem('facebook.reconnect');
      } else if (data.status === 'partial') {
        dispatch({
          type: SHOW_CONNECT_OPTIONS,
          value: data.data.pages
        });
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: (res.data && res.data.message) || CONNECT_FAILED
        });
      }
    }).catch((err) => {
      console.error('-------err:', err);
      dispatch({
        type: LOGIN_CONFIRMATION,
        value: CONNECT_FAILED
      });
    })
  }
}

function connectFacebookOptionAction(id) {
  return function (dispatch) {
    const token = window.localStorage.getItem('token');

    axios({
      url: getApiUrl() + '/api/v1/facebook/connect/'+id,
      method: 'post',
      headers: {
        'token': token
      }
    }).then((res) => {
      let data = res.data;

      if (data.status === 'successful') {
        dispatch({
          type: CLEAR_SHOW_CONNECT_OPTIONS
        });

        dispatch(loginUser(null, data.user, null, data.token));

        window.localStorage.setItem('token', data.token);
      }
    }).catch((err) => {
      dispatch({
        type: LOGIN_CONFIRMATION,
        value: CONNECT_FAILED
      });
    })
  }
}

function clearShowConnectOptions() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_SHOW_CONNECT_OPTIONS
    });
  }
}

function clearShowSoundCloudModal() {
  return function (dispatch) {
    dispatch({
      type: CLEAR_SHOW_SOUNDCLOUD_MODAL
    })
  }
}

function loginFacebookAction(data) {
  return function (dispatch) {
    axios.post(getApiUrl() + '/api/v1/login/facebook', {
      data
    }).then((res) => {
      let data = res.data;
      if (data.login === true || data.status === 'successful') {
        dispatch(loginUser(null, data.user, null, data.token));

        window.localStorage.setItem('token', data.token);
        history.push(REDIRECT_AFTER_LOGIN);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: (res.data && res.data.message) || LOGIN_FAILED
        });
      }
    }).catch((err) => {
      dispatch({
        type: LOGIN_CONFIRMATION,
        value: 'Login failed!'
      });
    })
  }
}

function loginDeezerAction(data) {
  return function (dispatch) {
    axios.post(getApiUrl() + '/api/v1/deezer/login', {
      data
    }).then((res) => {
      let data = res.data;
      if (data.login === true || data.status === 'successful') {
        dispatch(loginUser(null, data.user, null, data.token));

        window.localStorage.setItem('token', data.token);
        history.push(REDIRECT_AFTER_LOGIN);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: (res.data && res.data.message) || LOGIN_FAILED
        });
      }
    }).catch((err) => {
      if (err && err.res && err.res.status === 401) {
        dispatch(loginUser({errorMessage: 'Login failed!'}))
      }
    })
  }
}

function loginGoogleAction(googleData) {
  return function (dispatch) {
    axios.post(getApiUrl() + '/api/v1/login/google', {
      tokenId: googleData.tokenId
    }).then((res) => {
      let data = res.data;
      if (data.login === true || data.status === 'successful') {
        dispatch(loginUser(null, data.user, null, data.token));

        window.localStorage.setItem('token', data.token);
        history.push(REDIRECT_AFTER_LOGIN);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: (res.data && res.data.message) || LOGIN_FAILED
        });
      }
    }).catch((err) => {
      if (err && err.res && err.res.status === 401) {
        dispatch(loginUser({errorMessage: 'Login failed!'}))
      }
    })
  }
}

function loginFailedAction(message) {
  return function (dispatch) {
    dispatch({
      type: LOGIN_CONFIRMATION,
      value: message || LOGIN_FAILED
    });
  }
}

function loginUserAction(username, password) {
  // console.log('-----username:', username);
  // console.log('-----password:', password);
  return function (dispatch) {
    username = username.toLowerCase();
    axios.post(getApiUrl() + '/api/v1/login', {
      username,
      password
    }).then((res) => {
      let data = res.data;
      // console.log('----data:', data);
      if (data.login === true) {
        window.localStorage.setItem('token', data.token);
        dispatch(loginUser(null, data.user, null, data.token));
        console.log("----redirecting to ---", REDIRECT_AFTER_LOGIN);
        history.push(REDIRECT_AFTER_LOGIN);
      } else {
        dispatch({
          type: LOGIN_CONFIRMATION,
          value: (res.data && res.data.message) || LOGIN_FAILED
        });
      }
    }).catch((err) => {
      // console.error('----err:', err);
      dispatch({
        type: LOGIN_CONFIRMATION,
        value: 'Login failed!'
      });
    })
  }
}

function clearConfirmationMessage() {
  return {
    type: LOGIN_CONFIRMATION,
    value: ''
  }
}

function clearLoginErrorMessage(name, email) {
  return function (dispatch) {
    let user = {};
    user.firstName = name;
    user.email = email;
    dispatch(loginUser(null, user, null, null))
  }
}

function getUserProfile(token) {
  return function (dispatch) {
    axios({
      url: getApiUrl() + '/api/v1/profile',
      method: 'get',
      headers: {
        token: token,
        passcodeToken: window.localStorage.getItem('passcodeToken') || ''
      }
    })
      .then((res) => {
        if (res.data.profile && res.data.profile.knktId) {
          dispatch(loginUser(null, res.data.profile, res.data.brand, null));
        } else {
          let returnTo = window.location.pathname || '/login';
          returnTo = (isProtectedPage(returnTo)) ? '/login' : returnTo;
          history.push(returnTo);
        }

        dispatch(updateBrand(res.data.brand || false));
      })
  }
}

function updateBrand(brand) {
  return {
    type: 'UPDATE_BRAND',
    brand
  }
}

function updateToken(token) {
  return {
    type: 'UPDATE_TOKEN',
    token
  }
}


function updateField(field, value) {
  // console.log('--updateField-field:', field);
  // console.log('--updateField-value:', value);
  return {
    type: UPDATE_FIELD,
    field,
    value
  }
}

function updateErrorField(field, value) {
  // console.log('--updateErrorField-field:', field);
  // console.log('--updateErrorField-value:', value);
  return {
    type: UPDATE_ERROR_FIELD,
    field,
    value
  }
}

function updatePasscode(passcode) {
  return {
    type: UPDATE_PASSCODE,
    passcode
  }
}

function updatePasscodeAction(passcode) {
  // console.log('----pathname-update: ',window.location.pathname);
  const returnTo = window.location.pathname || REDIRECT_AFTER_LOGIN;
  return function (dispatch) {
    axios.get(getApiUrl() + '/api/v1/passcode', {
      params: {
        passcode
      }
    }).then(function(res) {
      let data = res.data;

      dispatch(updatePasscode(data.passcode));

      if (data.passcode && data.passcode === true) {
        window.localStorage.setItem('passcodeToken', data.passcodeToken || '');
        history.push(returnTo);
      }
    }).catch((err) => {
      console.log('passcode error: ', err);
    })
  }
}

function verifyPasscodeAction() {
  const returnTo = window.location.pathname || REDIRECT_AFTER_LOGIN;
  return function (dispatch) {
    axios({
      url: getApiUrl() + '/api/v1/verifyPasscode',
      method: 'get',
      headers: {
        token: window.localStorage.getItem('token') || '',
        passcodeToken: window.localStorage.getItem('passcodeToken') || ''
      }
    }).then((res) => {
      let data = res.data;

      dispatch(updatePasscode(data.passcode));
      if (data.passcode && data.passcode === true) {
        window.localStorage.setItem('passcodeToken', data.passcodeToken || '');
        history.push(returnTo);
      } else {
        window.localStorage.removeItem('passcodeToken');
      }
    }).catch((err) => {
      console.log('passcode error: ', err);
    })
  }
}


export {
  USER_LOGIN,
  UPDATE_ERROR_FIELD,
  UPDATE_FIELD,
  LOGIN_CONFIRMATION,
  LOGIN_FAILED,
  UPDATE_PASSCODE,
  SHOW_CONNECT_OPTIONS,
  CLEAR_SHOW_CONNECT_OPTIONS,
  SHOW_SOUNDCLOUD_MODAL,
  CLEAR_SHOW_SOUNDCLOUD_MODAL,

  initData,
  logoutUserAction,
  loginUserAction,
  loginFacebookAction,
  loginFailedAction,
  loginTwitterAction,
  loginGoogleAction,
  loginInstagramAction,
  loginUser,

  verifyToken,

  connectFacebookAction,
  connectFacebookOptionAction,

  clearLoginErrorMessage,
  getUserProfile,
  updateToken,
  updateField,
  updateErrorField,
  clearConfirmationMessage,
  updatePasscode,
  updatePasscodeAction,
  verifyPasscodeAction,

  loginDeezerAction,

  clearShowConnectOptions,
  clearShowSoundCloudModal
};