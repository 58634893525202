import React, { useState, useEffect } from 'react';
import { Pulse } from "react-awesome-reveal";

import { getDateForTopEngagement } from '../../../../utils/calendar';
import { getChangeValues, formatText } from '../../../../utils/utils';

const TopEngagements = props => {
  const [ selectedTabApiKey, setSelectedTabApiKey ] = useState('');
  const [ topEngagement, setTopEngagement ] = useState({});

  const baseStyles = {
    topEngagementParent: {
      marginTop: 5,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      paddingBottom: 5
    },
    topEngagementCell: {
      flexGrow: 0
    }
  };
  let styles = {};

  const setStyle = style => {
    styles = style;
  };

  useEffect(() => {
    if (props.topEngagement) {
      // console.log('-------props.topEngagement:', props.topEngagement);
      // console.log('-------selectedTabApiKey:', selectedTabApiKey);
      setTopEngagement(props.topEngagement);
    }
  }, [props.topEngagement]);

  useEffect(() => {
    setSelectedTabApiKey(props.selectedTabApiKey);
  }, [props.selectedTabApiKey]);

  useEffect(() => {
    if (props.styles) {
      setStyle([...baseStyles, ...props.styles]);
    }
    setSelectedTabApiKey(props.selectedTabApiKey);
  }, []);

  return (
    <React.Fragment>
      <Pulse direction="bottom">
        <div>Top Engagements:</div>
      </Pulse>
      {
        selectedTabApiKey && topEngagement[selectedTabApiKey] && topEngagement[selectedTabApiKey].topEngagements && topEngagement[selectedTabApiKey].topEngagements.length > 0 && topEngagement[selectedTabApiKey].topEngagements.map((engagement, i) => {
          const { change, changeStyle } = getChangeValues(engagement.changePercentage);

          return (
            <div key={i} style={baseStyles.topEngagementParent}>
              <div style={{color: '#b56b22', minWidth: '25%', ...styles.topEngagementCell}}>{getDateForTopEngagement(engagement.createdDate)}</div>
              <div style={{display: 'block', width: '100%'}}>
                <div style={{float: 'left'}}>
                  <a href={engagement.link} target="_blank" rel="noopener noreferrer" style={{marginLeft: 10, textDecoration: 'none', color: '#ffffff', ...styles.topEngagementCell}}>
                    {formatText({text: engagement.message || '', num: 50})}
                  </a>
                </div>
                <div style={{float: 'right', ...changeStyle}}>
                  {change>0?'+':''}{change} %
                </div>
              </div>
            </div>
          )
        })
      }
    </React.Fragment>
  )
}

export default TopEngagements;