import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
/* import Metrics from '../components/pages/insights/insightsX/Metrics'; */

import SocialFeed from '../components/pages/insights/insightsX/SocialFeed';
import { userTypePerPage } from '../utils/constants';
import history from '../redux/manageHistory';

const InsightsContainer =  (props) =>{

    if (props.user && props.user.account) {      
      if (!userTypePerPage['insightx'].includes(props.user.account)) {
        return history.push('/dashboard');
      }
    }

    const styles = {
        parentContainer: {
            height: "100%", color: "#fff", textAlign: "center"
        }
    };

    return(
    <Row style={styles.parentContainer}>
       <Col xs="12"><SocialFeed/></Col>
       {/* <Col xs="6"><Metrics/></Col> */}
    </Row>
    );
}

export default InsightsContainer;