import React, {Component} from 'react';
import {connect} from 'react-redux';

import Dialog from 'material-ui/Dialog';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import ProfileInit from '../components/pages/profile/ProfileInit';
import ProfilePage from '../components/pages/profile/ProfilePage';
import {updateInit, updateGenre, updateLocation} from '../actions/Profile/profileAction';

class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.user.init === 0,
      neverShowAgain: false
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleNeverShowAgain = this.handleNeverShowAgain.bind(this);
    this.handleUpdateStep1 = this.handleUpdateStep1.bind(this);
    this.handleUpdateStep2 = this.handleUpdateStep2.bind(this);
  }

  handleClose = () => {
    this.setState({open: false});
    if (this.state.neverShowAgain) {
      this.props.updateInit(99);
    }
  };

  handleUpdateStep1(allGenre) {
    this.props.updateGenre(allGenre);
  }

  handleUpdateStep2(props) {
    let {city, state, zip} = props;
    this.props.updateLocation({city, state, zip});
    this.props.updateInit(99);
    this.setState({
      open: false
    })
  }

  handleNeverShowAgain(event, isInputChecked) {
    this.setState({
      neverShowAgain: isInputChecked
    });
  }

  render() {
    return (
      <div>
        <div>
          <ProfilePage
            user={this.props.user}
            location={this.props.location}
          />
        </div>
        {/* <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme)}>
          <Dialog
            title="Let's get to know you better..."
            modal={false}
            open={this.state.open}
            onRequestClose={this.handleClose}
            autoScrollBodyContent={true}
          >
            <ProfileInit
              {...this.props}
              handleClose={this.handleClose}
              handleUpdateStep1={this.handleUpdateStep1}
              handleUpdateStep2={this.handleUpdateStep2}
              neverShowAgain={this.state.neverShowAgain}
              handleNeverShowAgain={this.handleNeverShowAgain}
            />
          </Dialog>
        </MuiThemeProvider> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    brand: state.site.brand
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateInit: (init) => dispatch(updateInit(init)),
    updateGenre: (genre) => dispatch(updateGenre(genre)),
    updateLocation: (values) => dispatch(updateLocation(values))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);