import React from 'react';

const AddAsWeeklyGoalInsights = props => {
  const styles = {
    legends: {
      display: 'inline-block',
      marginRight: props.noText? 5: 20,
      color: '#000000'
    },
    legendText: {
      color: '#ffffff'
    },
    informational: {
      color: '#db849a'
    },
    white: {
      color: '#ffffff'
    },
    red: {
      color: '#ff0000'
    },
    green: {
      color: '#00ff00'
    }
  };

  return (
    <div style={styles.legends}>
      <i style={styles.green} className="fas fa-plus">
        { !props.noText && (
          <span style={styles.legendText}> Add as weekly goal</span>
        )}
      </i>
    </div>
  )
};

export default AddAsWeeklyGoalInsights;