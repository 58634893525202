import React from 'react';
import ReactEcharts from 'echarts-for-react';

const KnktChart = props => {
  console.log("knkt chart style",props.style)
  return (
    <ReactEcharts
      option={props.option}
      style={props.style}
      opts={{ renderer: 'canvas' }}
      onEvents={props.onEvents}
      notMerge={true}
    />
  );
};

export default KnktChart;