const isLengthLongerThan = (data, length) => {
  if (!data) {
    return false;
  }
  return data.length > length;
};

const isOfLength = (data, length) => {
  if (!data) {
    return false;
  }
  return data.length === length;
};

const isLengthLessThan = (data, length) => {
  if (!data) {
    return false;
  }
  return data.length < length;
};

const isEmpty = data => {
  if (!data) {
    return true;
  }
  return isOfLength(data, 0);
};

const isNotEmpty = data => {
  if (!data) {
    return false;
  }
  return isLengthLongerThan(data, 0);
};


export {
  isEmpty,
  isNotEmpty,
  isLengthLongerThan,
  isOfLength,
  isLengthLessThan
}