import axios from 'axios';
import { getApiUrl, protectedPages } from '../config/siteConfig';

const validateEmail = (email) => {
  if (email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/)) {
    return true;
  }
  return false;
}

function isValidUrl(url) {
  // Regular expression to validate URLs starting with https:// and containing a domain extension
  const urlPattern = /^https:\/\/[^\s\/]+(\.[^\s\/]+)+$/;

  return urlPattern.test(url);
}

const capitalizeFirstCharacter = (word) => {
  if (word.length < 2) {
    return word;
  }
  let first = word[0];
  let others = word.slice(1);
  return first.toUpperCase() + others;
}

const isProduction = process.env.NODE_ENV === "production";
const isDevelopement = process.env.NODE_ENV === "development";

async function callApi(api, method, data, headers) {
  try {
    return await axios({
      url: getApiUrl() + api,
      method: method,
      data: data,
      headers: {
        'token': window.localStorage.token,
        ...headers
      }
    });
  } catch (err) {
    return {err};
  }
}

const getStateKeyFromState = (states, state) => {
  return Object.keys(states).findIndex((val) => {
    return val === state;
  })
}

const getStateFromStateKey = (states, stateKey) => {
  return Object.keys(states)[stateKey];
}

const getCurrentDate = _ => {
  const now = new Date();
  return formatDate(now);
}

function formatDate(date) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return `${day} ${monthNames[monthIndex]} ${year}`;
}

function isProtectedPage(page) {
  if (protectedPages.indexOf(page) > -1) {
    return true;
  }
  return false;
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0
}

const getChangeValues = changePercentage => {
  let change = parseFloat(changePercentage);
  let up = true;
  let changeStyle = {color: '#00ff00'};
  if (!isNaN(change)) {
    if (change < 0) {
      changeStyle = {color: '#ff0000'};
      up = false;
    }
    if (change === 0) {
      changeStyle = {color: '#a9a9a9'};
    }
  } else {
    change = 0;
  }
  return {
    change,
    changeStyle,
    up
  }
};

const capitalizeFirstLetter = text => {
  let char = text.split('');
  return char[0].toUpperCase()+char.slice(1).join('');
};

const formatText = ({text, num}) => {
  if (text.length > num) {
    return text.substring(0, num) + '...';
  }
  return text;
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const checkImageFormatAndSize = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }

  if (!isLt2M) {
    message.error("Image must be smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export {
  validateEmail,
  isValidUrl,
  capitalizeFirstCharacter,
  callApi,
  getStateKeyFromState,
  getStateFromStateKey,
  getCurrentDate,
  isProtectedPage,
  isEmptyObject,
  getChangeValues,
  capitalizeFirstLetter,
  formatText,
  isDevelopement,
  isProduction,
  getBase64,
  checkImageFormatAndSize
};