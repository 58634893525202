import React from 'react';
import { Col } from 'react-bootstrap';

import Footer from '../../common/Footer';

const styles = {
  parent: {
    color: '#cccccc'
  },
  title: {
    marginTop: 20,
    textAlign: 'center'
  },
  center: {
    textAlign: 'center'
  },
  footer: {
    marginTop: 50
  }
};

const Terms = () => (
  <Col xs={{ span: 10, offset: 1}} style={styles.parent}>
    <p style={styles.title}>
      <h1>Terms</h1>
    </p>

    <p style={styles.center}>
      This Agreement was last modified on April 12, 2018.
    </p>

    <p>
      Please read these Terms of Service (the “Terms”) carefully because they govern your use of our website located
      at www.knkt.io (the “Site”) and our online platform, which enables musicians, artists, fans and music industry
      companies and professionals to connect, interact, share, discover, track and promote musicians and artists and
      their music. To make these Terms easier to read, the Site and our online platform are collectively called the
      “Services.”
    </p>

    <p>
      <strong>Agreement to Terms</strong>
      <br />
      By using our Services, you agree to be bound by these Terms. If you don’t agree to these Terms, do not use the
      Services. If you are accessing and using the Services on behalf of a company (such as your employer) or other
      legal entity, you represent and warrant that you have the authority to bind that company or other legal entity
      to these Terms. In that case, “you” and “your” will refer to that company or other legal entity.
      <br />
      <br />
      We may modify the Terms at any time, in our sole discretion. If we do so, we’ll let you know either by posting
      the modified Terms on the Site or through other communications. It’s important that you review the Terms
      whenever we modify them because if you continue to use the Services after we have posted modified Terms on the
      Site, you are indicating to us that you agree to be bound by the modified Terms. If you don’t agree to be bound
      by the modified Terms, then you may not use the Services anymore. Because our Services are evolving over time we
      may change or discontinue all or any part of the Services, at any time and without notice, at our sole
      discretion.
    </p>

    <p>
      <strong>Who May Use the Services</strong>
      <br />
      <strong>Eligibility</strong>
      <br />
      If you are 13 years or older and not barred from using the Services under applicable law, you may use the free
      features of the Services. In order to use the Services described in the “Paid Services” section below you must
      be 18 years or older, capable of forming a binding contract with KNKT, and not barred from using the Services
      under applicable law.
    </p>

    <p>
      <strong>Registration and Your Information</strong>
      <br />
      If you want to use certain features of the Services you’ll first need to register with Facebook to create a
      Facebook account (“Facebook Account”) and then register with us to create a KNKT account (“Account”). If you are
      a musician, artist, music professional or third party acting on behalf of one of the foregoing (“Artist”), you
      may also create an Account by registering with SoundCloud to create a SoundCloud account (“SoundCloud Account”)
      or by registering with Spotify to create a Spotify account (“Spotify Account”). We’ll extract from your Facebook
      Account, SoundCloud Account and/or Spotify Account, as applicable, certain personal information, such as your
      name, location information and other general information that your privacy settings on the applicable Facebook
      Account, SoundCloud Account or Spotify Account permit us to access.
      <br />
      <br />
      After you create an Account, you may also connect your Account with your other accounts such as Twitter,
      YouTube, Spotify, etc. (each, a “Third Party Account”), and we’ll extract from your Third Party Account certain
      information that your privacy settings on the Third Party Account permit us to access.
      <br />
      <br />
      By using our YouTube integration features, users are agreeing to be bound by the Youtube Terms.
    </p>

    <p>
      It’s important that you provide us with accurate, complete and up-to-date information for your Account and you
      agree to update such information, as needed, to keep it accurate, complete and up-to-date. If you don’t, we
      might have to suspend or terminate your Account. You agree that you won’t disclose your Account password to
      anyone and you’ll notify us immediately of any unauthorized use of your Account. You’re responsible for all
      activities that occur under your Account, whether or not you know about them.
    </p>

    <p>
      <strong>Using the Services</strong>
      <br />
      Through use of the Services, Account holders may build a profile page (the “Page”) and embed, post and upload
      User Content and post reviews and comments (the “Comments”) on the Page of other Account holders. You
      acknowledge that the Comments, together with your username, the thumbnail version of the photo uploaded by you
      on your Page and a link to your Page may be visible on the Services, so please exercise discretion and good
      judgment when posting Comments.
    </p>

    <p>
      <strong>Use of Account Holder Information</strong>
      <br />
      If you are an Artist that receives any Account holder information from us (including but not limited to
      Promotion Data) you agree that your use of any such information will be in a manner that is consistent with
      these Terms and the KNKT Privacy Policy, and will at all times comply with all applicable laws and regulations
      (including, but not limited to, the CAN SPAM Act and any rules and regulations governing sweepstakes, contests
      and promotions in the United States and their respective equivalent worldwide).
    </p>

    <p>
      <strong>Privacy Policy</strong>
      <br />
      Please refer to our Privacy Policy (https://www.knkt.io/privacy) for information on how we collect, use and
      disclose information from our users.
    </p>

    <p>
      <strong>Content and Content Rights</strong>
      <br />
      For purposes of these Terms: (i) “Content” means data, text, graphics, images, music, software, audio, video,
      works of authorship of any kind, and information or other materials that are posted, generated, provided or
      otherwise made available through the Services; (ii) “User Content” means any Content that Account holders
      (including you) provide to be made available through the Services, including Comments; and (iii) “Artist
      Content” means User Content provided by or on behalf of Artists. Content includes without limitation User
      Content (and Artist Content).
    </p>

    <p>
      <strong>Connecting with Artists</strong>
      <br />
      Through use of our Services you will be able to access and interact with Artist Content. Artists will be able to
      engage with you privately through the use of the Site and Services, as well as through external media such as
      email, social media, mobile devices, and any other methods if you have interacted on the Artist's page or have
      subscribed or followed that Artist on your Third Party Accounts or through interacting with Artist Content on
      the Services. KNKT is not responsible for and accepts no liability in relation to your private communication and
      sharing of information with any Artist, including via media external to the Site and Services. Please exercise
      discretion and good judgment when you submit or post User Content, when interacting with Artists and others, and
      when giving away personal or other information to Artists and others.
    </p>

    <p>
      <strong>Content Ownership, Responsibility and Removal</strong>
      <br />
      KNKT does not claim any ownership rights in any User Content and nothing in these Terms will be deemed to
      restrict any rights that you may have to use and exploit your User Content. Subject to the foregoing and with
      exception of User Content, KNKT and its licensors exclusively own all right, title and interest in and to the
      Services and Content, including all associated intellectual property rights. You acknowledge that the Services
      and Content are protected by copyright, trademark, and other laws of the United States and foreign countries.
      You agree not to remove, alter or obscure any copyright, trademark, service mark or other proprietary rights
      notices incorporated in or accompanying the Services or Content.
    </p>

    <p>
      <strong>Rights in User Content Granted by You</strong>
      <br />
      By making any User Content available through Services you hereby grant to KNKT a non-exclusive, transferable,
      sub-licensable, worldwide, royalty-free license to use, copy, modify, create derivative works based upon,
      publish, publicly display, publicly perform and distribute your User Content in connection with operating and
      providing the Services and Content to you and to other Account holders.
    </p>

    <p>
      If you have any questions about these Terms or the Services, please contact KNKT at support@knkt.io.
    </p>

    <div style={styles.footer}>
      <Footer />
    </div>
  </Col>
);

export default Terms;