import React, { Component } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';

import history from '../redux/manageHistory';

import TimelineSelector from '../components/pages/insights/TimelineSelector';
import Highlights from '../components/pages/insights/Highlights';
import SocialSection from '../components/pages/insights/SocialSection';
import KnktMap from '../components/common/charts/KnktMap';
import KnktChart from '../components/common/charts/KnktChart';
import CountryCityTable from '../components/pages/insights/social/CountryCityTable';
import SignupStepUploadImage from '../components/pages/dashboard/SignupStepUploadImage';
import SignupStepSelectGenre from '../components/pages/dashboard/SignupStepSelectGenre';
import SignupStepSelectGoal from '../components/pages/dashboard/SignupStepSelectGoal';
import SignupStepAccountManagement from '../components/pages/dashboard/SignupStepAccountManagement';
import { timeline, subCharts } from '../components/pages/insights/data/timelineConfig';
import { insightsAgeGender, insightsLocation } from '../components/pages/insights/data/insightsTimelineConfig';
import { updateGenre, updateGoal, postImage } from '../utils/userUtils';

import '../styles/Dashboard.css';

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeline: [],
      selectedTimeLine: 'sevenDay',
      locationData: {},
      insightsAgeGender: {},
      postSignup: false,
      signupStep: 1,
      selectedGenres: props.user ? [...props.user.genre] : [],
      selectedGoals: props.user && props.user.goal ? [...props.user.goal] : [],
      account: props.user ? props.user.account : 'artist',
      genreError: '',
      goalError: '',
      imageSelected: null,
      postImageErrorMessage: '',
      savingImageMessage: ''
    };

    this.getTimeLineData = this.getTimeLineData.bind(this);
    this.getAgeGender = this.getAgeGender.bind(this);
    this.getLocationData = this.getLocationData.bind(this);
    this.onTimelineUpdated = this.onTimelineUpdated.bind(this);
    this.getChartOption = this.getChartOption.bind(this);
    this.getPieSeriesData = this.getPieSeriesData.bind(this);
    this.getChartType = this.getChartType.bind(this);
    this.getPieSeries = this.getPieSeries.bind(this);
    this.isPostSignup = this.isPostSignup.bind(this);
    this.dismissModal = this.dismissModal.bind(this);
    this.signupMoveToNextStep = this.signupMoveToNextStep.bind(this);
    this.selectGenre = this.selectGenre.bind(this);
    this.validateGenre = this.validateGenre.bind(this);
    this.submitGenre = this.submitGenre.bind(this);
    this.selectGoal = this.selectGoal.bind(this);
    this.validateGoal = this.validateGoal.bind(this);
    this.submitGoal = this.submitGoal.bind(this);
    this.setImageSelected = this.setImageSelected.bind(this);
    this.submitSelectedImage = this.submitSelectedImage.bind(this);
    this.getStep1 = this.getStep1.bind(this);
    this.getStep2 = this.getStep2.bind(this);
    this.getStep3 = this.getStep3.bind(this);
    this.determineNextStep = this.determineNextStep.bind(this);
    this.submitArtistManagement = this.submitArtistManagement.bind(this);
  }

  componentDidMount() {
    this.getTimeLineData();
    this.getLocationData();
    this.getAgeGender();
    this.isPostSignup();
  }

  isPostSignup() {
    // if postSignup = true, set postSignup = true
    const url = new URL(window.location.href);
    const postSignup = url.searchParams.get('postSignup');
    if (postSignup && (postSignup === 'true' || postSignup === true)) {
      this.setState({
        postSignup: true
      })
    }
  }

  getLocationData = () => {
    this.setState({
      locationData: insightsLocation,
    });
  };

  getAgeGender = (_) => {
    this.setState({
      insightsAgeGender: insightsAgeGender,
    });
  };

  getTimeLineData = () => {
    // TODO: Data should be feteched via an API call.
    let selectedTimeLine = 'sevenDay';
    let theTimeline = timeline.map((item) => {
      if (item.default) {
        selectedTimeLine = item.key;
      }
      return { ...item, isSelected: item.default };
    });
    // TODO: find this value from the data.

    this.setState({ timeline: theTimeline, selectedTimeLine });
  };

  onTimelineUpdated = (key, e) => {
    this.setState({
      selectedTimeLine: key.key,
    });
  };

  getChartOption = (key) => {
    let { fields, title, type } = subCharts.insights[key];

    return {
      title: {
        text: title,
        left: 'center',
        top: 20,
        textStyle: {
          color: '#ccc',
        },
      },

      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },

      visualMap: {
        show: false,
        min: this.getPieSeriesData({ fields: null }).min,
        max: this.getPieSeriesData({ fields: null }).max,
        inRange: {
          colorLightness: [0.2, 0.8],
        },
      },
      series: this.getPieSeries({ title, fields, type }),
    };
  };

  getPieSeriesData = ({ fields }) => {
    const { insightsAgeGender, selectedTimeLine } = this.state;
    let result = [];
    let min = 999999;
    let max = 0;
    if (selectedTimeLine && insightsAgeGender[selectedTimeLine]) {
      let values = insightsAgeGender[selectedTimeLine];
      if (!fields) {
        fields = Object.keys(values);
      }

      fields.forEach((key) => {
        const thisValue = values[key];
        if (key.indexOf('ageGroup_') === 0) {
          key = key.replace('ageGroup_', '');
          key = key.replace('_', '-');
        }
        result.push({
          name: key,
          value: thisValue,
        });
        if (thisValue < min) {
          min = thisValue;
        }
        if (thisValue > max) {
          max = thisValue;
        }
      });
    }

    return {
      result,
      min: min === 999999 ? 0 : min,
      max: max === 0 ? 9999 : max,
    };
  };

  getChartType = (type) => {
    if (type === 'donut') {
      return 'pie';
    }
    return type;
  };

  getPieSeries = ({ title, fields, type }) => {
    return [
      {
        type: this.getChartType(type),
        name: title || '',
        itemStyle: {
          color: '#c23531',
          shadowBlur: 200,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
        labelLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.3)',
          },
          smooth: 0.2,
          length: 10,
          length2: 20,
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (idx) {
          return Math.random() * 200;
        },
        roseType: type === 'pie' ? 'radius' : '',
        label: {
          color: 'rgba(255, 255, 255, 0.3)',
        },
        radius: type === 'pie' ? '55%' : ['20%', '50%'],
        center: ['50%', '50%'],
        data: this.getPieSeriesData({ fields }).result,
      },
    ];
  };

  validateGenre = _ => {
    return (this.state.selectedGenres).length > 0;
  }

  validateGoal = _ => {
    return (this.state.selectedGoals).length > 0;
  }

  dismissModal = _ => {
    this.setState({
      postSignup: false
    })
    history.push('/dashboard');
  }

  submitArtistManagement = _ => {
    this.setState({
      postSignup: false
    })
    history.push('/dashboard');
  }

  submitGoal = async () => {
    const goalOk = this.validateGoal(this.state.selectedGoals);

    if (goalOk) {
      // submit Goal
      const updated = await updateGoal(this.state.selectedGoals);

      if (updated) {
        this.dismissModal();
      } else {
        this.setState({
          goalError: 'Failed to update goal, please try again!'
        })
      }
    } else {
      this.setState({
        goalError: 'Please select at least one Goal!'
      })
    }
  }

  submitGenre = async() => {
    const genreOk = this.validateGenre(this.state.selectedGenres);
    if (genreOk) {
      // submit Genre
      const updated = await updateGenre(this.state.selectedGenres);

      if (updated) {
        const { signupStep, account } = this.state;

        if (account === 'label' || account === 'agent' || account === 'user') {
          this.dismissModal();
        } else {
          this.setState({
            signupStep: signupStep+1
          })
        }
      } else {
        this.setState({
          genreError: 'Failed to update genre, please try again!'
        })
      }
    } else {
      this.setState({
        genreError: 'Please select at least one Genre!'
      })
    }
  }

  signupMoveToNextStep = _ => {
    const { signupStep, account } = this.state;

    if (account === 'venue' || account === 'publisher' || account === 'media' || account === 'promoter') {
      this.dismissModal();
    } else {
      this.setState({
        signupStep: signupStep+1
      })
    }
  }

  submitSelectedImage = async () => {
    const { imageSelected, account } = this.state;

    this.setState({
      savingImageMessage: 'Saving your profile image...'
    })

    // update image
    const posted = await postImage({ 
      image: imageSelected, 
      name: (new Date()).getTime(), 
      description: 'avatar', 
      tags: 'avatar', 
      altText: 'avatar'
    });

    if (posted?.dams?.image !== null) {
      if (account === 'venue' || account === 'publisher' || account === 'media' || account === 'promoter') {
        this.dismissModal();
      } else {
        this.signupMoveToNextStep();
      }
    } else {
      this.setState({
        postImageErrorMessage: 'Something went wrong! Please try again later.'
      })
    }
  }

  selectGenre = ({ type }) => {
    // clear genre error message
    this.setState({
      genreError: ''
    });

    const { selectedGenres } = this.state;

    if (selectedGenres.indexOf(type) > -1) {
      // remove genre
      const genres = [];
      selectedGenres.forEach(g => {
        if (g !== type) {
          genres.push(g);
        }
      });
      this.setState({
        selectedGenres: [...genres]
      })
    } else {
      // add genre
      this.setState({
        selectedGenres: [...selectedGenres, type]
      })
    }
  }

  selectGoal = ({ type }) => {
    // clear genre error message
    this.setState({
      goalError: ''
    });

    const { selectedGoals } = this.state;

    if (selectedGoals.indexOf(type) > -1) {
      // remove goal
      const goals = [];
      selectedGoals.forEach(g => {
        if (g !== type) {
          goals.push(g);
        }
      });
      this.setState({
        selectedGoals: [...goals]
      })
    } else {
      // add goal
      this.setState({
        selectedGoals: [...selectedGoals, type]
      })
    }
  }

  setImageSelected = image => {
    this.setState({
      imageSelected: image
    })
  }

  getStep1 = _ => {
    const { postImageErrorMessage, savingImageMessage } = this.state;
    return (
      <SignupStepUploadImage
        setImageSelected={this.setImageSelected}
        postImageErrorMessage={postImageErrorMessage}
        savingImageMessage={savingImageMessage}
      />
    )
  }

  getStep2 = _ => {
    const {  selectedGenres, genreError, selectedGoals, goalError, account } = this.state;
    if (account === 'artistManager') {
      return (
        <SignupStepAccountManagement  
          selectGoal={this.selectGoal}
          selectedGoals={selectedGoals}
          goalError={goalError}
        />
      )
    } else {
      return (
        <SignupStepSelectGenre 
          selectGenre={this.selectGenre}
          selectedGenres={selectedGenres}
          genreError={genreError}
        />
      )
    }
  }

  getStep3 = _ => {
    const { selectedGoals, goalError } = this.state;

    return (
      <SignupStepSelectGoal 
        selectGoal={this.selectGoal}
        selectedGoals={selectedGoals}
        goalError={goalError}
      />
    )
  }

  determineNextStep = ({ signupStep, imageSelected }) => {
    const { account } = this.state;
    switch(signupStep) {
      case 1: {
        if (imageSelected) {
          return <Button onClick={this.submitSelectedImage}>Submit</Button>
        } else {
          return <Button onClick={this.signupMoveToNextStep}>Skip</Button>
        }
      }
      case 2: {
        if (account === 'artistManager') {
          return <Button onClick={this.submitArtistManagement}>Skip</Button>
        } else {
          return <Button onClick={this.submitGenre}>Next</Button>
        }
      }
      case 3: {
        return <Button onClick={this.submitGoal}>Done</Button>
      }
      default: return null;
    }
  }

  render() {
    const { 
      timeline, selectedTimeLine, locationData, postSignup, 
      signupStep, imageSelected
    } = this.state;

    const styles = {
      insights: {
        padding: 30,
      },
      pageHeading: {
        display: 'none',
        boxSizing: 'boder-box',
        textTransform: 'uppercase',
        // margin: '0px 160px',

        fontFamily: 'Oxygen',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        color: '#ffffff',
        textAlign: 'center',
        fontSize: 20,
        letterSpacing: 3,
        padding: 30,
      },
      topSection: {
        // height: 250,
        maxHeight: 300,
        marginBottom: 20,
      },
      socialSection: {
        // marginTop: 20
      },
      timelineSelector: {
        // borderBottom: 'solid 1px white',
        marginBottom: 5,
      },
      knktMap: {
        paddingTop: 20,
        paddingBottom: 20,
      },
      knktMapCustom: {
        width: '100%',
      }
    };

    return (
      <div>
        <Row nogutters="true" style={{ backgroundColor: '#000000' }}>
          <Col xs={12} style={styles.pageHeading}>
            Dashboard
          </Col>
          <Col style={styles.insights}>
            <Row nogutters="true">
              <Col xs={12} style={styles.timelineSelector}>
                <TimelineSelector
                  timeline={timeline}
                  selectedTimeLine={selectedTimeLine}
                  onTimelineUpdated={this.onTimelineUpdated}
                />
              </Col>
              <Col xs={12}>
                <div style={styles.topSection}>
                  <Highlights
                    timeline={timeline}
                    selectedTimeLine={selectedTimeLine}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <div style={styles.socialSection}>
                  <SocialSection />
                </div>
              </Col>
              <Col xs={12} style={styles.knktMap}>
                <KnktMap customMapStyle={styles.knktMapCustom} />
              </Col>

              {Object.keys(locationData).length > 0 && (
                <Col xs={12} lg={8}>
                  <Row>
                    <Col xs={12} md={6}>
                      <CountryCityTable
                        locationData={locationData}
                        selectedTabApiKey={selectedTimeLine}
                        dataType="countries"
                      />
                    </Col>

                    <Col xs={12} md={6}>
                      <CountryCityTable
                        locationData={locationData}
                        selectedTabApiKey={selectedTimeLine}
                        dataType="cities"
                      />
                    </Col>
                  </Row>
                </Col>
              )}

              <Col xs={12} lg={4}>
                <Row nogutters="true">
                  <Col
                    xs={12}
                    style={{ color: '#ffffff', marginTop: 10 }}
                  >
                    <div style={{ position: 'absolute', right: 10 }}>
                      <i className="fas fa-question-circle"></i>
                    </div>
                    <div>
                      {Object.keys(subCharts.insights).map((key) => {
                        return (
                          <KnktChart
                            key={key}
                            option={this.getChartOption(key)}
                          />
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Modal 
            size='xl' 
            // style={styles.modal} 
            show={postSignup} 
            onHide={this.dismissModal}
            backdropClassName={'modal-background'}
            backdrop={'static'}
            centered
          > 
            <Modal.Body>
              { signupStep === 1 && this.getStep1() }

              { signupStep === 2 && this.getStep2() }

              { signupStep === 3 && this.getStep3() }
            </Modal.Body>

            <Modal.Footer>
              <Row>
                <Col xs={12} md={2}>
                  { this.determineNextStep({ signupStep, imageSelected }) }
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </Row>
      </div>
    );
  }
}
export default DashboardContainer;
