import React, { useState, useEffect } from 'react';

import TabHeader from './TabHeader';
import HighlightsItem from './HighlightsItem';

const HighlightsModal = props => {
  const [ selectedTab, setSelectedTab ] = useState('');
  const [ tabData, setTabData ] = useState([]);
  const [ highlightsData, setHighlightsData ] = useState([]);
  const [ propsChange, setPropsChange ] = useState('');

  useEffect(() => {
    if (props.highlightsData) {
      // setHighlightsData(props.highlightsData[selectedTab]);
      setHighlightsData(props.highlightsData.insights);
    }
  // }, [propsChange]);
  }, [props.highlightsData]);

  // initial run
  useEffect(() => {
    setPropsChange(props.change);
    let activeTab = '';
    const tabsData = props.timeline.map(item => {
      const active = item.default;
      if (active) {
        activeTab = item.key;
      }
      return item;
    });

    setTabData(tabsData);
    setSelectedTab(props.selectedTimeLine || activeTab);
    // setHighlightsData(props.highlightsData[activeTab]);
    setHighlightsData(props.highlightsData.insights);
  }, [props.change]);

  const styles = {
    highlightsContent: {
      padding: 20,
      backgroundColor: '#2c2c2c'
    }
  };

  const onTabSelected = tab => {
    setSelectedTab(tab.key);
    if (props.highlightsData) {
      // setHighlightsData(props.highlightsData[tab.key])  ;
      setHighlightsData(props.highlightsData.insights);
    }
  };

  return (
    <div>
      <TabHeader
        onFansTabSelected={onTabSelected}
        selectedFansTab={selectedTab}
        tabData={tabData}
      />
      <div style={styles.highlightsContent}>
        {
          highlightsData && highlightsData.map((hl, i) => {
            return (
              <HighlightsItem
                data={hl}
                key={i}
                selectedTab={selectedTab}
                handleDismissInsights={props.handleDismissInsights}
                inModal={true}
              />
            )
          })
        }
      </div>
    </div>
  )
};

export default HighlightsModal;