import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { genre } from '../../../utils/constants';

const SignupStepSelectGenre = props => {
  const { selectedGenres, selectGenre, genreError } = props;
  if (!selectedGenres){
    return (
      <div>Loading Genres...</div>
    )
  }
  const styles = {
    selectGenre: {
      fontSize: '150%'
    },
    genreButtons: {
      margin: 5
    },
    genreError: {
      textAlign: 'center',
      color: '#ff0000',
      marginTop: 10,
      fontSize: '1.2em'
    }
  }

  return (
    <Row>
      <Col xs={12} style={styles.selectGenre}>
        Select your favorite genre
      </Col>
      <Col xs={12}>
        {
          genre.map((gen, i) => {
            const bg = (selectedGenres.indexOf(gen) > -1) ? 'success' : 'secondary';
            return (
              <Button 
                style={styles.genreButtons}
                variant={bg}
                key={i}
                onClick={selectGenre.bind(this, { type: gen })}
              >{gen}</Button>
            )
          })
        }
      </Col>
      {
        genreError && (
          <Col xs={12} style={styles.genreError}>
            { genreError }
          </Col>
        )
      }
    </Row>
  )
}

export default SignupStepSelectGenre;