import React, { Component } from 'react';
import { Col } from 'react-bootstrap';
import {connect} from 'react-redux';
import WOW from "wow.js";
// import { FaUser } from 'react-icons/lib/fa';

import { pickArtist, fetchArtists, updateArtists, deleteArtists, addArtists } from '../actions/Admin/adminAction';
import Artist from '../components/pages/admin/Artist';

class AdminContainer extends Component {
  constructor(props) {
    super(props);

    this.selectIcon = this.selectIcon.bind(this);
  }

  componentDidMount() {
    const wow = new WOW();
    wow.init();
    this.selectIcon('artist');
  }

  selectIcon(icon) {
    this.props.pickArtist(icon);
    switch(icon) {
      case 'artist' : {
        this.props.fetchArtists();
        break;
      }
      default: {}
    }
  }

  render() {
    return (
      <div>

        <Col xs={10} xsOffset={1} sm={8} smOffset={2} className="text-center">
          <Artist {...this.props} />
        </Col>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    icon: state.admin.icon,
    artists: state.admin.artists,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    pickArtist: (icon) => dispatch(pickArtist(icon)),
    fetchArtists: _ => dispatch(fetchArtists()),
    addArtists: (artists) => dispatch(addArtists(artists)),
    updateArtists: (artists) => dispatch(updateArtists(artists)),
    deleteArtists: (artists) => dispatch(deleteArtists(artists))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer);


// <Col
//   xs={1}
//   className="text-center"
//   style={{fontSize: '2em'}}
// >
//   <div style={{paddingTop: 50, paddingBottom: 50, paddingLeft: 5, paddingRight: 5}}>
//     <div>
//       <FaUser onClick={this.selectIcon.bind(this, 'artist')} />
//     </div>
//   </div>
// </Col>