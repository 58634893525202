
const AXIS_COLOR = '#4bb1ca';
const OUT_OF_RANGE_GRAPH_COLOR = '#00c9ed';

class EChartDataModel {
    constructor({ xAxis = [], series = [] }) {

        this.xAxis = {
            data: xAxis,
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: AXIS_COLOR
                }
            }
        };

        this.yAxis = {
            splitLine: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: AXIS_COLOR
                }
            },
            axisLabel: {
                formatter: function (value, index) {
                    let result = value;

                    if (value >= 1000) {
                        result = (value / 1000).toString() + 'K';
                    }

                    return result;
                }
            }
        };

        this.visualMap = {
            show: false,
            outOfRange: {
                color: OUT_OF_RANGE_GRAPH_COLOR
            }
        };

        this.tooltip = {
            trigger: "axis"
        };

        this.series = series;
    }
}

export default EChartDataModel;