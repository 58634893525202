import { callApi } from "./utils";

const updateGenre = async (values) => {
  return await callApi('/api/v1/updateProfile', 'post', {
    genres: values
  });
}

const updateGoal = async (values) => {
  return await callApi('/api/v1/updateProfile', 'post', {
    goals: values
  });
}

const postImage = async (data) => {

  const getImageContentType = name => {
    const extension = name.split('.').pop();
    switch(extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
        return 'image/jpg';
      case 'jpeg':
        return 'image/jpeg';
      case 'gif':
        return 'image/gif';
      default:
        return 'image/jpeg';
    }
  }

  const { image, name, description, tags, altText } = data;
  const headers = {
    'Content-Type': 'multipart/form-data'
  };

  const formData = new FormData();
  const theImage = new Blob([image], { type: getImageContentType(image.name) }); 
  formData.append('image', theImage);

  return await callApi(`/api/v1/postImage?name=${name}&description=${description}&tags=${tags}&altText=${altText}`, 'post', formData, headers);
}

export {
  updateGenre,
  updateGoal,
  postImage
}