import '../../../style/ContactsTable.css'
import Card from 'react-bootstrap'

const ContactsPage = ({props}) => {
  const contacts = [
    {
      name: 'Chinmay Swain',
      email: 'cs@gmail.com',
      campaign: 'Artist Page',
      phone: '',
      location: '',
      lastMessage: '1/23/2023, 3:57 AM',
      messages: 1
    },
    {
      name: 'vig shetty',
      email: 'vigneshshetty27@gmail.com',
      campaign: 'RequestCity',
      phone: '',
      location: '',
      lastMessage: '6/4/2019, 12:13 AM',
      messages: 1
    }
  ]
  const handleRowClick = (contact) => {
    console.log("Row clicked in Contact Table", contact)
    // Open another card or navigate to another page showing that contact's information
  }
  ;

  const NavBar = () => {
    return (
      <nav className="nav-bar">
        <div className="nav-item">CAMPAIGNS</div>
        <div className="nav-item">MY CONTACTS</div>
        <div className="nav-item download">DOWNLOAD CSV</div>
      </nav>
    );
  };

  return (
    <div className="container">
      <NavBar />
      <div id="contactTable" className="table">
        <div className="row header">
          <div className="header-cell">NAME</div>
          <div className="header-cell email">EMAIL</div>
          <div className="header-cell">CAMPAIGN</div>
          <div className="header-cell">PHONE</div>
          <div className="header-cell">LOCATION</div>
          <div className="header-cell">LAST MESSAGE</div>
          <div className="header-cell">MESSAGES</div>
        </div>
        {contacts.map((contact, index) => (
          <div className="row button-row"
          key={index}
          onClick = {()=>handleRowClick(contact)}
          >
            <div className="cell">{contact.name}</div>
            <div className="cell email">{contact.email}</div>
            <div className="cell">{contact.campaign}</div>
            <div className="cell">{contact.phone}</div>
            <div className="cell">{contact.location}</div>
            <div className="cell">{contact.lastMessage}</div>
            <div className="cell">{contact.messages}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactsPage;