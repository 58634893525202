import Radium from 'radium';


const ArtistManagersPage = () => {
  const styles = {
    heading: {
      color: 'white'
    }
  };
  return (
    <>
    <h1 style={styles.heading}>This is the Artist Managers Page.</h1>
    </>
  )
}

export default Radium(ArtistManagersPage);