import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import TabMenuSelector from '../TabMenuSelector';
import TabHeader from '../TabHeader';
import TabLineHeader from '../TabLineHeader';
import KnktChart from '../../../common/charts/KnktChart';
import TopEngagements from './TopEngagements';
import CountryCityTable from './CountryCityTable';

import { igCategories } from '../data/instagramTimelineConfig';
import { fbCategories } from '../data/fbTimelineConfig';
import {
  fbvideoCategories,
  sampleFbvideoData,
  sampleFbvideoTopEngagement,
  sampleFbvideoLocation,
} from '../data/fbvideoTimelineConfig';
import { timeline, subCharts } from '../data/timelineConfig';
import { getDateForLineGraph } from '../../../../utils/calendar';

import {
  sortSocialData,
  fetchFbPageSources,
  fetchFbLocation,
  fetchFbAudience,
  fetchFbMetrics,
  fetchIgLocation,
  fetchIgMetrics,
  fetchIgAudience,
} from '../../../../utils/insights';

import '../../../../styles/SocialInsights.css';

const SocialInsights = (props) => {
  const [insightsType, setInsightsType] = useState('');
  // const [ today, setToday ] = useState((new Date()).toDateString());
  const [tabs] = useState(timeline);
  const [igTabs, setIgTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState('');
  const [selectedTabApiKey, setSelectedTabApiKey] = useState('');
  const [selectedIgTab, setSelectedIgTab] = useState('');
  const [topEngagement, setTopEngagement] = useState({});
  const [igData, setIgData] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [dataCategories, setDataCategories] = useState([]);

  const [nowData, setNowData] = useState([]);
  const [deltaData, setDeltaData] = useState([]);
  const [sevenDayData, setSevenDayData] = useState([]);
  const [twentyEightDayData, setTwentyEightDayData] = useState([]);
  const [interestedProperties, setInterestedProperties] = useState(
    []
  );
  const [showNoData, setShowNoData] = useState(false);

  useEffect(() => {
    const theInsightsType = props.social;
    setInsightsType(theInsightsType);

    const allCategories = prepareDataCategory(theInsightsType);
    prepareIgTabs(allCategories);
  }, [props.social]);

  useEffect(() => {
    if (insightsType.length > 0) {
      getLocation();
      getAudience();
      getMetrics();
    }
  }, [insightsType]);

  useEffect(() => {
    if (selectedTab.length > 0 && selectedIgTab.length > 0) {
      updateData(igData);
    }

    const apiKey = getTheTabApiKey(selectedIgTab);

    if (apiKey) {
      if (selectedTab === 'sevenDay') {
        const noData =
          sevenDayData &&
          sevenDayData[apiKey] &&
          sevenDayData[apiKey].length < 2;
        setShowNoData(noData);
      } else if (selectedTab === 'twentyEightDay') {
        const noData =
          twentyEightDayData &&
          twentyEightDayData[apiKey] &&
          twentyEightDayData[apiKey].length < 2;
        setShowNoData(noData);
      }
    }
  }, [selectedTab, selectedIgTab]);

  const getTheTabApiKey = (key) => {
    const tab = igTabs.filter((i) => i.key === key);
    if (tab.length > 0) {
      return tab[0].apiKey;
    }
    return null;
  };

  const prepareDataCategory = (theInsightsType) => {
    const fetchDataCategories = (_) => {
      switch (theInsightsType) {
        case 'instagram': {
          return igCategories;
        }
        case 'facebook': {
          return fbCategories;
        }
        case 'facebookvideo': {
          return fbvideoCategories;
        }
        default: {
          return [];
        }
      }
    };

    const allCategories = fetchDataCategories();
    setDataCategories(allCategories);
    return allCategories;
  };

  const prepareIgTabs = (allCategories) => {
    const theInterestedProperties = allCategories.map(
      (cat) => cat.apiKey
    );

    setInterestedProperties(theInterestedProperties);
    let tabs = [...allCategories];
    tabs.unshift({
      text: 'All',
      key: 'all',
    });

    setIgTabs(tabs);
  };

  const getMetrics = async (_) => {
    const getFbMetrics = async (_) => {
      return await fetchFbMetrics();
    };

    const getIgMetrics = async (_) => {
      return await fetchIgMetrics();
    };

    const fetchMetricsData = (_) => {
      switch (insightsType) {
        case 'instagram': {
          return getIgMetrics();
          // return {metrics: sampleIgData};
        }
        case 'facebook': {
          return getFbMetrics();
        }
        case 'facebookvideo': {
          return { metrics: sampleFbvideoData };
        }
        default: {
          return { metrics: {} };
        }
      }
    };

    const metrics = await fetchMetricsData();

    const theIgData = sortSocialData(metrics.metrics);
    setIgData(theIgData);

    setSelectedTab('sevenDay');
    setSelectedTabApiKey('sevenDay');
    setSelectedIgTab('all');

    // console.log('-----theIgData:', theIgData);

    updateData(theIgData);
  };

  const updateData = (theIgData) => {
    const { now, delta } = getValues({ theIgData });

    const getOneDayData = (_) => {
      let response = {};

      let allData = [];

      interestedProperties.forEach((prop, i) => {
        allData[i] = [];
      });

      let lastSevenDaysIndex = 0;

      if (!theIgData && !theIgData.createdDate) {
        return response;
      }

      if (theIgData.oneDay && theIgData.oneDay.length > 0) {
        if (theIgData.oneDay.length > 7) {
          lastSevenDaysIndex = theIgData.oneDay.length - 1 - 7;
        }

        let sevenDay = {};
        let data28 = {};

        interestedProperties.forEach((prop, j) => {
          sevenDay[prop] = [];
          data28[prop] = [];
        });

        for (let i = 0; i < theIgData.oneDay.length; i++) {
          let thisData = theIgData.oneDay[i];

          interestedProperties.forEach((prop, j) => {
            if (i > lastSevenDaysIndex) {
              sevenDay[prop].push({
                createdDate: thisData.createdDate,
                data: thisData[prop],
              });
            }

            data28[prop].push({
              createdDate: thisData.createdDate,
              data: thisData[prop],
            });
          });
        }

        response = {
          sevenDay,
          twentyEightDay: data28,
        };
      }

      return response;
    };

    setNowData(now);
    setDeltaData(delta);
    setSevenDayData(getOneDayData().sevenDay);
    setTwentyEightDayData(getOneDayData().twentyEightDay);
  };

  const getLocation = async (_) => {
    // get locations
    const getFbLocation = async (_) => {
      return await fetchFbLocation();
    };

    const getIgLocation = async (_) => {
      return await fetchIgLocation();
    };

    const fetchLocation = (_) => {
      switch (insightsType) {
        case 'instagram': {
          return getIgLocation();
          // return {location: sampleIgLocation};
        }
        case 'facebook': {
          return getFbLocation();
          // return sampleFbLocation;
        }
        case 'facebookvideo': {
          return { location: sampleFbvideoLocation };
        }
        default: {
          return { location: {} };
        }
      }
    };

    const location = await fetchLocation();
    setLocationData(location.location);
  };

  const getAudience = async (_) => {
    // get page sources and audience
    const getFbPageSources = async (_) => {
      return await fetchFbPageSources();
    };

    const fetchPageSources = (_) => {
      switch (insightsType) {
        case 'instagram': {
          // return getInstagramPageSources();
          return { pageSources: {} };
          // return {pageSources: sampleIgTopEngagement};
        }
        case 'facebook': {
          return getFbPageSources();
          // return sampleFbTopEngagement;
        }
        case 'facebookvideo': {
          return { pageSources: sampleFbvideoTopEngagement };
        }
        default: {
          return { pageSources: {} };
        }
      }
    };

    const pageSources = await fetchPageSources();

    const getFbAudience = async (_) => {
      return await fetchFbAudience();
    };

    const getIgAudience = async (_) => {
      return await fetchIgAudience();
    };

    const fetchAudience = (_) => {
      switch (insightsType) {
        case 'instagram': {
          return getIgAudience();
          // return {audience: sampleIgTopEngagement};
        }
        case 'facebook': {
          return getFbAudience();
        }
        case 'facebookvideo': {
          return { audience: sampleFbvideoTopEngagement };
        }
        default: {
          return { audience: {} };
        }
      }
    };

    let audience = await fetchAudience();
    if (audience.audience) {
      audience = audience.audience;
    }

    // need to remap age groups, because the original format is not compatible
    ['sevenDay', 'twentyEightDay', 'lifeTime'].forEach((type) => {
      if (audience[type] && audience[type].ageGroup) {
        Object.keys(audience[type].ageGroup).forEach((age) => {
          const key = age.replace('age', 'ageGroup');
          audience[type][key] = audience[type].ageGroup[age];
        });
      }
    });

    // merge audience to pageSources => result
    const result = pageSources.pageSources;
    if (audience) {
      if (audience.sevenDay) {
        result.sevenDay = {
          ...result.sevenDay,
          ...audience.sevenDay,
        };
      }
      if (audience.twentyEightDay) {
        result.twentyEightDay = {
          ...result.twentyEightDay,
          ...audience.twentyEightDay,
        };
      }
      if (audience.lifeTime) {
        result.lifeTime = {
          ...result.lifeTime,
          ...audience.lifeTime,
        };
      }
    }

    // console.log('-------result:', result);

    setTopEngagement(result);
  };

  const onTabUpdated = (key) => {
    setShowNoData(false);
    setSelectedTab(key.key);
    setSelectedTabApiKey(key.apiKey);
  };

  const onFbTabUpdated = (key) => {
    if (key && key.key === 'all') {
      setShowNoData(false);
    }
    setSelectedIgTab(key.key);
  };

  const styles = {
    insights: {
      padding: 30,
      color: '#ffffff',
    },
    backToInsights: {
      textTransform: 'uppercase',
      paddingBottom: 15,
      letterSpacing: 3,
    },
    backToInsightsLink: {
      color: '#ffffff',
      paddingRight: 10,
      textDecoration: 'none',
    },
    pageHeading: {
      boxSizing: 'border-box',
      textTransform: 'uppercase',

      fontFamily: 'Oxygen',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      color: '#ffffff',
      textAlign: 'center',
      fontSize: 20,
      letterSpacing: 3,
      padding: 30,
    },
    chartStyle: {
      height: 400,
    },
    noData: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    topEngagmentParent: {
      marginTop: 5,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  };

  const customStyle = {
    tabHeaders: {
      justifyContent: 'left',
    },
    tabHeading: {
      width: 150,
      marginTop: 5,
    },
    btnActive: {
      backgroundColor: '#1a1d24',
    },
  };

  const getDimensions = (_) => {
    let dimensions = [];
    if (selectedIgTab === 'all') {
      dimensions = dataCategories.map((cat) => cat.text);
    } else {
      if (selectedTab === 'sevenDay') {
        if (sevenDayData && sevenDayData[selectedIgTab]) {
          sevenDayData[selectedIgTab].forEach((d) => {
            let thisDate = new Date(d.createdDate);
            dimensions.push(getDateForLineGraph(thisDate));
          });
        }
      } else if (selectedTab === 'twentyEightDay') {
        if (twentyEightDayData && twentyEightDayData[selectedIgTab]) {
          twentyEightDayData[selectedIgTab].forEach((d) => {
            let thisDate = new Date(d.createdDate);
            dimensions.push(getDateForLineGraph(thisDate));
          });
        }
      }
    }

    return dimensions;
  };

  const getValues = ({ theIgData }) => {
    let dimensions;
    let theSelectedTabApiKey = selectedTabApiKey;

    if (!theSelectedTabApiKey) {
      theSelectedTabApiKey = 'sevenDay';
    }

    if (!theIgData) {
      theIgData = igData;
    }

    if (!selectedIgTab || selectedIgTab === 'all') {
      dimensions = dataCategories.map((cat) => cat.apiKey);
    } else {
      let cat = dataCategories.filter(
        (cat) => cat.key === selectedIgTab
      );
      dimensions = [cat.length > 0 ? cat[0].apiKey : ''];
    }

    let delta = [];
    let now = [];

    if (theIgData) {
      delta = dimensions.map((name) => {
        if (
          theIgData[name] &&
          theIgData[name][theSelectedTabApiKey]
        ) {
          return theIgData[name][theSelectedTabApiKey];
        } else {
          return 0;
        }
      });

      now = dimensions.map((name, i) => {
        if (theIgData[name] && theIgData[name]['lifeTime']) {
          return theIgData[name]['lifeTime'] - delta[i];
        } else {
          return 0;
        }
      });
    }

    return {
      now,
      delta,
    };
  };

  const getTabApiKey = (_) => {
    for (let i = 0; i < dataCategories.length; i++) {
      const cat = dataCategories[i];
      if (cat.key === selectedIgTab) {
        return cat.apiKey;
      }
    }
    return '';
  };

  const getPieSeriesData = ({ fields }) => {
    let result = [];
    let min = 999999;
    let max = 0;
    if (
      selectedTabApiKey &&
      topEngagement &&
      topEngagement[selectedTabApiKey]
    ) {
      let values = topEngagement[selectedTabApiKey];

      if (!fields) {
        fields = Object.keys(values);
      }

      fields.forEach((key) => {
        const thisValue = values[key];
        if (key !== 'topEngagements') {
          if (key.indexOf('ageGroup_') === 0) {
            key = key.replace('ageGroup_', '');
            key = key.replace('_', '-');
          }
          result.push({
            name: key,
            value: thisValue,
          });
          if (thisValue < min) {
            min = thisValue;
          }
          if (thisValue > max) {
            max = thisValue;
          }
        }
      });
    }

    return {
      result,
      min: min === 999999 ? 0 : min,
      max: max === 0 ? 9999 : max,
    };
  };

  const getChartType = (type) => {
    if (type === 'donut') {
      return 'pie';
    }
    return type;
  };

  const getPieSeries = ({ title, fields, type }) => {
    return [
      {
        type: getChartType(type),
        name: title || '',
        itemStyle: {
          color: '#c23531',
          shadowBlur: 200,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
        labelLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.3)',
          },
          smooth: 0.2,
          length: 10,
          length2: 20,
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function (idx) {
          return Math.random() * 200;
        },
        roseType: type === 'pie' ? 'radius' : '',
        label: {
          color: 'rgba(255, 255, 255, 0.3)',
        },
        radius: type === 'pie' ? '55%' : ['20%', '50%'],
        center: ['50%', '50%'],
        data: getPieSeriesData({ fields }).result,
      },
    ];
  };

  const getSeries = (_) => {
    let response = [];

    let data = nowData;

    const tabApiKey = getTabApiKey();

    if (selectedIgTab !== 'all') {
      if (selectedTab === 'sevenDay' && sevenDayData) {
        data = sevenDayData[tabApiKey];
      } else if (
        selectedTab === 'twentyEightDay' &&
        twentyEightDayData
      ) {
        data = twentyEightDayData[getTabApiKey()];
      }
      let records = [];
      if (data && data.length > 0) {
        records = data.map((d) => d.data);
        data = [...records];
      }
    }

    response.push({
      type: selectedIgTab === 'all' ? 'bar' : 'line',
      name: 'Existing',
      barWidth: 20,
      connectNulls: true,
      /*         itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0, color: '#982926' // color at 0% position
            }, {
              offset: 1, color: '#d76662' // color at 100% position
            }],
            global: false // false by default
          },
          barBorderRadius: [0, 0, 0, 0],
          borderWidth: 10
        }, */
      stack: 'one',
      data: data,
    });

    if (selectedIgTab === 'all') {
      response.push({
        type: selectedIgTab === 'all' ? 'bar' : 'line',
        name: 'New',
        barWidth: 20,
        itemStyle: {
          color: '#d76662', //'#fa0c04',
          barBorderRadius: [10, 10, 0, 0],
        },
        stack: 'one',
        data: deltaData,
      });
    }

    return response;
  };

  const option = {
    backgroundColor: '#1a1d24',
    xAxis: {
      axisLine: {
        onZero: true,
      },
      axisLabel: {
        show: true,
        color: '#05c2fb',
      },
      data: getDimensions(),
    },
    yAxis: {
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#4bb1ca',
        },
      },
    },
    animationDurationUpdate: 1200,
    tooltip: {
      show: true,
      showContent: true,
      triggerOn: 'mousemove',
    },
    series: getSeries(),
  };

  const getLargeSize = (_) => {
    return selectedIgTab === 'all' ? 8 : 12;
  };

  const getChartOption = (key) => {
    let { fields, title, type } = subCharts[insightsType][key];

    return {
      title: {
        text: title,
        left: 'center',
        top: 20,
        textStyle: {
          color: '#ccc',
        },
      },

      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },

      visualMap: {
        show: false,
        min: getPieSeriesData({ fields: null }).min,
        max: getPieSeriesData({ fields: null }).max,
        inRange: {
          colorLightness: [0.2, 0.8],
        },
      },
      series: getPieSeries({ title, fields, type }),
    };
  };

  const showFacebookVideoLink = (_) => {
    let first = { text: 'page insights', link: '/insights/fb' };
    let second = {
      text: 'video insights',
      link: '/insights/fbvideo',
    };
    switch (insightsType) {
      case 'facebook': {
        break;
      }
      case 'facebookvideo': {
        first = { text: 'video insights', link: '/insights/fbvideo' };
        second = { text: 'page insights', link: '/insights/fb' };
        break;
      }
      default:
        return insightsType;
    }

    return (
      <React.Fragment>
        <span>
          facebook (
          <strong
            style={{
              textShadow: '2px 2px 3px red',
              borderBottom: '2px solid #ffffff',
            }}
          >
            {first.text}
          </strong>{' '}
          /{' '}
        </span>
        <span>
          <Link to={second.link} style={styles.backToInsightsLink}>
            {second.text})
          </Link>
        </span>
      </React.Fragment>
    );
  };

  return (
    <div>
      <Row nogutters="true" style={{ display: 'block' }}>
        <Col xs={12} style={styles.pageHeading}>
          {insightsType === 'facebookvideo'
            ? 'facebook video'
            : insightsType}
        </Col>
        <Col style={styles.insights}>
          <Row>
            <Col style={styles.backToInsights}>
              <Link to="/dashboard" style={styles.backToInsightsLink}>
                Dashboard
              </Link>
              <span style={styles.backToInsightsLink}>
                <i className="fas fa-caret-right"></i>
              </span>
              {showFacebookVideoLink()}
            </Col>
            <Col xs={12} md={12}>
              <TabMenuSelector>
                <TabLineHeader
                  onFansTabSelected={onTabUpdated}
                  selectedFansTab={selectedTab}
                  tabData={tabs}
                  customStyle={customStyle}
                />
              </TabMenuSelector>
            </Col>
            <Col xs={12} md={12}>
              <Row nogutters="true">
                <Col xs={12} lg={12}>
                  <TabHeader
                    onFansTabSelected={onFbTabUpdated}
                    selectedFansTab={selectedIgTab}
                    tabData={igTabs}
                    customStyle={customStyle}
                  />
                </Col>
              </Row>
              <Row nogutters="true">
                <Col xs={12} lg={getLargeSize()}>
                  <Row nogutters="true">
                    {insightsType && nowData && nowData.length > 0 && (
                      <Col xs={12}>
                        <div
                          style={{
                            backgroundColor: '#1a1d24',
                            overflow: 'hidden',
                            color: '#ffffff',
                            paddingTop: 20,
                          }}
                        >
                          {showNoData ? (
                            <div
                              style={{
                                ...styles.chartStyle,
                                ...styles.noData,
                              }}
                            >
                              No change in data during this timeframe!
                            </div>
                          ) : (
                            <KnktChart
                              option={option}
                              style={styles.chartStyle}
                              opts={{ renderer: 'svg' }}
                              onEvents={{}}
                            />
                          )}
                        </div>
                      </Col>
                    )}

                    {locationData &&
                      Object.keys(locationData).length > 0 && (
                        <Col xs={12}>
                          <Row>
                            <Col xs={12} md={6}>
                              <CountryCityTable
                                locationData={locationData}
                                selectedTabApiKey={selectedTabApiKey}
                                dataType="countries"
                              />
                            </Col>

                            <Col xs={12} md={6}>
                              <CountryCityTable
                                locationData={locationData}
                                selectedTabApiKey={selectedTabApiKey}
                                dataType="cities"
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                  </Row>
                </Col>

                {selectedIgTab === 'all' &&
                  selectedTabApiKey &&
                  topEngagement &&
                  topEngagement[selectedTabApiKey] && (
                    <Col xs={12} lg={4}>
                      <Row nogutters="true">
                        <Col xs={12} className="topEngagement">
                          <Row nogutters="true">
                            <Col xs={12}>
                              <div
                                style={{
                                  border: '1px solid #295759',
                                  backgroundColor: '#1a1d24',
                                  color: '#ffffff',
                                  padding: 5,
                                }}
                              >
                                <TopEngagements
                                  selectedTabApiKey={
                                    selectedTabApiKey
                                  }
                                  topEngagement={topEngagement}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          xs={12}
                          style={{ color: '#ffffff', marginTop: 10 }}
                        >
                          <div
                            style={{
                              position: 'absolute',
                              right: 10,
                            }}
                          >
                            <i className="fas fa-question-circle"></i>
                          </div>
                          <div>
                            {Object.keys(subCharts[insightsType]).map(
                              (key, i) => {
                                return (
                                  <KnktChart
                                    key={i}
                                    option={getChartOption(key)}
                                  />
                                );
                              }
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SocialInsights;
