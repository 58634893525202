import React, { Fragment, useState, useEffect } from 'react';
import Radium from 'radium';
const styles = {
    SearchBarInput: {
        borderRadius: '10rem',
        backgroundColor: 'rgb(22 21 21)',
        width: '50%',
        minWidth: '300px',
        height: '40px',
        borderStyle: 'none',
        color: '#ffffff',
        paddingLeft: '1rem',
        fontSize: '1rem',
        transition: 'all 300ms ease',
        ":focus": {
            outline: 'none',
            // boxShadow: '0px 3px 3px -2px rgb(255 0 0), 0px 3px 4px 0px rgb(255 0 0), 0px 1px 8px 0px rgb(255 0 0)',
            boxShadow: "rgb(0 123 255) 0px 0px 20px -1px",
            width: '75%',
        }  
    },
}

const SearchBar = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [dataSet, setDataSet] = useState([]);

    const handleChange = ev => {
        setSearchTerm(ev.target.value);
        (ev.target.value) ? props.isFiltered(true) : props.isFiltered(false);
    }

    useEffect(() => {
        setDataSet(props.data)
        props.isFiltered(false);
    }, [])

    useEffect(() => {
        // console.log(`The dataSet in SearchBar: ${dataSet}`);
        const results = dataSet.filter(item => {
            //item.text.toLowerCase().includes(searchTerm) || item.platform.toLowerCase().includes(searchTerm)
            return Object.keys(item).some(key => {
                return typeof item[key] === "string" && item[key].toLowerCase().includes(searchTerm.toLowerCase());
                // if (typeof item[key] === "string") return item[key].toLowerCase().includes(searchTerm.toLowerCase());
            })
        }
        )
        setSearchResults(results);
        props.getResult(results);

    }, [searchTerm,dataSet]);

    return (
        <div>
            <input
                style={styles.SearchBarInput}
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleChange}
            />
        </div>
    )
}

export default Radium(SearchBar);