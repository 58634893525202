const platfroms = [
  {
    name: 'FB',
    color: '#6676f5',
    value: {
      fans: 10,
      plays: 10
    }
  },
  {
    name: 'Youtube',
    color: '#ff44a4',
    value: {
      fans: 86.4,
      plays: 10
    }
  },
  {
    name: 'Instagram',
    color: '#ffeb1e',
    value: {
      fans: 72.4,
      plays: 10
    }
  },
  {
    name: 'Twitter',
    color: '#26cad3',
    value: {
      fans: 20,
      plays: 10
    }
  },
  {
    name: 'Apple Music',
    color: '#00f3a7',
    value: {
      fans: 83,
      plays: 10
    }
  }
];

const chartConfig = {
  type: 'bar',
  barGap: '130%',
  barWidth: 25,
  label: {
    show: true,
    formatter: '{a}',
    position: 'bottom'
  },
  // silent: true
};

const itemStyle = {
  barBorderRadius: 50
};

const getDimensions = _ => {
  return platfroms.map(plat => plat.name);
};

const getSeries = _ => {
  return [...Array(5)].map((n, i) => {
    return {
      ...chartConfig,
      itemStyle: {
        ...itemStyle,
        color: platfroms[i].color
      }
    }
  })
};

const getValues = type => {
  let obj = {};
  platfroms.forEach((plat, i) => {
    obj[plat.name] = plat.value[type];
  });
  return obj;
};

const mockData = {
  fans: {
    dataset: {
      dimension: ['Platform', ...getDimensions()],
      source: [
        {
          'Platform': 'Likes',
          ...getValues('fans')
        }
      ]
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: "#4bb1ca"
        }
      },
      axisLabel: {
        show: false
      }
    },
    yAxis: {
      splitLine: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: "#4bb1ca"
        }
      }
    },
    animationDurationUpdate: 1200,
    tooltip: {
      show: true,
      showContent: true,
      triggerOn: 'mousemove'
    },
    series: [
      ...getSeries()
    ]
  },
  plays: {
    dataset: {
      dimension: ['Platform', ...getDimensions()],
      source: [
        {
          'Platform': 'Likes',
          ...getValues('plays')
        }
      ]
    },
    xAxis: {
      type: 'category',
      axisLine: {
        lineStyle: {
          color: "#4bb1ca"
        }
      },
      axisLabel: {
        show: false
      }
    },
    yAxis: {
      splitLine: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: "#4bb1ca"
        }
      }
    },
    animationDurationUpdate: 1200,
    tooltip: {
      show: true,
      showContent: true,
      triggerOn: 'mousemove'
    },
    series: [
      ...getSeries()
    ]
  }
};

export { mockData };